import React, { useEffect, useState, DragEvent } from 'react';
import './NewFileModal.css';
import { RootState, useAppDispatch } from '../../store';
import { fetchFileTypes, fetchStructure } from '../../store/actions/FolderAction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import GlobalDropdown, { DropdownOption } from '../shared/GlobalDropdown';
import { hideModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createNewDocument } from '../../store/actions/DocumentsAction';
import { FolderProps } from './Files';
import { ICaseItem } from '../Marketplace/Interfaces/ICaseItem';
import EmailTagsInput from './EmailTagInput';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { hideSidePanel } from '../../store/actions/sidePanelActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { getUserType } from '../../store/actions/ChatAction';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';

export interface NewFileData {
  folderId: string;
  fileName: string;
  fileType: 'Contract' | 'Legal Document' | 'General' | 'Template';
  file: File | undefined;
  additionalFields: {
    PartiesInvolved?: string[];
    StartDate?: string;
    EndDate?: string;
    Approvers?: string[];
    Assignees?: string[];
    SharedWith?: string[];
    LegalType?: string;
    CaseID?: string;
    Expiry?: string;
  };
}

interface NewFileModalProps {
  folders: FolderProps[] | undefined;
  onSave: (data: NewFileData) => void;
  currentUserId: string;
  preSelectedFolderId?: string;
}

const NewFileModal: React.FC<NewFileModalProps> = ({ folders, onSave, currentUserId, preSelectedFolderId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const fileTypes = useSelector((state: RootState) => state.folders.fileTypes);
  const structure = useSelector((state: RootState) => state.folders.structure);
  const [selectedFileType, setSelectedFileType] = useState<'Contract' | 'Legal Document' | 'General' | 'Template'>('General');
  const caseList = useSelector((root: RootState) => root.case.caseList);
  const [file, setFile] = useState<File | null>(null);
  const userType = getUserType();

  useEffect(() => {
    dispatch(fetchFileTypes());
  }, [dispatch]);

  const formattedCaseOptions = caseList && caseList
    .filter((caseItem: ICaseItem) => caseItem.Status === "Ongoing")
    .map((caseItem: ICaseItem) => ({
      label: `${caseItem.CaseType} - ${caseItem.MaxBudget}`,
      id: caseItem.CaseID
    }));

  const getValidationSchema = () => {
    let schemaFields = {
      FolderID: Yup.string().required(t('Please select a folder')),
      Title: Yup.string().required(t('File title/name required')),
    };

    return Yup.object().shape(schemaFields);
  };
  const validationSchema = getValidationSchema();

  const formik = useFormik({
    initialValues: {
      FolderID: '',
      FolderName: '',
      Title: '',
      Content: '',
      FileType: 'General' as 'Contract' | 'Legal Document' | 'General' | 'Template',
      PartiesInvolved: [],
      StartDate: '',
      EndDate: '',
      Approvers: [],
      Assignees: [],
      SharedWith: [],
      LegalType: '',
      CaseID: '',
      Expiry: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const additionalFields = {
        PartiesInvolved: sanitizeEmails(values.PartiesInvolved),
        StartDate: values.StartDate,
        EndDate: values.EndDate,
        Approvers: sanitizeEmails(values.Approvers || []),
        Assignees: sanitizeEmails(values.Assignees || []),
        SharedWith: sanitizeEmails(values.SharedWith || []),
        LegalType: values.LegalType,
        CaseID: values.CaseID,
        Expiry: values.Expiry || '',
      };
  
      const newFileData: NewFileData = {
        folderId: preSelectedFolderId ? preSelectedFolderId : values.FolderID,
        fileName: values.Title,
        fileType: values.FileType,
        file: file || undefined,
        additionalFields,
      };

        await handleConfirmCreateFile(newFileData);
        formik.resetForm(); 
        setFile(null);
        dispatch(hideModal());
        dispatch(hideSidePanel());
    }
  });
  
  const buildFolderOptions = (folders: FolderProps[]): DropdownOption[] => {
    return folders.map(folder => ({
      id: folder.FolderID.toString(),
      label: folder.FolderName,
      subOptions: folder.SubFolders && folder.SubFolders.length > 0 ? buildFolderOptions(folder.SubFolders) : undefined
    }));
  };

  const folderOptions: DropdownOption[] = buildFolderOptions(structure);

  const handleFolderChange = (selectedId: string) => {
    const findFolder = (folders: FolderProps[], id: string): FolderProps | undefined => {
      for (let folder of folders) {
        if (folder.FolderID.toString() === id) return folder;
        if (folder.SubFolders) {
          const found = findFolder(folder.SubFolders, id);
          if (found) return found;
        }
      }
      return undefined;
    };

    const selectedFolder = findFolder(structure, selectedId);
    if (selectedFolder) {
      formik.setFieldValue('FolderName', selectedFolder.FolderName);
      formik.setFieldValue('FolderID', selectedFolder.FolderID);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0]);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFileTypeSelect = (fileType: 'Contract' | 'Legal Document' | 'General' | 'Template') => {
    setSelectedFileType(fileType);
    formik.setFieldValue('FileType', fileType);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const fileTypeIcons = {
    Contract: 'fas fa-file-contract',
    Legal_Document: 'fas fa-gavel',
    General: 'fas fa-file-alt',
    Template: 'fas fa-file-invoice',
  };

  const sanitizeEmails = (emails: string[]) => {
    return emails.map(email => email.replace(/^['"]|['"]+$/g, '').trim());
  };

  const [isFolderSelectionDisabled, setIsFolderSelectionDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (preSelectedFolderId && folders) {
      // const selectedFolder = folders.find(folder => folder.FolderID.toString() === preSelectedFolderId);
      formik.setFieldValue('FolderID', folders[0].FolderID.toString());
      formik.setFieldValue('FolderName', folders[0].FolderName);
      setIsFolderSelectionDisabled(true);
      // if (selectedFolder) {
      // }
    }
  }, [preSelectedFolderId]);  

  const handleConfirmCreateFile = async (newFileData: NewFileData) => {
    const { folderId, fileName, fileType, file, additionalFields } = newFileData;
  
    if (!userType) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: t('Something went wrong, please try again.'),
        showModal: true,
        duration: 3000

      }))
      return;
    }
  
    let additionalFieldsData: { [key: string]: string | string[] } = {};
    switch (fileType) {
      case 'Contract':
        additionalFieldsData = {
          FileName: fileName,
          PartiesInvolved: additionalFields.PartiesInvolved || [],
          ContractStart: additionalFields.StartDate || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          Expiry: additionalFields.Expiry || '',
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      case 'Legal Document':
        additionalFieldsData = {
          FileName: fileName,
          LegalType: additionalFields.LegalType || '',
          CaseID: additionalFields.CaseID || '',
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      case 'General':
      case 'Template':
        additionalFieldsData = {
          FileName: fileName,
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      default:
        console.error('Unknown file type:', fileType);
        return;
    }
  
    try {
      const resp: any = await dispatch(createNewDocument(fileType, folderId.toString(), file, additionalFieldsData));
      if (resp) {
        dispatch(fetchStructure());
        dispatch(showFeedbackModal({
          showModal: true,
          message: t('File created successfully'),
          modalType: 'success',
          duration: 3000
        }));
      }
    } catch (error) {
      console.error("Error creating document:", error);
      dispatch(showFeedbackModal({
        showModal: true,
        message: t('Failed to create file'),
        modalType: 'error',
        duration: 3000
      }));
    }
  };

  return (
    <div className='form-wrapper--document'>
      <form onSubmit={formik.handleSubmit} className="modal__form">
        <h2 className="modal__title">{t('Create New File')}</h2>
        <div className="file-type-buttons">
          {fileTypes.map((type: any) => (
            <button
              type="button"
              key={type.FileType}
              className={`file-type-button ${selectedFileType === type.FileType ? 'selected' : ''}`}
              onClick={() => handleFileTypeSelect(type.FileType as 'Contract' | 'Legal Document' | 'General' | 'Template')}
            >
              <i className={fileTypeIcons[type.FileType as keyof typeof fileTypeIcons]}></i>
              <span>{t(`${type.FileTypeName}`)}</span>
            </button>
          ))}
        </div>
        <label className="modal__label">
          <p>{t('File Name')}</p>
          <input
            className="modal__input"
            type="text"
            value={formik.values.Title}
            onChange={formik.handleChange('Title')}
            placeholder={t('Enter file name')}
          />
          {formik.errors.Title && formik.touched.Title ? (
            <span style={{ color: 'red', fontSize: '0.8rem' }}>{formik.errors.Title}</span>
          ) : null}
        </label>

        {/* Conditional Fields Based on File Type */}
        {formik.values.FileType === 'Contract' && (
          <>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Parties Involved')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.PartiesInvolved}
                placeholder={t('Enter parties involved')}
                onChange={(tags) => formik.setFieldValue('PartiesInvolved', tags)}
              />
            </label>
            <div className='document-form--row'>
              <label className="modal__label">
                <p>{t('Start Date')}</p>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDateTimePicker
                       value={formik.values.StartDate ? dayjs(formik.values.StartDate) : null}
                       onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                          formik.setFieldValue('StartDate', newValue.format("YYYY-MM-DD HH:mm:ss"));
                        }
                      }}
                       minDateTime={dayjs()}
                       width='100%'
                                            />
                  </LocalizationProvider>
                </ThemeProvider>
              </label>
              <label className="modal__label">
                <p>{t('End Date')}</p>
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDateTimePicker
                        value={formik.values.EndDate ? dayjs(formik.values.EndDate) : null}
                       onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                          formik.setFieldValue('EndDate', newValue.format("DD.MM.YYYY"));
                        }
                      }}
                       minDateTime={dayjs()}
                       width='100%'
                          />
                  </LocalizationProvider>
                </ThemeProvider>
              </label>
            </div>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Approvers')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.Approvers}
                placeholder={t('Enter approvers')}
                onChange={(tags) => formik.setFieldValue('Approvers', tags)}
              />
            </label>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Assignees')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.Assignees}
                placeholder={t('Enter assignees')}
                onChange={(tags) => formik.setFieldValue('Assignees', tags)}
              />
            </label>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Shared With')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.SharedWith}
                placeholder={t('Enter emails to share with')}
                onChange={(tags) => formik.setFieldValue('SharedWith', tags)}
              />
            </label>
          </>
        )}

        {formik.values.FileType === 'Legal Document' && (
          <>
            <label className="modal__label">
              <p>{t('Legal Type')}</p>
              <input
                className="modal__input"
                type="text"
                value={formik.values.LegalType}
                onChange={formik.handleChange('LegalType')}
                placeholder={t('Enter legal type')}
              />
            </label>
            <label className="modal__label">
              <p>{t('Case')}</p>
              <GlobalDropdown
                options={formattedCaseOptions}
                onSelect={(id) => formik.setFieldValue("CaseID", id)}
                selectedLabel={formattedCaseOptions.find((option: any) => option.id === formik.values.CaseID)?.label || ''}
                style={{ width: '100%' }}
                name='CaseID'
              />
            </label>
            <label className="modal__label">
              <div className='label-icon--row'>
                <p>{t('Shared With')}</p>
                <i title={t('Please enter emails and separate by comma.')} className='fa fa-exclamation-circle'></i>
              </div>
              <EmailTagsInput
                value={formik.values.SharedWith}
                placeholder={t('Enter emails to share with')}
                onChange={(tags) => formik.setFieldValue('SharedWith', tags)}
              />
            </label>
          </>
        )}

        <label className="modal__label">
          <p>{t('Deadline')}</p>
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomDateTimePicker
                        value={formik.values.Expiry ? dayjs(formik.values.Expiry) : null}
                        onChange={(newValue: Dayjs | null) => {
                          if (newValue) {
                            formik.setFieldValue('Expiry', newValue.format("YYYY-MM-DD HH:mm:ss"));
                          }
                        }}
                       minDateTime={dayjs()}
                       width='100%'
                          />
            </LocalizationProvider>
          </ThemeProvider>
        </label>


        <label className="modal__label">
  <p>{t('Folder')}</p>
  {preSelectedFolderId ? (
    <div className="selected-folder">
      <p>{formik.values.FolderName}</p>
    </div>
  ) : (
    <div className="newfile-dropdown">
      <GlobalDropdown
        options={folderOptions}
        onSelect={handleFolderChange}
        selectedLabel={formik.values.FolderName}
        placeholder={t('Select a folder')}
        style={{ width: '100%' }}
        headerStyle={{ width: '95%' }}
      />
      {formik.errors.FolderName && formik.touched.FolderName ? (
        <span style={{ color: 'red' }}>{formik.errors.FolderName}</span>
      ) : null}
    </div>
  )}
</label>


        <label className="modal__label">
          <p>{t('Import File Content')}</p>
          <input
            id="fileUploadInput"
            type="file"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <div
            className="file-upload-dropzone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById('fileUploadInput')?.click()}
          >
            {file ? (
              <div className="uploaded-file--docs">
                <span>{file.name}</span>
                <button type="button" onClick={() => setFile(null)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            ) : (
              <p>{t('Drag and drop a file here or click to upload')}</p>
            )}
          </div>
        </label>

        <div className="modal__buttons">
          <button type="submit" className="primary-button">
            {t('Create')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewFileModal;
