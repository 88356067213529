import axios, { AxiosError } from "axios";
import { AppThunk, FETCH_CHATGROUPS_SUCCESS, FETCH_CONTRACTS_SUCCESS, SET_CHAT_GROUP_ID, SET_DOCUMENT_VISIBILITY, SET_FOLDER_VISIBILITY, SET_SELECTED_MODEL } from "../types";
import { ErrorResponse } from "./SignInAction";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { useAppDispatch } from "..";
import { Dispatch } from "redux";

export const getUserID = () => {
  const userData = localStorage.getItem("token") || sessionStorage.getItem("token");
  // const userData = useSelector((state: RootState) => state);
  if (userData) {
    return JSON.parse(userData).userId;
  }
  return null;
};
export const getUserType = () => {
  const userData = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (userData) {
    return JSON.parse(userData).userType;
  }
  return null;
}

export const getKycStatus = () => {
  const userData = localStorage.getItem("token") || sessionStorage.getItem("token");
  if (userData) {
    return JSON.parse(userData).kycStatus;
  }
  return null;
}

export interface SomeInputType {
  LCMID: string;
  UserID: string;
  Section: string;
  SectionData: string;
  UserType: string
}


export interface ApiResponse {
  ConfirmMessage: string;
  DataID: string;
  NextSection: string;
  Output: string;
  Section: string;
  SectionData: string;
  UserFullName: string;
  UserID: string;
  LCM?: any;
}

export interface AIModel {
  model_content: string;
  model_display_name: string;
  model_id: string;
  model_name: string;
  model_role: string;
}


export interface ServiceAPIResponse {
  LawResearchResponse?: string;
  ContractAnalysisResponse?: string;
  ContractGeneratorResponse?: string;
  CaseResearchResponse?: string;
  ChatResponse?: string;
  ChatGroupID?: string; // New property
  ChatID?: string; // New property
  ChatTitle?: string;    // New property
  ContractGenerationResponse?: string
  message?: string;
}

export interface ChatGroupData {
  AIType: string,
  ChatGroupID: string,
  ChatTitle: string,
  CreationDate: string,
  LastModified: string,
  UserID: string,
  id: string,
  status: string
}


/**
 * Call the Chat API with a message and optionally a file.
 *
 * @param {string} prompt - The message to be sent to the API.
 * @param {number} max_tokens - Maximum tokens for the response.
 * @param {File} [file] - An optional file to be sent to the API.
 * @returns {Promise<any>} - The API response.
 */

export const callChatAPI = (
  prompt: string,
  max_tokens: number,
  file?: File
) => {
  return async (dispatch: any) => {

  try {
    const formData = new FormData();
    const userID = getUserID();
    const userType = getUserType();
    if (userID) {
      formData.append('UserID', userID);
    }
    if (userType) {
      formData.append('UserType', userType);
    }
    
    if (file) {
      formData.append('document', file);
    }
    
    
    const response = await apis({
      method: "POST",
      url: "ponsai/gpt",
      data: formData
    })
    if (response && response.status === 200) {
      return response.data;
    }
    else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            modalType: 'error',
            duration: 3000,
            message: (erroMessage.message || 'An unknown error occurred'),
            showModal: true
          }))
        } else {
          dispatch(showFeedbackModal({
            modalType: 'error',
            message: ('Network error, please try again'),
            showModal: true,
            duration: 3000
          }))
        }
      }
    }

  } catch (error) {
    console.error('Error calling Azure Function:', error);

    if (error instanceof AxiosError) {
      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
    }
    throw error;
  }
  }
}



export const setSelectedModel = (model: string) => {
  localStorage.setItem('selectedModel', model);
  // localStorage.setItem('chatGroupID', '');
  return {
    type: SET_SELECTED_MODEL,
    payload: model,
  };
};


export const setChatGroupIDStore = (chatGroupID: string) => {
  localStorage.setItem('chatGroupID', chatGroupID);
  return {
    type: SET_CHAT_GROUP_ID,
    payload: chatGroupID,
  };
};

export const callLCMAPI = async (data: SomeInputType): Promise<ApiResponse> => {
  try {
    const response = await apis({
      method: "POST",
      url: "ponsai/lcm/generateLCM",
      data: data
    });

    return response.data;
  } catch (error) {
    console.error('Error calling LCM API:', error);
    throw error;
  }
};


export const callServiceAPI = async (service: string, data: any, file1: any, file2: any, groupID: string = ''): Promise<ServiceAPIResponse> => {
  let endpoint;
  let payload = new FormData();
  // payload.append('UserID', getUserID());
  payload.append("UserType", getUserType());

  switch (service) {
    case 'LawResearch':
      endpoint = 'ponsai/lawResearch';
      payload.append('GroupID', groupID);
      if (file1) {
        payload.append('Document', file1);
      }
      break;
    case 'CaseResearch':
      endpoint = 'ponsai/caseResearch';
      payload.append('GroupID', groupID);
      if (file1) {
        payload.append('Document', file1);
      }
      break;
    case 'ContractAdvisor':
      endpoint = 'ponsai/contractAnalysis';
      payload.append('GroupID', groupID);
      if (file1) {
        payload.append('Document1', file1);
      }
      if (file2) {
        payload.append('Document2', file2);
      }
      break;
    case 'ContractGenerator':
      payload.append('GroupID', groupID);
      endpoint = 'ponsai/contractGenerator';
      break;
    case 'CompanyAgent':
      endpoint = 'companyai/chat';
      break;
    case 'CustomAgent':
      endpoint = 'customai/chat'; 
      break;
    default:
      throw new Error('Service not recognized');
  }

  Object.keys(data).forEach(key => {
    payload.append(key, data[key]);
  });

  try {
    const response = await apis({
      method: 'POST',
      url: endpoint,
      data: payload
    });
    return response.data;
  } catch (error: any) {
    console.error(`Error calling ${endpoint}:`, error);
    throw error;
  }
};


export interface ChatGroupData {
  AIType: string,
  ChatGroupID: string,
  ChatTitle: string,
  CreationDate: string,
  LastModified: string,
  UserID: string,
  id: string,
  status: string
}

export const chatGroupApi = (UserID: string, AIType: string, UserType: string): AppThunk => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ponsai/getChatGroups",
      data: {
        UserID,
        AIType,
        UserType
      }
    })

    if (response && response.status === 200) {
      dispatch({
        type: FETCH_CHATGROUPS_SUCCESS,
        payload: response.data
      });
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }

  } catch (err) {
    throw err;
  }

}


// Modify your chatHistoryApi to correctly type the return value
export const chatHistoryApi = (
  UserID: string,
  AIType: string,
  GroupID: string,
  UserType: string,
  signal: AbortSignal
): AppThunk<Promise<{ Prompt: string; Reply: string }[]>> => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ponsai/getChatHistory",
      data: { UserID, AIType, GroupID, UserType },
      signal: signal  // Pass the abort signal to fetch
    });
    if (response && response.status === 200) {
      return response.data;
    } else {
      throw new Error('Unable to fetch chat history');
    }
  } catch (err: any) {
    if (err.name !== 'AbortError') {
      console.error('API error:', err);
    }
    throw err;
  }
};

export const deleteChatGroup = (GroupID: string,): AppThunk => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ponsai/deleteChatGroup",
      data: {
        UserID: getUserID(),
        GroupID,
        UserType: getUserType()
      }
    })

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Chat deleted',
        modalType: 'success',
        duration: 3000
      }));
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }

  } catch (err) {
    throw err;
  }
}


export const submitChatFeedback = (
  ChatID: string,
  GroupID: string,
  Like: string,
  Dislike: string,
  Comment: string
): AppThunk => async (dispatch) => {
  try {
    let response = await apis({
      method: "POST",
      url: "ponsai/chatFeedback",
      data: {
        UserID: getUserID(),
        UserType: getUserType(),
        ChatID,
        GroupID,
        Like,
        Dislike,
        Comment
      }
    });

    if (response && response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Feedback submitted',
        modalType: 'success',
        duration: 3000
      }));
      return response.data;
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse;
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (err) {
    throw err;
  }
};


export const setFolderVisibility = (isVisible: boolean) => ({
  type: SET_FOLDER_VISIBILITY,
  payload: isVisible,
});

export const setDocumentVisibility = (isVisible: boolean) => ({
  type: SET_DOCUMENT_VISIBILITY,
  payload: isVisible,
});

export const fetchContracts = () => async (dispatch: any) => {
  try {
    const response = await apis({
      method: "GET",
      url: "contract/types",
    })
    if (response.status === 200) {
      dispatch({
        type: FETCH_CONTRACTS_SUCCESS,
        payload: response.data
      });
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: (error.response.data.message || 'An unknown error occurred'),
        modalType: 'error',
        duration: 3000
      }));
    } else {
      dispatch(showFeedbackModal({
        showModal: true,
        message: ('Network error, please try again'),
        modalType: 'error',
        duration: 3000
      }));
    }
  }
};


export const getAiModels = () => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "GET",
        url: "ponsai/getAIModels",
      })
      if (response && response.status === 200) {
        return response.data
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (err) {
      console.error(err)
    }

  }
}

export const deleteChatHistory = (GroupID: string, UserID: string, UserType: string) => {
  return async (dispatch: any) => {
    try {
      const response = await apis({
        method: "POST",
        url: "ponsai/deleteChatHistory",
        data: {
          GroupID,
          UserID,
          UserType
        }
      })
      if (response && response.status === 200) {
        return response.data
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (err) {
      console.error(err)
    }

  }
}