import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../ChatBot.css'; 
import { RootState, useAppDispatch } from '../../../store';
import { deleteBlobDocument, deleteIndex, getBlobDocuments, getIndexInfo, reRunIndex, uploadAdditionalCompanyFiles, uploadCompanyFiles } from '../../../store/actions/CompanyAgentAction';
import { ClipLoader } from 'react-spinners';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import { FileProps } from '../../../pages/DocumentsPage';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { fetchDocuments } from '../../../store/actions/DocumentsAction';

interface UploadedFile {
  fileName: string;
  documentURL: string;
  title?: string;
  file?: File;
}

const CompanyAgent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [imageExist, setimageExist] = useState(false);
  const indexExists = useSelector((state: RootState) => state.companyAgent.indexExists);
  const indexInfo = useSelector((state: RootState) => state.companyAgent.indexInfo);
  const blobDocuments = useSelector((state: RootState) => state.companyAgent.blobDocuments);
  const loading = useSelector((state: RootState) => state.companyAgent.loading);
  const userType = getUserType();
  const polling = useSelector((state: RootState) => state.companyAgent.polling);
  const statusMessage = useSelector((state: RootState) => state.companyAgent.statusMessage);

  const handleDeleteClick = (fileName: string) => {
    dispatch(showModal({
      type: 'confirmation',
      message: t('Are you sure you want to delete document from the Company Agent?'),
      onConfirm: () => handleConfirmDelete(fileName),
      onCancel: handleCancel,
      showModal: true,
    }));
  };

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const handleConfirmDelete = async (fileName: string) => {
    dispatch(hideModal());
    try {
      await dispatch(deleteBlobDocument(fileName));
      dispatch(getBlobDocuments());
      dispatch(getIndexInfo());
    } catch (error) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Failed to delete document.',
        modalType: 'error',
        duration: 3000
      }));
    }
  };

  useEffect(() => {
    if (userType !== "Client" && userType !== "Lawyer") {
      dispatch(getIndexInfo());
      dispatch(getBlobDocuments());
    }
  }, [dispatch, userType]);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>, isZip: boolean) => {
    const files = Array.from(e.target.files || []);
    const validFiles = files.filter(file => isZip ? file.type === 'application/zip' : true);
    const uploadedFileObjects = validFiles.map(file => ({
      fileName: file.name,
      documentURL: URL.createObjectURL(file),
      file
    }));
    setUploadedFiles(prevFiles => [...prevFiles, ...uploadedFileObjects]);
  };

  const handleFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  
    try {
      const fileData = e.dataTransfer.getData("application/json");
  
      if (fileData) {
        const data = JSON.parse(fileData);
  
        if (data.type === 'folder' && data.folderId) {
          // Fetch documents for the dropped folder
  
          const userId = getUserID();
  
          try {
            const documents = await dispatch(fetchDocuments(userId, data.folderId, userType));
  
            const folderFiles = documents.map((file: FileProps) => {
              const documentURL = typeof file.FileUrl === 'string' ? file.FileUrl : file.FileUrl[0];
              return {
                fileName: file.FileName,
                documentURL,
                title: file.FileName,
                file: new File([documentURL], file.FileName) // Ensure `file` property is added
              };
            });
  
            // Filter out duplicate files
            const uniqueFolderFiles = folderFiles.filter((file: UploadedFile) => !uploadedFiles.some(uploadedFile => uploadedFile.fileName === file.fileName));
  
            setUploadedFiles(prevFiles => [...prevFiles, ...uniqueFolderFiles]);
          } catch (error) {
          }
        } else if (data.type === 'file' && data.documentURL && data.title) {
          // It's a single file
  
          const documentURL = Array.isArray(data.documentURL) ? data.documentURL[0] : data.documentURL;
          const title = data.title;
  
          // Check for duplicates before adding the file
          if (!uploadedFiles.some(uploadedFile => uploadedFile.fileName === title)) {
            setUploadedFiles(prevFiles => [...prevFiles, { fileName: title, documentURL, title, file: new File([documentURL], title) }]);
          } else {
          }
        }
      } else {
        const files = Array.from(e.dataTransfer.files);
  
        const validFiles = files.map(file => ({
          fileName: file.name,
          documentURL: URL.createObjectURL(file),
          file
        }));
  
        // Filter out duplicate files
        const uniqueFiles = validFiles.filter(file => !uploadedFiles.some(uploadedFile => uploadedFile.fileName === file.fileName));
  
        setUploadedFiles(prevFiles => [...prevFiles, ...uniqueFiles]);
      }
    } catch (error) {
      console.error("Error handling file drop:", error);
    }
  };
  
  

  const handleClick = (isZip: boolean) => {
    const input = isZip ? fileInputRef.current?.children[1] : fileInputRef.current?.children[0];
    (input as HTMLInputElement)?.click();
  };

  const removeUploadedFile = (index: number) => {
    setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    if (uploadedFiles.length > 0) {
      const uploadPromises = uploadedFiles.map(file => {
        if (file.file) {
          return indexExists
            ? dispatch(uploadAdditionalCompanyFiles(file.file))
            : dispatch(uploadCompanyFiles(file.file));
        } else {
          console.error("File object is missing for file:", file.fileName);
          return Promise.resolve();
        }
      });
  
      Promise.all(uploadPromises).then(() => {
        setUploadedFiles([]);
        dispatch(getBlobDocuments());
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
      }).catch(error => {
        console.error("Error uploading files:", error);
      });
    }
  };
  
  const handleDragOverCompany = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeaveCompany = () => {
    setIsDragOver(false);
  };

  const groupedFiles = uploadedFiles.reduce((acc: { [key: string]: UploadedFile[] }, file) => {
    const path = file.file?.webkitRelativePath ? file.file.webkitRelativePath.split('/')[0] : 'root';
    if (!acc[path]) acc[path] = [];
    acc[path].push(file);
    return acc;
  }, {});


  const renderLoading = (message: string) => (
    <div className="companyai-loading-container">
      <ClipLoader size={15} color={"#fff"} />
      <p className="companyai-loading-text">{message}</p>
    </div>
  );

  const filteredDocuments = blobDocuments?.filter((document: string) =>
    document.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDeleteIndex = () => {
    dispatch(showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete the index? This action cannot be undone.'),
        onConfirm: () => confirmDeleteIndex(),
        onCancel: () => dispatch(hideModal()),
        showModal: true,
    }));
};

const confirmDeleteIndex = async () => {
    try {
        await dispatch(deleteIndex());
        dispatch(getBlobDocuments());
        dispatch(getIndexInfo());
        dispatch(showFeedbackModal({
          modalType: 'success',
          message: (t('Agent deleted')),
          showModal: true,
          duration: 3000
        }))
        dispatch(hideModal());
    } catch (error) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: (t('Failed to delete the index.')),
        showModal: true,
        duration: 3000
      }))
        dispatch(hideModal());
    }
};

  const renderCompanyAgentUI = () => (
    <div className="company-ai-ui">
      <div className={`attachment-container`}>
        {(loading || polling) ? (
          renderLoading(statusMessage)
        ) : (
          <>
            <div className='companyai-files-row' style={{ justifyContent: indexExists ? 'space-between' : 'center' }}>
              {indexExists && blobDocuments && (
                <div className="index-info">
                  <div className='companyai-tag-row'>
                    <p>{t('Last Update')}</p>
                    <p className='companyai-last_run_time--tag'>{t(`${indexInfo?.last_run_time}`)}</p>
                  </div>
                  <div className='companyai-tag-row'>
                    <p>{t('Documents')}</p>
                    <p className='companyai-document_count--tag'>{t(`${indexInfo?.document_count}`)}</p>
                  </div>
                  <div className='companyai-existing-docs'>
                    <input
                      type="text"
                      placeholder={t('Search documents...')}
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      className="companyai-search-input"
                    />
                    <ul>
                      {Array.isArray(filteredDocuments) ? (
                        filteredDocuments.map((document: string, index: number) => (
                          <div className='blob-document-item' key={index}>
                            <li>{document.split('/').pop()}</li>
                            {/* <button onClick={() => handleDeleteClick(document)}><i className='fas fa-trash'></i></button> */}
                          </div>
                        ))
                      ) : (
                        <li>{t('No files')}</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <div style={{ maxWidth: indexExists ? '50%' : '99%' }} className='companyai-upload-container'>
                <div className="upload-options">
                  <button
                    onClick={handleSubmit}
                    disabled={
                      loading ||
                      ((uploadedFiles.length === 0) && (blobDocuments?.length === indexInfo?.document_count))
                    }
                    className={`primary-button ${
                      loading ||
                      ((uploadedFiles.length === 0) && (blobDocuments?.length === indexInfo?.document_count))
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    {indexExists ? t('Update Agent') : t('Create Agent')}
                  </button>
                  <div className='upload-options--image'>
                    {!imageExist ? (
                      <img src={require("../../../resources/FavIconSoftware.png")} alt="Company Logo" />
                    ) : (
                      <img src="" alt="" />
                    )}
                  </div>
                </div>
                {uploadedFiles.length === 0 ? (
                  <div
                    className={`comapnyai-upload-empty ${isDragOver ? 'drag-over' : ''}`}
                    onDragOver={handleDragOverCompany}
                    onDragLeave={handleDragLeaveCompany}
                    onDrop={handleFileDrop}
                  >
                    <div ref={fileInputRef} style={{ display: 'none' }}>
                      <input
                        type="file"
                        onChange={e => handleFileInput(e, false)}
                        multiple
                      />
                      <input
                        type="file"
                        onChange={e => handleFileInput(e, true)}
                        accept=".zip"
                      />
                      <input
                        type="file"
                        ref={(input) => {
                          if (input) input.setAttribute('webkitdirectory', 'true');
                        }}
                        onChange={e => handleFileInput(e, false)}
                      />
                    </div>
                    <div className="upload-option" onClick={() => handleClick(true)}>
                      <i className="fa fa-plus upload-icon"></i>
                      <p>{t('Add documents or a .zip folder')}</p>
                    </div>
                  </div>
                ) : (
                  <div className={`uploaded-files ${isDragOver ? 'drag-over' : ''}`}
                    onDragOver={handleDragOverCompany}
                    onDragLeave={handleDragLeaveCompany}
                    onDrop={handleFileDrop}>
                    {Object.entries(groupedFiles).map(([path, files], index) => (
                      <div key={index} className="file-group">
                        {path !== 'root' && <h4 className="file-group-header">{path}</h4>}
                        {files.map((file, fileIndex) => (
                          <div key={fileIndex} className="uploaded-file">
                            <p>{file.fileName}</p>
                            <button onClick={() => removeUploadedFile(uploadedFiles.indexOf(file))} className="destructive-button">
                              {t('Remove')}
                            </button>
                          </div>
                        ))}
                      </div>
                    ))}
                    <div className="upload-option" onClick={() => handleClick(true)}>
                      <i className="fa fa-plus upload-icon"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {/* {showSuccessMessage && <div className="success-message">{indexExists ? t('Index successfully updated!') : t('Index successfully created!')}</div>} */}
    </div>
  );

  return (
    <>
      {renderCompanyAgentUI()}
    </>
  );
};

export default CompanyAgent;
