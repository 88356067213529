import { useEffect, useState, useRef } from "react";
import { useAppDispatch } from "../../store";
import { useTranslation } from "react-i18next";

const useMicrophone = (language: string = 'en-US') => {
    const [finalText, setFinalText] = useState<string>("");
    const [interimText, setInterimText] = useState<string>("");
    const [isListening, setIsListening] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const microphoneRef = useRef<SpeechRecognition | null>(null);
    const lastResultIndex = useRef<number>(0);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || (window as any).webkitSpeechRecognition;

        if (!SpeechRecognition) {
            setError("Speech Recognition API is not supported in this browser.");
            console.warn("Speech Recognition API is not supported in this browser.");
            return;
        }

        const recognition = new SpeechRecognition();
        microphoneRef.current = recognition;
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = language;
        recognition.maxAlternatives = 1; // Ensures only one transcription alternative is returned

        recognition.onresult = (event: SpeechRecognitionEvent) => {
            let interim = "";
            let final = "";

            // Process only new results
            for (let i = lastResultIndex.current; i < event.results.length; ++i) {
                const transcript = event.results[i][0].transcript.trim();
                if (event.results[i].isFinal) {
                    final += transcript + " ";
                } else {
                    interim += transcript + " ";
                }
            }

            // Update the last processed index
            lastResultIndex.current = event.results.length;

            // Update states
            if (final) {
                setFinalText(prev => prev + final);
            }
            if (interim) {
                setInterimText(interim);
            }
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
            console.error("Speech recognition error", event);
            setError("An error occurred with speech recognition.");
            setIsListening(false);
        };

        // Cleanup on unmount
        return () => {
            recognition.stop();
        };
    }, [language]);

    const startListening = () => {
        if (microphoneRef.current) {
            setFinalText("");
            setInterimText("");
            lastResultIndex.current = 0; // Reset the index when starting
            microphoneRef.current.start();
            setIsListening(true);
            setError(null);
        }
    };

    const stopListening = () => {
        if (microphoneRef.current) {
            microphoneRef.current.stop();
            setIsListening(false);
        }
    };


// useEffect(() => {
//     let timer: NodeJS.Timeout;
//     if (isListening) {
//         timer = setTimeout(() => {
//             stopListening();
//             dispatch(showFeedbackModal({
//                 modalType: 'warning',
//                 message: t("Stopped listening after 30 seconds."),
//                 showModal: true,
//                 duration: 5000
//             })
                
//                 );
//         }, 30000); 
//     }
//     return () => clearTimeout(timer);
// }, [isListening, stopListening, t]);


    const transcript = finalText + interimText;

    return {
        transcript,
        isListening,
        startListening,
        stopListening,
        hasRecognitionSupport: !!microphoneRef.current,
        error
    };
};

export default useMicrophone;
