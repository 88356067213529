import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppDispatch } from './store';
import { RootState } from './store';
import { useSelector } from 'react-redux';
import { changeLanguage } from './i18n/i18n';
import { isTokenExpired } from './utils/config';
import { signOut } from './store/actions/SignInAction';


interface PrivateRouteProps extends RouteProps {
  // children?: React.ReactNode;
  component?: React.ComponentType<any>;
  render?: () => React.ReactNode;
  subscriptionId: string;
  restricted: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, render: Render, subscriptionId, restricted, ...rest }) => {
  let userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  let accessToken = ""
  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const dispatch = useAppDispatch()


  if (userCred) {
    let data = JSON.parse(userCred);
    accessToken = data.token
  }

  useEffect(() => {
    if (preferences && preferences.Language) {
      changeLanguage(preferences.Language);
    }
  }, [preferences]);

  useEffect(() => {
    const fetchData = () => {
      if (accessToken && isTokenExpired(accessToken)) {
        dispatch(signOut())
      }
    };
    const intervalId = setInterval(fetchData, 10 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dispatch, accessToken]);

  const renderComponentOrRedirect = (props: any) =>
    Component ? <Component {...props} /> : Render ? Render() : null;

  const checkRedirects = (props: any) => {
    const isResetPasswordRoute = props.match.path === '/reset-password/:token';

    if (isResetPasswordRoute || subscriptionId) {
      return renderComponentOrRedirect(props);
    }

    if (!accessToken) {
      return <Redirect to="/" />;
    }

    if (!subscriptionId && restricted) {
      return <Redirect to="/plans" />;
    }

    return renderComponentOrRedirect(props);
  };


  return (
    // <div style={{ background: 'transparent'}}>
    //   <Route
    //     {...rest}
    //     render={(props) => {

    //       const isResetPasswordRoute = props.match.path === "/reset-password/:token";
    //       if (isResetPasswordRoute) {
    //         return Component ? <Component {...props} /> : Render ? Render() : null;
    //       }

    //       if (!accessToken) {
    //         return <Redirect to="/" />;
    //       }

    //       if (subscriptionId) {
    //         return Component ? <Component {...props} /> : Render ? Render() : null;
    //       }


    //       if (!subscriptionId && restricted) {
    //         return <Redirect to="/plans" />;
    //       }


    //       return Component ? <Component {...props} /> : Render ? Render() : null;
    //     }
    //     }
    //   />
    // </div>
    <div style={{ background: 'transparent' }}>
      <Route {...rest} render={checkRedirects} />
    </div>
  );
}

export default PrivateRoute;
