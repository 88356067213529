import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { updateAISettings, getAISettings } from '../../../store/actions/DataAction';
import { setFontSize } from '../../../store/actions/FontSizeAction';
import { setVoice } from '../../../store/actions/SpeechAction';
import GlobalDropdown, { DropdownOption } from '../../shared/GlobalDropdown';
import './../Settings.css'; 
import { useTranslation } from 'react-i18next';
import { AISettings } from '../../../store/reducer/DataReducer';
import { useLocation } from 'react-router-dom';
import { fetchAIVoices, fetchAIJurisdictions, fetchAILanguages, fetchAIFontSizes } from '../../../store/actions/DropdownActions';

const AISettingsComponent: React.FC<{style?: CSSProperties, backgroundColor?: string}> = ({style, backgroundColor}) => {
  const dispatch = useAppDispatch();
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const aiVoices = useSelector((state: RootState) => state.dropdown.aiVoices);
  const aiJurisdictions = useSelector((state: RootState) => state.dropdown.aiJurisdictions);
  const aiLanguages = useSelector((state: RootState) => state.dropdown.aiLanguages);
  const fontSizes = useSelector((state: RootState) => state.dropdown.aiFontSizes);
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedAILanguage, setSelectedAILanguage] = useState<string>('');
  const [selectedVoice, setSelectedVoiceState] = useState<string>('');
  const [selectedFontSize, setSelectedFontSizeState] = useState<string>('');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>('');
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);

  useEffect(() => {
    const userId = getUserID();
    const userType = getUserType();
    dispatch(getAISettings(userType));
    dispatch(fetchAIVoices());
    dispatch(fetchAIJurisdictions());
    dispatch(fetchAILanguages());
    dispatch(fetchAIFontSizes());
  }, [dispatch]);

  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedFontSizeState(aiSettings.FontSize);
      setSelectedJurisdiction(aiSettings.CountryOfJurisdiction || '');
      setIsDarkTheme(aiSettings.ThemeColor === 'dark');
    }
  }, [aiSettings]);

  const handleSelectAILanguage = (id: string) => {
    const selectedOption = aiLanguages.find(option => option.id === id);
    if (selectedOption) {
      setSelectedAILanguage(selectedOption.id);
      updateAISettingsOnServer({ AILanguageResponse: selectedOption.id });
    }
  };

  const handleSelectAIVoice = (id: string) => {
    const selectedOption = aiVoices.find(option => option.id === id);
    if (selectedOption) {
      setSelectedVoiceState(selectedOption.id);
      dispatch(setVoice(selectedOption.id));
      updateAISettingsOnServer({ AIVoice: selectedOption.id });
    }
  };

  const handleSelectFontSize = (id: string) => {
    const selectedOption = fontSizes.find(option => option.id === id);
    if (selectedOption) {
      setSelectedFontSizeState(selectedOption.id);
      dispatch(setFontSize(selectedOption.id));
      updateAISettingsOnServer({ FontSize: selectedOption.id });
    }
  };

  const handleSelectJurisdiction = (id: string) => {
    const selectedOption = aiJurisdictions.find(option => option.id === id);
    if (selectedOption) {
      setSelectedJurisdiction(selectedOption.id);
      updateAISettingsOnServer({ CountryOfJurisdiction: selectedOption.id });
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    updateAISettingsOnServer({ ThemeColor: !isDarkTheme ? 'dark' : 'light' });
  };

  const updateAISettingsOnServer = (updatedFields: Partial<AISettings>) => {
    const updatedAISettings = {
      UserID: getUserID(),
      UserType: getUserType(),
      AIVoice: updatedFields.AIVoice || selectedVoice,
      AILanguageResponse: updatedFields.AILanguageResponse || selectedAILanguage,
      FontSize: updatedFields.FontSize || selectedFontSize,
      // CountryOfJurisdiction: updatedFields.CountryOfJurisdiction || selectedJurisdiction,
      CountryOfJurisdiction: 'Norway',
      ThemeColor: updatedFields.ThemeColor || (isDarkTheme ? 'dark' : 'light'),
      Nationality: userInfo?.Nationality || '',
      FontFamily: '"Poppins", sans-serif',
    };
    dispatch(updateAISettings(updatedAISettings));
  };

  return (
    <div className="ai-settings-container" style={{ backgroundColor: backgroundColor }}>
      {location.pathname === '/settings/account' && (
        <h1 className="ai-settings-title">{t('AI Settings')}</h1>
      )}
      <div className="ai-settings-form">
        {/* Jurisdiction Dropdown */}
        <div className="ai-settings-field" style={style}>
          <div className='ai-settings-field--div'>
            <label className="ai-settings-label">{t('Jurisdiction')}</label>
            {location.pathname === '/settings/account' && (
              <span className='ai-settings-field--span'>{t('Select your jurisdiction.')}</span>
            )}
          </div>
          <GlobalDropdown
            options={[]}  
            selectedLabel="Norway"
            onSelect={handleSelectJurisdiction}
            headerStyle={{ border: '1px solid #fff' }}
            isStatic={true} 
            
          />
        </div>

        {/* Language Dropdown */}
        <div className="ai-settings-field" style={style}>
          <div className='ai-settings-field--div'>
            <label className="ai-settings-label">{t('Language')}</label>
            {location.pathname === '/settings/account' && (
              <span className='ai-settings-field--span'>{t('Select AI conversation language.')}</span>
            )}
          </div>
          <GlobalDropdown
            options={aiLanguages}
            selectedLabel={
              aiLanguages.find(option => option.id === selectedAILanguage)?.label || 'Select Language'
            }
            onSelect={handleSelectAILanguage}
            headerStyle={{ maxWidth: 150 }}
          />
        </div>

        {/* Voice Dropdown */}
        <div className="ai-settings-field" style={style}>
          <div className='ai-settings-field--div'>
            <label className="ai-settings-label">{t('Voice')}</label>
            {location.pathname === '/settings/account' && (
              <span className='ai-settings-field--span'>{t('Select AI Voice.')}</span>
            )}
          </div>
          <GlobalDropdown
            options={aiVoices}
            selectedLabel={
              aiVoices.find(option => option.id === selectedVoice)?.label || 'Select Voice'
            }
            onSelect={handleSelectAIVoice}
            headerStyle={{ maxWidth: 150 }}
          />
        </div>

        {/* Font Size Dropdown */}
        <div className="ai-settings-field" style={style}>
          <div className='ai-settings-field--div'>
            <label className="ai-settings-label">{t('Font Size')}</label>
            {location.pathname === '/settings/account' && (
              <span className='ai-settings-field--span'>{t('Select AI conversation font size.')}</span>
            )}
          </div>
          <GlobalDropdown
            // options={fontSizes.map((f) => ({
            //   id: f.id,
            //   label: `${f.label}px`,
            // }))}
            options={[]}
            selectedLabel="14px"
            // selectedLabel={selectedFontSize ? `${selectedFontSize}px` : 'Select Font Size'}
            onSelect={handleSelectFontSize}
            headerStyle={{ border: '1px solid #fff' }}
            isStatic={true} 
          />
        </div>
      </div>
    </div>
  );
};

export default AISettingsComponent;
