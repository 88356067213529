import React from "react";
import './Document.css';
import { getUserID, getUserType } from "../../store/actions/ChatAction";
import { deleteDocument } from "../../store/actions/DocumentsAction";
import { useAppDispatch } from "../../store";
import { showModal, hideModal } from "../../store/actions/modalActions";
import { FileProps } from "../../pages/DocumentsPage";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

interface DocumentProps {
  file: FileProps | null;
  onOpenDocument: () => void;
  onFileDeleted: () => void;
}

const Document: React.FC<DocumentProps> = ({ file, onOpenDocument, onFileDeleted }) => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { t } = useTranslation();


  const handleDeleteClick = () => {
    dispatch(showModal({
      type: 'confirmation',
      message: t('Are you sure you want to delete this document?'),
      onConfirm: handleConfirmDelete,
      onCancel: handleCancel,
      showModal: true,
    }));
  };

  const handleConfirmDelete = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }
    const userId = getUserID();
    dispatch(deleteDocument(file.FileID, userId, userType, () => {
      onFileDeleted();
    }));

    dispatch(hideModal());
  };

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const handleDownload = () => {
    if (file && file.FileUrl) {
      const urlToOpen = Array.isArray(file.FileUrl) ? file.FileUrl[0] : file.FileUrl;
      window.open(urlToOpen, '_blank');
    }
  };

  if (!file) {
    return (
      <div className="document-container-no-select">
        <p className="document-no-selected">{t('No file selected')}</p>
      </div>
    );
  }

  const convertToArray = (value: string | string[] | undefined): string[] => {
    if (!value) return [];
    return Array.isArray(value) ? value : value.split(',').map(item => item.trim());
  };

  const renderAdditionalFields = () => {
    switch (file.FileType) {
      case 'Contract':
        const partiesInvolved = convertToArray(file.PartiesInvolved);
        const approvers = convertToArray(file.Approvers);

        return (
          <>

            {/* <div className="info-block">
              <label>{t('Start Date')}</label>
              <span>
                {file.StartDate ? (
                  dayjs(file.StartDate, 'YYYY-MM-DD HH:mm:ss').isValid() ? (
                    dayjs(file.StartDate, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm')
                  ) : (
                    t('Invalid date')
                  )
                ) : (
                  <span className="info-block__placeholder">{t('No start date')}</span>
                )}
              </span>
            </div> */}
            <div className="info-block">
              <label>{t('Deadline')}</label>
              <span>
                {file.Expiry ? (
                  dayjs(file.Expiry, 'YYYY-MM-DD HH:mm:ss').isValid() ? (
                    dayjs(file.Expiry, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm')
                  ) : (
                    t('Invalid date')
                  )
                ) : (
                  <span className="info-block__placeholder">{t('No deadline')}</span>
                )}
              </span>
            </div>
            <div className="info-block">
              <label>{t('Parties Involved')}</label>
              <div className="info-block__tags-container">
                {partiesInvolved.length > 0
                  ? (
                    <span>{renderEmailCircles(file.PartiesInvolved)}</span>
                  )
                  : <span className="info-block__placeholder">{t('No parties involved')}</span>}
              </div>
            </div>
            <div className="info-block">
              <label>{t('Approvers')}</label>
              <div className="info-block__tags-container">
                {approvers.length > 0
                  ? (
                    <span>{renderEmailCircles(file.Approvers)}</span>
                  )
                  : <span className="info-block__placeholder">{t('No approvers')}</span>}
              </div>
            </div>
          </>
        );
      case 'Legal Document':
        return (
          <>
            <div className="info-block">
              <label>{t('Legal Type')}</label>
              <span>{file.LegalType || <span className="info-block__placeholder">{t('No legal type')}</span>}</span>
            </div>
            <div className="info-block">
              <label>{t('Case')}</label>
              <span>{file.CaseID || <span className="info-block__placeholder">{t('No case associated')}</span>}</span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const sharedWith = convertToArray(file.SharedWith);

  const renderEmailCircles = (emails: string[] | string | undefined) => {
    if (!emails || emails.length === 0) return <span>{t('None')}</span>;
    const emailArray = typeof emails === 'string' ? emails.split(',').map(email => email.trim()) : emails;
    return emailArray.map((email, index) => (
      <Tooltip title={email} key={index} content={email}>
        <div className="email-circle">
          {email[0]?.toUpperCase() || "?"}
        </div>
      </Tooltip>
    ));
  };

  return (
    <div className="document-container">
      <div className="document-header">
        <div>
          <h2>{file.FileName}</h2>
          <span className="document-file-type--span">{file.FileType}</span>
        </div>
        <div className="document-buttons-end">
          <button onClick={handleDownload} className="primary-button"><span>{t('Download')}</span><i className="fas fa-cloud-arrow-down"></i></button>
          <button className="secondary-button" onClick={onOpenDocument}>
            <span>{file.FileType === "Template" ? t('View') : t('File Manager')}</span>
            <i className="fas fa-file-pen"></i>
          </button>
        </div>
      </div>

      <div className="document-info-block--container">

          <div className="info-block">
            <label>{t('Date Created')}</label>
            <span>{new Intl.DateTimeFormat('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
              }).format(new Date(file.Creation))}</span>
          </div>
          <div className="info-block">
            <label>{t('Last Modified')}</label>
            {/* <span>{file.Modified}</span> */}
            <span>{new Intl.DateTimeFormat('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
              }).format(new Date(file.Modified))}</span>
          </div>


        {renderAdditionalFields()}
          <div className="info-block">
            <label>{t('Assignees')}</label>
            <div className="info-block__tags-container">
              {file.Assignees && Array.isArray(file.Assignees) && file.Assignees.length > 0
                ?  (
                  <span style={{ backgroundColor: 'transparent' }}>{renderEmailCircles(file.Assignees)}</span>
                )
                : <span className="info-block__placeholder">{t('No assignees')}</span>}
            </div>
        </div>
          <div className="info-block">
            <label>{t('Shared with')}</label>
            <div className="info-block__tags-container">
              {sharedWith.length > 0
                ?  (
                  <span>{renderEmailCircles(file.SharedWith)}</span>
                )
                : <span className="info-block__placeholder">{t('Not shared with anyone')}</span>}
            </div>
          </div>

        {/* <div className="document-buttons">
        <div className="document-buttons-start">
          <button onClick={handleDownload} className="document-btn document-btn__download"><i className="bi bi-download"></i></button>
          <button className="document-btn document-btn__trash" onClick={handleDeleteClick}>
            <i className="bi bi-trash-fill"></i>
          </button>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default Document;
