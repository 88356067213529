import React, { useEffect, useState } from 'react';
import '../Settings.css';
import PersonalInfoAndProfile, { UserProps } from './PersonalInfoAndProfile';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store';
import { AnyAction } from 'redux';
import { userInformation } from '../../../store/actions/DataAction';
import ApiLoader from '../../ApiLoader';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';


const AccountSettings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const [data, setData] = useState<UserProps>({
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    StreetAddress: "",
    Email: "",
    ZipCode: "",
    ProfilePicture: "",
    City: "",
    Country: "",
    CountryOfJurisdiction: "Norway",
    State: "",
    DateOfBirth: "",
    // Occupation: ""

  })

  const userCred = localStorage.getItem("token") || sessionStorage.getItem("token")
  let userId = getUserID();
  let userType= getUserType()
  if (userCred) {
    let data = JSON.parse(userCred)
    userId = data.userId
    userType = data.userType
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let resp = await dispatch(userInformation(userType))

      if (resp) {
        setData(resp)
        setIsLoading(false);
      }
    }

    fetchData()

  }, [userId])





  return (
    <>
      
      {
        isLoading ? (
          <ApiLoader />
        ) : (
          <>
            <div className="account-settings">
              <PersonalInfoAndProfile />
            </div>
          </>

        )
      }
    </>

  );
};

export default AccountSettings;
