import React, { useState } from 'react';
import '../Settings.css';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { EditUser, userInformation } from '../../../store/actions/DataAction';
import * as yup from 'yup';
import { useFormik } from 'formik';
import PreferencesSection from './Preferences';
import Reminder from './Reminder';
import { getUserType } from '../../../store/actions/ChatAction';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { showPasswordProfileModal } from '../../../store/actions/modalActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import AISettingsComponent from './AISettings';
import ApiLoader from '../../ApiLoader';
import CustomDateTimePicker from '../../shared/CustomDateTimePicker';
import { Tooltip } from '@mui/material';

export interface UserProps {
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  StreetAddress: string;
  Email: string;
  CountryOfJurisdiction: string;
  ZipCode?: string;
  ProfilePicture?: string;
  City?: string;
  Country?: string;
  State?: string;
  DateOfBirth?: string;
  Password?: string;
  Biography?: string;
  HourlyRate?: string;
}

const PersonalInfoAndProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const userData = useSelector((state: RootState) => state.user.userInfo) || {};
  const userType = getUserType();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object({
    FirstName: yup.string().required('Required'),
    LastName: yup.string().required('Required'),
    PhoneNumber: yup.string().required('Required'),
    // Email is now static and excluded from validation
    Address: yup.string(),
    Country: yup.string(),
    State: yup.string(),
    City: yup.string(),
    ZipCode: yup.string(),
    ProfilePicture: yup.string(),
    DateOfBirth: yup
      .string()
      .required(t('Date of Birth is required'))
      .test('is-valid-date', t('Invalid date'), (value) =>
        dayjs(value, 'DD-MM-YYYY', true).isValid()
      ),
  });

  const formik = useFormik({
    initialValues: {
      FirstName: userData.FirstName || '',
      LastName: userData.LastName || '',
      PhoneNumber: (userType === 'LawfirmAdmin' || userType === 'BusinessAdmin') ? userData.PrimaryContact : userData.PhoneNumber || '',
      StreetAddress: userData.StreetAddress || '',
      Email: userData.Email || '', // Keep Email in initialValues
      ZipCode: userData.ZipCode || '',
      City: userData.City || '',
      Country: userData.Country || '',
      State: userData.State || '',
      DateOfBirth: userData.DateOfBirth || '',
      CountryOfJurisdiction: 'Norway',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: UserProps) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('UserID', userData.UserID);
      formData.append('UserType', userType);

      Object.keys(values).forEach((key) => {
        formData.append(key, (values as any)[key] ?? '');
      });

      const resp = await dispatch(EditUser(formData, onSuccess));
      if (resp) {
        setIsLoading(false);
        setIsEditing(false);
      } else {
        setIsLoading(false);
      }
    },
  });

  const onSuccess = () => {
    setIsEditing(false);
    dispatch(userInformation(userType));
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: t('Account updated'),
        modalType: 'success',
        duration: 3000,
      })
    );
  };

  if (!userData) {
    return <div>{t('Loading...')}</div>;
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsLoading(false);
  };

  const handleDOBChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('DD-MM-YYYY'); // Ensure consistent format
      formik.setFieldValue('DateOfBirth', formattedDate);
    } else {
      formik.setFieldValue('DateOfBirth', '');
    }
  };
  
  const handleEditSubmit = () => {
    dispatch(showPasswordProfileModal(formik.values, userData.UserID, userType, onSuccess));
  };

  const formatDate = (dateString?: string | null): string => {
    if (!dateString) {
      return 'No Date';
    }

    const inputFormat = 'MM-DD-YYYY';
    const date = dayjs(dateString, inputFormat, true);

    if (!date.isValid()) {
      return 'Invalid Date';
    }

    const outputFormat = 'DD MMMM YYYY'; 
    return date.format(outputFormat);
  };

  return (
    <div style={{ width: '100%', overflowY: 'auto', marginBottom: 10, position: 'relative', scrollbarWidth: 'thin', overflowX: 'hidden', scrollbarColor: '#444 transparent' }}>
      {isLoading && (
        <div>
          <ApiLoader />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, position: 'relative', height: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, borderRadius: 10, flex: 1, position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, borderRadius: 10, flex: 1, height: '100%', position: 'relative' }}>
            <div className="personal-information-container">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>{t('Personal Information')}</h2>
                {isEditing ? (
                  <div className="Buttons">
                    <button className="destructive-button" type="button" onClick={handleCancel}>
                      {t('Cancel')}
                    </button>
                    <button className="primary-button" type="button" onClick={handleEditSubmit} disabled={isLoading}>
                      {t('Save')}
                    </button>
                  </div>
                ) : (
                  <button className="primary-button" onClick={handleEdit}>
                    {t('Update')}
                  </button>
                )}
              </div>
              {isEditing ? (
                <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} onSubmit={formik.handleSubmit}>
                  <div className="personal-info-section">
                    <Section title={t('First Name')} type="text" {...formik.getFieldProps('FirstName')} />
                    <Section title={t('Last Name')} type="text" {...formik.getFieldProps('LastName')} />
                    <SectionDisplay title={t('Email')} value={userData.Email} disabled /> {/* Static Email */}
                    <Section title={t('Street Address')} type="text" {...formik.getFieldProps('StreetAddress')} />
                    <Section title={t('Mobile Number')} type="text" {...formik.getFieldProps('PhoneNumber')} />
                    <Section title={t('Country')} type="text" {...formik.getFieldProps('Country')} />
                    <Section title={t('City')} type="text" {...formik.getFieldProps('City')} />
                    <Section title={t('Zip Code')} type="text" {...formik.getFieldProps('ZipCode')} />
                    <Section
                      title={t('Date of Birth')}
                      type="date"
                      value={formik.values.DateOfBirth}
                      handleDateChange={handleDOBChange}
                      placeholder={t('Set date of birth')}
                    />
                  </div>
                </form>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px' }}>
                  <div className="personal-info-section">
                    <SectionDisplay title={t('First Name')} value={userData.FirstName} disabled />
                    <SectionDisplay title={t('Last Name')} value={userData.LastName} disabled />
                    <SectionDisplay title={t('Email')} value={userData.Email} disabled /> {/* Static Email */}
                    <SectionDisplay title={t('Street Address')} value={userData.StreetAddress} disabled />
                    <SectionDisplay title={t('Mobile Number')} value={userType === 'LawfirmAdmin' ? userData.PrimaryContact : userData.PhoneNumber} disabled />
                    <SectionDisplay title={t('Country')} value={userData.Country} disabled />
                    <SectionDisplay title={t('City')} value={userData.City} disabled />
                    <SectionDisplay title={t('Zip Code')} value={userData.ZipCode} disabled />
                    <SectionDisplay title={t('Date of Birth')} value={formatDate(userData.DateOfBirth)} disabled />
                  </div>
                </div>
              )}
            </div>
            <Reminder />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 0.8, height: '100%' }}>
            <AISettingsComponent />
            <PreferencesSection />
          </div>
        </div>
      </div>
    </div>
  );
};

interface BaseSectionProps {
  title: string;
  value?: string;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  style?: React.CSSProperties;
  selectedFileName?: string | null;
  placeholder?: string;
}

interface DateSectionProps extends BaseSectionProps {
  type: 'date';
  handleDateChange: (date: Dayjs | null) => void;
}

interface NonDateSectionProps extends BaseSectionProps {
  type: Exclude<string, 'date'>;
  handleDateChange?: never;
}

type SectionProps = DateSectionProps | NonDateSectionProps;

interface SectionDisplayProps {
  title: string;
  value?: string;
  type?: string;
  disabled?: boolean;
}


const Section: React.FC<SectionProps> = (props) => {
  const {
    title,
    value,
    onChange,
    onBlur,
    name,
    type,
    placeholder,
    disabled,
  } = props;
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  if (type === 'date') {
    const { handleDateChange } = props as DateSectionProps;

    return (
      <div className="personal-info__display">
        <span>{title}</span>
        <ThemeProvider theme={darkTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDateTimePicker
              value={value ? dayjs(value, 'MM-DD-YYYY') : null}
              onChange={handleDateChange}
              placeholder={placeholder || 'Set date'}
              dateOnly={true}
              width="100%"
            />
          </LocalizationProvider>
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <div className="personal-info__display">
        <span>{title}</span>
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={title}
        />
      </div>
    );
  }
};

const SectionDisplay: React.FC<SectionDisplayProps> = ({ title, value, type, disabled }) => (
  <Tooltip title={`${title === 'Email' ? ('Contact support to change Email') : ''}`}>
  <div className="personal-info__display">
    <span>{title}</span>
    <input type={type ?? 'text'} value={value} disabled={disabled} placeholder={title} />
  </div>
  </Tooltip>
);

export default PersonalInfoAndProfile;
