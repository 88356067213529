import { AnyAction, Dispatch } from 'redux';
import { SidePanelState } from '../reducer/sidePanelReducer';
import { FolderProps } from '../../components/Documents/Files';
import { Description, Section } from '../../components/Help/types';
import { Member, Team } from '../../components/Dashboard/AdminFirm/FirmComponents/ManageTeam';
import { Lawyer } from '../../components/Dashboard/AdminFirm/interface/IFirmLawyer';
import { IAdminCaseItem } from '../../components/Marketplace/Interfaces/IAdminCaseItem';

export const showSidePanel = (payload: SidePanelState): AnyAction => ({
  type: 'SHOW_SIDE_PANEL',
  payload,
});

export const hideSidePanel = (): AnyAction => ({
  type: 'HIDE_SIDE_PANEL',
});

export const expandSidePanel = (): AnyAction => ({
  type: 'EXPAND_SIDE_PANEL',
});

export const collapseSidePanel = (): AnyAction => ({
  type: 'COLLAPSE_SIDE_PANEL',
});

export const updateSidePanel = (payload: Partial<SidePanelState>): AnyAction => ({
  type: 'UPDATE_SIDE_PANEL',
  payload,
});

export const showAddTaskSidePanel = () => {
  return (dispatch: Dispatch) => {
    dispatch(showSidePanel({
      type: 'addTask',
      showSidePanel: true,
      width: '50%',
      onConfirm: () => {},
      onCancel: () => {},
    }));
  };
};

export const showAddFileSidePanel = (folders: FolderProps[], folderId?: string) => {
    return (dispatch: Dispatch) => {
      dispatch(showSidePanel({
        type: 'addFile',
        showSidePanel: true,
        width: '50%',
        onConfirm: () => {},
        onCancel: () => {},
        folders: folders,
        preSelectedFolderId: folderId,
      }));
    };
  };

  export const showSupportTicketSidePanel = () => {
    return (dispatch: Dispatch) => {
      dispatch(showSidePanel({
        type: 'supportTicket',
        showSidePanel: true,
        isExpanded: false,
        width: '50%',
        onConfirm: () => {},
        onCancel: () => {},
      }));
    };
  };

  export const showHelpContentSidePanel = (section: Section, description: Description) => {
    return (dispatch: Dispatch) => {
      dispatch(showSidePanel({
        type: 'helpContent',
        showSidePanel: true,
        width: '50%',
        content: { section, description },
        onConfirm: () => {},
        onCancel: () => {},
      }));
    };
  };

  export const showOnboardingSidePanel = (
    addEmployee: (employee: Member) => void,
    employeeCount: number,
    employeeLimit: number
  ) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'onboarding',
          showSidePanel: true,
          width: '50%',
          onCancel: () => dispatch(hideSidePanel()),
          addEmployee,
          employeeCount,
          employeeLimit,
        })
      );
    };
  };
  
  export const showEmployeeViewSidePanel = (employee: Lawyer) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'employeeView',
          showSidePanel: true,
          width: '50%',
          onCancel: () => dispatch(hideSidePanel()),
          employee,
        })
      );
    };
  };
  
  export const showTeamViewSidePanel = (team: Team) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'teamView',
          showSidePanel: true,
          width: '50%',
          onCancel: () => dispatch(hideSidePanel()),
          team,
        })
      );
    };
  };
  
  export const showCaseViewSidePanel = (caseItem: IAdminCaseItem) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'caseView',
          showSidePanel: true,
          width: '50%',
          onCancel: () => dispatch(hideSidePanel()),
          caseItem,
        })
      );
    };
  };

  export const showManageTeamSidePanel = (
    allMembers: Member[],
    subjects: any[]
  ) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'manageTeam',
          showSidePanel: true,
          width: '50%',
          onCancel: () => dispatch(hideSidePanel()),
          allMembers,
          subjects,
        })
      );
    };
  };
  
  export const showAddClientSidePanel = () => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'addClient',
          showSidePanel: true,
          width: '50%',
          onCancel: () => dispatch(hideSidePanel()),
        })
      );
    };
  };
  
  export const showClientViewSidePanel = (client: any, selectedCase?: any) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'clientView',
          showSidePanel: true,
          width: selectedCase ? '85%' : '50%', 
          isExpanded: !!selectedCase,
          onCancel: () => dispatch(hideSidePanel()),
          client,
          selectedCase,
        })
      );
    };
  };

  export const showTimekeepingSidePanel = (day: string | null, data: any, isLoading: boolean, stats: any) => {
    return (dispatch: Dispatch) => {
      dispatch(
        showSidePanel({
          type: 'timekeepingView',
          showSidePanel: true,
          width: '50%',
          isExpanded: false,
          onCancel: () => dispatch(hideSidePanel()),
          day,
          data,
          isLoading,
          stats
        })
      );
    };
  };