import React from 'react';
import { useSelector } from 'react-redux';
import './UserFeedbackModal.css';
import { RootState } from '../../store';
import { Alert } from 'flowbite-react';

const UserFeedbackModal: React.FC = () => {
  const userFeedbackModalState = useSelector((state: RootState) => state.userFeedback);

  const getAlertColor = () => {
    switch (userFeedbackModalState.modalType) {
      case 'success':
        return 'success';
      case 'error':
        return 'failure';
      case 'warning':
        return 'warning';
      default:
        return 'info';
    }
  };
  const getIcon = () => {
    switch (userFeedbackModalState.modalType) {
      case 'success':
        return <i className='fa-solid fa-check-circle success-icon'></i>;
      case 'error':
        return <i className='fa-solid fa-circle-info error-icon'></i>;
      case 'warning':
        return <i className='fa-solid fa-exclamation-circle warning-icon'></i>;
      default:
        return null;
    }
  };
  const getColor = () => {
    switch (userFeedbackModalState.modalType) {
      case 'success':
        return 'success-icon';
      case 'error':
        return 'error-icon'
      case 'warning':
        return 'warning-icon'
      default:
        return null;
    }
  };

  const getAlertClass = () => {
    switch (userFeedbackModalState.modalType) {
      case 'success':
        return 'success-alert';
      case 'error':
        return 'error-alert';
      case 'warning':
        return 'warning-alert';
      default:
        return 'info-alert';
    }
  };
  if (!userFeedbackModalState.showModal) return null;


  return (

    <Alert
      color={getAlertColor()}
      // withIcon={true}
      // icon={getIcon}
      className={`bottom-alert ${userFeedbackModalState.showModal ? 'bottom-alert-visible' : ''} ${getAlertClass()}`}
      rounded={true}
      withBorderAccent={true}
    >
      {getIcon()}
      <span className={`font-medium ${getColor()}`}>
        {userFeedbackModalState.message}
      </span>
    </Alert>
  );
};

export default UserFeedbackModal;
