import React from "react";
import { TicketItem } from "./SupportTicketSystem";
import './TicketDetail.css';
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import Avatar from '@mui/material/Avatar';
import { Tooltip } from "@mui/material";

interface TicketDetailProps {
  ticket: TicketItem;
  onClose: () => void;
  isLoading: boolean;
}

const TicketDetail: React.FC<TicketDetailProps> = ({ ticket, onClose, isLoading }) => {
  const { t } = useTranslation();

  const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'open':
        return 'rgb(66, 212, 66)'; 
      case 'closed':
        return '#008000'; 
      case 'pending':
        return '#0000FF'; 
      default:
        return '#808080'; 
    }
  };

  if (isLoading) {
    return (
      <div className="ticket-detail-loading">
        <ClipLoader size={30} color="#fff" loading={true} />
        <p>{t('Loading ticket details...')}</p>
      </div>
    );
  }

  return (
    <div className="ticket-detail">
      <button className="close-ticket-detail-btn" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button> 
      
      <div className="ticket-detail-header">
        <div className="ticket-header-info">
          <h3>{t('Ticket Details')}</h3>
          <p className="ticket-subject--detail">{ticket.subject}</p>
          <span
            className="ticket-status--detail"
            style={{ color: getStatusColor(ticket.status) }}
          >
            {t(ticket.status)}
          </span>
        </div>
      </div>

      <div className="ticket-detail-content">
        <div className="detail-row">
          <label>{t('Category')}</label>
          <p>{ticket.category}</p>
        </div>
        <div className="detail-row">
          <label>{t('Description')}</label>
          <p className="ticket-description">{ticket.description}</p>
        </div>
        {/* <div className="detail-row">
          <label>{t('Client Email')}</label>
          <p>{ticket.client_email}</p>
        </div> */}
        <div className="details-row">
        <div className="detail-row">
          <label>{t('Created At')}</label>
          <p>{new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'long', 
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }).format(new Date(ticket.created_at))}</p>
        </div>
        <div className="detail-row">
          <label>{t('Updated At')}</label>
          <p>{new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'long', 
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }).format(new Date(ticket.updated_at))}</p>
        </div>
        </div>
        {/* Optional: Add Status Comment or Additional Details */}
        {/* {ticket.status_comment && (
          <div className="detail-row">
            <label>{t('Status Comment')}:</label>
            <p>{ticket.status_comment}</p>
          </div>
        )} */}
      </div>

      {/* Optional: Action Buttons */}
      <div className="ticket-detail-actions">
        {/* Example: Update Status Button */}
        {/* <Tooltip title={t('Coming soon')}>
          <button className="primary-button disabled" disabled>
            {t('Update Status')}
          </button>
        </Tooltip> */}
        {/* Example: Add Comment Button */}
        <Tooltip title={t('Coming soon')}>
          <button className="secondary-button disabled" disabled>
            {t('Add Comment')}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default TicketDetail;
