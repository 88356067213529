import { HelpData } from '../../components/Help/types';
import { HelpActionTypes } from '../actions/DataAction';
import { GET_AI_SETTINGS_SUCCESS, GET_PREFERENCES_SUCCESS, GET_USER_MANUAL_FAILURE, GET_USER_MANUAL_REQUEST, GET_USER_MANUAL_SUCCESS, SET_AI_SETTINGS, SET_AI_VOICES, SET_CHAT_LIST, SET_CITY, SET_COUNTRY, SET_CURRENCY, SET_JURISDICTION_COUNTRY, SET_MESSAGE_LIST, SET_PREFERENCES, SET_STATE } from '../types';

export interface AISettings {
  UserID: string;
  UserType: string;
  FontSize: string;
  AIVoice: string;
  AILanguageResponse: string;
  ThemeColor: string;
  CountryOfJurisdiction: string;
  FontFamily: string;
}
export interface IPreferences {
  UserID: string;
  UserType: string;
  CurrencyName: string;
  Currency: string;
  Communication: string;
}

interface Country {
  countries: [] | null;
  states: [] | null;
  cities: [] | null;
  CountryOfJurisdictionList: [] | null;
  currencies: [] | null;
  preferences: IPreferences | null;
  aiSettings: AISettings | null;
  chatList: any[] | [];
  MessageList: any[] | [];
  aiVoices: any[] | [];
  loading: boolean;
  data: HelpData | null;
  error: string | null;
}
const initialState: Country = {
  countries: [],
  states: [],
  cities: [],
  CountryOfJurisdictionList: [],
  currencies: [],
  preferences: null,
  aiSettings: null,
  chatList: [],
  MessageList: [],
  aiVoices: [],
  loading: false,
  data: null,
  error: null,
};

const DataReducer = (state = initialState, action: HelpActionTypes | any) => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        countries: action.countries,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currencies: action.currencies,
      };
    case SET_STATE:
      return {
        ...state,
        states: action.states,
      };
    case SET_CITY:
      return {
        ...state,
        cities: action.cities,
      };
    case SET_PREFERENCES:
    case GET_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: action.preferences[0],
      };
    case SET_AI_SETTINGS:
    case GET_AI_SETTINGS_SUCCESS:
      return {
        ...state,
        aiSettings: action.aiSettings[0],
      };
    case SET_JURISDICTION_COUNTRY:
      return {
        ...state,
        CountryOfJurisdictionList: action.CountryOfJurisdictionList,
      };
    case SET_CHAT_LIST:
      return {
        ...state,
        chatList: action.chat_list,
      };
    case SET_MESSAGE_LIST:
      return {
        ...state,
        MessageList: action.message_list,
      };
    case SET_AI_VOICES:
      return {
        ...state,
        aiVoices: action.voices,
      };
    case GET_USER_MANUAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USER_MANUAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_USER_MANUAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default DataReducer;
