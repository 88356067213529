import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './GlobalSidePanel.css';
import { RootState } from '../../store';
import {
  hideSidePanel,
  expandSidePanel,
  collapseSidePanel,
  updateSidePanel,
} from '../../store/actions/sidePanelActions';
import AddTaskComponent from '../Marketplace/Lawyer/AddTaskComponent';
import NewFileModal, { NewFileData } from '../Documents/NewFileModal';
import { getUserID } from '../../store/actions/ChatAction';
import SupportTicketSystem from '../Feedback/SupportTicketSystem';
import HelpContent from '../Help/HelpContent';
import CaseView from '../Dashboard/AdminFirm/FirmComponents/CaseView';
import EmployeeView from '../Dashboard/AdminFirm/FirmComponents/EmployeeView';
import Onboarding from '../Dashboard/AdminFirm/FirmComponents/Onboarding';
import TeamView from '../Dashboard/AdminFirm/FirmComponents/TeamView';
import ManageTeam from '../Dashboard/AdminFirm/FirmComponents/ManageTeam';
import { SidePanelState } from '../../store/reducer/sidePanelReducer';
import AddClientForm from '../ClientPortal/AddClientForm';
import SidePanel from '../ClientPortal/SidePanel';
import TimekeepingSidePanel from '../TimeTracker/SidePanel';

const GlobalSidePanel: React.FC = () => {
  const sidePanelState = useSelector(
    (state: RootState): SidePanelState => state.sidePanel
  );
  const dispatch = useDispatch();
  const userId = getUserID();

  const handleClose = () => {
    if (sidePanelState.onCancel) {
      sidePanelState.onCancel();
    }
    dispatch(hideSidePanel());
  };

  const handleToggleExpand = () => {
    if (sidePanelState.isExpanded) {
      dispatch(collapseSidePanel());
    } else {
      dispatch(expandSidePanel());
    }
  };

  const renderContent = () => {
    switch (sidePanelState.type) {
      case 'addTask':
        return <AddTaskComponent />;
      case 'addFile':
        return (
          <NewFileModal
            folders={sidePanelState.folders || []}
            onSave={(fileData: NewFileData) => {
              if (sidePanelState.onConfirm) {
                sidePanelState.onConfirm(fileData);
              }
              handleClose();
            }}
            currentUserId={userId}
            preSelectedFolderId={sidePanelState.preSelectedFolderId}
          />
        );
      case 'supportTicket':
        return <SupportTicketSystem />;
      case 'helpContent':
        return <HelpContent content={sidePanelState.content} />;
      case 'onboarding':
        return (
          <Onboarding
            addEmployee={sidePanelState.addEmployee}
            closePanel={handleClose}
          />
        );
      case 'employeeView':
        return (
          <EmployeeView
            employee={sidePanelState.employee}
            isAddingTaskPanelOpen={sidePanelState.isExpanded || false}
            setIsAddingTaskPanelOpen={(isOpen: boolean) => {
              if (isOpen) {
                dispatch(expandSidePanel());
              } else {
                dispatch(collapseSidePanel());
              }
            }}
          />
        );
      case 'teamView':
        return <TeamView team={sidePanelState.team} />;
      case 'caseView':
        return (
          <CaseView
            formatNumber={(value: string) =>
              value.replace(/\B(?=(\d{3})+\b)/g, ' ')
            }
            caseItem={sidePanelState.caseItem}
          />
        );
      case 'manageTeam':
        return (
          <ManageTeam
            allMembers={sidePanelState.allMembers}
            subjects={sidePanelState.subjects}
          />
        );
        case 'addClient':
          return <AddClientForm onClose={handleClose} />;
          case 'clientView':
            return (
              <SidePanel
                client={sidePanelState.client}
                onClose={handleClose}
                setSelectedCase={(caseItem) => {
                  dispatch(
                    updateSidePanel({
                      selectedCase: caseItem,
                      isExpanded: !!caseItem,
                      width: caseItem ? '85%' : '50%',
                    })
                  );
                }}
                selectedCase={sidePanelState.selectedCase}
              />
            );
            case 'timekeepingView':
              return (
                <TimekeepingSidePanel
                day={sidePanelState.day}
                data={sidePanelState.data}
                isLoading={sidePanelState.isLoading}
                stats={sidePanelState.stats}
                onClose={handleClose}
              />
              );
      case 'custom':
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={`global-backdrop ${
          sidePanelState.showSidePanel ? 'show' : ''
        }`}
        onClick={handleClose}
      ></div>
      <div
        className={`global-side-panel ${
          sidePanelState.showSidePanel ? 'open' : ''
        } ${sidePanelState.isExpanded ? 'expanded' : ''}`}
        style={{ width: sidePanelState.width }}
      >
        <button
          className="global-close-btn--sidepanel"
          onClick={handleClose}
        >
          <i className="fas fa-chevron-right"></i>
        </button>
        {/* Optional Expand/Collapse Button */}
        {/* <button className="expand-btn--sidepanel" onClick={handleToggleExpand}>
          {sidePanelState.isExpanded ? 'Collapse' : 'Expand'}
        </button> */}
        <div className="global-side-panel-content">{renderContent()}</div>
      </div>
    </>
  );
};

export default GlobalSidePanel;
