import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './TranslationModal.css';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../store';
import { fetchTranslationLanguages, translateDocument } from '../../store/actions/DocumentsAction';
import { hideModal } from '../../store/actions/modalActions';
import GlobalDropdown, { DropdownOption } from '../shared/GlobalDropdown';

interface TranslationModalProps {
  fileID: string | undefined;
}

const TranslationModal: React.FC<TranslationModalProps> = ({ fileID }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const translationLanguages = useSelector(
    (state: RootState) => state.documents.translationLanguages
  );
  const translationLoading = useSelector(
    (state: RootState) => state.documents.translationLoading
  );
  const translationError = useSelector(
    (state: RootState) => state.documents.translationError
  );

  const [selectedLanguageId, setSelectedLanguageId] = useState<string>('');

  useEffect(() => {
    dispatch(fetchTranslationLanguages());
  }, [dispatch]);

  const handleTranslate = () => {
    if (!selectedLanguageId) {
      alert(t('Please select a language to translate.'));
      return;
    }
    dispatch(translateDocument(fileID!, selectedLanguageId))
      .then((resp: any) => {
        if (resp.payload) {
          alert(t('Document translated successfully.'));
          dispatch(hideModal());
        } else {
          alert(t('Failed to translate the document.'));
        }
      })
      .catch(() => {
        alert(t('An error occurred during translation.'));
      });
  };

  return (
    <div className="translation-modal">
      {translationLoading ? (
        <div className="translate-loader-container">
          <ClipLoader size={20} color="#fff" loading={true} />
        </div>
      ) : (
        <>
          {translationError && <p className="error-message">{t(translationError)}</p>}
          <div className="dropdown-container">
            <GlobalDropdown
              options={translationLanguages.map((lang: any) => ({
                id: lang.TranslationCode,
                label: lang.Language,
              }))}
              onSelect={(id) => setSelectedLanguageId(id)}
              selectedLabel={
                selectedLanguageId
                  ? translationLanguages.find((lang: any) => lang.TranslationCode === selectedLanguageId)?.Language || ''
                  : t('Select Language')
              }
              placeholder={t('Select Language')}
            />
          </div>
          <div className="modal-actions">
            <button className="primary-button" onClick={handleTranslate} disabled={!selectedLanguageId}>
              {t('Translate')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TranslationModal;
