import { SET_ACTIVE_ROLE, SET_SLIDER_POSITION } from "../types";


export const setActiveRole = (role: string) => ({
    type: SET_ACTIVE_ROLE,
    payload: role
});

export const setSliderPosition = (position: number) => ({
    type: SET_SLIDER_POSITION,
    payload: position
});
