import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Marketplace.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import GlobalDropdown from '../shared/GlobalDropdown';
import { caseTypeOptions, preferredLanguageOptions } from './Client/LCMUploadForm';

interface FilterDropdownProps {
  applyFilters: (filters: Record<string, string | number>) => void;
  toggleDropdown: () => void;
}

export type FilterSetting = [string, string];

const FilterDropdown: React.FC<FilterDropdownProps> = ({ applyFilters, toggleDropdown }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const urgencyList = useSelector((state: RootState) => state.case.urgencyList);
  const {t} = useTranslation()

  // Initialize filters from local storage if available
  const initialFilters = JSON.parse(localStorage.getItem('filters') || '{}');
  const [filters, setFilters] = useState({
    country: initialFilters.country || '',
    caseType: initialFilters.caseType || '',
    language: initialFilters.language || '',
    urgency: initialFilters.urgency || '',
    minBudget: initialFilters.minBudget || 0,
    maxBudget: initialFilters.maxBudget || 0
  });


  useEffect(() => {
    updateSliderBackground(filters.minBudget, 100000, 'minBudget');
    updateSliderBackground(filters.maxBudget, 1000000, 'maxBudget');
  }, [filters.minBudget, filters.maxBudget]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  const handleFilterChange = useCallback((name: string, value: string | number) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleApplyFilters = useCallback(() => {
    applyFilters(filters);
    toggleDropdown();
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters, applyFilters, toggleDropdown]);

  const handleClearFilters = useCallback(() => {
    const resetFilters = {
      country: '',
      caseType: '',
      language: '',
      urgency: '',
      minBudget: 0,
      maxBudget: 0
    };
    setFilters(resetFilters);
    applyFilters(resetFilters);
    localStorage.setItem('filters', JSON.stringify(resetFilters));
    toggleDropdown();
  }, [applyFilters, toggleDropdown]);

  const formatNumber = (value: string) => {
    return value.toString().replace(/\B(?=(\d{3})+\b)/g, " ");
  };


  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    const max = parseFloat(e.target.max);
    const name = e.target.name;
    setFilters(prev => ({ ...prev, [name]: value }));
    updateSliderBackground(value, max, name);
  };


  const updateSliderBackground = (value: number, max: number, name: string) => {
    const percentage = (value / max) * 100;
    const slider = document.querySelector(`input[name='${name}']`) as HTMLInputElement;
    if (slider) {
      slider.style.setProperty('--range-percentage', `${percentage}%`);
    }
  };





  return (
    <div className={`filter-dropdown ${isDropdownOpen ? 'open' : ''}`} ref={dropdownRef}>

      <div className='filter-dropdown__row'>
        <div className="filter-option">
          <label>{t('Case Type')}</label>
          <GlobalDropdown
            options={caseTypeOptions.map((caseType: string) => ({ id: caseType, label: caseType }))}
            onSelect={(selectedId) => handleFilterChange('caseType', selectedId)}
            selectedLabel={filters.caseType}
            placeholder="Select Case Type"
            style={{ width: '100%' }}
          />
        </div>
        {/* Urgency filter */}
        <div className="filter-option">
          <label>{t('Urgency')}</label>
          <GlobalDropdown
            options={urgencyList.map((urgency: string) => ({ id: urgency, label: urgency }))}
            onSelect={(selectedId) => handleFilterChange('urgency', selectedId)}
            selectedLabel={filters.urgency}
            placeholder="Select Urgency"
            style={{ width: '100%' }}
          />
        </div>
      </div>

      <div className='filter-dropdown__row'>
        <div className="filter-option">
          <label>{t('Country')}</label>
          <GlobalDropdown
            options={[{ label: 'Norway', id: 'Norway' }, { label: 'Sweden', id: 'Sweden' }, { label: 'Spain', id: 'Spain' }, { label: 'United Kingdom', id: 'United Kingdom' }, { label: 'United States', id: 'United States' }, { label: 'Danmark', id: 'Danmark' }, { label: 'Finland', id: 'Finland' }, { label: 'India', id: 'India' }]}
            onSelect={(selectedId) => handleFilterChange('country', selectedId)}
            selectedLabel={filters.country}
            placeholder="Select Country"
            style={{ width: '100%' }}
          />
        </div>

        {/* Preferred Language filter */}
        <div className="filter-option">
          <label>{t('Preferred Language')}</label>
          <GlobalDropdown
            options={preferredLanguageOptions.map(language => ({ id: language, label: language }))}
            onSelect={(selectedId) => handleFilterChange('language', selectedId)}
            selectedLabel={filters.language}
            placeholder="Select Language"
            style={{ width: '100%' }}
          />
        </div>
      </div>
      {/* Budget filters */}
      <div className="filter-option">
        <label>{t('Min Budget')}</label>
        <input
          type="range"
          min="0"
          max="100000"  
          value={filters.minBudget}
          onChange={handleRangeChange}
          name="minBudget"
          style={{ width: '100%', cursor: 'pointer' }}
        />
        <span>{formatNumber(filters.minBudget)}</span>
      </div>
      <div className="filter-option">
        <label>{t('Max Budget')}</label>
        <input
          type="range"
          min="0"
          max="1000000"  
          value={filters.maxBudget}
          onChange={handleRangeChange}
          name="maxBudget"
          style={{ width: '100%', cursor: 'pointer' }}
        />
        <span>{formatNumber(filters.maxBudget)}</span>
      </div>
     
    <div className='filter-lawyer__buttons'>
      <button onClick={handleApplyFilters} className="apply-filters-button">{t('Apply Filters')}</button>
      <button onClick={handleClearFilters} className="clear-filters-button">{t('Clear Filters')}</button>
      </div>
    </div>
  );
};

export default FilterDropdown;
