import React from 'react';
import './TimeTracker.css';
import { ClipLoader } from 'react-spinners';
import { Description } from '../Help/types';
import { useTranslation } from 'react-i18next';

interface Task {
  name: string;
  hours: number;
  case: string;
}

interface Case {
  name: string;
  hours: number;
}

interface DayDetails {
  tasks: Task[];
  cases: Case[];
}

interface SidePanelProps {
  day: string | null;
  onClose: () => void;
  data: any;
  isLoading: boolean;
  stats: {
    clocked_hours: number;
    negative_hours: number;
    overtime: number;
    total_hours_worked: number;
    tasks: string[];
    casesWorkedOn: number;
  } | null;
}

const TimekeepingSidePanel: React.FC<SidePanelProps> = ({ day, onClose, data, isLoading, stats }) => {
  const { t } = useTranslation();

  if (!data) {
    return (
      <div className="employee-day--details">
        <div className="non-access">
          {t('No data available for')} {day}
        </div>
      </div>
    );
  }

  const formatDecimalHours = (decimalHours: number): string => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="employee-day--details">
      {isLoading ? (
        <div style={{ width: '100%', alignItems: 'center' }} className="non-access">
          <ClipLoader size={20} color="#fff" />
        </div>
      ) : (
        <>
          <h3>
            {t('Details for')} {day}
          </h3>

          {/* Display Stats */}
          {stats && (
            <div className="stats-container">
              <div className="stat-item">
                <span className="stat-title">{t('Tasks Completed')}</span>
                <span className="stat-value">{stats.tasks.length}</span>
              </div>
              <div className="stat-item">
                <span className="stat-title">{t('Cases Worked On')}</span>
                <span className="stat-value">{stats.casesWorkedOn || 0}</span>
              </div>
              <div className="stat-item">
                <span className="stat-title">{t('Clocked Hours')}</span>
                <span className="stat-value">{formatDecimalHours(stats.clocked_hours)}</span>
              </div>
              <div className="stat-item">
                <span className="stat-title">{t('Negative Hours')}</span>
                <span className="stat-value">{formatDecimalHours(stats.negative_hours)}</span>
              </div>
              <div className="stat-item">
                <span className="stat-title">{t('Overtime')}</span>
                <span className="stat-value">{formatDecimalHours(stats.overtime)}</span>
              </div>
              <div className="stat-item">
                <span className="stat-title">{t('Total Hours Worked')}</span>
                <span className="stat-value">{formatDecimalHours(stats.total_hours_worked)}</span>
              </div>
            </div>
          )}

          {/* Tasks Details */}
          <div className="employee-day--tasks-details">
            <h4>{t('Tasks Completed')}</h4>
            {data.map((task: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  {task.task_id !== '' ? (
                    <ul style={{ marginBottom: '10px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <li>
                          <span>{task.task_detail.TaskTitle}</span>
                        </li>
                        <li>
                          <span
                            style={{
                              padding: '3px 8px',
                              borderRadius: 4,
                              backgroundColor: '#1e1f21',
                              color: '#64d2ff',
                            }}
                          >
                            {task.task_detail.Status}
                          </span>
                        </li>
                      </div>
                      <li>
                        <span>{formatDecimalHours(parseFloat(task.duration))} {t('Hours spent')}</span>
                      </li>
                      <li>
                        <span
                          style={{
                            padding: 8,
                            backgroundColor: '#1e1f21',
                            flex: 1,
                            minHeight: 50
                          }}
                        >
                          {task.task_detail.TaskDescription}
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <div className='non-access'>{t('No tasks worked on')}</div>
                  )}
                </React.Fragment>
              );
            })}
          </div>

          {/* Cases Details */}
          <div className="employee-day--cases-details">
            <h4>{t('Cases Worked On')}</h4>
            {data.map((task: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  {task.case_id !== '' ? (
                    <ul style={{ marginBottom: '10px' }}>
                      <li>
                        <strong>{t('Case Type')}:</strong> <span>{task.case_detail.CaseType}</span>
                      </li>
                      <li>
                        <strong>{t('Case Description')}:</strong>{' '}
                        <span>{task.case_detail.Description}</span>
                      </li>
                      <li>
                        <strong>{t('Desired outcome')}:</strong>{' '}
                        <span>{task.case_detail.DesiredOutcome}</span>
                      </li>
                      <li>
                        <strong>{t('Expected outcome')}:</strong>{' '}
                        <span>{task.case_detail.ExpectedOutcome}</span>
                      </li>
                      <li>
                        <strong>{t('Status')}:</strong> <span>{task.case_detail.Status}</span>
                      </li>
                      <li>
                        <strong>{t('Total Work Hours')}:</strong>{' '}
                        <span>{formatDecimalHours(parseFloat(task.duration))}</span>
                      </li>
                    </ul>
                  ) : (
                    <div className='non-access'>{t('No cases worked on')}</div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default TimekeepingSidePanel;
