import { useTranslation } from "react-i18next";


function TimeTrackingPage() {
    const { t } = useTranslation();
    return (
        <div style={{ height: 'calc(100vh - 120px)' }}>
             <div style={{ height: '100%' }} className="non-access">{t('Coming in Q4')}</div>
        </div>
    )
}

export default TimeTrackingPage;