import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDropdownLCM from '../../../CustomDropdownLCM.tsx/CustomDropdownLCM';
import { Team, Member } from './ManageTeam'; 
import './ManageTeam.css';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormValues } from './AddTeam';

interface ManageTeamDetailsProps {
  team: Team;
  allMembers: Member[];
  subjects: any[];
}

const ManageTeamDetails: React.FC<ManageTeamDetailsProps> = ({
  team,
  allMembers,
  subjects,
}) => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState(team.TeamName);
  const [teamLead, setTeamLead] = useState(`${team.TeamLeadFirstName} ${team.TeamLeadLastName}`);

  const transformSubjects = (subjects: any[]) => {
    return subjects.map(sub => ({
      value: sub.LegalSubjectID.toString(),
      label: sub.SubCategory || sub.LegalSubjectName
    }));
  };


  const uniqueLegalSubjects = transformSubjects(subjects);
  const handleUpdateTeam = (e: React.FormEvent) => {
    e.preventDefault();
    // if (team) {
    //   updateTeam({
    //     ...team,
    //     TeamName: teamName,
    //     TeamLeadID: teamLead
    //   });
    // }
  };

  const handleDeleteTeam = () => {
    if (team) {
      // deleteTeam(team.LegalTeamID);
      // setActivePanel(null);
      setTeamName('');
      setTeamLead('');
    }
  };

  // const handleRemoveMember = (memberId: string) => {
  //   const updatedMembers = team.Members.filter(member => member.MemberID !== memberId);
  //   const removedMember = team.Members.find(member => member.MemberID === memberId);
  //   const updatedTeamLead = teamLead === (removedMember ? removedMember.MemberFirstName : '') ? '' : teamLead;

  //   updateTeam({
  //     ...team,
  //     Members: updatedMembers,
  //     TeamLeadID: updatedTeamLead
  //   });

  //   setTeamLead(updatedTeamLead);
  // };
  

  const getValidationSchema = () => {
    return Yup.object().shape({
      teamName: Yup.string().required(t('Team Name is required')),
      teamLead: Yup.string().required(t('Team Lead is required')),
      members: Yup.array().min(2, t('Select at least 2 members')).required(t('Members are required')),
      legalSubjects: Yup.array().required(t('Select at least one legal subject')),
    });
  };

  const validationSchema = getValidationSchema();
  const formik = useFormik<FormValues>({
    initialValues: {
      teamName: team.TeamName,
      teamLead: team.TeamLeadFirstName  + " " + team.TeamLeadLastName,
      members: team.Members.map((val: any) => val.MemberFirstName + " " + val.MemberLastName),
      legalSubjects: team.LegalSubjects.map((val: any) => val.LegalSubjectID ),
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // const selectedMembers = lawyers.filter(lawyer => values.members.includes(`${lawyer.FirstName} ${lawyer.LastName}`));
      // const newMembers = selectedMembers.map(lawyer => ({
      //   UserID: lawyer.LawfirmLawyerID,
      //   UserType: 'LawfirmLawyer'
      // }));
      // const teamLead = selectedMembers.find(lawyer => `${lawyer.FirstName} ${lawyer.LastName}` === values.teamLead);
      // const legalSubjectIDs = values.legalSubjects.map(subject => subject);

      // const payload = {
      //   LawfirmID: userId,
      //   TeamName: values.teamName,
      //   TeamLeadUserID: teamLead?.LawfirmLawyerID || '',
      //   TeamLeadUserType: 'LawfirmLawyer',
      //   LegalSubjects: legalSubjectIDs,
      //   Members: newMembers
      // };

      // await dispatch(createTeam(payload));
      // resetForm();
      // setIsPanelOpen(null);
      // dispatch(getLegalTeams(userId));
    },
  });

  const handleMembersChange = (value: string | string[]) => {
    const selectedMembers = Array.isArray(value) ? value : [value];
    formik.setFieldValue('members', selectedMembers);
    if (!selectedMembers.includes(formik.values.teamLead)) {
      formik.setFieldValue('teamLead', '');
    }
  };


  // const handleRemoveSubject = (subject: { LegalSubjectID: string; LegalSubjectName: string }) => {
  //   const updatedSubjects = team.LegalSubjects.filter(sub => sub.LegalSubjectID !== subject.LegalSubjectID);
  //   updateTeam({
  //     ...team,
  //     LegalSubjects: updatedSubjects
  //   });
  // };

  // const handleAddSubject = (selectedSubjects: { LegalSubjectID: string; LegalSubjectName: string }[]) => {
  //   const newSubjects = selectedSubjects.filter(
  //     (newSub) => !team.LegalSubjects.some((existingSub) => existingSub.LegalSubjectID === newSub.LegalSubjectID)
  //   );
  //   const updatedSubjects = [...team.LegalSubjects, ...newSubjects];
  //   updateTeam({
  //     ...team,
  //     LegalSubjects: updatedSubjects
  //   });
  // };

  const handleLegalSubjectsChange = (selectedSubjects: string | string[]) => {

    const SelectedSubjects = Array.isArray(uniqueLegalSubjects)
      ? uniqueLegalSubjects
        .filter((subject: any) =>
          Array.isArray(selectedSubjects)
            ? selectedSubjects.includes(subject.label) 
            : selectedSubjects === subject.label 
        )
        .map((subject: any) => subject.value) 
      : "";
    formik.setFieldValue('legalSubjects', SelectedSubjects);
  };

  return (
    <form onSubmit={handleUpdateTeam} className='manage-team-details'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h2 style={{ color: '#fff', paddingLeft: 30, fontSize: '1.1rem', marginBottom: 5 }}>{t('Manage existing team')}</h2>
        
        <div className='team-group'>
          <label htmlFor='team-name' className='form-label'>{t('Team Name')}</label>
          <input
            type='text'
            name='teamName'
            maxLength={70}
            value={formik.values.teamName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`team-control-input`}
            id='team-name'
          />
        </div>

        <div className='team-group'>
          <CustomDropdownLCM
            label={t('Select Members')}
            options={allMembers.map((val: any) => `${val.FirstName} ${val.LastName}`)}
            value={formik.values.members}
            onChange={handleMembersChange}
            multiSelect={true} 
            className={`dropdown-Members`}
          />
        </div>

        <div className='team-group'>
          <CustomDropdownLCM
            label={t('Select Team Lead')}
            options={formik.values.members.map(member => member)}
            value={formik.values.teamLead}
            onChange={(value) => formik.setFieldValue('teamLead', value)}
            className={`dropdown-Members`}
          />
        </div>

        <div className='team-group'>
          <CustomDropdownLCM
            label={t('Add Legal Subjects')}
            options={uniqueLegalSubjects.map(sub => sub.label)}
            value={formik.values.legalSubjects.map((selectedValue: string) => {
              const matchingSubject = uniqueLegalSubjects.find(subject => subject.value === selectedValue);
              return matchingSubject ?  matchingSubject.label : '';

            })}
            onChange={handleLegalSubjectsChange}
            multiSelect={true} // Enable multi-select
            className={`dropdown-Subjects`}
          />
        </div>
      </div>

      <div className='team-details--buttons'>
        <button className='primary-button' type="submit">{t('Update Team')}</button>
        <button className='destructive-button' type="button" onClick={handleDeleteTeam}>{t('Delete Team')}</button>
      </div>
    </form>
  );
};

export default ManageTeamDetails;