import React, { useEffect, useState } from 'react';
import './ClientPortal.css';
import { useAppDispatch } from '../../store';
import { analyzeCase, createCase, deleteCase, deleteClient, fetchAllClientCases, fetchCase, generateDocument, updateCase, updateCaseStatus } from '../../store/actions/ClientAction';
import { CaseData } from '../../store/reducer/ClientReducer';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx'; 
import ClipLoader from 'react-spinners/ClipLoader';
import { hideModal, showModal } from '../../store/actions/modalActions';
import GlobalDropdown from '../shared/GlobalDropdown';
import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import TurndownService from 'turndown';
import { marked } from 'marked'
import { Avatar, Tooltip } from '@mui/material';
import { collapseSidePanel, expandSidePanel } from '../../store/actions/sidePanelActions';

interface SidePanelProps {
  client: any;
  onClose: () => void;
  setSelectedCase: (clientCase: any) => void;
  selectedCase: any;
}

const statusOptions = [
  { id: 'Active', label: 'Active' },
  { id: 'Pending', label: 'Pending' },
  { id: 'Closed', label: 'Closed' },
  { id: 'Completed', label: 'Completed' },
];


const SidePanel: React.FC<SidePanelProps> = ({ client, onClose, setSelectedCase, selectedCase }) => {
  const [isAddingCase, setIsAddingCase] = useState(false);
  const [caseData, setCaseData] = useState<any>({});
  const [customFields, setCustomFields] = useState<{ key: string; value: string }[]>([]);
  const [newCustomFieldName, setNewCustomFieldName] = useState<string>('');
  const [clientCases, setClientCases] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [analyzing, setAnalyzing] = useState(false); 
  const [showSummary, setShowSummary] = useState(false); 
  const [editableFields, setEditableFields] = useState<any>({});
  const [editableConclusion, setEditableConclusion] = useState<string>('');
  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [clientOptions, setClientOptions] = useState(false);

  useEffect(() => {
    if (client) {
      fetchClientCases();
    }
  }, [client, dispatch]);

  useEffect(() => {
    console.log('Selected case updated:', selectedCase);
  }, [selectedCase]);  

  useEffect(() => {
    const processConclusion = async () => {
      if (selectedCase) {
        const htmlConclusion = await marked(selectedCase.case_conclusion || '');
        setEditableConclusion(htmlConclusion);
      }
    };
    processConclusion();
  }, [selectedCase]);
  

  const fetchClientCases = async () => {
    setIsLoading(true);
    try {
      const caseList = await dispatch(fetchAllClientCases(client.UserID));
      if (Array.isArray(caseList)) {
        setClientCases(caseList);
      }
    } catch (err) {
      console.error('Failed to fetch client cases:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditableChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('custom_fields.')) {
      const fieldName = name.replace('custom_fields.', '');
      setEditableFields({
        ...editableFields,
        custom_fields: {
          ...editableFields.custom_fields,
          [fieldName]: value,
        },
      });
    } else {
      setEditableFields({
        ...editableFields,
        [name]: value,
      });
    }
  };
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCaseData({
      ...caseData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCustomFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomFieldName(e.target.value);
  };

  const addCustomField = () => {
    if (newCustomFieldName.trim() !== '') {
      setCustomFields([...customFields, { key: newCustomFieldName, value: '' }]);
      setNewCustomFieldName(''); 
    }
  };

  const handleCustomFieldValueChange = (index: number, value: string) => {
    const updatedFields = [...customFields];
    updatedFields[index].value = value;
    setCustomFields(updatedFields);
  };

  const handleCaseSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
  
    const newCase: CaseData = {
      client_id: client.UserID,
      case_type: caseData.case_type || '',
      case_status: 'Active',
      lawyer_id: client.lawyer_id,
      main_issue: caseData.main_issue || '',
      case_summary: caseData.case_summary || '',
      plaintiff_info: caseData.plaintiff_info || '',
      defendant_info: caseData.defendant_info || '',
      witness_statements: caseData.witness_statements || '',
      evidence: caseData.evidence || '',
      legal_arguments: caseData.legal_arguments || '',
      court_dates: caseData.court_dates || '',
      custom_fields: customFields.reduce((acc, field) => {
        if (field.key.trim()) {
          acc[field.key] = field.value;
        }
        return acc;
      }, {} as Record<string, string>),
    };
  
    const success = await dispatch(createCase(newCase));
  
    if (success) {
      setIsAddingCase(false);
      setCaseData({}); 
      setCustomFields([]); 
      await fetchClientCases();
    } else {
      console.error('Error creating case');
    }
  
    setIsLoading(false);
  };

  const handleAnalyzeCase = async () => {
    if (!selectedCase) return;
    setAnalyzing(true);
    setShowSummary(false); 
    try {
      const response = await dispatch(analyzeCase(selectedCase.CaseID));
      if (response && response.data) {
        const analysisResponse = response.data.gpt_analysis; 
        if (analysisResponse) {
          const updatedCase = { ...selectedCase, case_conclusion: analysisResponse };
          setSelectedCase(updatedCase); 

          setShowSummary(true);
        } else {
          console.warn('Analysis response not found');
          setShowSummary(false);
        }
      } else {
        console.error('Invalid response from analysis');
        setShowSummary(false);
      }
    } catch (err) {
      console.error('Error analyzing case:', err);
      setShowSummary(false); 
    } finally {
      setAnalyzing(false);
    }
  };
  
  
  
  
  const handleCaseClick = (clientCase: any) => {
    if (selectedCase && clientCase.CaseID === selectedCase.CaseID) {
      setSelectedCase(null);
    } else {
      setSelectedCase(clientCase);
      setIsAddingCase(false); 
      setShowSummary(false);
    }
  };
  

  const closeDetailPanel = () => {
    setSelectedCase(null);
    setIsAddingCase(false);
    dispatch(collapseSidePanel())
  };

  const handleEdit = () => {
    setIsUpdating(true);
    setEditableFields({
      ...selectedCase.fields,
      custom_fields: selectedCase.custom_fields || {},
    });
  
    const htmlConclusion = marked.parse(selectedCase.case_conclusion || '') as string;
    setEditableConclusion(htmlConclusion);
  };
  

  const handleConclusionChange = (content: string) => {
    setEditableConclusion(content);
  };

  const onToggle = () => {
    setShowSummary(!showSummary)
  }

  const handleGenerateDocument = async () => {
    if (!selectedCase) return;
    setIsGeneratingDocument(true);
  
    try {
      const response = await dispatch(generateDocument(selectedCase.CaseID));
  
      if (response && response.data) {
        const documentUrl = response.data.document_url; 
  
        if (documentUrl) {
          window.open(documentUrl, '_blank');
        }
      } else {
        console.error('Invalid response from document generation');
      }
    } catch (err) {
      console.error('Error generating document:', err);
    } finally {
      setIsGeneratingDocument(false);
    }
  };

  const handleDeleteClient = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: 'Are you sure you want to delete this client?',
        onConfirm: async () => {
          const success = await dispatch(deleteClient(client.UserID));
          if (success) {
            onClose();
          }
          dispatch(hideModal())
        },
        onCancel: () => {
          dispatch(hideModal())
        },
      })
    );
  };
  
  const handleDeleteCase = () => {
    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: 'Are you sure you want to delete this case?',
        onConfirm: async () => {
          if (selectedCase) {
            const success = await dispatch(deleteCase(selectedCase.CaseID, client.UserID));
            if (success) {
              setSelectedCase(null);
              await fetchClientCases();
            }
            setSelectedCase(null);
            await fetchClientCases();
            dispatch(hideModal())
          }
        },
        onCancel: () => {
          dispatch(hideModal())
        },
      })
    );
  };
  
  
  
  const handleStatusChange = async (newStatusId: string) => {
    if (selectedCase) {
      const success = await dispatch(updateCaseStatus(selectedCase.CaseID, client.UserID, newStatusId));
      if (success) {
        setSelectedCase({ ...selectedCase, case_status: newStatusId });
      }
    }
  };  

  const turndownService = new TurndownService();

  const handleSave = async () => {
    setIsUpdating(false);
    if (selectedCase) {
      // Convert HTML (from React Quill) to Markdown for storage
      const markdownConclusion = turndownService.turndown(editableConclusion);
  
      const updatedCaseData: CaseData = {
        case_id: selectedCase.CaseID,
        client_id: client.UserID,
        case_type: selectedCase.case_type || '',
        main_issue: editableFields.main_issue || '',
        case_summary: editableFields.case_summary || '',
        plaintiff_info: editableFields.plaintiff_info || '',
        defendant_info: editableFields.defendant_info || '',
        witness_statements: editableFields.witness_statements || '',
        evidence: editableFields.evidence || '',
        legal_arguments: editableFields.legal_arguments || '',
        court_dates: editableFields.court_dates || '',
        case_conclusion: markdownConclusion || '',
        custom_fields: editableFields.custom_fields || {},
      };
  
      const success = await dispatch(updateCase(updatedCaseData));
  
      if (success) {
        // Update the selectedCase with new data
        setSelectedCase((prevCase: any) => ({
          ...prevCase,
          fields: {
            ...prevCase.fields,
            ...editableFields,
          },
          custom_fields: editableFields.custom_fields || {},
          case_conclusion: markdownConclusion,
        }));
  
        // Update the clientCases array
        setClientCases((prevCases) =>
          prevCases.map((caseItem) =>
            caseItem.CaseID === selectedCase.CaseID
              ? { ...caseItem, ...updatedCaseData, fields: { ...caseItem.fields, ...editableFields } }
              : caseItem
          )
        );
      }
    }
  };
  

  const handleAddClientClick = () => {
    setIsAddingCase(!isAddingCase);
    setSelectedCase(null)
    if (isAddingCase) {
      dispatch(collapseSidePanel())
    } else if (!isAddingCase) {
    dispatch(expandSidePanel())
    }
  }

  const handleOptions = () => {
    setClientOptions(!clientOptions);
  };

  const getInitials = (client: any): string => {
    if (client.UserType === 'ExternalBusinessClient') {
      const contactName = client.contact_name || '';
      const initials = contactName
        .split(' ')
        .map((name: string) => name.charAt(0).toUpperCase())
        .join('');
      return initials || client.company_name.charAt(0).toUpperCase();
    } else {
      const firstInitial =
        client.first_name?.charAt(0).toUpperCase() || client.FirstName?.charAt(0).toUpperCase() || '';
      const lastInitial =
        client.last_name?.charAt(0).toUpperCase() || client.LastName?.charAt(0).toUpperCase() || '';
      return `${firstInitial}${lastInitial}`;
    }
  };

  if (!client) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <div className={`client-portal--side-panel ${(selectedCase || isAddingCase) ? 'expand' : ''}`}>

      <div className='client-view-container--header'>
          <h2>{t('Client Details')}</h2>
          <div className='client-options--wrapper'>
            <button className={`client-options-btn ${clientOptions ? 'open' : ''}`} onClick={handleOptions}>
              <i className='fas fa-ellipsis-vertical'></i>
            </button>
            {clientOptions && (
              <div className='client-options'>
                <button
                  style={{ fontSize: '0.8rem' }}
                  className='destructive-button'
                  onClick={handleDeleteClient}
                >
                  {t('Delete Client')}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='client-profile'>
          <div className='client-profile--header'>
            <div className='client-profile--header__first'>
              <Avatar
                src={undefined}
                alt={`${client.UserType === 'ExternalBusinessClient' ? client.company_name : client.first_name} profile`}
                className='profile-picture'
                sx={{
                  width: 80,
                  height: 80,
                  fontSize: '1.5rem',
                  borderRadius: 2,
                  background: '#111',
                  color: '#64d2ff',
                  border: '1px solid #444',
                }}
                variant='square'
              >
                {getInitials(client)}
              </Avatar>
              <div className='client-view--column'>
                <p className='clientname'>
                  {client.UserType === 'ExternalBusinessClient'
                    ? client.company_name
                    : `${client.first_name || client.FirstName} ${client.last_name || client.LastName}`}
                </p>
                <p className='clientemail'>
                  {client.UserType === 'ExternalBusinessClient' ? client.contact_name : ''}
                </p>
                <p className='clientemail'>
                  {client.UserType === 'ExternalBusinessClient' ? client.contact_email : client.email}
                </p>
                <p className='clientphone'>
                  {client.UserType === 'ExternalBusinessClient' ? client.contact_phone : client.phone_number}
                </p>
              </div>
            </div>
            <div className='client-availability-container'>
              <p className='client--type'>{client.UserType === 'ExternalBusinessClient' ? t('Business') : t('Client')}</p>
            </div>
          </div>
          <div className='client-view--row'>
            <div className='client-view--column'>
              <p>{t('Address')}</p>
              <div className='client-profile__address--wrapper'>
                <span>{client.address || t('No address provided')}</span>
              </div>
            </div>
            <div className='client-view--column'>
              <div>
                <p>{t('Created At')}</p>
                <p className='client-created-at'>
                  {new Intl.DateTimeFormat('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  }).format(new Date(client.created_at))}
                </p>
              </div>
            </div>
          </div>
        </div>

        {isLoading && <div className="analyzing-loader"><ClipLoader size={10} color='white' /></div>}

        {!isLoading && (
          <>
          <div className='client-portal--detail-header' style={{ margin: '20px 0px' }}>
            <h4 style={{ margin: 0 }}>{t('Client Cases')}</h4>
            <button   onClick={handleAddClientClick} className={`primary-button ${isAddingCase ? 'adding' : ''}`}>
          {isAddingCase ? t('Cancel Adding Case') : t('Add Case')}
        </button>
        </div>
            {clientCases.length > 0 ? (
             <ul className="client--case-list">
             {clientCases.map((clientCase) => (
               <li
                 key={clientCase.CaseID}
                 className={`client--case-item ${selectedCase && selectedCase.CaseID === clientCase.CaseID ? 'active' : ''}`} // Add a check for selectedCase
                 onClick={() => handleCaseClick(clientCase)}
               >
                 <div>
                   <p>{clientCase.case_type}</p>
                   <p>{t('Active')}</p>
                 </div>
                 <p>{new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(clientCase.created_at))}</p>
               </li>
             ))}
           </ul>           
            ) : (
              <div style={{ minHeight: '25vh', height: '50%' }} className='non-access'>{t('No cases available for this client')}</div>
            )}
          </>
        )}
      </div>

      {(selectedCase || isAddingCase) && (
              <div className="client-portal--side-panel-detail">
                <div className='client-portal--detail-header'>
                <button className="close-detail-btn" onClick={closeDetailPanel}>
                  <i className='far fa-chevron-right'></i>
                </button>
                {selectedCase && (
                  <div className='client-portal--detail-header--row'>
                {/* disabled={isGeneratingDocument || !selectedCase} */}
                <Tooltip title={t('Coming soon')}>
                <button className='primary-button disabled' disabled onClick={handleGenerateDocument}>
                    {isGeneratingDocument ? 'Generating...' : 'Generate Document'}
                </button>
                </Tooltip>
                <button className='secondary-button' onClick={handleAnalyzeCase} disabled={analyzing}>
                  {analyzing ? 'Analyzing...' : selectedCase?.case_conclusion ? 'Reanalyze Case' : 'Analyze Case'}
                </button>
                </div>
                )}
                </div>
                <div className='client-portal--detail-wrapper'>
                {isAddingCase ? (

            <form onSubmit={handleCaseSubmit} className="add-case-form">
            <div className='add-case-form--grid-wrapper'>
              {/* Standard Fields */}
              <label>
                {t('Case Type')}
                <input type="text" name="case_type" value={caseData.case_type || ''} onChange={handleInputChange} required />
              </label>
              <label>
                {t('Main Issue')}
                <textarea name="main_issue" value={caseData.main_issue || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Case Summary')}
                <textarea name="case_summary" value={caseData.case_summary || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Plaintiff Info')}
                <textarea name="plaintiff_info" value={caseData.plaintiff_info || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Defendant Info')}
                <textarea name="defendant_info" value={caseData.defendant_info || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Witness Statements')}
                <textarea name="witness_statements" value={caseData.witness_statements || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Evidence')}
                <textarea name="evidence" value={caseData.evidence || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Legal Arguments')}
                <textarea name="legal_arguments" value={caseData.legal_arguments || ''} onChange={handleInputChange} />
              </label>
              <label>
                {t('Court Dates')}
                <textarea name="court_dates" value={caseData.court_dates || ''} onChange={handleInputChange} />
              </label>

              {/* Custom Fields */}
              <h4>{t('Custom Fields')}</h4>
              <div className="add-custom-field-input">
                <input
                  type="text"
                  placeholder="Enter Field Name"
                  value={newCustomFieldName}
                  onChange={handleCustomFieldNameChange}
                />
                <button type="button" onClick={addCustomField} className="add-custom-field-btn secondary-button">{t('Add Custom Field')}</button>
              </div>

              {customFields.map((field, index) => (
                <label key={index}>
                  {field.key}
                  <textarea
                    placeholder={`Enter value for ${field.key}`}
                    value={field.value}
                    onChange={(e) => handleCustomFieldValueChange(index, e.target.value)}
                    className="custom-field-input"
                  />
                </label>
              ))}
            </div>
            <button type="submit" className="primary-button">{t('Submit Case')}</button>
            </form>
            ) : analyzing ? (
        <div className="analyzing-loader">
          <ClipLoader size={30} color="#fff" loading={true} />
          <p className="analyzing-text">{t('Analyzing case...')}</p>
        </div>
      ) : (
      <>
      <div className='client-portal--detail-header'>
        <h4>{t('Case Details')}</h4>
        {selectedCase.case_conclusion && (
        <div className="toggle-switch__client">
            <button className={`button left ${showSummary ? '' : 'active'}`} onClick={onToggle}>{t('Details')}</button>
            <button className={`button right ${showSummary ? 'active' : ''}`} onClick={onToggle}>{t('Conclusion')}</button>
            <div className={`slider-client ${showSummary ? 'right' : ''}`}></div>
        </div>
    )}
    </div>
      <>
        <div className='client-portal--detail-header style'>
          <div className='client-portal--detail-header--style__row'>
          <div>
          <span>{t('Case Type')}</span>
          <p>{selectedCase.case_type}</p>
          </div>
          <div>
              <span>{t('Date Created')}</span>
              <p>{new Intl.DateTimeFormat('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
              }).format(new Date(selectedCase.created_at))}</p>
            </div>
            </div>
                <div>
                <GlobalDropdown
                  options={statusOptions}
                  selectedLabel={selectedCase.case_status || 'Select Status'}
                  onSelect={(id) => handleStatusChange(id)}
                />
                </div>
        </div>
        {showSummary ? (
          <div className='case-conclusion--wrapper'>
        <h4>{t('Case Conclusion')}</h4>
          {isUpdating ? (
            <ReactQuill
              value={editableConclusion}
              onChange={handleConclusionChange}
              theme="snow"
              className="case-conclusion-editor"
            />
          ) : (
            <Markdown>{selectedCase.case_conclusion}</Markdown> 
          )}
      </div>
    ) : (
        <div className='client-portal--detail--field--wrapper'>
          {/* Main Issue */}
          <div className='client-portal--detail--field'>
            <span>{t('Main Issue')}</span>
            {isUpdating ? (
              <textarea
                name="main_issue"
                value={editableFields.main_issue || ''}
                onChange={handleEditableChange}
                className="client-portal--detail--input"
              />
            ) : (
              <p>{selectedCase.fields?.main_issue}</p>
            )}
          </div>
          
          {/* Case Summary */}
          <div className='client-portal--detail--field'>
            <span>{t('Case Summary')}</span>
            {isUpdating ? (
              <textarea
                name="case_summary"
                value={editableFields.case_summary || ''}
                onChange={handleEditableChange}
                className="client-portal--detail--input"
              />
            ) : (
              <p>{selectedCase.fields?.case_summary}</p>
            )}
          </div>

          {/* Plaintiff Info */}
          <div className='client-portal--detail--field'>
            <span>{t('Plaintiff Info')}</span>
            {isUpdating ? (
              <textarea
                name="plaintiff_info"
                value={editableFields.plaintiff_info || ''}
                onChange={handleEditableChange}
                className="client-portal--detail--input"
              />
            ) : (
              <p>{selectedCase.fields?.plaintiff_info}</p>
            )}
          </div>

          {/* Defendant Info */}
            <div className='client-portal--detail--field'>
              <span>{t('Defendant Info')}</span>
              {isUpdating ? (
                <textarea
                  name="defendant_info"
                  value={editableFields.defendant_info || ''}
                  onChange={handleEditableChange}
                  className="client-portal--detail--input"
                />
              ) : (
                <p>{selectedCase.fields?.defendant_info}</p>
              )}
            </div>

            {/* Witness Statements */}
            <div className='client-portal--detail--field'>
              <span>{t('Witness Statements')}</span>
              {isUpdating ? (
                <textarea
                  name="witness_statements"
                  value={editableFields.witness_statements || ''}
                  onChange={handleEditableChange}
                  className="client-portal--detail--input"
                />
              ) : (
                <p>{selectedCase.fields?.witness_statements}</p>
              )}
            </div>

            {/* Evidence */}
            <div className='client-portal--detail--field'>
              <span>{t('Evidence')}</span>
              {isUpdating ? (
                <textarea
                  name="evidence"
                  value={editableFields.evidence || ''}
                  onChange={handleEditableChange}
                  className="client-portal--detail--input"
                />
              ) : (
                <p>{selectedCase.fields?.evidence}</p>
              )}
            </div>

            {/* Legal Arguments */}
            <div className='client-portal--detail--field'>
              <span>{t('Legal Arguments')}</span>
              {isUpdating ? (
                <textarea
                  name="legal_arguments"
                  value={editableFields.legal_arguments || ''}
                  onChange={handleEditableChange}
                  className="client-portal--detail--input"
                />
              ) : (
                <p>{selectedCase.fields?.legal_arguments}</p>
              )}
            </div>

            {/* Court Dates */}
            <div className='client-portal--detail--field'>
              <span>{t('Court Dates')}</span>
              {isUpdating ? (
                <textarea
                  name="court_dates"
                  value={editableFields.court_dates || ''}
                  onChange={handleEditableChange}
                  className="client-portal--detail--input"
                />
              ) : (
                <p>{selectedCase.fields?.court_dates}</p>
              )}
            </div>

            {selectedCase.custom_fields && Object.keys(selectedCase.custom_fields).length > 0 && (
              <div className='client-portal--detail--field'>
                <span>{t('Custom Fields')}</span>
                <ul>
                  {Object.entries(selectedCase.custom_fields).map(([key, value]) => (
                    <li key={key}>
                      <span>{key}</span>
                      {isUpdating ? (
                        <textarea
                          name={`custom_fields.${key}`}
                          value={editableFields.custom_fields[key] || ''}
                          onChange={handleEditableChange}
                          className="client-portal--detail--input"
                        />
                      ) : (
                        <p>{String(value)}</p>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

        </div>
    )}
    </>
              <div className='client-portal--detail__buttons'>
    {isUpdating ? (
      <>
                <button onClick={handleSave} className='primary-button'>{t('Save')}</button>
                <button
                    onClick={() => {
                      setIsUpdating(false);
                      setEditableFields({
                        ...selectedCase.fields,
                        custom_fields: selectedCase.custom_fields || {},
                      });
                    }}
                    className='destructive-button'
                  >
                    {t('Cancel')}
                  </button>
                </>
            ) : (
              <>
              <button onClick={handleEdit} className='primary-button'>{t('Update')}</button>
              <button onClick={handleDeleteCase} className='destructive-button'>{t('Delete Case')}</button>
              </>
            )}
              </div>
    </>
          )}
    </div>
      </div>
      )}
    </div>
  );
};

export default SidePanel;
