import React, { useState, useEffect, SyntheticEvent } from 'react';
import './DualThumbSlider.css';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';

interface DualThumbSliderNewProps {
    min: number;
    max: number;
    onChange: (value: number[]) => void; 
    currencies: string[];
    onCurrencyChange: (currency: string) => void;
    error: string
}

const DualThumbSliderNew: React.FC<DualThumbSliderNewProps> = ({
    min, max, onChange, currencies, onCurrencyChange, error
}) => {
    const [value, setValue] = useState([min, max]);
    const [currency, setCurrency] = useState(currencies[0])
    const {t} = useTranslation()
    useEffect(() => {
        onChange(value);
        onCurrencyChange(currency)
    }, [value,currency]);

    const handleSliderChange = (event: Event | SyntheticEvent, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setValue(newValue as [number, number]);
        }
    };

    const handleInputChange = (index: 0 | 1, newValue: number) => {
        const newValues = [...value];
        newValues[index] = Math.min(Math.max(newValue, min), max);
        setValue(newValues as [number, number]);
    };


    const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrency(e.target.value);
        onCurrencyChange(e.target.value);
    };

    const formatNumber = (num: number) => num.toString().replace(/\B(?=(\d{3})+\b)/g, " ");

    const parseNumber = (formatted: string) => {
        return parseInt(formatted.replace(/\s/g, ''), 10) || 0;
    };

    return (
        <div className='dual-slider__container'>
            <p className='price-range__header'>{t('Select your budget range')}</p>
            <div className='upload-case__price-range__row'>
                <div className="price-range-display">
                    <select className="currency-selector" value={currency} onChange={handleCurrencyChange}>
                        {currencies.map((curr, index) => (
                            <option key={index} value={curr}>{curr}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        className="price-input"
                        value={formatNumber(value[0])}
                        onChange={(e) => handleInputChange(0, parseNumber(e.target.value))}
                    />
                    <input
                        type="text"
                        className="price-input"
                        value={formatNumber(value[1])}
                        onChange={(e) => handleInputChange(1, parseNumber(e.target.value))}
                    />
                </div>
                <div className='case-upload__slider'>
                    <Slider
                        value={value}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={formatNumber}
                        min={min}
                        max={max}
                        disableSwap
                        sx={{
                            color: '#64d2ff',
                            '& .MuiSlider-thumb': {
                                color: '#64d2ff',
                            },
                            '& .MuiSlider-track': {
                                color: '#64d2ff',
                            },
                            '& .MuiSlider-rail': {
                                color: '#64d2ff'
                            }
                        }}
                    />
                </div>
            </div>
            <span className='error-message'>{error}</span>

        </div>
    );
};

export default DualThumbSliderNew;
