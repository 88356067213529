import React, { useState, useEffect } from 'react';
import './Header.css';
import { getUserType } from '../../store/actions/ChatAction';
import { useAppDispatch } from '../../store';
import { switchUser } from '../../store/actions/userSwitchAction';

const RoleDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const userType = getUserType(); 
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    if (userType === 'LawfirmAdmin' || userType === 'BusinessAdmin') {
      setSelectedRole('Admin');
    } else if (userType === 'Lawfirmlawyer' || userType === 'Employee') {
      setSelectedRole('Employee');
    } else {
      setSelectedRole('');
    }
  }, [userType]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleRoleChange = (role: string) => {
    if (selectedRole !== role) {
      setSelectedRole(role);
      setIsOpen(false);
      dispatch(switchUser(role));
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className="role-dropdown">
      <p className={`adminmark--header ${isOpen ? 'selected' : ''}`}  onClick={toggleDropdown}>
        {selectedRole} <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
      </p>
      {isOpen && (
        <div className="dropdown-menu">
          {selectedRole !== 'Admin' && (
            <div className="dropdown-item" onClick={() => handleRoleChange('Admin')}>
              Admin
            </div>
          )}
          {selectedRole !== 'Employee' && (
            <div className="dropdown-item" onClick={() => handleRoleChange('Employee')}>
              Employee
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RoleDropdown;
