import { useEffect, useRef } from 'react';
import random from 'canvas-sketch-util/random';
import math from 'canvas-sketch-util/math';

const VisualizationCanvas = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const width = (canvas.width = 1080);
    const height = (canvas.height = 1080);

    const agents = [];

    class Vector {
      constructor(x, y) {
        this.x = x;
        this.y = y;
      }

      getDistance(v) {
        const dx = this.x - v.x;
        const dy = this.y - v.y;
        return Math.sqrt(dx * dx + dy * dy);
      }
    }

    class Agent {
      constructor(x, y) {
        this.pos = new Vector(x, y);
        this.vel = new Vector(random.range(-1, 1), random.range(-1, 1));
        this.radius = random.range(4, 8);
      }

      bounce(width, height) {
        if (this.pos.x <= 0 || this.pos.x >= width) this.vel.x *= -1;
        if (this.pos.y <= 0 || this.pos.y >= height) this.vel.y *= -1;
      }

      update() {
        this.pos.x += this.vel.x;
        this.pos.y += this.vel.y;
      }

      draw(context) {
        context.save();
        context.translate(this.pos.x, this.pos.y);

        context.lineWidth = 2;
        context.beginPath();
        context.arc(0, 0, this.radius, 0, Math.PI * 2);
        context.fillStyle = '#64d2ff';
        context.fill();
        context.strokeStyle = 'rgba(255, 255, 255, 0.5)';
        context.stroke();

        context.restore();
      }
    }

    for (let i = 0; i < 50; i++) {
      const x = random.range(0, width);
      const y = random.range(0, height);
      agents.push(new Agent(x, y));
    }

    const chainLength = 300;
    const tiltAngle = 0; // Static scale

    const drawScale = () => {
      // Calculate vertical offset to center the scale
      const topY = height / 4;
      const baseHeight = 20; // Reduced base height
      const bottomY = height / 1.5 + baseHeight + 10; // Base Y position + base height
      const scaleCenterY = (topY + bottomY) / 2;
      const verticalOffset = (height / 2) - scaleCenterY;

      // Draw the "scale of justice"

      // ----- Draw Base and Pedestal (Unscaled) -----
      context.save();
      context.fillStyle = 'rgba(255, 223, 0, 1)';

      // Base platform with shadows (Reduced height)
      context.fillRect(
        width / 2 - 120, // Slightly wider
        height / 1.5 + 40 + verticalOffset,
        240, // Adjusted width
        baseHeight // Reduced height
      );
      context.fillStyle = 'rgba(255, 223, 0, 0.5)';
      context.fillRect(
        width / 2 - 120,
        height / 1.5 + 40 + baseHeight + verticalOffset,
        240,
        10
      );


      // Reversed pedestal (wide side on bottom)
      context.beginPath();
      context.moveTo(width / 2 - 70, height / 1.5 + 40 + verticalOffset);
      context.lineTo(width / 2 - 50, height / 1.5 + verticalOffset);
      context.lineTo(width / 2 + 50, height / 1.5 + verticalOffset);
      context.lineTo(width / 2 + 70, height / 1.5 + 40 + verticalOffset);
      context.closePath();
      context.fillStyle = 'rgba(255, 223, 0, 0.5)';
      context.fill();
      context.strokeStyle = 'rgba(255, 223, 0, 0.4)';
      context.lineWidth = 2;
      context.stroke();

      // "PONS" inside the pedestal
      context.fillStyle = '#fff';
      context.font = '500 24px Arial'; // Adjusted font size
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(
        'PONS',
        width / 2,
        height / 1.5 + verticalOffset + 20 // Centered in the pedestal
      );

      // Central pillar extended above crossbeam
      const pillarTop = height / 3 - 30 + verticalOffset; // Adjusted position
      const pillarGradient = context.createLinearGradient(
        width / 2,
        height / 1.5 + verticalOffset,
        width / 2,
        pillarTop
      );
      pillarGradient.addColorStop(0, 'rgba(150, 150, 150, 1)');
      pillarGradient.addColorStop(1, '#444');

      // ----- Central Pillar -----
      context.beginPath();
      context.moveTo(width / 2 - 6, height / 1.5 + verticalOffset);
      context.lineTo(width / 2 - 3, pillarTop);
      context.lineTo(width / 2 + 3, pillarTop);
      context.lineTo(width / 2 + 6, height / 1.5 + verticalOffset);
      context.closePath();
      context.fillStyle = pillarGradient;
      context.fill();
      context.strokeStyle = 'rgba(120, 120, 120, 1)';
      context.lineWidth = 2;
      context.stroke();

      // ----- Connected Decoration Between Pillar and Crossbeam -----
      context.beginPath();

      // Left side of the decoration
      context.moveTo(width / 2 - 3, pillarTop);
      context.bezierCurveTo(
        width / 2 - 30,
        pillarTop - 50,
        width / 2 - 50,
        pillarTop - 100,
        width / 2,
        pillarTop - 150
      );

      // Right side of the decoration
      context.bezierCurveTo(
        width / 2 + 50,
        pillarTop - 100,
        width / 2 + 30,
        pillarTop - 50,
        width / 2 + 3,
        pillarTop
      );

      context.closePath();
      context.fillStyle = 'rgba(255, 223, 0, 1)'; // Solid gold color
      context.fill();
      context.strokeStyle = 'rgba(120, 120, 120, 1)'; // Subtle outline
      context.lineWidth = 2;
      context.stroke();

      // ----- Added Bent Line Inside the Decoration -----
      context.beginPath();
      context.moveTo(width / 2, pillarTop - 145); // Slightly below the top point
      context.bezierCurveTo(
        width / 2 - 25,
        pillarTop - 95, // Control point 1
        width / 2 - 15,
        pillarTop - 50, // Control point 2
        width / 2,
        pillarTop + 5 // End point slightly below the base
      );
      context.strokeStyle = 'rgba(200, 200, 0, 0.8)'; // Slightly lighter gold
      context.lineWidth = 2;
      context.stroke();

      // ----- End of Bent Line -----

      context.restore();

      // ----- Draw Crossbeam and Scales (Scaled) -----
      context.save();
      context.translate(width / 2, height / 4 + verticalOffset);
      context.rotate(tiltAngle);

      // Crossbeam with bends (straight line in this case)
      const crossbeamPoints = [
        { x: -220, y: -20 }, // Extended left
        { x: -100, y: -20 }, // Left point
        { x: 0, y: 60 }, // Middle point
        { x: 100, y: -20 }, // Right point
        { x: 220, y: -20 }, // Extended right
      ];

      // Draw crossbeam
      context.beginPath();
      context.moveTo(crossbeamPoints[0].x, crossbeamPoints[0].y);
      for (let i = 1; i < crossbeamPoints.length; i++) {
        context.lineTo(crossbeamPoints[i].x, crossbeamPoints[i].y);
      }
      context.lineWidth = 12; // Line width for the crossbeam
      context.strokeStyle = 'rgba(255, 223, 0, 1)';
      context.stroke();

      // Decorative ends of crossbeam
      // Start decoration
      context.beginPath();
      context.arc(crossbeamPoints[0].x, crossbeamPoints[0].y, 15, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 223, 0, 1)';
      context.fill();
      context.strokeStyle = 'rgba(120, 120, 120, 1)';
      context.lineWidth = 2;
      context.stroke();

      // End decoration
      context.beginPath();
      context.arc(crossbeamPoints[4].x, crossbeamPoints[4].y, 15, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 223, 0, 1)';
      context.fill();
      context.strokeStyle = 'rgba(120, 120, 120, 1)';
      context.lineWidth = 2;
      context.stroke();

      // ----- Left Chains Connecting to Half-Circle Plate -----
      const leftPlateX = -220;
      const plateY = chainLength - 100;

      const leftChainConnections = [
        { beamPoint: crossbeamPoints[0], plateX: leftPlateX - 100 }, // Leftmost
        { beamPoint: crossbeamPoints[0], plateX: leftPlateX }, // Middle
        { beamPoint: crossbeamPoints[0], plateX: leftPlateX + 100 }, // Rightmost
      ];

      leftChainConnections.forEach(({ beamPoint, plateX }) => {
        context.beginPath();
        context.setLineDash([5, 5]); // Dashed line pattern
        context.moveTo(beamPoint.x, beamPoint.y);
        context.lineTo(plateX, plateY);
        context.lineWidth = 3;
        context.strokeStyle = 'rgba(255, 223, 0, 0.9)';
        context.stroke();
        context.setLineDash([]); // Reset line dash
      });

      // ----- Right Chains Connecting to Half-Circle Plate -----
      const rightPlateX = 220;

      const rightChainConnections = [
        { beamPoint: crossbeamPoints[4], plateX: rightPlateX + 100 }, // Rightmost
        { beamPoint: crossbeamPoints[4], plateX: rightPlateX }, // Middle
        { beamPoint: crossbeamPoints[4], plateX: rightPlateX - 100 }, // Leftmost
      ];

      rightChainConnections.forEach(({ beamPoint, plateX }) => {
        context.beginPath();
        context.setLineDash([5, 5]); // Dashed line pattern
        context.moveTo(beamPoint.x, beamPoint.y);
        context.lineTo(plateX, plateY);
        context.lineWidth = 3;
        context.strokeStyle = 'rgba(255, 223, 0, 0.9)';
        context.stroke();
        context.setLineDash([]); // Reset line dash
      });

      // ----- Left Half-Circle Plate -----
      context.save();
      context.translate(leftPlateX, plateY);
      context.rotate(-tiltAngle);
      context.beginPath();
      context.ellipse(0, 0, 100, 60, 0, 0, Math.PI, false);
      context.fillStyle = 'rgba(255, 223, 0, 1)';
      context.fill();
      context.strokeStyle = 'rgba(255, 223, 0, 0.8)';
      context.lineWidth = 2;
      context.stroke();
      context.restore();

      // ----- Right Half-Circle Plate -----
      context.save();
      context.translate(rightPlateX, plateY);
      context.rotate(-tiltAngle);
      context.beginPath();
      context.ellipse(0, 0, 100, 60, 0, 0, Math.PI, false);
      context.fillStyle = 'rgba(255, 223, 0, 1)';
      context.fill();
      context.strokeStyle = 'rgba(255, 223, 0, 0.8)';
      context.lineWidth = 2;
      context.stroke();
      context.restore();

      context.restore();
    };

    const animate = () => {
      context.clearRect(0, 0, width, height);
      context.fillStyle = '#0D0D12'; // Dark background for contrast
      context.fillRect(0, 0, width, height);

      drawScale();

      // Draw agents and lines between them
      for (let i = 0; i < agents.length; i++) {
        const agent = agents[i];

        for (let j = i + 1; j < agents.length; j++) {
          const other = agents[j];
          const dist = agent.pos.getDistance(other.pos);

          if (dist > 150) continue;

          context.lineWidth = math.mapRange(dist, 0, 150, 4, 0.5);
          context.strokeStyle = 'rgba(151, 204, 222, 0.5)';

          context.beginPath();
          context.moveTo(agent.pos.x, agent.pos.y);
          context.lineTo(other.pos.x, other.pos.y);
          context.stroke();
        }
      }

      agents.forEach((agent) => {
        agent.update();
        agent.draw(context);
        agent.bounce(width, height);
      });

      requestAnimationFrame(animate);
    };

    animate();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={1080}
      height={1080}
      style={{ width: '100%', height: '98%' }}
    />
  );
};

export default VisualizationCanvas;
