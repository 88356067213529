import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FirstPage from "./pages/FirstPage";
import TermsConditions from "./components/SignUpRequirements/TermsConditions";
import PrivacyPolicy from "./components/SignUpRequirements/PrivacyPolicy";
import DashboardPage from "./pages/DashboardPage";
import AIAssistantPage from "./pages/AIAssistantPage";
import DocumentsPage from "./pages/DocumentsPage";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";
import HelpPage from "./pages/HelpPage";
import FeedbackPage from "./pages/FeedbackPage";
import { UserContext, UserContextProps } from "../src/contexts/UserContext";
import "../node_modules/flag-icon-css/css/flag-icons.min.css";
import { ForgotPasswordLink } from "./components/ForgotPasswordLink/ForgotPasswordLink";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./PrivateRoutes";
import ResetPassword from "./components/LoginSection/ResetPassword";
import { MsalProvider } from "@azure/msal-react";
import pca from "./msalInstance";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Marketplace from "./components/Marketplace/Marketplace";
import PrivateLayout from "./pages/PrivateLayout";
import LCMPost from "./components/AIAssistant/lcm/LCMPost";
import GlobalModal from "./components/shared/GlobalModal";
import './App.css'
import { PeerProvider } from "./contexts/Peer";
import PaymentResults from "./components/Payment/PaymentResults";
import UserFeedbackModal from "./components/shared/UserFeedbackModal";
import FinancePage from "./pages/FinancePage";
import OtpInput from "./components/LoginSection/OtpInput";
import ProjectsPage from "./pages/ProjectsPage";
import { getUserType } from "./store/actions/ChatAction";
import MessageCenterPage from "./pages/MessageCenterPage";
import TimeKeepingPage from "./pages/TimeKeepingPage";
import ClientPortalPage from "./pages/ClientPortalPage";
import PricingPage from "./components/PlanCard/PricingPage";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import SocketServices from "./utils/SocketServices";
import { UserProvider } from "./contexts/UserProvider";
import { TimerProvider } from "./components/TimeTracker/TimeProvider";
import { DropdownProvider } from "./components/shared/contexts/DropdownContext";
import GlobalSidePanel from "./components/shared/GlobalSidePanel";
import BusinessAdminComponent from "./components/Dashboard/AdminBusiness/BusinessAdminComponent";
import TimeTrackingPage from "./pages/TimeTrackingPage";

const App: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const [activeView, setActiveView] = useState('defaultView');
  const userType = getUserType();
  const [subscriptionId, setSubscriptionID] = useState("")

  useEffect(() => {
    // SocketServices.initializeSocket()
    setSubscriptionID(userInfo.stripe_subscription_id)
  }, [userInfo.stripe_subscription_id])

  const initialOptions = {
    clientId: "AZpIad7HV6zBmtZMkg2jqRHXtizhVRKc7yn7rDOhtWfxvkDHV94i-2spkf9elfCa5JekwSRf96zy0uCS",
    currency: "USD",
    intent: "capture",
  };
  return (
    <PeerProvider>

      {/* <PayPalScriptProvider options={initialOptions}> */}
      <MsalProvider instance={pca}>
        <DropdownProvider>
          <UserProvider>
              <Router>
                <UserFeedbackModal />
                <ToastContainer position="top-right" theme="dark" autoClose={1500} />
                <Switch>
                  <Route path="/" exact component={FirstPage} />
                  <Route path="/forgot-password" component={ForgotPasswordLink} />
                  <Route path="/terms-conditions" component={TermsConditions} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route path="/auth/otp" component={OtpInput} />
                  {/* <Route exact path="/reset-password/:token" component={ResetPassword} /> */}

                  <PrivateRoute path="/reset-password/:token" component={ResetPassword} subscriptionId={subscriptionId} restricted={false} />

                  <TimerProvider>
                  <PrivateRoute
                    path="/"
                    // exact
                    render={() => (
                      <PrivateLayout activeView={activeView} setActiveView={setActiveView}>

                        {userType === 'LawfirmAdmin' ? (
                          <PrivateRoute path="/dashboard/admin" component={DashboardPage} subscriptionId={subscriptionId} restricted={false} />
                        ) : (userType === 'Lawfirmlawyer' || userType === 'BusinessAdmin' ) ? (
                          <PrivateRoute path="/dashboard/panel" component={DashboardPage} subscriptionId={subscriptionId} restricted={false} />
                        ) : (
                          <PrivateRoute path="/dashboard" component={DashboardPage} subscriptionId={subscriptionId} restricted={false} />
                        )}

                          {userType === 'LawfirmAdmin' && (
                            <PrivateRoute path={`/dashboard/time-tracking`} component={TimeTrackingPage} subscriptionId={subscriptionId} restricted={false} />
                          )}

                        {/* Restricted Routes */}
                        {userType === 'BusinessAdmin' && (
                          <PrivateRoute path="/dashboard/admin" component={BusinessAdminComponent} subscriptionId={subscriptionId} restricted={false} />
                        )}
                        <PrivateRoute path="/documents" component={DocumentsPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/ai-assistant" component={AIAssistantPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/LCM" component={LCMPost} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/analytics" component={ReportsPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/projects" component={ProjectsPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/dashboard/finance" component={FinancePage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path={`${userType === 'Lawyer' ? '/time-keeping' : '/dashboard/time-keeping'}`} component={TimeKeepingPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/marketplace" component={Marketplace} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path={`${userType === 'LawfirmAdmin' || userType === 'Lawfirmlawyer' || userType === 'BusinessAdmin' ? '/dashboard/message-center' : '/message-center'}`} component={MessageCenterPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/client-portal" component={ClientPortalPage} subscriptionId={subscriptionId} restricted={false} />


                        {/* Non-restricted routes accessible to everyone */}
                        <PrivateRoute path="/help" component={HelpPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/feedback" component={FeedbackPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/settings" component={SettingsPage} subscriptionId={subscriptionId} restricted={false} />
                        <PrivateRoute path="/payment-result" component={PaymentResults} subscriptionId={subscriptionId} restricted={false} />
                        {/* <PrivateRoute path="/client-portal" component={ClientPortalPage} subscriptionId={subscriptionId} restricted={false} /> */}

                        {/* Dynamic message-center path for different user types */}
                        {/* <PrivateRoute path={`${userType === 'LawfirmAdmin' || userType === 'Lawfirmlawyer' ? '/dashboard/message-center' : '/message-center'}`} component={MessageCenterPage} /> */}

                        {/* Pricing plans accessible if not subscribed */}
                        <PrivateRoute path="/plans" component={PricingPage} subscriptionId={subscriptionId} restricted={false} />
                      </PrivateLayout>
                    )}
                    subscriptionId={userInfo.stripe_subscription_id} // Pass subscription ID to ensure access control
                    restricted={false} // This is the main layout so it's not restricted
                    />
                    </TimerProvider>
                </Switch>


              <GlobalModal />
              <GlobalSidePanel />
              </Router>

          </UserProvider>
        </DropdownProvider>
      </MsalProvider>
      {/* </PayPalScriptProvider> */}
    </PeerProvider>

  );
};

export default App;
