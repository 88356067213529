import React, { useState } from 'react';
import {
  StaticDateTimePicker,
  StaticDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Popover, Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import './CustomDateTimePicker.css';

interface CustomDateTimePickerProps {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
  minDateTime?: Dayjs;
  width?: string;
  placeholder?: string;
  dateOnly?: boolean; 
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
  value,
  onChange,
  minDateTime,
  width,
  placeholder = 'Select date',
  dateOnly = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false)
  };

  const open = Boolean(anchorEl);

  const handleDateChange = (newValue: Dayjs | null) => {
    onChange(newValue);
    handleClose();
  };

  const formattedValue = value
  ? value.format(dateOnly ? 'MM-DD-YYYY' : 'DD/MM/YYYY HH:mm')
  : placeholder;

  return (
    <div>
      <button
        style={{
          backgroundColor: '#0e1013',
          color: '#fff',
          border: '1px solid #444',
          width: width,
          textTransform: 'none',
          textAlign: 'left'
        }}
        className={`date-time-picker--button ${isOpen ? 'open' : ''}`}
        // variant="contained"
        onClick={handleOpen}
      >
        {formattedValue}
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: 'none' },
        }}
      >
        <Box sx={{ p: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {dateOnly ? (
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={handleDateChange}
                minDate={minDateTime}
                disableFuture={false}
              />
            ) : (
              <StaticDateTimePicker
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={handleDateChange}
                minDateTime={minDateTime}
                ampm={false}
                slotProps={{
                  toolbar: { hidden: false },
                }}
              />
            )}
          </LocalizationProvider>
        </Box>
      </Popover>
    </div>
  );
};

export default CustomDateTimePicker;
