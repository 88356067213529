import React, { useEffect, useState } from 'react';
import './TaskDeadlineTracker.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';
import { parse, format } from 'date-fns';
import {
  getAllLawfirmAssignedTask,
  completeLawfirmAssignedTask,
  deleteLawfirmAssignedTask,
} from '../../store/actions/LawfirmActions';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { showAddTaskModal } from '../../store/actions/modalActions';
import { showAddTaskSidePanel } from '../../store/actions/sidePanelActions';

const TaskDeadlineTracker: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();

  const [isTasksLoading, setIsTasksLoading] = useState(false);

  const assignedTasks = useSelector((state: RootState) =>
    state.lawfirm.tasks.filter((task: any) => task.Status === 'Active')
  );

  useEffect(() => {
    const fetchTasks = async () => {
      setIsTasksLoading(true);
      try {
        await dispatch(getAllLawfirmAssignedTask(userType));
      } finally {
        setIsTasksLoading(false);
      }
    };
    fetchTasks();
  }, [dispatch, userType]);

  const handleAddTask = () => {
    dispatch(showAddTaskModal());
  };

  const handleOpenAddTask = () => {
    dispatch(showAddTaskSidePanel());
  };

  const handleCompleteTask = async (taskId: string) => {
    try {
      await dispatch(completeLawfirmAssignedTask(userType, taskId));
      dispatch(getAllLawfirmAssignedTask(userType));
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await dispatch(deleteLawfirmAssignedTask(userType, taskId));
      dispatch(getAllLawfirmAssignedTask(userType));
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  // Process and sort tasks
  const sortedTasks = assignedTasks
    .map((task: any) => {
      const taskDeadline = parse(task.TaskDeadline, 'dd-MM-yyyy HH:mm', new Date());
      const timeDifference = taskDeadline.getTime() - new Date().getTime();
      return {
        ...task,
        timeDifference: isNaN(timeDifference) ? Infinity : timeDifference,
        formattedDeadline: !isNaN(taskDeadline.getTime())
          ? format(taskDeadline, 'dd-MM-yyyy HH:mm')
          : 'Invalid date',
      };
    })
    .sort((a: any, b: any) => a.timeDifference - b.timeDifference);

  const closestTask = sortedTasks[0];
  const otherTasks = sortedTasks.slice(1);

  return (
    <div className="task-deadline-tracker-container">
      <div className="firm-container-header">
        <span>{t('Task and Deadline Tracker')}</span>
      </div>
      <div className="task-deadline-tracker-content">
        {isTasksLoading ? (
          <div className="loader-container">
            <ClipLoader color="#fff" loading={isTasksLoading} size={30} />
          </div>
        ) : assignedTasks && assignedTasks.length > 0 ? (
          <div className="task-and-deadline-tracker-widget">
            <div className="task-header">
              <button
                className={`add-task-btn ${assignedTasks.length === 0 ? 'full' : ''}`}
                onClick={handleOpenAddTask}
              >
                <i className="far fa-plus"></i>
                <span>{t('Add Task')}</span>
              </button>
              {closestTask && (
                <div className="task-and-deadline__item closest-task">
                  <div className="task-and-deadline__item-details">
                    <span>{closestTask.TaskDeadline}</span>
                    <p>{closestTask.TaskTitle}</p>
                  </div>
                  <div className="task-and-deadline__item-icons">
                    <i
                      onClick={() => handleCompleteTask(closestTask.TaskID)}
                      className={`fas fa-circle-${closestTask.Status === 'Active' ? 'check' : 'times'}`}
                    ></i>
                    <i onClick={() => handleDeleteTask(closestTask.TaskID)} className="fas fa-trash"></i>
                  </div>
                </div>
              )}
            </div>

            {/* Other Tasks */}
            <ul className="all-task-list">
              {otherTasks.map((task: any) => (
                <li key={task.TaskID}>
                  <div className="task-and-deadline__item-details">
                    <p>{task.TaskTitle}</p>
                    <span>{task.TaskDeadline}</span>
                  </div>
                  <div className="task-and-deadline__item-icons">
                    <i
                      onClick={() => handleCompleteTask(task.TaskID)}
                      className={`fas fa-circle-${task.Status === 'Active' ? 'check' : 'times'}`}
                    ></i>
                    <i onClick={() => handleDeleteTask(task.TaskID)} className="fas fa-trash"></i>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="no-tasks-container">
            <button
              className={`add-task-btn ${assignedTasks.length === 0 ? 'full' : ''}`}
              onClick={handleOpenAddTask}
            >
              <i className="far fa-plus"></i>
              <span>{t('Add Task')}</span>
            </button>
            <div className="no-tasks-message">{t('No tasks')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskDeadlineTracker;
