import { useState } from "react";
import { useTranslation } from "react-i18next";

const BusinessAdminComponent = () => {
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const [isServicePanelOpen, setIsServicePanelOpen] = useState<boolean>(false);
    const {t} = useTranslation()

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const toggleServicePanel = () => {
        setIsServicePanelOpen(!isServicePanelOpen);
    };

    const employees = [
        { 
            fullName: "Alice Johnson", 
            email: "alice.johnson@example.com", 
            role: "Manager", 
            department: "Sales", 
            phone: "123-456-7890", 
            status: "Active", 
            services: [
                { name: "Law Research Inquiries", usage: 80 },
                { name: "Contract Analysis", usage: 60 },
                { name: "Company Agent Token Usage", usage: 50 }
            ]
        },
        { 
            fullName: "Bob Smith", 
            email: "bob.smith@example.com", 
            role: "Developer", 
            department: "IT", 
            phone: "123-456-7891", 
            status: "Active", 
            services: [
                { name: "Contract Generators", usage: 70 },
                { name: "Legal Case Mappings", usage: 40 }
            ]
        },
        { 
            fullName: "Carol White", 
            email: "carol.white@example.com", 
            role: "Designer", 
            department: "Marketing", 
            phone: "123-456-7892", 
            status: "Inactive", 
            services: [
                { name: "Law Research Inquiries", usage: 30 },
                { name: "Company Agent Token Usage", usage: 20 }
            ]
        },
        { 
            fullName: "David Brown", 
            email: "david.brown@example.com", 
            role: "Tester", 
            department: "Quality Assurance", 
            phone: "123-456-7893", 
            status: "Active", 
            services: [
                { name: "Legal Case Mappings", usage: 90 },
                { name: "Contract Analysis", usage: 50 }
            ]
        },
        { 
            fullName: "Eva Green", 
            email: "eva.green@example.com", 
            role: "HR", 
            department: "Human Resources", 
            phone: "123-456-7894", 
            status: "Active", 
            services: [
                { name: "Law Research Inquiries", usage: 70 },
                { name: "Contract Generators", usage: 60 },
                { name: "Company Agent Token Usage", usage: 40 }
            ]
        },
        { 
            fullName: "Frank Wilson", 
            email: "frank.wilson@example.com", 
            role: "Support", 
            department: "Customer Service", 
            phone: "123-456-7895", 
            status: "Inactive", 
            services: [
                { name: "Contract Analysis", usage: 20 },
                { name: "Legal Case Mappings", usage: 10 }
            ]
        },
        { 
            fullName: "Grace Lee", 
            email: "grace.lee@example.com", 
            role: "Product Manager", 
            department: "Product", 
            phone: "123-456-7896", 
            status: "Active", 
            services: [
                { name: "Contract Generators", usage: 80 },
                { name: "Law Research Inquiries", usage: 60 },
                { name: "Company Agent Token Usage", usage: 30 }
            ]
        },
        { 
            fullName: "Hank Turner", 
            email: "hank.turner@example.com", 
            role: "Sales", 
            department: "Sales", 
            phone: "123-456-7897", 
            status: "Active", 
            services: [
                { name: "Legal Case Mappings", usage: 50 },
                { name: "Contract Analysis", usage: 70 },
                { name: "Company Agent Token Usage", usage: 40 }
            ]
        }
    ];

    return (
        <div className="business-admin-main__container">
                        <div className="business-container employees">
                    <div className='bussiness-header-container'>
                        <span>{t('Employee Management')}</span>
                        <button onClick={togglePanel}>{t('Onboard Employee')}</button>
                    </div>
                    <div className='business-employee--wrapper'>
                        <table className="business-table">
                        <thead>
                            <tr>
                            <th>{t('Name')}</th>
                            <th>{t('Role')}</th>
                            <th>{t('Email')}</th>
                            <th>{t('Department')}</th>
                            <th>{t('Phone')}</th>
                            <th>{t('Status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, index) => (
                            <tr key={index} className="business-employee--item">
                                <td>{employee.fullName}</td>
                                <td>{employee.role}</td>
                                <td>{employee.email}</td>
                                <td>{employee.department}</td>
                                <td>{employee.phone}</td>
                                <td>{employee.status}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                            <div className='business-container' style={{ flex: 1, height: 'calc(100vh / 2.5)', padding: 10, backgroundColor: '#1e1f21', borderRadius: 8 }}>
                <div className='bussiness-header-container'>
                    <span>{t('Service usage and allocation')}</span>
                    <button onClick={toggleServicePanel}>{t('Allocate Services')}</button>
                </div>
                <div className='business-container-body'>
                    <div className='sub-business-container-body'>
                        <div className='sub-business-container-body-chart'>
                            {/* <div className='bussiness-header-container'>
                                <span>{t('Service Utilization Rates')}</span>
                            </div> */}
                            <div className="service-usage-overview">
                                {employees.map((employee) => (
                                    <div key={employee.fullName} className="service-usage-item">
                                        <span>{employee.fullName}</span>
                                        <div className='service-usage-item--details'>
                                        {employee.services.map((service, index) => (
                                            <div key={index} className="service-detail">
                                                <span>{t(service.name)}</span><span>{service.usage}</span>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`side-panel ${isPanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={() => togglePanel()}><i className='fas fa-chevron-right'></i></button>
                <h1 style={{ textAlign: "center", color: "#fff", width: "fit-content", alignSelf: "center" }}>{t('Add Employee')} </h1>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'none' }}>
                </div>
            </div>

            <div className={`side-panel ${isServicePanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={toggleServicePanel}><i className='fas fa-chevron-right'></i></button>
                <h1 style={{ textAlign: "center", color: "#fff", width: "fit-content", alignSelf: "center" }}>{t('Allocate Services')}</h1>
                <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'none' }}>
                    {/* Service Allocation Form */}
                    <div>
                        <label>{t('Service Name')}</label>
                        <input type="text" placeholder={t('Enter service name')} />
                    </div>
                    <div>
                        <label>{t('Usage Percentage')}</label>
                        <input type="number" placeholder={t('Enter usage percentage')} />
                    </div>
                    <button>{t('Allocate')}</button>
                </div>
            </div>
        </div>
    )
}

export default BusinessAdminComponent;