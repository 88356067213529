import React, { useState } from 'react';
import '../../Marketplace.css'; 
import { useTranslation } from 'react-i18next';
import { ReceiverMethod } from '../../../Settings/FinanceSettings';
import { useAppDispatch } from '../../../../store';
import { ShowReceiverOptions } from '../../../../store/actions/modalActions';

type MonthlyStatement = {
    id: number;
    month: string;
    year: string,
    invoicedAmount: string;
    receivedAmount: string;
    outstanding: string;
  };
  
  type PaymentReceived = {
    id: number;
    client: string;
    amount: string;
    dateReceived: string;
    relatedInvoice: string;
  };
  
  type FinanceDataItem = MonthlyStatement | PaymentReceived;
  
  type FinanceData = {
    monthlyStatements: MonthlyStatement[];
    paymentsReceived: PaymentReceived[];
  };
  

const LawyerFinance: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const dispatch = useAppDispatch()
  const {t} = useTranslation()

  
  const [receiverMethods, setReceiverMethods] = useState([
    { id: 1, method: 'Bank Account', details: 'Bank of America, Acc: ****1234', isDefault: true },
    { id: 2, method: 'E-Wallet', details: 'Payoneer: *****@lawfirm.com', isDefault: false },
  ]);

  const addReceiverMethod = () => {
    dispatch(ShowReceiverOptions())
  };

  const setDefaultReceiverMethod = (methodId: number) => {
    setReceiverMethods(receiverMethods => receiverMethods.map(method =>
      ({ ...method, isDefault: method.id === methodId })
    ));
  };

  const ReceiverInformation: React.FC<{ receivers: ReceiverMethod[] }> = ({ receivers }) => (
    <div className="finance-settings__section quater">
      <div className="finance-market-header">
        <h2>{t('Receiver Information')}</h2>
        <div className="market-finance__receiver--header">
          <button onClick={addReceiverMethod}>
            {t('Add Receiver Account')}
          </button>
        </div>
      </div>
      <div style={{ display: "flex", overflowX: "auto", scrollbarWidth: "none", gap: '20px', padding: '10px', width: '95%' }}>
        {receivers.map((method) => (
          <div key={method.id} className={`payment-method-card ${method.isDefault ? "payment-selected" : ""}`} onClick={() => setDefaultReceiverMethod(method.id)}>
            <div className="method-info">
              <i className="fas fa-university" />
              <span>{method.method}</span>
              <span className="method-details">{method.details}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  

  const financeData: FinanceData = {
    monthlyStatements: [
        { id: 1, month: 'January',year: '2024', invoicedAmount: '$5000', receivedAmount: '$4200', outstanding: '$500' },
        { id: 2, month: 'February',year: '2024', invoicedAmount: '$3820', receivedAmount: '$3200', outstanding: '$500' },
        { id: 3, month: 'March',year: '2024', invoicedAmount: '$4500', receivedAmount: '$3800', outstanding: '$500' },
        { id: 4, month: 'April',year: '2024', invoicedAmount: '$8900', receivedAmount: '$7500', outstanding: '$500' },
        { id: 5, month: 'May',year: '2024', invoicedAmount: '$7890', receivedAmount: '$6800', outstanding: '$500' },
    ],
    paymentsReceived: [
        { id: 1, client: 'Client A', amount: '$1500', dateReceived: '2024-01-10', relatedInvoice: 'Invoice 1' },
        { id: 2, client: 'Client B', amount: '$1200', dateReceived: '2024-01-22', relatedInvoice: 'Invoice 2' },
        { id: 3, client: 'Client C', amount: '$900', dateReceived: '2024-02-04', relatedInvoice: 'Invoice 3' },
        { id: 4, client: 'Client D', amount: '$1700', dateReceived: '2024-02-20', relatedInvoice: 'Invoice 4' },
        { id: 5, client: 'Client E', amount: '$1100', dateReceived: '2024-03-01', relatedInvoice: 'Invoice 5' },
    ],
  };

  const isMonthlyStatement = (item: FinanceDataItem): item is MonthlyStatement => {
    return (item as MonthlyStatement).month !== undefined;
  };
  

  const toggleSection = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const spaceCapitalLetters = (text: string) => {
    return text.split('').reduce((acc, char, index) => {
      if (char === char.toUpperCase() && index !== 0) {
        return `${acc} ${char}`;
      }
      return acc + char;
    }, '');
  };


  return (
    <div className="finance-container">
      <div className="static-header-view">
        <h1 className="header-title-finance">{t('Finance & Invoices')}</h1>
      </div>

        <h2 style={{ textAlign: 'center', padding: 10, color: '#64d2ff', borderRadius: 10, position: 'relative', fontWeight: '500' }}>{t('PONS Earnings')}</h2>
      <div className='earnings-lawyer'>
        <div>
          <p> {t('This Month')}</p>
          <p style={{ color: 'rgb(66, 212, 66)' }}>NOK 16.514,71</p>
        </div>

        <div>
          <p>{t('Total on PONS')}</p>
          <p style={{ color: 'rgb(66, 212, 66)' }}>NOK 164.211,18</p>
        </div>

      </div>
      <div className='market-finance__receiver'>
      <ReceiverInformation receivers={receiverMethods} />
      </div>

      <div className="finance-scroll-container">
        {Object.entries(financeData).map(([section, data]) => (
          <div key={section} className="section-container">
            <button className="section-header-finance" onClick={() => toggleSection(section)}>

            <p>{t(spaceCapitalLetters(section.charAt(0).toUpperCase() + section.slice(1)))}</p>
            <i className={`fas ${expandedSection === section ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
            </button>
            {expandedSection === section && (
              <div className="section-content">

                {data.map((item, index) => (
                <button key={index} className="item-container" >
                    <span className='finance-item-container__amountreceived'>
                    {isMonthlyStatement(item) ? item.receivedAmount : `${item.amount}`}
                    </span>
                  <span>
                    {isMonthlyStatement(item) ? `${t(item.month)} ${item.year}` : `${item.client}` }
                    </span>

                </button>
                ))}

              </div>
            )}
          </div>
        ))}
      </div>

    </div>
  );
};

export default LawyerFinance;
