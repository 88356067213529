import { AnyAction } from 'redux';
import { FolderProps } from '../../components/Documents/Files';
import { Description, Section } from '../../components/Help/types';
import { IAdminCaseItem } from '../../components/Marketplace/Interfaces/IAdminCaseItem';
import { Member, Team } from '../../components/Dashboard/AdminFirm/FirmComponents/ManageTeam';
import { Lawyer } from '../../components/Dashboard/AdminFirm/interface/IFirmLawyer';

interface CommonSidePanelState {
  showSidePanel: boolean;
  width?: string;
  isExpanded?: boolean;
  onConfirm?: (data?: any) => void;
  onCancel?: () => void;
}

interface AddTaskSidePanelState extends CommonSidePanelState {
  type: 'addTask';
}

interface AddFileSidePanelState extends CommonSidePanelState {
  type: 'addFile';
  folders?: FolderProps[];
  preSelectedFolderId?: string;
}

interface SupportTicketSidePanelState extends CommonSidePanelState {
  type: 'supportTicket';
}

interface HelpContentSidePanelState extends CommonSidePanelState {
  type: 'helpContent';
  content: {
    section: Section;
    description: Description;
  };
}

interface OnboardingSidePanelState extends CommonSidePanelState {
  type: 'onboarding';
  addEmployee: (employee: Member) => void;
  employeeCount: number;
  employeeLimit: number;
}

interface EmployeeViewSidePanelState extends CommonSidePanelState {
  type: 'employeeView';
  employee: Lawyer;
}

interface TeamViewSidePanelState extends CommonSidePanelState {
  type: 'teamView';
  team: Team;
}

interface CaseViewSidePanelState extends CommonSidePanelState {
  type: 'caseView';
  caseItem: IAdminCaseItem;
}

interface ManageTeamSidePanelState extends CommonSidePanelState {
  type: 'manageTeam';
  allMembers: Member[];
  subjects: any[];
}

interface CustomSidePanelState extends CommonSidePanelState {
  type: 'custom';
}

interface AddClientSidePanelState extends CommonSidePanelState {
  type: 'addClient';
}

interface ClientViewSidePanelState extends CommonSidePanelState {
  type: 'clientView';
  client: any; 
  selectedCase?: any; 
}
interface TimekeepingSidePanelState extends CommonSidePanelState {
  type: 'timekeepingView';
  day: string | null;
  data: any;
  isLoading: boolean;
  stats: {
    clocked_hours: number;
    negative_hours: number;
    overtime: number;
    total_hours_worked: number;
    tasks: string[];
    casesWorkedOn: number;
  } | null;
}

export type SidePanelState =
  | AddTaskSidePanelState
  | AddFileSidePanelState
  | SupportTicketSidePanelState
  | HelpContentSidePanelState
  | OnboardingSidePanelState
  | EmployeeViewSidePanelState
  | TeamViewSidePanelState
  | CaseViewSidePanelState
  | ManageTeamSidePanelState
  | AddClientSidePanelState
  | ClientViewSidePanelState
  | CustomSidePanelState
  | TimekeepingSidePanelState; 

const initialState: SidePanelState = {
  showSidePanel: false,
  type: 'custom',
  width: '50%',
  isExpanded: false,
};

export const sidePanelReducer = (
  state = initialState,
  action: AnyAction
): SidePanelState => {
  switch (action.type) {
    case 'SHOW_SIDE_PANEL':
      return {
        ...state,
        ...action.payload,
        showSidePanel: true,
      };
    case 'HIDE_SIDE_PANEL':
      return {
        ...state,
        showSidePanel: false,
        isExpanded: false,
      };
    case 'EXPAND_SIDE_PANEL':
      return {
        ...state,
        isExpanded: true,
        width: '85%',
      };
    case 'COLLAPSE_SIDE_PANEL':
      return {
        ...state,
        isExpanded: false,
        width: '50%',
      };
      case 'UPDATE_SIDE_PANEL':
        return {
          ...state,
          ...action.payload,
        };
    default:
      return state;
  }
};

export default sidePanelReducer;
