import { Dispatch } from 'redux';
import {
  FETCH_AI_VOICES_REQUEST,
  FETCH_AI_VOICES_SUCCESS,
  FETCH_AI_VOICES_FAILURE,
  FETCH_AI_FONTSIZES_REQUEST,
  FETCH_AI_FONTSIZES_SUCCESS,
  FETCH_AI_FONTSIZES_FAILURE,
  FETCH_AI_JURISDICTIONS_REQUEST,
  FETCH_AI_JURISDICTIONS_SUCCESS,
  FETCH_AI_JURISDICTIONS_FAILURE,
  FETCH_AI_LANGUAGES_REQUEST,
  FETCH_AI_LANGUAGES_SUCCESS,
  FETCH_AI_LANGUAGES_FAILURE,
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_BUSINESSES_FAILURE,
  FETCH_CASE_STATUSES_REQUEST,
  FETCH_CASE_STATUSES_SUCCESS,
  FETCH_CASE_STATUSES_FAILURE,
  FETCH_CASE_TYPES_REQUEST,
  FETCH_CASE_TYPES_SUCCESS,
  FETCH_CASE_TYPES_FAILURE,
  FETCH_DESIRED_OUTCOMES_REQUEST,
  FETCH_DESIRED_OUTCOMES_SUCCESS,
  FETCH_DESIRED_OUTCOMES_FAILURE,
  FETCH_EXPECTED_OUTCOMES_REQUEST,
  FETCH_EXPECTED_OUTCOMES_SUCCESS,
  FETCH_EXPECTED_OUTCOMES_FAILURE,
  FETCH_LAWFIRM_ROLES_REQUEST,
  FETCH_LAWFIRM_ROLES_SUCCESS,
  FETCH_LAWFIRM_ROLES_FAILURE,
  FETCH_LEGAL_SUBJECTS_REQUEST,
  FETCH_LEGAL_SUBJECTS_SUCCESS,
  FETCH_LEGAL_SUBJECTS_FAILURE,
  FETCH_PLATFORM_CURRENCIES_REQUEST,
  FETCH_PLATFORM_CURRENCIES_SUCCESS,
  FETCH_PLATFORM_CURRENCIES_FAILURE,
  FETCH_PLATFORM_LANGUAGES_REQUEST,
  FETCH_PLATFORM_LANGUAGES_SUCCESS,
  FETCH_PLATFORM_LANGUAGES_FAILURE,
  FETCH_PRACTICE_AREAS_REQUEST,
  FETCH_PRACTICE_AREAS_SUCCESS,
  FETCH_PRACTICE_AREAS_FAILURE,
  FETCH_PREFERRED_LANGUAGES_REQUEST,
  FETCH_PREFERRED_LANGUAGES_SUCCESS,
  FETCH_PREFERRED_LANGUAGES_FAILURE,
  FETCH_URGENCY_STATUS_REQUEST,
  FETCH_URGENCY_STATUS_SUCCESS,
  FETCH_URGENCY_STATUS_FAILURE,
} from '../types';
import apis from '../../utils/apis';
import { DropdownOption } from '../../components/shared/GlobalDropdown';

// Helper function to handle API calls
const fetchDropdownData = (
    endpoint: string,
    requestType: string,
    successType: string,
    failureType: string
  ) => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: requestType });
      try {
        const response = await apis({
          method: 'GET',
          url: endpoint,
        });
  
        if (response && response.status === 200 && Array.isArray(response.data.list)) {
          const dataList: DropdownOption[] = response.data.list.map((item: any) => {
            const active = item.active !== undefined ? item.active === true : undefined;
            return {
              id: endpoint === '/dropdown/legalSubjects' ? item.ID : item.Value ? item.Value.toLowerCase() : '',
              label: item.DisplayName || '',
              active: active, 
            };
          });
          dispatch({ type: successType, payload: dataList });
        } else {
          dispatch({
            type: failureType,
            payload: response.data.message || 'Error fetching data.',
          });
        }
      } catch (error: any) {
        dispatch({
          type: failureType,
          payload: error.message || 'Error fetching data.',
        });
      }
    };
  };
  
// Action Creators

export const fetchAIVoices = () =>
  fetchDropdownData(
    '/dropdown/aiVoices',
    FETCH_AI_VOICES_REQUEST,
    FETCH_AI_VOICES_SUCCESS,
    FETCH_AI_VOICES_FAILURE
  );

export const fetchAIFontSizes = () =>
  fetchDropdownData(
    '/dropdown/fontsizes',
    FETCH_AI_FONTSIZES_REQUEST,
    FETCH_AI_FONTSIZES_SUCCESS,
    FETCH_AI_FONTSIZES_FAILURE
  );

export const fetchAIJurisdictions = () =>
  fetchDropdownData(
    '/dropdown/jurisdictions',
    FETCH_AI_JURISDICTIONS_REQUEST,
    FETCH_AI_JURISDICTIONS_SUCCESS,
    FETCH_AI_JURISDICTIONS_FAILURE
  );

export const fetchAILanguages = () =>
  fetchDropdownData(
    '/dropdown/aiLanguages',
    FETCH_AI_LANGUAGES_REQUEST,
    FETCH_AI_LANGUAGES_SUCCESS,
    FETCH_AI_LANGUAGES_FAILURE
  );

export const fetchBusinesses = () =>
  fetchDropdownData(
    '/dropdown/Businesses',
    FETCH_BUSINESSES_REQUEST,
    FETCH_BUSINESSES_SUCCESS,
    FETCH_BUSINESSES_FAILURE
  );

export const fetchCaseStatuses = () =>
  fetchDropdownData(
    '/dropdown/caseStatuses',
    FETCH_CASE_STATUSES_REQUEST,
    FETCH_CASE_STATUSES_SUCCESS,
    FETCH_CASE_STATUSES_FAILURE
  );

export const fetchCaseTypes = () =>
  fetchDropdownData(
    '/dropdown/caseTypes',
    FETCH_CASE_TYPES_REQUEST,
    FETCH_CASE_TYPES_SUCCESS,
    FETCH_CASE_TYPES_FAILURE
  );

export const fetchDesiredOutcomes = () =>
  fetchDropdownData(
    '/dropdown/desiredOutcomes',
    FETCH_DESIRED_OUTCOMES_REQUEST,
    FETCH_DESIRED_OUTCOMES_SUCCESS,
    FETCH_DESIRED_OUTCOMES_FAILURE
  );

export const fetchExpectedOutcomes = () =>
  fetchDropdownData(
    '/dropdown/expectedOutcomes',
    FETCH_EXPECTED_OUTCOMES_REQUEST,
    FETCH_EXPECTED_OUTCOMES_SUCCESS,
    FETCH_EXPECTED_OUTCOMES_FAILURE
  );

export const fetchLawfirmRoles = () =>
  fetchDropdownData(
    '/dropdown/lawfirmRoles',
    FETCH_LAWFIRM_ROLES_REQUEST,
    FETCH_LAWFIRM_ROLES_SUCCESS,
    FETCH_LAWFIRM_ROLES_FAILURE
  );

export const fetchLegalSubjects = () =>
  fetchDropdownData(
    '/dropdown/legalSubjects',
    FETCH_LEGAL_SUBJECTS_REQUEST,
    FETCH_LEGAL_SUBJECTS_SUCCESS,
    FETCH_LEGAL_SUBJECTS_FAILURE
  );

export const fetchPlatformCurrencies = () =>
  fetchDropdownData(
    '/dropdown/platformCurrencies',
    FETCH_PLATFORM_CURRENCIES_REQUEST,
    FETCH_PLATFORM_CURRENCIES_SUCCESS,
    FETCH_PLATFORM_CURRENCIES_FAILURE
  );

export const fetchPlatformLanguages = () =>
  fetchDropdownData(
    '/dropdown/platformLanguages',
    FETCH_PLATFORM_LANGUAGES_REQUEST,
    FETCH_PLATFORM_LANGUAGES_SUCCESS,
    FETCH_PLATFORM_LANGUAGES_FAILURE
  );

export const fetchPracticeAreas = () =>
  fetchDropdownData(
    '/dropdown/practiceAreas',
    FETCH_PRACTICE_AREAS_REQUEST,
    FETCH_PRACTICE_AREAS_SUCCESS,
    FETCH_PRACTICE_AREAS_FAILURE
  );

export const fetchPreferredLanguages = () =>
  fetchDropdownData(
    '/dropdown/preferdLanguages',
    FETCH_PREFERRED_LANGUAGES_REQUEST,
    FETCH_PREFERRED_LANGUAGES_SUCCESS,
    FETCH_PREFERRED_LANGUAGES_FAILURE
  );

export const fetchUrgencyStatus = () =>
  fetchDropdownData(
    '/dropdown/urgencyStatus',
    FETCH_URGENCY_STATUS_REQUEST,
    FETCH_URGENCY_STATUS_SUCCESS,
    FETCH_URGENCY_STATUS_FAILURE
  );
