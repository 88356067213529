import React, { useEffect, useRef, useState } from 'react';
import './MessageList.css';
import { ServiceAPIResponse, submitChatFeedback } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { setVoice } from '../../store/actions/SpeechAction';
import { setFontSize } from '../../store/actions/FontSizeAction';
import { ShowPromptFeedback } from '../../store/actions/modalActions';

export type Message = {
  id?: number;
  text: string | JSX.Element;
  sender: 'user' | 'assistant';
  action: 'confirm' | null;
  file1?: File | null;  
  file2?: File | null;
  oldChat?: boolean;
  customClass?: string;
  ChatGroupID?: string;
  Contracts?: string[];
};

type MessageListProps = {
  messages: Message[];
  isFolderStructureVisible: boolean;
  onConfirm: (confirmation: string) => void;
  isDocumentVisible: boolean;
  isLoading: boolean;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  loaderText: string;
  speakText: (textToSpeak: string) => void;
  latestResponse: ServiceAPIResponse | null;
};

const MessageList: React.FC<MessageListProps> = ({
  messages,
  isFolderStructureVisible,
  isDocumentVisible,
  loaderText,
  speakText,
  latestResponse
}) => {
  const [displayedTexts, setDisplayedTexts] = useState<(string | JSX.Element)[]>(messages.map(msg => msg.text));
  const [completedTexts, setCompletedTexts] = useState<boolean[]>(messages.map(msg => !!msg.oldChat));
  const messageEndRef = useRef<HTMLDivElement | null>(null);

  const [showScrollDownArrow, setShowScrollDownArrow] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null); 
  const dispatch = useAppDispatch();
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel)
  const fontSize = useSelector((state: RootState) => state.fontSize.fontSize);
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const [likedMessages, setLikedMessages] = useState<{ [key: number]: boolean }>({});
  const [dislikedMessages, setDislikedMessages] = useState<{ [key: number]: boolean }>({});


  useEffect(() => {
    if (aiSettings) {
      dispatch(setFontSize(aiSettings.FontSize));
      dispatch(setVoice(aiSettings.AIVoice));
    }
  }, [aiSettings, dispatch]);

  useEffect(() => {
    scrollToBottomIfNeeded();
  }, [messages.length]); 

  useEffect(() => {
    const isUserAtBottom = () => {
      const container = messageEndRef.current?.parentElement;
      if (!container) return false;

      const scrollPosition = container.scrollTop + container.clientHeight;
      const threshold = container.scrollHeight - 50;
      return scrollPosition >= threshold;
    };

    const scrollToBottomIfNeeded = () => {
      if (isUserAtBottom()) {
        scrollToBottomImmediate();
      }
    };

    if (messages.some(msg => msg.oldChat)) {
      scrollToBottomImmediate();
    } else if (messages.length > 0 && !messages[messages.length - 1].oldChat) {
      const timer = setTimeout(scrollToBottomIfNeeded, 50);
      return () => clearTimeout(timer);
    }
  }, [messages, messages.length]);

  const scrollToBottomImmediate = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  const handleUpVoteMessage = (index: number) => {
    const chatId = latestResponse?.ChatID ?? '';
    const groupId = latestResponse?.ChatGroupID ?? '';
    if (chatId && groupId) {
      dispatch(submitChatFeedback(chatId, groupId, 'yes', '', ''));
      setLikedMessages(prev => ({ ...prev, [index]: true }));
      setDislikedMessages(prev => ({ ...prev, [index]: false }));
    } else {
      console.error('Chat ID or Group ID is undefined');
    }
  };
  
  const handleDownVoteMessage = (index: number) => {
    const chatId = latestResponse?.ChatID ?? '';
    const groupId = latestResponse?.ChatGroupID ?? '';
    if (chatId && groupId) {
      dispatch(ShowPromptFeedback()).then((comment: string) => {
        dispatch(submitChatFeedback(chatId, groupId, '', 'yes', comment));
        setDislikedMessages(prev => ({ ...prev, [index]: true }));
        setLikedMessages(prev => ({ ...prev, [index]: false }));
      });
    } else {
      console.error('Chat ID or Group ID is undefined');
    }
  };

  const copyToClipboard = (text: string, index: number) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000); 
  };

  useEffect(() => {
    function setSpeech() {
      if (typeof window.speechSynthesis === 'undefined' || window.speechSynthesis.getVoices().length === 0) {
        window.speechSynthesis.onvoiceschanged = setSpeech;
      }

      if (messages.length === 0) {
        window.speechSynthesis.cancel()
      }
    }
    setSpeech();
  }, []);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    function handleScroll() {
      const element = messageEndRef.current;
      if (element && element.parentElement) {
        const show = element.parentElement.scrollHeight - element.parentElement.scrollTop > element.parentElement.clientHeight + 100;
        setShowScrollDownArrow(show);
      }
    }

    const container = messageEndRef.current?.parentElement;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messages]);

  const formatAssistantMessage = (text: string | JSX.Element | null | undefined): JSX.Element | string => {
    if (typeof text !== 'string') {
        return text || ''; 
    }

    const formatLine = (line: string, index: number) => {
      let jsxElements: (JSX.Element | string)[] = [];
  
      // Headers
      if (line.startsWith('### ')) {
        jsxElements.push(<h3 key={`h3-${index}`}><strong>{line.substring(4)}</strong></h3>);
      } else if (line.startsWith('#### ')) {
        jsxElements.push(<h4 key={`h4-${index}`}><strong>{line.substring(5)}</strong></h4>);
      } else if (line.startsWith('##### ')) {
        jsxElements.push(<h5 key={`h5-${index}`}><strong>{line.substring(6)}</strong></h5>);
      } else if (line.startsWith('###### ')) {
        jsxElements.push(<h6 key={`h6-${index}`}><strong>{line.substring(7)}</strong></h6>);
      } else if (line.match(/^\d+\.\s/)) {
        // Numbered list
        const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
        tokens.forEach((token, i) => {
          if (token.startsWith('**')) {
            jsxElements.push(<strong key={`strong-${index}-${i}`}>{token.slice(2, -2)}</strong>);
          } else if (token.startsWith('*')) {
            jsxElements.push(<em key={`em-${index}-${i}`}>{token.slice(1, -1)}</em>);
          } else if (token.match(/\[doc\d+\]/)) {
            jsxElements.push('');
            // jsxElements.push(<i key={`icon-${index}-${i}`} title='source' style={{ color: '#64d2ff' }} className="fas fa-file-lines"></i>);
          } else {
            jsxElements.push(token);
          }
        });
        jsxElements = [<li style={{ fontFamily: 'Arial, Helvetica, sans-serif' }} key={`li-${index}`}>{jsxElements}</li>];
      } else if (line.startsWith('- ')) {
        // Unordered list
        const tokens = line.substring(2).split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
        tokens.forEach((token, i) => {
          if (token.startsWith('**')) {
            jsxElements.push(<strong key={`strong-${index}-${i}`}>{token.slice(2, -2)}</strong>);
          } else if (token.startsWith('*')) {
            jsxElements.push(<em key={`em-${index}-${i}`}>{token.slice(1, -1)}</em>);
          } else if (token.match(/\[doc\d+\]/)) {
            jsxElements.push('');
            // jsxElements.push(<i key={`icon-${index}-${i}`} title='source' style={{ color: '#64d2ff' }} className="fas fa-file-lines"></i>);
          } else {
            jsxElements.push(token);
          }
        });
        jsxElements = [<li style={{ fontFamily: 'Arial, Helvetica, sans-serif' }} key={`li-${index}`}>{jsxElements}</li>];
      } else {
        // Regular text with possible inline formatting
        const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
        tokens.forEach((token, i) => {
          if (token.startsWith('**')) {
            jsxElements.push(<strong key={`strong-${index}-${i}`}>{token.slice(2, -2)}</strong>);
          } else if (token.startsWith('*')) {
            jsxElements.push(<em key={`em-${index}-${i}`}>{token.slice(1, -1)}</em>);
          } else if (token.match(/\[doc\d+\]/)) {
            jsxElements.push('');
            // jsxElements.push(<i key={`icon-${index}-${i}`} title='source' style={{ color: '#64d2ff' }} className="fas fa-file-lines"></i>);
          } else {
            jsxElements.push(token);
          }
        });
      }
  
      return <div key={index}>{jsxElements}</div>;
    };
  
    // Formatting the entire text for contract detection and styling
    const formatEntireText = (text: string): JSX.Element | string => {
      const contractStartRegex = /---\s*Begin\w*\s*of\s*the\s*Contract\s*---/gi;
      const contractEndRegex = /---\s*End\s*of\s*the\s*Contract\s*---/gi;
  
      const startIndex = text.search(contractStartRegex);
      const endIndex = text.search(contractEndRegex);
  
      if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        const beforeContract = text.substring(0, startIndex).trim();
        const contractContent = text.substring(startIndex + 3, endIndex).replace(contractStartRegex, '').trim();
        const afterContract = text.substring(endIndex + 3).replace(contractEndRegex, '').trim();
  
        return (
          <>
            {beforeContract && <div>{formatAssistantMessage(beforeContract)}</div>}
            <div style={{ background: '#222', borderRadius: '8px', padding: '20px 10px', color: 'white', margin: '10px 0' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <i style={{ fontSize: 80 }} className='fa-kit fa-logo'></i>
              </div>
              <div className="contract-format" style={{ whiteSpace: 'pre-wrap' }}>
                {formatAssistantMessage(contractContent)}
              </div>
            </div>
            {afterContract && <div>{formatAssistantMessage(afterContract)}</div>}
          </>
        );
      }
  
      return text;
    };
  
    // Check if the entire text contains contract markers and format accordingly
    const containsContractMarkers = typeof text === 'string' && text.includes('--- Begin') && text.includes('--- End');
  
    return (
      <div className="formatted-assistant-message">
        {containsContractMarkers
          ? formatEntireText(text)
          : typeof text === 'string'
          ? text.split('\n').map((line, index) => <React.Fragment key={index}>{formatLine(line, index)}<br /></React.Fragment>)
          : text}
      </div>
    );
  };

  const scrollToBottomIfNeeded = () => {
    const container = messageEndRef.current?.parentElement;
    if (!container) return;
  
    const scrollPosition = container.scrollTop + container.clientHeight;
    const threshold = container.scrollHeight - 50;
    if (scrollPosition < threshold) {
      scrollToBottom();
    }
  };

  useEffect(() => {
    const completedAnimations = new Set();

    const lastAssistantMessageIndex = messages.map((m, index) => ({ ...m, index }))
      .reverse()
      .find(m => m.sender === 'assistant')?.index;

    messages.forEach((message, index) => {
      if (typeof message.text === 'string' && message.sender === 'assistant' && index === lastAssistantMessageIndex) {
        if (message.oldChat) {
          const formattedMessage = formatAssistantMessage(message.text);
          setDisplayedTexts(prev => {
            const updatedTexts = [...prev];
            updatedTexts[index] = formattedMessage;
            return updatedTexts;
          });
        } else {
          let currentLength = 0;
          let fullText = message.text;

          const timer = setInterval(() => {
            setDisplayedTexts(prev => {
              const updatedTexts = [...prev];
              currentLength++;
              scrollToBottomImmediate();
              if (currentLength <= fullText.length) {
                const textSegment = fullText.substring(0, currentLength);
                updatedTexts[index] = formatAssistantMessage(textSegment);
              } else {
                updatedTexts[index] = formatAssistantMessage(fullText);
                clearInterval(timer);
                completedAnimations.add(message.id);
              }
              return updatedTexts;
            });
            scrollToBottomIfNeeded();
          }, 10);

          return () => clearInterval(timer);
        }
      }
    });
  }, [messages]);

  const formatUserMessage = (text: string | JSX.Element): JSX.Element | string => {
    if (typeof text === 'string') {
      const initiatingRegex = /^Initiating (.+?)\.\.\./;
      const match = text.match(initiatingRegex);
  
      if (match) {
        const contractType = match[1];
        return (
          <div className="formatted-user-message">
            <span style={{ background: '#444', padding: '3px 10px', borderRadius: '5px', display: 'inline-block', marginBottom: '10px', color: '#64d2ff' }}>
              {contractType}
            </span>
          </div>
        );
      }
  
      const formattedText = text.replace(/\n/g, '<br />');
      return <div className="formatted-user-message" dangerouslySetInnerHTML={{ __html: formattedText }} />;
    }
    return text;
  };

  return (
    <>
      <div className={`message-list-container ${(isFolderStructureVisible && selectedModel === "Law Research") && (isFolderStructureVisible && selectedModel === "Case Research") && (isFolderStructureVisible && selectedModel === "Contract Analysis") ? '' : 'expanded'} ${isDocumentVisible && selectedModel === 'Law Research' ? 'shrink' : ''}`}>
        {messages.map((message, index) => (
          <section
            key={index}
            className={`message-bubble ${message.sender === 'user' ? 'user-message' : 'assistant-message'}`}
          >
            <div className={`message-bubble__wrapper ${isDocumentVisible ? 'shrink' : ''}`}>
              {message.text === 'Loading...' && (
                <div className="loader">
                  <i className="fa-kit fa-logo spin" style={{ fontSize: 40, color: '#64d2ff' }}></i>
                  {/* <p>{loaderText}</p> */}
                </div>
              )}

              {message.sender === 'assistant' && message.action !== 'confirm' && message.text !== 'Loading...' && (
                <div className={`message-content assistant-message-content`} style={{ fontSize: `${fontSize}px` }}>
                  {typeof message.text === 'string'
                      ? (completedTexts[index]
                        ? formatAssistantMessage(displayedTexts[index] as string)
                        : displayedTexts[index] || message.text)
                      : message.text}
                  {displayedTexts && (
                    <div className="message-actions">
                      <button
                        className=""
                        onClick={() => speakText(message.text as string)}
                        title={('Listen')}
                      >
                        <i className="fa-solid fa-volume"></i>
                      </button>
                      <button className="copy-icon" title='Copy to clipboard' onClick={() => copyToClipboard(typeof message.text === 'string' ? message.text : '', index)}>
                        <i className={`fa-regular ${copiedIndex === index ? 'fa-clipboard-check' : 'fa-clipboard'}`}></i>
                      </button>
                      {likedMessages[index] ? (
                        <button className="liked" onClick={() => handleUpVoteMessage(index)}>
                          <i className="fa-solid fa-thumbs-up" style={{ color: '#64d2ff' }}></i>
                        </button>
                      ) : dislikedMessages[index] ? null : (
                        <button onClick={() => handleUpVoteMessage(index)}>
                          <i className="fa-solid fa-thumbs-up"></i>
                        </button>
                      )}
                      {dislikedMessages[index] ? (
                        <button className="disliked" onClick={() => handleDownVoteMessage(index)}>
                          <i className="fa-solid fa-thumbs-down" style={{ color: '#ff6b6b' }}></i>
                        </button>
                      ) : likedMessages[index] ? null : (
                        <button onClick={() => handleDownVoteMessage(index)}>
                          <i className="fa-solid fa-thumbs-down"></i>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}

              {message.sender === 'user' && (
                <div className={`message-content user-message-content`} style={{ fontSize: `${fontSize}px` }}>
                  {formatUserMessage(message.text)}
                  {(message.file1 || message.file1) && 
                  <div className='file-info__attachments--row'>
                  {message.Contracts ? (
                    message.Contracts.map((contractUrl, idx) => (
                      <div key={idx} className="file-info__attachments">
                        <a href={contractUrl} target="_blank" rel="noopener noreferrer">
                          {`Contract ${idx + 1}`}
                        </a>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                    {message.file1 && (
                      <div className="file-info__attachments">
                        <span>{message.file1.name}</span>
                      </div>
                    )}
                    {message.file2 && (
                      <div className="file-info__attachments">
                        <span>{message.file2.name}</span>
                      </div>
                    )}
                  </div>
                  }
                </div>
              )}
            </div>
          </section>
        ))}
        <div ref={messageEndRef}></div>
      </div>
      {showScrollDownArrow && (
        <i onClick={scrollToBottom} className="fa-duotone fa-circle-chevron-down scroll-down-arrow"></i>
      )}
    </>
  );
};

export default MessageList;
