import axios, { AxiosError } from "axios"
import apis from "../../utils/apis"
import { ErrorResponse } from "./SignInAction"
import { showFeedbackModal } from "./UserFeedbackActions"



export const addProject = (UserType: string, ProjectName: string, ProjectDescription: string, ProjectLeadEmail: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "projects/addProject",
                data: {
                    UserType,
                    ProjectName,
                    ProjectDescription,
                    ProjectLeadEmail,
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'New Project Created!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const addProjectAssignee = (UserType: string, ProjectID: string, Assignees: string[] | [], Deadline: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "projects/addProjectAssignee",
                data: {
                    UserType,
                    ProjectID,
                    Assignees,
                    Deadline,
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'New Members added!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const businessAssignTask = (TaskID: string, Assignees: string[] | [],) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/assignTask",
                data: {
                    Assignees,
                    TaskID,
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task Assigned to respective users!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}


export const getBusinessAssignTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/getTask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const getAllBusinessAssignedTask = (UserType: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/getAllTask",
                data: {
                    UserType,
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const deleteBusinessAssignedTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/deleteTask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task Deleted Successfully!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}


export const completeBusinessAssignedTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/business/completetask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task Completed Successfully!'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}