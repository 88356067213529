import React, { useState, ChangeEvent, FormEvent, useEffect, useRef, useCallback, useMemo } from 'react';
import SocketServices from '../../../utils/SocketServices';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import { ICaseItem } from '../Interfaces/ICaseItem';
import '../Marketplace.css'
import FullscreenImageModal from './FullScreenImage';
import { useSwipeable } from 'react-swipeable';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import VideoCallComponent from '../VideoCallComponent';
import ScheduleMeeting from '../Lawyer/ScheduleMeeting';
import { IAdminCaseItem } from '../Interfaces/IAdminCaseItem';
import { useAppDispatch } from '../../../store';
import { getLatestMeeting } from '../../../store/actions/ScheduleMeetingActions';
import { hideModal, showModal } from '../../../store/actions/modalActions';

export interface ChatMessage {
  id: number;
  Message: string;
  timestamp: Date,
  sender?: 'client' | 'lawyer';
  attachments?: File[];
  SenderID?: string,
  RecieverID?: string,
  CaseID?: String,
  Type?: string,
  callStartedAt?: string,
  callEndedAt?: string,
}

interface ChatInterfaceProps {
  onSendMessage: (message: string, attachments: File[]) => void;
  CaseItem: ICaseItem | IAdminCaseItem
}

interface ChatImagesProps {
  attachments: any
}


export interface MessageProps {
  msg: any
}


export const ChatImages: React.FC<ChatImagesProps> = ({ attachments }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  let displayAttachments = attachments;
  let remainingCount = 0

  if (attachments.length > 2) {
    displayAttachments = attachments.slice(0, 2);
    remainingCount = attachments.length - 2;

  }



  const openModal = () => {
    setSelectedImages(attachments);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const renderAttachment = (file: any, index: number) => {
    // Check if the file is a URL and if it's an image
    const isImageUrl = typeof file === 'string' && (file.startsWith('http://') || file.startsWith('https://'));
  
    if (isImageUrl) {
      return (
        <div key={index} className="attachment-item--msg" onClick={openModal}>
          <img src={file} alt={`file`} className="attachment-image" />
          {remainingCount > 0 && index === 1 && (
            <div className="attachment-overlay">
              +{remainingCount}
            </div>
          )}
        </div>
      );
    } else if (file?.type?.startsWith('image/')) {
      return (
        <div key={index} className="attachment-item--msg" onClick={openModal}>
          <img src={URL.createObjectURL(file)} alt={file.name} className="attachment-image" />
          {remainingCount > 0 && index === 1 && (
            <div className="attachment-overlay">
              +{remainingCount}
            </div>
          )}
        </div>
      );
    } else if (file?.type?.startsWith('audio/')) {
      return (
        <div key={index} className="attachment-item--msg">
          <audio controls className="attachment-audio">
            <source src={URL.createObjectURL(file)} type={file.type} />
            Your browser does not support the audio element.
          </audio>
        </div>
      );
    } else if (file?.type === "application/pdf") {
      return (
        <div key={index} className="attachment-item--msg">
          <a href={URL.createObjectURL(file)} download={file.name} style={{color: "#fff"}} className="attachment-text">
            {file.name}
          </a>
        </div>
      );
    } else {
      return (
        <div key={index} className="attachment-item--msg">
          <a href={URL.createObjectURL(file)} download={file.name} className="attachment-other">
            {file.name}
          </a>
        </div>
      );
    }
  };
  useEffect(() => {
    return () => {
      attachments.forEach((file: any) => {
        if (file instanceof Blob) {
          URL.revokeObjectURL(URL.createObjectURL(file));
        }
      });
    };
  }, [attachments]);

  return (
    <div className="attachments-container--msg">
      {displayAttachments.map((file: any, index: number) => renderAttachment(file, index))}

      {
        modalIsOpen &&
        <FullscreenImageModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          images={selectedImages}
        />
      }
    </div>
  );
};

const ChatInterface: React.FC<ChatInterfaceProps> = ({ CaseItem }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [message, setMessage] = useState<string>('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [socketId, setSocketId] = useState("")
  const TYPING_TIMER_LENGTH = 1000; // ms
  const [typing, setTyping] = useState(false)
  const userType = getUserType()
  const id = userType === "Client" ? `lawyer_${CaseItem.CaseID}` : `client_${CaseItem.CaseID}`
  const userId = getUserID()
  const { t } = useTranslation()
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const date: Date = useMemo(() => new Date(), []);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const [messageReply, setMessageReply] = useState<any>("")
  dayjs.extend(durationPlugin);
  const [meeting, setMeeting] = useState<any>({})
  const [isMeeetingTime, setIsMeetingTime] = useState(false)
  const dispatch = useAppDispatch()


  const fetchLatestMeeting = useCallback(async () => {
    try {
      // if (userType === 'Client' && !meeting) {
        const response: any = await dispatch(getLatestMeeting(CaseItem.CaseID))
        if (response.meeting) {
          setMeeting(response.meeting);
        } else {
          setMeeting({});
        }
      // }

    } catch (err) {

    }
  }, [CaseItem.CaseID, dispatch])
  useEffect(() => {
    fetchLatestMeeting();
    const interval = setInterval(fetchLatestMeeting, 20000);
    return () => clearInterval(interval);
  }, [CaseItem, dispatch, fetchLatestMeeting]);

  useEffect(() => {
    let typingTimeout: string | number | NodeJS.Timeout | undefined;
    if (typing) {
      setTyping(true);
      clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        setTyping(false);
      }, 3000);
    }
    return () => clearTimeout(typingTimeout);
  }, [typing]);


  useEffect(() => {
    if (id) {
      setSocketId(id)
    }
  }, [id])

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getChatList = useCallback(async (chatList: any) => {
    setMessages(chatList.chatList)
  }, [])

  useEffect(() => {
    SocketServices.emit("user_join", { username: userType === "Lawyer" ? `lawyer_${CaseItem.CaseID}` : `client_${CaseItem.CaseID}`, userId: userId, caseid: CaseItem.CaseID })

    SocketServices.on('recieve message', function (data: any) {
      const {
        fromUsername,
        message
      } = data;

      setSocketId(fromUsername)
      setMessages(messages => [
        ...messages,
        { id: (messages.length + 1), Message: message, timestamp: date, attachments, sender: "lawyer" }
      ])
      setTyping(false);
    });

    SocketServices.on('receive_message', function (data: any) {
      const {
        message
      } = data;
      setMessages(messages => [
        ...messages,
        { id: (messages.length + 1), Message: message, timestamp: date, attachments, sender: "lawyer" }
      ])
      setTyping(false);
    });


    SocketServices.on('typing', function (data: any) {
      if (data.reciever_name !== socketId) {
        setTyping(true);
      }
    });

    SocketServices.on('stop_typing', function (data: any) {
      if (data.reciever_name !== socketId) {
        setTyping(false);
      }
    });

    SocketServices.on('chat_list', getChatList)

    return () => {
      SocketServices.removeListener('receive message');
      SocketServices.removeListener('typing');
      SocketServices.removeListener('stop_typing');
      SocketServices.removeListener('receive_message');
      SocketServices.removeListener('chat_list')

    };

  }, [CaseItem.CaseID, attachments, socketId, userId, userType, getChatList, date])

  useEffect(() => {
    SocketServices.emit("chat_list", { caseid: CaseItem.CaseID, receiver_username: userType === "Client" ? `client_${CaseItem.CaseID}` : `lawyer_${CaseItem.CaseID}` })

  }, [CaseItem.CaseID, userType])

  let lastTypingTime = (new Date()).getTime();
  setTimeout(() => {
    const typingTimer = (new Date()).getTime();
    const timeDiff = typingTimer - lastTypingTime;
    if (timeDiff >= TYPING_TIMER_LENGTH && typing) {
      SocketServices.emit('stop_typing', {
        reciever_name: userType === "Lawyer" ? `client_${CaseItem.CaseID}` : `lawyer_${CaseItem.CaseID}`,
        caseid: CaseItem.CaseID
      });
      setTyping(false);
    }
  }, TYPING_TIMER_LENGTH);

  const updateTyping = () => {

    if (!typing) {
      SocketServices.emit('typing', {
        reciever_name: userType === "Lawyer" ? `client_${CaseItem.CaseID}` : `lawyer_${CaseItem.CaseID}`,
        caseid: CaseItem.CaseID
      });
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setAttachments([...attachments, ...filesArray]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };
  const handleRemoveMessage = () => {
    setMessageReply("")
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();

    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const phonePattern = /\b\d{10}\b/;

    if (emailPattern.test(message) || phonePattern.test(message)) {
      dispatch(showModal({
        type: 'warning',
        message: t('Sending personal information like emails or phone numbers is not allowed.'),
        onConfirm: () => hideModal(),
        showModal: true,
      }));
      return;
    }

    if (!message && attachments.length === 0) {
      return;
    }
    if (!message && attachments.length > 0) {
      setMessages(messages => [...messages, { id: (messages.length + 1), Message: message, timestamp: date, attachments, sender: "client" }]);
      setMessage('');
      setAttachments([]);
    }


    if (message.trim()) {
      setMessages(messages => [...messages, { id: (messages.length + 1), Message: message, timestamp: date, attachments, sender: "client" }]);
      SocketServices.emit("send_message", {
        message: message,
        receiver_username: userType === "Lawyer" ? `client_${CaseItem.CaseID}` : `lawyer_${CaseItem.CaseID}`
      })
      setMessage('');
      setAttachments([]);
    }
  };

  const handleTextChange = (text: string) => {
    setMessage(text);
  };

  function formatDate(timestamp: Date) {
    const date: any = new Date(timestamp);
    const now: any = new Date();

    const differenceInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return 'Today';
    } else if (differenceInDays === 1) {
      return 'Yesterday';
    } else if (differenceInDays <= 3) {
      const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
      return date.toLocaleDateString('en-GB', options);
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' };
      return `${date.toLocaleDateString('en-GB', options)}, ${date.getFullYear()}`;
    }
  }

  const groupedMessages = useMemo(() => {
    const groups: { [date: string]: ChatMessage[] } = {};
    messages.forEach((msg: ChatMessage) => {
      const date = formatDate(msg.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
    });
    return groups;
  }, [messages]);

  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }); // Customize the time format as needed
  };


  const Message: React.FC<MessageProps> = ({ msg }) => {
    const [isSwiping, setIsSwiping] = useState(false);
    const [swipeDirection, setSwipeDirection] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const messageLimit = 100;
    const [duration, setDuration] = useState("0s")
    useEffect(() => {
      if (msg.Type === "Call") {
        const start = dayjs(msg.callStartedAt);
        const end = dayjs(msg.callEndedAt);

        const callDuration = dayjs.duration(end.diff(start));
        let formattedDuration = "";
        if (callDuration.hours() > 0) {
          formattedDuration += `${callDuration.hours()}h `;
        }
        if (callDuration.minutes() > 0) {
          formattedDuration += `${callDuration.minutes()}m `;
        }
        formattedDuration += `${callDuration.seconds()}s`;

        setDuration(formattedDuration);
      }
    }, [msg.Type, msg.callStartedAt, msg.callEndedAt]);


    const handlers = useSwipeable({
      onSwipedLeft: () => {
        setSwipeDirection('left');
        setIsSwiping(true);
        setMessageReply(msg)

      },
      onSwipedRight: () => {
        // setSwipeDirection('right');
        // setIsSwiping(true);
        // setMessageReply(msg)
      },
      onSwiping: (eventData) => {
        setSwipeDirection(eventData.dir);
        setIsSwiping(true);
      },
      onSwiped: () => {
        setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
      },
      delta: 10,
      preventScrollOnSwipe: false,
      trackMouse: true,
    });

    useEffect(() => {
      if (isSwiping) {
        const timer = setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [isSwiping]);
    const messageClass = msg.sender
      ? msg.sender
      : userType === 'Lawyer'
        ? msg.SenderID === `lawyer_${CaseItem.CaseID}`
          ? 'client'
          : 'lawyer'
        : msg.SenderID === `lawyer_${CaseItem.CaseID}`
          ? 'lawyer'
          : 'client';

    return (
      <>
        {
          msg.Type ? (

            msg.Type === "Text" ? (
              <div
                key={msg.id}
                {...handlers}
                className={`message__marketplace ${messageClass} ${isSwiping ? 'swiping' : ''} ${swipeDirection}`}
              >
                {msg.attachments && msg.attachments.length > 0 && (
                  msg.attachments && <ChatImages attachments={msg.attachments} />
                )}
                <div className="message-content">
                  {isExpanded || msg.Message.length <= messageLimit
                    ? msg.Message
                    : `${msg.Message.substring(0, messageLimit)}...`
                  }
                  {!isExpanded && msg.Message.length > messageLimit && (
                    <span className="read-more" onClick={() => setIsExpanded(true)}>
                      Read more
                    </span>
                  )}
                  {isExpanded && <span className="read-more" onClick={() => setIsExpanded(false)}>
                    Read less
                  </span>
                  }
                </div>
                <div className="message-timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px", marginBottom: "10px" }}>
                <i className='fa-regular fa-video' style={{ color: "grey", fontSize: '16px' }}></i>
                <span style={{ color: "grey", fontSize: '16px' }}>{msg.Message}</span>
                <span style={{ color: "grey", fontSize: '16px' }}>{duration}</span>

              </div>
            )
          )
            : (
              <div
                key={msg.id}
                {...handlers}
                className={`message__marketplace ${messageClass} ${isSwiping ? 'swiping' : ''} ${swipeDirection}`}
              >
                {msg.attachments && <ChatImages attachments={msg.attachments} />}
                <div className="message-content">
                  {isExpanded || msg.Message.length <= messageLimit
                    ? msg.Message
                    : `${msg.Message.substring(0, messageLimit)}...`
                  }
                  {!isExpanded && msg.Message.length > messageLimit && (
                    <span className="read-more" onClick={() => setIsExpanded(true)}>
                      Read more
                    </span>
                  )}
                  {isExpanded && <span className="read-more" onClick={() => setIsExpanded(false)}>
                    Read less
                  </span>
                  }
                </div>
                <div className="message-timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            )
        }
      </>
    );
  };


  return (
    <>
      <div className='inbox-header-meeting__client'>
        <ScheduleMeeting caseItem={CaseItem} meeting={meeting} isMeetingTime={isMeeetingTime} setIsMeetingTime={setIsMeetingTime} />
        <VideoCallComponent caseItem={CaseItem} isMeetingTime={isMeeetingTime} setIsMeetingTime={setIsMeetingTime} meeting={meeting} />
      </div>
      <div className="chat-container__marketplace">
        <div className="messages-list__marketplace">
          {Object.keys(groupedMessages).map((date) => (
            <React.Fragment key={date}>
              <div className="message-date">{date}</div>
              {groupedMessages[date].map((msg: any) => (
                <Message key={msg.id} msg={msg} />
              ))}
            </React.Fragment>
          ))}

          {typing && (
            <div
              className="marketplace__typing-effect"
              style={{ opacity: typing ? 1 : 0, transform: `translateY(${typing ? 0 : 10}px)` }}
            >
              {t('Typing...')}
            </div>
          )}
          <div style={{ marginTop: '0px' }} ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSendMessage} className="message-form__marketplace">
          {attachments.length > 0 && (
            <div className="attachments-preview__marketplace">
              {attachments.map((file, index) => (
                <div key={index} className="attachment-preview-item__marketplace">
                  {file.name}
                  <i onClick={() => handleRemoveAttachment(index)} className='fa-solid fa-square-xmark'></i>
                </div>
              ))}
            </div>
          )}

          {
            messageReply && (
              <div className="reply-preview-container">
                <div className="reply-preview-item">
                  {messageReply.attachments && messageReply.attachments.length > 0 && <ChatImages attachments={messageReply.attachments} />
                  }
                  <span className="reply-message">{messageReply.Message}</span>
                  <i onClick={() => handleRemoveMessage()} className="fa-solid fa-square-xmark remove-reply-icon"></i>
                </div>
              </div>
            )
          }
          <div className='message-form__marketplace--row'>
            <label htmlFor="file-upload" className="file-upload-label">
              <i className='fa-solid fa-circle-plus'></i>
            </label>
            <input
              id="file-upload"
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <input
              type="text"
              placeholder={t("Type a message...")}
              value={message}
              onChange={(e) => { updateTyping(); handleTextChange(e.target.value) }}
              className="message-input__marketplace"
            />
            <button type="submit" className="send-button__marketplace">
              <i className='fa-solid fa-send'></i>
            </button>
          </div>
        </form >
      </div >
    </>

  );
};

export default ChatInterface;
