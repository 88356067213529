import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import apis from '../../utils/apis';
import SocketServices from '../../utils/SocketServices';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface PricingPageProps {
    userType: string
}

const PricingPage: React.FC = () => {
    // SocketServices.initializeSocket()
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const userInfo = useSelector((state: RootState) => state.user.userInfo)
    const userType = getUserType()
    const userId = getUserID()
    const [userID, setUSerID] = React.useState("")
    

    React.useEffect(() => {
        const fun = async () => {
            const resp = await apis({
                method: "POST",
                url: "convert/decryptString",
                data: {
                    "InputString": userId
                }
            })

            if (resp && resp.status === 200) {
                setUSerID(resp.data['Decrypted string'])

            }
        }
        fun()
    }, [userId])


    return (

        <div>

            {
                userID && (
                    userType === "Client" ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1PrKhDCKZInHFLiaxSQts34W"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw"
                            client-reference-id={userID}
                        >

                        </stripe-pricing-table>
                    ) : userType === "Lawyer" ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1PrKdUCKZInHFLiaBBHltsYc"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw"
                            client-reference-id={userID}
                        >
                        </stripe-pricing-table>
                    ) : userType === "BusinessAdmin" ? (
                        <stripe-pricing-table pricing-table-id="prctbl_1PrKlJCKZInHFLia9GF95AJU"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw"
                            client-reference-id={userID}
                        >
                        </stripe-pricing-table>
                    ) : (
                        <stripe-pricing-table pricing-table-id="prctbl_1Pqd71CKZInHFLiaa9nH9i9X"
                            publishable-key="pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw">
                        </stripe-pricing-table>
                    )
                )

            }

        </div>


    );
}

export default PricingPage;