import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import {
  ONBOARD_CLIENT,
  CREATE_CASE,
  FETCH_CASE,
  FETCH_CLIENT,
  DELETE_CASE_FIELD,
  ANALYZE_CASE,
  FETCH_ALL_CLIENTS,
  FETCH_ALL_CLIENT_CASES,
  GENERATE_DOCUMENT_REQUEST,
  GENERATE_DOCUMENT_FAILURE,
  GENERATE_DOCUMENT_SUCCESS,
  FETCH_ALL_EXTERNAL_CASES
} from "../types";
import { CaseData, ClientData } from "../reducer/ClientReducer";
import { getUserType } from "./ChatAction";

export const fetchAllClients = () => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/clients/getAll",
      });

      if (resp && resp.status === 200) {
        dispatch({ type: FETCH_ALL_CLIENTS, payload: resp.data.client_list });
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: "Clients fetched successfully!",
        //     modalType: "success",
        //     duration: 3000, 
        //   })
        // );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      handleError(dispatch, err);
    }
    return false;
  };
};

export const fetchAllClientCases = (client_id: string) => {
  return async (dispatch: any): Promise<any> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/clients/getCases",
        data: { client_id: client_id }
      });

      if (resp && resp.status === 200) {
        dispatch({ type: FETCH_ALL_CLIENT_CASES, payload: resp.data.case_list });
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: "Client cases fetched successfully!",
        //     modalType: "success",
        //     duration: 3000, 
        //   })
        // );
        return resp.data.case_list;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      handleError(dispatch, err);
      return []; 
    }
  };
};

export const fetchAllExternalCases = () => {
  return async (dispatch: any): Promise<any> => {
    const userType = getUserType();
    try {
      const resp = await apis({
        method: "POST",
        url: "/externalClients/getAllCases",
        data: { UserType: userType }
      });

      if (resp && resp.status === 200) {
        dispatch({ type: FETCH_ALL_EXTERNAL_CASES, payload: resp.data.case_list });
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: "Client cases fetched successfully!",
        //     modalType: "success",
        //     duration: 3000, 
        //   })
        // );
        return resp.data.case_list;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      handleError(dispatch, err);
      return []; 
    }
  };
};


export const onboardClient = (clientData: ClientData) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      let url = '';
      let data: any;

      if (clientData.client_type === 'individual') {
        url = 'company/clients/onboard';
        data = {
          first_name: clientData.first_name,
          last_name: clientData.last_name,
          email: clientData.email,
          phone_number: clientData.phone_number,
          address: clientData.address,
          client_type: clientData.client_type,
        };
      } else if (clientData.client_type === 'business') {
        url = 'company/businessClients/onboard';
        data = {
          UserType: 'ExternalBusinessClient',
          FirstName: clientData.first_name,
          LastName: clientData.last_name,
          company_name: clientData.company_name,
          contact_name: clientData.contact_name,
          contact_email: clientData.contact_email,
          organization_number: clientData.organization_number || '',
          contact_phone: clientData.contact_phone,
          address: clientData.address,
          client_type: clientData.client_type,
        };
      } else {
        throw new Error('Invalid client type');
      }

      const resp = await apis({
        method: 'POST',
        url: url,
        data: data,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ONBOARD_CLIENT, payload: resp.data });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || 'Client onboarded',
            modalType: 'success',
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      handleError(dispatch, err);
    }
    return false;
  };
};
  
  // Create Case
  export const createCase = (caseData: CaseData) => {
    return async (dispatch: any): Promise<boolean> => {
      try {
        const resp = await apis({
          method: "POST",
          url: "/cases/create",
          data: caseData,
        });
  
        if (resp && (resp.status === 200 || resp.status === 201)) {
          dispatch({ type: CREATE_CASE, payload: resp.data });
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || "Case created successfully!",
              modalType: "success",
              duration: 3000,
            })
          );
          return true;
        } else {
          handleError(dispatch, resp);
        }
      } catch (err) {
        handleError(dispatch, err);
      }
      return false;
    };
  };
  
  // Fetch Case
  export const fetchCase = (caseId: string) => {
    return async (dispatch: any): Promise<any> => {
      try {
        const resp = await apis({
          method: "GET",
          url: `/cases`,
          data: { case_id: caseId }
        });
  
        if (resp && resp.status === 200) {
          dispatch({ type: FETCH_CASE, payload: resp.data });
          return resp.data;
        } else {
          handleError(dispatch, resp);
        }
      } catch (err) {
        handleError(dispatch, err);
      }
      return null;
    };
  };
  
  // Fetch Client
    export const fetchClient = (clientId: string) => {
      return async (dispatch: any): Promise<any> => {
        try {
          const resp = await apis({
            method: "GET",
            url: `/clients/${clientId}`,
          });

          if (resp && resp.status === 200) {
            dispatch({ type: FETCH_CLIENT, payload: resp.data });
            return resp.data;
          } else {
            handleError(dispatch, resp);
          }
        } catch (err) {
          handleError(dispatch, err);
        }
        return null;
      };
    };
  
  // Delete Case Field
  export const deleteCaseField = (caseId: string, fieldName: string) => {
    return async (dispatch: any): Promise<boolean> => {
      try {
        const resp = await apis({
          method: "DELETE",
          url: `/cases/${caseId}/fields/${fieldName}`,
        });
  
        if (resp && resp.status === 200) {
          dispatch({ type: DELETE_CASE_FIELD, payload: { caseId, fieldName } });
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || "Field deleted successfully!",
              modalType: "success",
              duration: 3000,
            })
          );
          return true;
        } else {
          handleError(dispatch, resp);
        }
      } catch (err) {
        handleError(dispatch, err);
      }
      return false;
    };
  };
  
// Analyze Case
export const analyzeCase = (caseId: string) => {
  return async (dispatch: any): Promise<any> => {
    try {
      const resp = await apis({
        method: "POST",
        url: `/cases/analyze`,
        data: { case_id: caseId },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: ANALYZE_CASE, payload: resp.data });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Case analyzed",
            modalType: "success",
            duration: 3000,
          })
        );
        return resp;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      handleError(dispatch, err);
    }
    return null; 
  };
};

  
  // Error handling
  const handleError = (dispatch: any, error: any) => {
    if (error.response && error.response.data) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: error.response.data.message || "An error occurred",
          modalType: "error",
          duration: 3000,
        })
      );
    } else {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: "Network error, please try again",
          modalType: "error",
          duration: 3000,
        })
      );
    }
  };

  export const generateDocument = (caseId: string) => {
    return async (dispatch: any): Promise<any> => {
      dispatch({ type: GENERATE_DOCUMENT_REQUEST });
  
      try {
        const resp = await apis({
          method: 'POST',
          url: '/cases/generateDocument',
          data: { case_id: caseId },
          responseType: 'blob', 
        });
  
        if (resp && resp.status === 200) {
          dispatch({ type: GENERATE_DOCUMENT_SUCCESS });
  
          const blob = new Blob([resp.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Case_${caseId}_Document.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
  
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: 'Document generated and downloaded',
              modalType: 'success',
              duration: 3000,
            })
          );
  
          return resp;
        } else {
          const errorMessage = resp.data?.message || 'Failed to generate document';
          dispatch({ type: GENERATE_DOCUMENT_FAILURE, payload: errorMessage });
          handleError(dispatch, resp);
        }
      } catch (err: any) {
        const errorMessage = err.message || 'An error occurred';
        dispatch({ type: GENERATE_DOCUMENT_FAILURE, payload: errorMessage });
        handleError(dispatch, err);
      }
      return null;
    };
  };
  

export const deleteClient = (client_id: string) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/clients/delete/client",
        data: { client_id },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: 'DELETE_CLIENT_SUCCESS', payload: client_id });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Client deleted successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      handleError(dispatch, err);
    }
    return false;
  };
};

export const deleteCase = (case_id: string, client_id: string) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/cases/delete/case",
        data: { case_id, client_id },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: 'DELETE_CASE_SUCCESS', payload: { case_id, client_id } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Case deleted successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      handleError(dispatch, err);
    }
    return false;
  };
};

// Update Case
export const updateCase = (caseData: CaseData) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/cases/update/case",
        data: caseData,
      });

      if (resp && resp.status === 200) {
        dispatch(showFeedbackModal({
          modalType: 'success',
          showModal: true,
          message: 'Case updated',
          duration: 3000
        }))
      
        if (!resp.data.updated_case.client_id) {
          
        }
      
        dispatch({ type: 'UPDATE_CASE_SUCCESS', payload: resp.data.updated_case });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Case updated successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;      
      } else {
        // If status is 200, but we're handling as an error, log what might be wrong
        console.error('Unexpected response format:', resp);
        handleError(dispatch, resp); // Investigate the response in this block
      }
    } catch (err) {
      console.error('Caught error:', err); // Log the caught error
      handleError(dispatch, err); // Check what this error is
    }
    return false;
  };
};


export const updateCaseStatus = (case_id: string, client_id: string, case_status: string) => {
  return async (dispatch: any): Promise<boolean> => {
    try {
      const resp = await apis({
        method: "POST",
        url: "/cases/update/caseStatus",
        data: { case_id, client_id, case_status },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: 'UPDATE_CASE_STATUS_SUCCESS', payload: { case_id, case_status } });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Case status updated successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      handleError(dispatch, err);
    }
    return false;
  };
};


