import React, { useState, useEffect, useRef } from 'react';
import './FilesContainer.css';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { updateDocumentOrder, updateFolderOrder } from '../../store/actions/DocumentsAction';
import { IonReorder, IonReorderGroup } from '@ionic/react';
import { hideModal, showAddFileModal, showCreateFolderModal, showModal } from '../../store/actions/modalActions';
import { FileProps, StructureProps } from '../../pages/DocumentsPage';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../shared/OutsideClick';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { fetchStructure, renameFolder } from '../../store/actions/FolderAction';
import GlobalDropdown from '../shared/GlobalDropdown';
import { showAddFileSidePanel } from '../../store/actions/sidePanelActions';
import { Tooltip } from '@mui/material';


export interface FolderProps {
  FolderID: string;
  FolderName: string;
  Creation: string;
  Expiry?: string;
  FolderType: string;
  ParentFolderID?: string;
  Status: string;
  UserID?: string;
  UserType?: string;
  id?: string;
  OrderIndex?: string;
  SubFolders: FolderProps[];
  Documents: FileProps[];
}

interface FilesProps {
  onSelectFile: (file: FileProps) => void;
  initialFolders: FolderProps[];
  handleCreateFolder: (folderName: string) => void;
  handleDeleteFolder: (folderName: string) => void;
  handleCreateSubFolder: (folderName: string, FolderId: string) => void;
}

const Files: React.FC<FilesProps> = ({ onSelectFile, initialFolders, handleCreateFolder, handleDeleteFolder, handleCreateSubFolder }) => {
  const dispatch = useAppDispatch();
  const [folders, setFolders] = useState<FolderProps[]>([]);
  const documents = useSelector((state: RootState) => state.documents.documents);
  const [openOptionsFolderId, setOpenOptionsFolderId] = useState<string | "">("")
  const foldersDropdown = useSelector((state: RootState) => state.folders.folders);
  const [openFolderIds, setOpenFolderIds] = useState<string[]>([]);
  const { t } = useTranslation()
  const [renamingFolderId, setRenamingFolderId] = useState<string | null>(null);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const structure = useSelector((state: RootState) => state.folders.structure)
  const [filters, setFilters] = useState({
    FileType: '',
    Creation: '',
    Modified: '',
    Expiry: '',
    CaseID: '',
    Assignees: '',
    SharedWith: '',
    Approvers: '',
    PartiesInvolved: ''
  });
  const [filteredStructure, setFilteredStructure] = useState<StructureProps[]>(structure);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const sortFolders = (folders: FolderProps[]): FolderProps[] => {
    if (!folders || !folders.length) return [];

    const sortedFolders = [...folders].sort((a, b) => {
      const orderA = parseInt(a.OrderIndex || '0', 10);
      const orderB = parseInt(b.OrderIndex || '0', 10);
      return orderA - orderB;
    });

    return sortedFolders.map(folder => ({
      ...folder,
      Documents: sortDocuments(folder.Documents),
      SubFolders: sortFolders(folder.SubFolders),
    }));
  };

  const sortDocuments = (documents: FileProps[]): FileProps[] => {
    if (!documents || !documents.length) return [];

    return [...documents].sort((a, b) => {
      const orderA = parseInt(a.OrderIndex || '0', 10);
      const orderB = parseInt(b.OrderIndex || '0', 10);
      return orderA - orderB;
    });
  };

  useEffect(() => {
    const sortedFolders = sortFolders(structure);
    setFolders(sortedFolders);
  }, [structure]);

  useEffect(() => {
    const { filteredFolders, openFolders } = applyFilter(folders, filters);
    setFilteredStructure(filteredFolders);
    if (Object.values(filters).some(filter => filter !== '')) {
      setOpenFolderIds(openFolders);
    } else {
      setOpenFolderIds([]);
    }
  }, [filters, folders]);



  useEffect(() => {
    const sortedFolders = sortFolders(structure);
    setFolders(sortedFolders);
  }, [structure]);


  useEffect(() => {
    const { filteredFolders, openFolders } = applyFilter(structure, filters);
    setFilteredStructure(filteredFolders);
    if (Object.values(filters).some(filter => filter !== '')) {
      setOpenFolderIds(openFolders);
    } else {
      setOpenFolderIds([]);
    }
  }, [filters, structure]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFilterApply = () => {
    const { filteredFolders, openFolders } = applyFilter(structure, filters);
    setFilteredStructure(filteredFolders);
    setOpenFolderIds(openFolders);
    setIsFilterOpen(false);
  };

  const applyFilter = (
    folders: StructureProps[],
    filters: { [key: string]: string | boolean }
  ): { filteredFolders: StructureProps[]; openFolders: string[] } => {
    let openFolders: string[] = [];

    const filterDocuments = (docs: FileProps[]): FileProps[] => {
      return docs.filter(doc => {
        const isExpired = (expiryDate: string) => new Date(expiryDate) < new Date();
        const isExpiringSoon = (expiryDate: string) => {
          const date = new Date(expiryDate);
          const now = new Date();
          const daysUntilExpiry = Math.ceil((date.getTime() - now.getTime()) / (1000 * 3600 * 24));
          return daysUntilExpiry <= 30;
        };

        return (
          (filters.FileType ? doc.FileType === filters.FileType : true) &&
          (filters.Creation === 'last7days' ? new Date(doc.Creation) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) : true) &&
          (filters.Creation === 'last30days' ? new Date(doc.Creation) >= new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) : true) &&
          (filters.Modified === 'last7days' ? new Date(doc.Modified) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) : true) &&
          (filters.Modified === 'last30days' ? new Date(doc.Modified) >= new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) : true) &&
          (filters.Expiry === 'expired' ? isExpired(doc.Expiry || '') : true) &&
          (filters.Expiry === 'expiringSoon' ? isExpiringSoon(doc.Expiry || '') : true) &&
          (filters.CaseID ? !!doc.CaseID === filters.CaseID : true) &&
          (filters.Assignees ? !!doc.Assignees?.length === filters.Assignees : true) &&
          (filters.SharedWith ? !!doc.SharedWith?.length === filters.SharedWith : true) &&
          (filters.Approvers ? !!doc.Approvers?.length === filters.Approvers : true) &&
          (filters.PartiesInvolved ? !!doc.PartiesInvolved?.length === filters.PartiesInvolved : true)
        );
      });
    };

    const filteredFolders: StructureProps[] = folders.map((folder) => {
      const filteredDocuments = filterDocuments(folder.Documents || []);

      const { filteredFolders: filteredSubFolders, openFolders: subOpenFolders } = applyFilter(folder.SubFolders || [], filters);

      openFolders = openFolders.concat(subOpenFolders);

      const shouldOpen = filteredDocuments.length > 0 || filteredSubFolders.length > 0;

      if (shouldOpen) {
        openFolders.push(folder.FolderID);
      }

      // **Sort the filteredDocuments and filteredSubFolders**
      const sortedDocuments = sortDocuments(filteredDocuments);
      const sortedSubFolders = sortFolders(filteredSubFolders);

      return {
        ...folder,
        Documents: sortedDocuments,
        SubFolders: sortedSubFolders,
      };
    });

    // **Sort the filteredFolders**
    const sortedFilteredFolders = sortFolders(filteredFolders);

    return {
      filteredFolders: sortedFilteredFolders.filter(
        (folder) =>
          folder.Documents.length > 0 ||
          folder.SubFolders.length > 0 ||
          folder.FolderName.toLowerCase().includes((filters.FileType as string).toLowerCase())
      ),
      openFolders
    };
  };


  const folderOptionsRef = useOutsideClick(() => {
    setOpenOptionsFolderId("");
  });


  const handleRenameFolder = (folderId: string, newName: string) => {
    dispatch(renameFolder(folderId, newName)).then(() => {
      setRenamingFolderId(null);
      setFolders(folders.map(folder =>
        folder.FolderID === folderId ? { ...folder, FolderName: newName } : folder
      ));
      dispatch(fetchStructure())
    });
  };

  const handleRenameClick = (folder: FolderProps) => {
    setRenamingFolderId(folder.FolderID);
    setNewFolderName(folder.FolderName);
    toggleFolderOptions(folder.FolderID);
  };

  const handleRenameSave = (folderId: string) => {
    handleRenameFolder(folderId, newFolderName);
  };


  const toggleFolderOptions = (folderId: string) => {

    if (openOptionsFolderId === folderId) {
      setOpenOptionsFolderId("");
    } else {
      setOpenOptionsFolderId(folderId);
    }
  };

  const handleSubFolderCreate = async (folderID: string) => {
    dispatch(showCreateFolderModal((folderName) => {
      handleCreateSubFolder(folderName, folderID);
      dispatch(hideModal());
    }));

  }


  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-CA', options);
  };

  const doFolderReorder = async (event: CustomEvent) => {
    const { detail } = event;

    const reorderedFolders = [...folders].filter(folder => folder.Status === 'Active');
    reorderedFolders.splice(detail.to, 0, reorderedFolders.splice(detail.from, 1)[0]);

    setFolders(reorderedFolders);

    const updatedOrder = reorderedFolders.map((folder, index) => ({
      FolderID: folder.FolderID,
      newOrder: index
    }));

    localStorage.setItem('folderOrder', JSON.stringify(updatedOrder));

    detail.complete();

    try {
      dispatch(updateFolderOrder(updatedOrder))
    } catch (error) {
      console.error('Error updating folder order:', error);

    }
  };

  useEffect(() => {

    const storedOrder = localStorage.getItem('folderOrder');
    if (storedOrder) {
      const order = JSON.parse(storedOrder);

      const orderedFolders = order.map((id: string) => folders && folders.length && folders.find(folder => folder.FolderID === id)).filter(Boolean);
      if (orderedFolders.length === folders.length) {
        setFolders(orderedFolders);
      } else {

      }
    }
  }, [folders]);


  const doReorderDocuments = async (event: CustomEvent) => {
    event.detail.complete();
    event.stopPropagation();

    const { detail } = event;
    const documents = folders.flatMap(folder => folder.Documents);
    const [movedDocument] = documents.splice(detail.from, 1);
    documents.splice(detail.to, 0, movedDocument);
    const documentsWithNewOrder = documents.map((document: any, index) => ({
      FileID: document.FileID,
      newOrder: index,
    }));

    try {

      dispatch(updateDocumentOrder(documentsWithNewOrder))

    } catch (error) {
      console.error('Error updating document order:', error);
    }
  };



  useEffect(() => {
    const storedOrderDocs = localStorage.getItem('documentsOrder');
    if (storedOrderDocs) {
      const orderDocs = JSON.parse(storedOrderDocs);

      const orderedDocuments = orderDocs.map((orderDoc: { DocumentID: string }) =>
        documents.find((doc: FileProps) => doc.FileID === orderDoc.DocumentID)
      ).filter(Boolean);

      if (orderedDocuments.length === documents.length) {
        // setDocuments(orderedDocuments);
      }
    }
  }, [documents]);


  const DeleteFolder = async (folderId: string) => {
    const confirmDeletion = () => {
      dispatch(hideModal());
      handleDeleteFolder(folderId);
    };

    dispatch(showModal({
      type: 'confirmation',
      message: t('Are you sure you want to delete this folder?'),
      onConfirm: confirmDeletion,
      onCancel: () => dispatch(hideModal()),
      showModal: true
    }));
  };


  const handleFolderClick = (folderId: string) => {
    setOpenFolderIds((prevOpenFolderIds) =>
      prevOpenFolderIds.includes(folderId)
        ? prevOpenFolderIds.filter((id) => id !== folderId)
        : [...prevOpenFolderIds, folderId]
    );
  };

  const openNewFilePanelForFolder = (folderId: string) => {
    setOpenOptionsFolderId('');
    dispatch(showAddFileSidePanel(folders, folderId));
  };

  const openNewFilePanel = () => {
    if (folders && folders.length > 0) {
      dispatch(showAddFileSidePanel(folders));
    } else {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('You need to create a folder before you can create a file'),
          modalType: 'warning',
          duration: 5000,
        })
      );
      console.error('Folders data is unavailable.');
    }
  };
  const openNewFolderModal = () => {
    dispatch(showCreateFolderModal((folderName) => {
      handleCreateFolder(folderName);
      dispatch(hideModal());
    }));
  };


  const handleFileClick = (file: FileProps) => {
    onSelectFile(file);
  };

  const moveToTop = (folderId: string) => {
    const index = folders.findIndex(folder => folder.FolderID === folderId);
    if (index > -1) {
      const newFolders = [...folders];
      const [folderToMove] = newFolders.splice(index, 1);
      newFolders.unshift(folderToMove);
      setFolders(newFolders);
    }
  };

  const moveToBottom = (folderId: string) => {
    const index = folders.findIndex(folder => folder.FolderID === folderId);
    if (index > -1) {
      const newFolders = [...folders];
      const [folderToMove] = newFolders.splice(index, 1);
      newFolders.push(folderToMove);
      setFolders(newFolders);
    }
  };

  const renderFolders = (folders: StructureProps[], level = 0) => {
    return folders.map((folder) => (
      <div key={folder.FolderID} >
        <div
          className={`folder-box ${level > 0 ? 'subfolder-box' : ''}`}
          onClick={() => handleFolderClick(folder.FolderID)}
          style={{ paddingLeft: `${level * 15}px` }}
        >
          <div className="folder-box__first">
            {level === 0 ? (
              <IonReorder>
                <i className="fa-solid fa-bars"></i>
              </IonReorder>
            ) : (
              <div className="subfolder-marker">
                {openFolderIds.includes(folder.FolderID) ? (
                  <i className="fa-solid fa-pipe"></i>
                ) : (
                  <i className="fa-solid fa-minus"></i>
                )}
              </div>
            )}

            <p>{folder.FolderName}</p>
          </div>
        </div>
        {openFolderIds.includes(folder.FolderID) && (
          <div>
            {folder.Documents?.map((doc) => (
              <div key={doc.FileID} className="file-box" onClick={() => handleFileClick(doc)}>
                <div className="file-box__first">
                  <IonReorder className="file_bars">
                    <i className="fa-solid fa-bars"></i>
                  </IonReorder>
                  <p>{doc.FileName}</p>
                </div>
                <div className="file-box__last">
                  <p>{formatDate(doc.Creation)}</p>
                </div>
              </div>
            ))}
            {renderFolders(folder.SubFolders || [], level + 1)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      <div className="files-container">
        <div className="files-container__grid">
          <div className="files-container__header--folders">
            <h2>{t('Folders')}</h2>
            <div className="folder-actions__container">
              <div className="folder-file__buttons">
                <Tooltip title={t('Add new folder')}>
                  <button className="folder-files__btn" onClick={openNewFolderModal}>
                    <i className="fa-solid fa-folder-plus"></i>
                  </button>
                </Tooltip>
                <Tooltip title={t('Add new file')}>
                  <button className="folder-files__btn" onClick={openNewFilePanel}>
                    <i className="fa-solid fa-file-plus"></i>
                  </button>
                </Tooltip>
                <div className='sort-by__container'>
                  <button onClick={() => setIsFilterOpen(!isFilterOpen)} className="filter-button">
                    <span>{t('Filter')}</span>
                    <i className='fa-regular fa-bars-filter'></i>
                  </button>
                  {isFilterOpen && (
                    <div className="filter-container" ref={filterContainerRef}>
                      <label>
                        {t('File Type')}
                        <GlobalDropdown
                          options={[
                            { id: '', label: t('All') },
                            { id: 'Contract', label: t('Contract') },
                            { id: 'Legal Document', label: t('Legal Document') },
                            { id: 'General', label: t('General') },
                            { id: 'Template', label: t('Template') }
                          ]}
                          onSelect={(id) => setFilters(prevFilters => ({ ...prevFilters, FileType: id }))}
                          selectedLabel={filters.FileType ? t(filters.FileType) : t('All')}
                        />
                      </label>
                      <label>
                        {t('Creation Date')}
                        <GlobalDropdown
                          options={[
                            { id: '', label: t('All') },
                            { id: 'last7days', label: t('Last 7 Days') },
                            { id: 'last30days', label: t('Last 30 Days') }
                          ]}
                          onSelect={(id) => setFilters(prevFilters => ({ ...prevFilters, Creation: id }))}
                          selectedLabel={filters.Creation ? t(filters.Creation) : t('All')}
                        />
                      </label>
                      <label>
                        {t('Modified Date')}
                        <GlobalDropdown
                          options={[
                            { id: '', label: t('All') },
                            { id: 'last7days', label: t('Last 7 Days') },
                            { id: 'last30days', label: t('Last 30 Days') }
                          ]}
                          onSelect={(id) => setFilters(prevFilters => ({ ...prevFilters, Modified: id }))}
                          selectedLabel={filters.Modified ? t(filters.Modified) : t('All')}
                        />
                      </label>
                      <label>
                        {t('Expiry')}
                        <GlobalDropdown
                          options={[
                            { id: '', label: t('All') },
                            { id: 'expired', label: t('Expired') },
                            { id: 'expiringSoon', label: t('Expiring Soon') }
                          ]}
                          onSelect={(id) => setFilters(prevFilters => ({ ...prevFilters, Expiry: id }))}
                          selectedLabel={filters.Expiry ? t(filters.Expiry) : t('All')}
                        />
                      </label>
                      <label className="custom-checkbox-container">
                        <span>{t('File Connected to a Case')}</span>
                        <input
                          type="checkbox"
                          name="CaseID"
                          checked={!!filters.CaseID}
                          onChange={handleFilterChange}
                        />
                        <span className="custom-checkmark"></span>
                      </label>
                      <label className="custom-checkbox-container">
                        <span>{t('Assignees')}</span>
                        <input
                          type="checkbox"
                          name="Assignees"
                          checked={!!filters.Assignees}
                          onChange={handleFilterChange}
                        />
                        <span className="custom-checkmark"></span>
                      </label>
                      <label className="custom-checkbox-container">
                        <span>{t('Shared With')}</span>
                        <input
                          type="checkbox"
                          name="SharedWith"
                          checked={!!filters.SharedWith}
                          onChange={handleFilterChange}
                        />
                        <span className="custom-checkmark"></span>
                      </label>
                      <label className="custom-checkbox-container">
                        <span>{t('Approvers')}</span>
                        <input
                          type="checkbox"
                          name="Approvers"
                          checked={!!filters.Approvers}
                          onChange={handleFilterChange}
                        />
                        <span className="custom-checkmark"></span>
                      </label>
                      <label className="custom-checkbox-container">
                        <span>{t('Parties Involved')}</span>
                        <input
                          type="checkbox"
                          name="PartiesInvolved"
                          checked={!!filters.PartiesInvolved}
                          onChange={handleFilterChange}
                        />
                        <span className="custom-checkmark"></span>
                      </label>
                      {/* <button onClick={handleFilterApply} className="apply-filters-button">
                  {t('Apply Filters')}
                </button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="files-container__header">
            <div className="files-container__header--first">
              <p>{t('Name')}</p>
            </div>
          </div> */}
          <div className="files-container__list-wrapper">
            <div className="files-container__list">
              <IonReorderGroup disabled={false} onIonItemReorder={doFolderReorder}>
                {filteredStructure && filteredStructure.length > 0 ? (
                  filteredStructure
                    .filter((folder) => folder.Status === 'Active')
                    .map((folder) => (
                      <div key={folder.FolderID}>
                        <div className="folder-box">
                          <div style={{ width: '100%' }} onClick={() => handleFolderClick(folder.FolderID)}>
                            <div className="folder-box__first">
                              <IonReorder>
                                <i className="fa-solid fa-bars"></i>
                              </IonReorder>
                              {renamingFolderId === folder.FolderID ? (
                                <input
                                  type="text"
                                  value={newFolderName}
                                  onChange={(e) => setNewFolderName(e.target.value)}
                                />
                              ) : (
                                <p>{folder.FolderName}</p>
                              )}
                            </div>
                          </div>
                          <div className="folder-box__last">
                            <p>{folder.Documents.length}</p>
                            {folder.FolderName === 'LegalCaseMapping' || folder.FolderName === 'TermsOfServices' ? (
                              <i title="This folder is locked." className="fa-regular fa-lock"></i>
                            ) : renamingFolderId === folder.FolderID ? (
                              <button onClick={() => handleRenameSave(folder.FolderID)}>
                                <i style={{ color: 'rgb(66, 212, 66)' }} className="fa-solid fa-save"></i>
                              </button>
                            ) : (
                              <button onClick={() => toggleFolderOptions(folder.FolderID)} className="ignore-outside-click">
                                <i className="fa-regular fa-ellipsis-v ignore-outside-click"></i>
                              </button>
                            )}
                          </div>
                          {openOptionsFolderId === folder.FolderID && (
                            <div className="folder-options__container" ref={folderOptionsRef} onClick={(e) => e.stopPropagation()}>
                              <div onClick={() => openNewFilePanelForFolder(folder.FolderID)}>
                                <i className="fa-solid fa-file-plus" style={{ color: '#aaa' }}></i>
                                <p>{t('Create a file')}</p>
                              </div>
                              <div onClick={() => { handleSubFolderCreate(folder.FolderID) }}>
                                <i className="fa-solid fa-folder-plus" style={{ color: '#aaa' }}></i>
                                <p>{t('Create Sub Folder')}</p>
                              </div>
                              <div onClick={() => handleRenameClick(folder)}>
                                <i style={{ color: '#aaa' }} className="fa-solid fa-edit"></i>
                                <p>{t('Rename Folder')}</p>
                              </div>
                              <div onClick={() => DeleteFolder(folder.FolderID)}>
                                <i style={{ color: 'rgb(255, 40, 0)' }} className="fa-solid fa-trash"></i>
                                <p>{t('Delete Folder')}</p>
                              </div>
                              {/* <div onClick={() => { moveToTop(folder.FolderID); toggleFolderOptions(folder.FolderID) }}>
                                <i className="fa-solid fa-arrow-up" style={{ color: '#fff' }}></i>
                                <p>{t('Move to top')}</p>
                              </div>
                              <div onClick={() => { moveToBottom(folder.FolderID); toggleFolderOptions(folder.FolderID) }}>
                                <i className="fa-solid fa-arrow-down" style={{ color: '#aaa' }}></i>
                                <p>{t('Move to bottom')}</p>
                              </div> */}
                            </div>
                          )}
                        </div>
                        {openFolderIds.includes(folder.FolderID) && (
                          <div className={openFolderIds.includes(folder.FolderID) ? 'file-list visible' : 'file-list'}>
                            <IonReorderGroup disabled={false} onIonItemReorder={doReorderDocuments}>
                              {folder.Documents?.filter((doc) => doc.Status === 'Active').map((doc) => (
                                <div key={doc.FileID} className="file-box" onClick={() => handleFileClick(doc)}>
                                  <div className="file-box__first">
                                    <IonReorder className="file_bars">
                                      <i className="fa-solid fa-bars"></i>
                                    </IonReorder>
                                    <p>{doc.FileName}</p>
                                  </div>
                                  <div className="file-box__last">
                                    <p>{doc.FileType}</p>
                                  </div>
                                </div>
                              ))}
                              {renderFolders(folder.SubFolders, 1)}
                            </IonReorderGroup>
                          </div>
                        )}
                      </div>
                    ))
                ) : (
                  <div style={{ height: '65vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff', fontWeight: '500', fontSize: 22 }}>
                    {t('No Folders')}
                  </div>
                )}
              </IonReorderGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Files;
