import React, { useEffect, useState } from 'react';
import './ClientPortal.css';
import SidePanel from './SidePanel';
import AddClientForm from './AddClientForm';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchAllClientCases, fetchAllClients, fetchAllExternalCases } from '../../store/actions/ClientAction';
import { useTranslation } from 'react-i18next';
import ClipLoader from 'react-spinners/ClipLoader';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { showAddClientSidePanel, showClientViewSidePanel } from '../../store/actions/sidePanelActions';

const ClientPortal: React.FC = () => {
  const [selectedClient, setSelectedClient] = useState<any | null>(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false);
  const [selectedCase, setSelectedCase] = useState<any>(null);
  const allClients = useSelector((state: RootState) => state.client.allClients);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const externalCases = useSelector((state: RootState) => state.client.allExternalCases);
  const [isCasesLoading, setIsCasesLoading] = useState(false);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setIsCasesLoading(true);
      setIsClientsLoading(true);
      try {
        await dispatch(fetchAllClients());
        await dispatch(fetchAllExternalCases());
      } finally {
        setIsCasesLoading(false);
        setIsClientsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  // const handleClientClick = (client: any, caseItem?: any) => {
  //   setSelectedClient(client);
  //   setShowSidePanel(true);
  //   setIsAddingClient(false);
  //   setSelectedCase(caseItem || null);
  //   dispatch(fetchAllClientCases(client.UserID));
  // };  

  const handleAddClientClick = () => {
    dispatch(showAddClientSidePanel());
  };
  
  const handleClientClick = (client: any, caseItem?: any) => {
    dispatch(showClientViewSidePanel(client, caseItem));
  };
  

  // const handleAddClientClick = () => {
  //   setSelectedClient(null);
  //   setShowSidePanel(true);
  //   setIsAddingClient(true);
  //   setSelectedCase(null);
  // };

  const closeSidePanel = () => {
    setShowSidePanel(false);
    setSelectedClient(null);
    setIsAddingClient(false);
    setSelectedCase(null);
  };

  const handleCaseClick = (caseItem: any) => {
    const client = allClients.find((c) => c.UserID === caseItem.client_id);
    if (client) {
      handleClientClick(client, caseItem);
    } else {
      console.error('Client not found for case', caseItem);
    }
  };
  

  const handleMarketClick = () => {
    // dispatch(setActiveView('market'));
    history.push('/marketplace/lawyer/market');
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Options for formatting
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long', // Change to 'short' for abbreviated month names
    day: 'numeric',
  };

  return date.toLocaleDateString(undefined, options);
};

  return (
    <div className="client-portal">
      {/* Client Container */}
      <div className={`backdrop ${showSidePanel ? 'show' : ''}`} onClick={closeSidePanel}></div>
      <div className="client-container">
      <div className="client-header">
          <h2>{t('Clients')}</h2>
          <button className="primary-button" onClick={handleAddClientClick}>
            {t('Add Client')}
          </button>
        </div>
        {isClientsLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
            <ClipLoader color="#fff" loading={isClientsLoading} size={30} />
          </div>
        ) : allClients.length > 0 ? (
          <div className="client-list">
            {allClients.map((client: any) => (
              <div
                key={client.UserID}
                className="client-item"
                onClick={() => handleClientClick(client)}
              >
                {client.UserType === 'ExternalClient' ? (
                  <div>
                    <h3>{client.first_name} {client.last_name || ''}</h3>
                    <p>{client.email}</p>
                    {/* <p>{client.phone_number}</p> */}
                    <p>{client.address}</p>
                  </div>
                ) : client.UserType === 'ExternalBusinessClient' ? (
                  <div>
                    <h3>{client.company_name}</h3>
                    <p>{client.contact_email}</p>
                    {/* <p>{client.contact_phone}</p> */}
                    <p>{client.address}</p>
                  </div>
                ) : null}
              <span className='clientportal--typetag'>
              {client.UserType === 'ExternalBusinessClient' ? 'Business Client' : 'Individual Client'}
              </span>
              </div>
            ))}
          </div>
        ) : (
          <p className='non-access'>{t('No clients available')}</p>
        )}
      </div>

      {/* Right Side Column */}
      <div className="right-column">
        {/* Stats Row */}
        <div className="stats-row">
          <div className="stat-card">
            <h3>{t('Total Clients')}</h3>
            <p>{allClients.length}</p>
          </div>
          <div className="stat-card">
            <h3>{t('Total Cases')}</h3>
            <p>{externalCases.length}</p>
          </div>
        </div>

        {/* Marketplace Clients Container */}
        <div className="marketplace-clients-container">
        <div className="client-header">
          <h2>{t('My Cases')}</h2>
          <button className="secondary-button disabled" disabled onClick={() => handleMarketClick()}>
            {t('Browse Market')}
          </button>
          </div>
          <div className="marketplace-client-list">
            {isCasesLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ClipLoader color="#fff" loading={isCasesLoading} size={30} />
              </div>
            ) : (
              externalCases && externalCases.length > 0 ? externalCases.map((item: any, index: number) => (
                <Tooltip key={index} title={item.fields.main_issue || 'No issue provided'}>
                <div key={index} className="marketplace-client-item" 
                  onClick={() => handleCaseClick(item)}
                >
                  <div className='dashboard-lawyer__case-item__row'>
                    <p className="dashboard-lawyer__case-item--casetype">{item.case_type}</p>
                    <p className="caseUrgency">{item.case_status}</p>
                  </div>
                  <div className='dashboard-lawyer__case-item__row'>
                    <p className="casePrice">{formatDate(item.created_at)}</p>
                    {/* <p className="caseCountry">{item.CountryOfJurisdiction}</p> */}
                  </div>
                </div>
                </Tooltip>
              )) : (
                <p className="non-access">{t('No cases')}</p>
              )
            )}
          </div>
        </div>
      </div>

      {/* Side Panel for Client Details or Add Client */}
      {/* <div className={`side-panel ${showSidePanel ? 'open_panel' : ''} ${selectedCase ? 'expanded' : ''}`}>
        <button className="close-btn--sidepanel" onClick={closeSidePanel}>
          <i className="fas fa-chevron-right"></i>
        </button>
        <div style={{ minHeight: 300, overflowY: "auto", padding: 10, scrollbarWidth: 'thin', scrollbarColor: '#64d2ff transparent' }}>
          {isAddingClient ? (
            <AddClientForm onClose={closeSidePanel} />
          ) : (
            <SidePanel client={selectedClient} onClose={closeSidePanel} setSelectedCase={setSelectedCase} selectedCase={selectedCase} />
          )}
        </div>
      </div> */}
    </div>
  );
};

export default ClientPortal;
