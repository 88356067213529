import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {
  completeLawfirmAssignedTask,
  getAllLawfirmAssignedTask,
} from '../../store/actions/LawfirmActions';
import { fetchCaseList } from '../../store/actions/marketplace/caseActions';
import { getUserType } from '../../store/actions/ChatAction';
import {
  getTimeEntriesApi,
  pauseTimerApi,
  resumeTimerApi,
  startTimerApi,
  stopTimerApi,
} from '../../store/actions/TimeManagementActions';
import { TimeEntry } from '../../store/reducer/TimeManagementReducer';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { showModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';

interface TimerContextType {
  time: number;
  isRunning: boolean;
  isPaused: boolean;
  selectedItems: any[]; // Changed from selectedItem to selectedItems array
  activeList: 'tasks' | 'cases';
  tasks: any[];
  cases: any[];
  timeEntries: TimeEntry[];
  startTimer: () => void;
  stopTimer: (description: string, completeTask?: boolean) => void;
  pauseTimer: () => void;
  resumeTimer: () => void;
  setActiveList: (list: 'tasks' | 'cases') => void;
  handleItemClick: (item: any) => void;
  resetState: () => void;
}

const TimerContext = createContext<TimerContextType | undefined>(undefined);

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};

export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]); // Changed to array
  const [activeList, setActiveList] = useState<'tasks' | 'cases'>('tasks');
  const tasks = useSelector((state: RootState) => state.lawfirm.tasks).filter(
    (task: any) => task.Status === 'Active'
  );
  const cases = useSelector((state: RootState) => state.case.caseList);
  const timeEntries = useSelector((state: RootState) => state.time.timeEntries);
  const userType = getUserType();
  const [startTime, setStartTime] = useState<number | null>(null);
  const { t } = useTranslation();

  const saveTimerState = useCallback(() => {
    localStorage.setItem(
      'timerState',
      JSON.stringify({
        startTime,
        time,
        isRunning,
        isPaused,
        selectedItems,
        activeList,
      })
    );
  }, [isPaused, isRunning, selectedItems, startTime, time, activeList]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllLawfirmAssignedTask(userType));
      await dispatch(fetchCaseList());
    };
    if (userType) {
    fetchData();
    }
  }, [dispatch, userType]);

  useEffect(() => {
    const loadSavedState = async () => {
      const savedTimerState = localStorage.getItem('timerState');
      if (savedTimerState) {
        const {
          startTime: savedStartTime,
          isRunning,
          isPaused,
          selectedItems,
          activeList,
        } = JSON.parse(savedTimerState);
        setStartTime(savedStartTime);
        setSelectedItems(selectedItems);
        setIsRunning(isRunning);
        setIsPaused(isPaused);
        // Fetch time entries for all selected items
        for (const item of selectedItems) {
          const case_id = activeList === 'cases' ? item.CaseID : null;
          const task_id = activeList === 'tasks' ? item.TaskID : null;
          await dispatch(getTimeEntriesApi(case_id, task_id));
        }
        if (isRunning && savedStartTime) {
          const elapsedSeconds = Math.floor((Date.now() - savedStartTime) / 1000);
          setTime(elapsedSeconds);
        }
      }
    };
    loadSavedState();
  }, [dispatch]);

  useEffect(() => {
    if (isRunning || isPaused) {
      saveTimerState();
    }
  }, [time, isRunning, isPaused, startTime, selectedItems, saveTimerState]);

  useEffect(() => {
    if (isRunning && !isPaused) {
      const id = setInterval(() => setTime((prevTime) => prevTime + 1), 1000);
      setIntervalId(id);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isRunning, isPaused]);

  // Start the timer
  const startTimer = async () => {
    // Start timer for all selected items
    if (selectedItems.length === 0) return;

    for (const item of selectedItems) {
      const case_id = activeList === 'cases' ? item.CaseID : null;
      const task_id = activeList === 'tasks' ? item.TaskID : null;
      await dispatch(startTimerApi(case_id, task_id));
      await dispatch(getTimeEntriesApi(case_id, task_id));
    }

    setTime(0);
    setIsRunning(true);
    setIsPaused(false);
    setStartTime(Date.now());
  };

  // Stop the timer
  const stopTimer = async (description: string, completeTask?: boolean) => {
    if (selectedItems.length === 0) return;
  
    localStorage.removeItem('timerState');
  
    dispatch(
      showModal({
        type: 'timeStop',
        message: `${t('Do you want to stop the timer?')}`,
        onConfirm: async (data: string | File, completeTask?: boolean) => {
          const description = typeof data === 'string' ? data : '';
          for (const item of selectedItems) {
            const case_id = activeList === 'cases' ? item.CaseID : null;
            const task_id = activeList === 'tasks' ? item.TaskID : null;
  
            await dispatch(stopTimerApi(case_id as string, task_id as string, description));
  
            if (completeTask && task_id) {
              await dispatch(completeLawfirmAssignedTask(userType, task_id));
              await dispatch(getAllLawfirmAssignedTask(userType));
            }
  
            // Fetch updated time entries
            await dispatch(getTimeEntriesApi(case_id, task_id));
          }
          resetState();
        },
        showModal: true,
        taskId: selectedItems.map(item => item.TaskID || item.CaseID), // Pass selected task IDs
      })
    );
  };

  const pauseTimer = async () => {
    // Pause timer for all selected items
    if (selectedItems.length === 0) return;

    for (const item of selectedItems) {
      const case_id = activeList === 'cases' ? item.CaseID : null;
      const task_id = activeList === 'tasks' ? item.TaskID : null;
      await dispatch(pauseTimerApi(case_id as string, task_id as string));
    }
    setIsPaused(true);
    setIsRunning(false);
  };

  const resumeTimer = async () => {
    // Resume timer for all selected items
    if (selectedItems.length === 0) return;

    for (const item of selectedItems) {
      const case_id = activeList === 'cases' ? item.CaseID : null;
      const task_id = activeList === 'tasks' ? item.TaskID : null;
      await dispatch(resumeTimerApi(case_id as string, task_id as string));
    }
    setIsPaused(false);
    setIsRunning(true);
  };

  const resetState = () => {
    setIsRunning(false);
    setIsPaused(false);
    setTime(0);
    setSelectedItems([]);
    localStorage.removeItem('timerState');
  };

  const handleItemClick = async (item: any) => {
    const itemID = item.TaskID || item.CaseID;
    const isSelected = selectedItems.some((selectedItem) => {
      const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
      return selectedItemID === itemID;
    });

    if (isSelected) {
      // Deselect the item
      const newSelectedItems = selectedItems.filter((selectedItem) => {
        const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
        return selectedItemID !== itemID;
      });
      setSelectedItems(newSelectedItems);
    } else {
      // Select the item
      setSelectedItems([...selectedItems, item]);
    }

    // Fetch time entries for the item
    const case_id = activeList === 'cases' ? item.CaseID : null;
    const task_id = activeList === 'tasks' ? item.TaskID : null;

    await dispatch(getTimeEntriesApi(case_id, task_id));
  };

  return (
    <TimerContext.Provider
      value={{
        time,
        isRunning,
        isPaused,
        selectedItems,
        activeList,
        tasks,
        cases,
        timeEntries,
        startTimer,
        stopTimer,
        pauseTimer,
        resumeTimer,
        setActiveList,
        handleItemClick,
        resetState,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
