import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import ToggleButton from './ToggleButton';
import { ClientCase } from '../data/dummyData';
import './MessageCenter.css';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store';
import { Lawyer } from '../interface/IFirmLawyer';
import { getAllLawyers } from '../../../../store/actions/LawfirmActions';
import { getUserID, getUserType } from '../../../../store/actions/ChatAction';
import SocketServices from '../../../../utils/SocketServices';
import { fetchCHatList, fetchMessageList } from '../../../../store/actions/DataAction';
import { useTranslation } from 'react-i18next';

const MessageCenter: React.FC = () => {
  const [selectedChatId, setSelectedChatId] = useState<number | null | string>(null);
  const [isEmployeeView, setIsEmployeeView] = useState(true);
  const [openListView, setOpenListView] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<Lawyer | null>(null)
  const [newChat, setNewChat] = useState(false)
  const dispatch = useAppDispatch();
  const userID = getUserID();
  const date: Date = useMemo(() => new Date(), []);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const chatList = useSelector((state: RootState) => state.lists.chatList)
  const MessageList = useSelector((state: RootState) => state.lists.MessageList)
  const [messages, setMessages] = useState<any[]>(MessageList)
  const selectedChat: any = chatList.find((chat: any) => chat.chatID === selectedChatId);
  const { t } = useTranslation()
  const userType = getUserType()
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const [isChatLoading, setIsChatLoading] = useState(false)
  const [unreadCounts, setUnreadCounts] = useState({});
  const [lastMessages, setLastMessages] = useState({});

  useEffect(() => {
    const storedUnreadCounts = localStorage.getItem('unreadCounts');
    if (storedUnreadCounts) {
      setUnreadCounts(JSON.parse(storedUnreadCounts));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('unreadCounts', JSON.stringify(unreadCounts));
  }, [unreadCounts]);


  useEffect(() => {
    if (userType === 'Lawfirmlawyer') {
      dispatch(getAllLawyers(userInfo.LawfirmID));
    } else {
      dispatch(getAllLawyers(userID));
    }
    dispatch(fetchCHatList())
  }, [dispatch, userID, userType]);

  const recievePersonalMessage = useCallback(async (data: any) => {
    const {
      message,
      img_urls,
      chatId
    } = data;

    setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: "", attachments: img_urls }]);

    if (selectedChatId === chatId) {
      // await dispatch(fetchCHatList())
      SocketServices.emit("reset_unread_count", { "ChatID": chatId, "UserID": userID })
    }

    if (selectedChatId !== chatId) {
      // await dispatch(fetchCHatList())
      console.log(selectedChatId, chatId);

      setUnreadCounts((prevCounts: any[]) => ({
        ...prevCounts,
        [chatId]: (prevCounts[chatId] || 0) + 1
      }));
    }
    setLastMessages(prevLastMessages => ({
      ...prevLastMessages,
      [chatId]: message
    }));

  }, [date, selectedChatId])

  const emitUserJoin = useCallback(() => {
    SocketServices.emit("user_join", { username: userID, caseid: "" });
  }, [userID]);

  const newChatStarted = useCallback((data: any) => {
    const { chat_id } = data
    if (chat_id) {
      dispatch(fetchCHatList())
      setSelectedChatId(chat_id)
    }
  }, [dispatch])

  // const personal_chat_list = useCallback(() => {
  //   SocketServices.emit("personal_chat_list", {})
  // },[])


  const recieve_personal_chat_list = useCallback((data: any) => {
    const { messages } = data
    console.log(messages);

    if (messages) {
      setIsChatLoading(false)
      setMessages(messages)
    }

  }, [])

  useEffect(() => {
    emitUserJoin()
    SocketServices.on('recieve_personal_message', recievePersonalMessage)
    SocketServices.on("new_chat_started", newChatStarted)
    SocketServices.on("personal_chat_list", recieve_personal_chat_list)
    return () => {
      SocketServices.removeListener('recieve_personal_message')
      SocketServices.removeListener("new_chat_started")
      SocketServices.emit("personal_chat_list")
    };
  }, [userID, date, recievePersonalMessage, emitUserJoin, newChatStarted, recieve_personal_chat_list])

  const handleChatSelect = async (id: string) => {
    console.log(id, "chat_id");
    setUnreadCounts((prevCounts: any[]) => ({
      ...prevCounts,
      [id]: 0
    }));

    setIsChatLoading(true)
    setSelectedChatId(id);
    setMessages([])
    if (id) {
      SocketServices.emit("reset_unread_count", { "ChatID": id, "UserID": userID })
      SocketServices.emit("personal_chat_list", { "ChatID": id, "UserID": userID })
      await dispatch(fetchCHatList())
    }

  };

  const readFileAsArrayBuffer = (file: File): Promise<{ fileName: string; fileType: string; fileData: ArrayBuffer | string }> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        if (event.target?.result) {
          resolve({
            fileName: file.name,
            fileType: file.type,
            fileData: event.target.result,
          });
        } else {
          reject(new Error("Failed to read file"));
        }
      };

      fileReader.onerror = () => {
        reject(new Error("Error reading file"));
      };

      fileReader.readAsArrayBuffer(file);
    });
  }


  const handleSendMessage = async (message: string, attachments: any[]) => {
    let newAttachments: any[] = []
    if (attachments.length > 0) {
      newAttachments = await Promise.all(
        attachments.map(file => readFileAsArrayBuffer(file))
      );

    }
    setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: userID, attachments }]);
    SocketServices.emit('send_personal_message', { "ChatID": selectedChatId, SenderID: userID, RecieverID: selectedEmployee?.LawfirmLawyerID ? selectedEmployee?.LawfirmLawyerID : Object.keys(selectedChat).length > 0 ? selectedChat.senderId === userID ? selectedChat.senderId : selectedChat.recieverId : selectedChat.recieverId === userID ? selectedChat.recieverId : selectedChat.senderId, Message: message, GroupID: "", attachments: newAttachments })
    await dispatch(fetchCHatList())
  };



  const handleEmplyeeList = () => {
    setOpenListView(true)
  }

  const handleSelectEmployee = (employee: Lawyer) => {
    console.log(chatList, employee);

    let existing_chat = chatList && chatList.length > 0 && chatList.find((chat: any) => {
      if (chat.userData.Email === employee.Email) {
        return chat;
      }
      return null
    })

    if (existing_chat && Object.keys(existing_chat).length > 0) {
      handleChatSelect(existing_chat.chatID)
      setSelectedEmployee(existing_chat)
      setOpenListView(false)

    } else {
      setSelectedChatId(null)
      setSelectedEmployee(employee)
      setOpenListView(false)
      setNewChat(true)
      setMessages([])
    }

  }

  return (
    <div className="message-center">
      <div className="message-center--sidebar">
        {/* <ToggleButton isEmployeeView={isEmployeeView} setIsEmployeeView={setIsEmployeeView} /> */}
        <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'end', marginBottom: 10 }}>
          <h2 style={{ margin: 0, fontSize: '1rem', fontWeight: '500' }}>{t('Colleagues')}</h2>
          {/* <button className='primary-button' onClick={handleEmplyeeList}>{t('New Chat')}</button> */}
        </div>
        <ChatList chatList={chatList} selectedChat={selectedChatId} onChatSelect={handleChatSelect} unreadMessages={unreadCounts} isLoading={isLoading} setIsLoading={setIsLoading} handleSelectEmployee={handleSelectEmployee} />
      </div>
      {openListView && (
        <div className="emp-center--sidebar">
          <div className='emp-center--sidebar-top'>
            <i onClick={() => setOpenListView(false)} className='fas fa-chevron-left icon-hover-effect-message-center'></i>
          </div>
        </div>
      )

      }
      <div className="message-center--main">
        {selectedChat && selectedChatId ? (
          <ChatWindow
            chatId={selectedChatId}
            messages={messages}
            // chatName={isEmployeeView ? selectedChat && selectedChat.userData.FullName ? selectedChat.userData.FullName : selectedChat.userData.FirstName + " " + selectedChat.userData.LastName : (selectedChat as ClientCase).caseType}
            selectedChat={isEmployeeView ? selectedChat && selectedChat : (selectedChat as ClientCase).caseType}

            onSendMessage={handleSendMessage}
            isLoading={isChatLoading}
          // MessageList={MessageList}
          // setMessages={setMessages}
          />
        ) : newChat ? (
          <ChatWindow
            chatId={""}
            messages={messages}
            // selectedChat={selectedEmployee ? selectedEmployee.FirstName + " " + selectedEmployee.LastName : ""}
            selectedChat={selectedEmployee}

            onSendMessage={handleSendMessage}
            isLoading={isChatLoading}
          // MessageList={[]}
          // setMessages={setMessages}
          />
        ) : (
          <div className="empty-chat-window">
            <p>{t('Select a chat to start messaging')}</p>
          </div>
        )
        }
      </div >
    </div >
  );
};

export default MessageCenter;
