import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import ClientSection from './Client/ClientSection';
import LawyerSection from './Lawyer/LawyerSection';
import { getUserType } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { RootState, useAppDispatch } from '../../store';
import { userInformation } from '../../store/actions/DataAction';
import SocketServices from '../../utils/SocketServices';

type MarketplaceProps = {
    activeView: string
}

const Marketplace: React.FC = () => {
    const userType = getUserType();
    const { path } = useRouteMatch();
    const aiSettings = useSelector((state: RootState) => state.lists.aiSettings)
    const activeView = useSelector((state: RootState) => state.activeView.activeView);
    const { t } = useTranslation();
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');


    // useEffect(() => {
    //     SocketServices.initializeSocket()
    //     // SocketServices.emit("add user", { fullName: user.FirstName + " " + user.LastName, userId: userId, userType: userType })

    // }, [])

    const renderRedirect = () => {
        switch (userType) {
            case 'Client':
            case 'Employee':
            case 'BusinessAdmin':
                return <Redirect to={`${path}/client/cases`} />;
            case 'Lawyer':
            case 'Lawfirmlawyer':
            case 'LawfirmAdmin':
                return <Redirect to={`${path}/lawyer/market`} />;
            default:
                return <Redirect to="/" />;
        }
    };

    return (
        <>
        {/* {aiSettings && aiSettings.CountryOfJurisdiction ? (
            aiSettings.CountryOfJurisdiction !== "Norway" ? (
      <div className='non-access'>Coming to {aiSettings.CountryOfJurisdiction} in Q4 2024</div>
    ) : ( */}
    {!isDemoEnvironment ? (
        <Switch>
            <Route exact path={path} render={renderRedirect} />
            <Route path={`${path}/client`}>
                <ClientSection />
            </Route>
            <Route path={`${path}/lawyer`}>
                <LawyerSection />
            </Route>
        </Switch>
        ) : (
                <div style={{ height: 'calc(100vh - 120px)' }} className='non-access'>{t('Coming in Q4')}</div>
        )}
    {/* )
        )  : (
            <div className='non-access'>You have no Jurisdiction, please go to settings to add one.</div>
            )} */}
        </>
    );
};

export default Marketplace;
