import React, { useState, useEffect } from 'react';
import './ProjectsOverviewWidget.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';
import { format, parse } from 'date-fns';
// import { getUserID } from '../../store/actions/ChatAction';

interface Project {
  ProjectID: string;
  ProjectName: string;
  ProjectDescription: string;
  ProjectLeadEmail: string;
  Deadline: string; 
  Status: 'Ongoing' | 'Completed';
  Priority: 'High' | 'Medium' | 'Low';
  Assignees: string[]; 
}

const ProjectOverviewWidget: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = 'testuser@example.com'; 

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'Ongoing' | 'Completed'>('Ongoing');
  const [projects, setProjects] = useState<Project[]>([]);
  const [recommendedProjects, setRecommendedProjects] = useState<Project[]>([]);

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const dummyProjects: Project[] = [
          {
            ProjectID: '1',
            ProjectName: 'Breach of Contract Case',
            ProjectDescription: 'Handling a breach of contract legal matter.',
            ProjectLeadEmail: 'lead@example.com',
            Deadline: '15-12-2023',
            Status: 'Ongoing',
            Priority: 'High',
            Assignees: [userId, 'colleague@example.com'],
          },
          {
            ProjectID: '2',
            ProjectName: 'Intellectual Property Dispute',
            ProjectDescription: 'Resolving an IP dispute with a competitor.',
            ProjectLeadEmail: 'lead@example.com',
            Deadline: '10-11-2023',
            Status: 'Ongoing',
            Priority: 'Medium',
            Assignees: [userId],
          },
          {
            ProjectID: '3',
            ProjectName: 'Contract Renewal',
            ProjectDescription: 'Renewing contracts with key suppliers.',
            ProjectLeadEmail: 'lead@example.com',
            Deadline: '05-11-2023',
            Status: 'Completed',
            Priority: 'Low',
            Assignees: [userId, 'colleague@example.com'],
          },
          {
            ProjectID: '4',
            ProjectName: 'Contract Renewal',
            ProjectDescription: 'Renewing contracts with key suppliers.',
            ProjectLeadEmail: 'lead@example.com',
            Deadline: '05-11-2023',
            Status: 'Ongoing',
            Priority: 'Low',
            Assignees: [userId, 'colleague@example.com'],
          },
          {
            ProjectID: '5',
            ProjectName: 'Contract',
            ProjectDescription: 'Renewing contracts with key suppliers.',
            ProjectLeadEmail: 'lead@example.com',
            Deadline: '05-11-2023',
            Status: 'Ongoing',
            Priority: 'Low',
            Assignees: [userId, 'colleague@example.com'],
          },
          {
            ProjectID: '6',
            ProjectName: 'Renewal',
            ProjectDescription: 'Renewing contracts with key suppliers.',
            ProjectLeadEmail: 'lead@example.com',
            Deadline: '05-11-2023',
            Status: 'Ongoing',
            Priority: 'Low',
            Assignees: [userId, 'colleague@example.com'],
          },
        ];

        const assignedProjects = dummyProjects.filter((project) =>
          project.Assignees.includes(userId)
        );

        setProjects(assignedProjects);

        const recommendations = assignedProjects
          .filter((project) => project.Status === 'Ongoing')
          .sort((a, b) => {
            const aDeadline = parse(a.Deadline, 'dd-MM-yyyy', new Date());
            const bDeadline = parse(b.Deadline, 'dd-MM-yyyy', new Date());
            return aDeadline.getTime() - bDeadline.getTime();
          })
          .slice(0, 1); 

        setRecommendedProjects(recommendations);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [userId]);

  const handleTabChange = (tab: 'Ongoing' | 'Completed') => {
    setActiveTab(tab);
  };

  const handleProjectClick = (project: Project) => {
    // Dispatch action to show project details modal
    // dispatch(showProjectDetailsModal(project));
  };

  return (
    <div className="project-overview-widget">
      <div className="project-overview-header">
        <h2>{t('My Projects')}</h2>
        <div className="project-overview-tabs">
          <button
            className={activeTab === 'Ongoing' ? 'active' : ''}
            onClick={() => handleTabChange('Ongoing')}
          >
            {t('Ongoing')}
          </button>
          <button
            className={activeTab === 'Completed' ? 'active' : ''}
            onClick={() => handleTabChange('Completed')}
          >
            {t('Completed')}
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="loader-container">
          <ClipLoader color="#fff" loading={isLoading} size={30} />
        </div>
      ) : (
        <div className="project-overview-content">
          {recommendedProjects.length > 0 && activeTab === 'Ongoing' && (
            <div className="recommended-projects">
              <h3>{t('Recommended Projects')}</h3>
              {recommendedProjects.map((project) => (
                <div
                  key={project.ProjectID}
                  className="project-item recommended"
                  onClick={() => handleProjectClick(project)}
                >
                  <div className="project-details">
                    <p className="project-name">{project.ProjectName}</p>
                    <p className="project-deadline">
                                {project.Deadline}
                    </p>
                  </div>
                  <span className={`priority-tag ${project.Priority.toLowerCase()}`}>
                    {t(project.Priority)}
                  </span>
                </div>
              ))}
            </div>
          )}
          <div className="project-list">
            {projects
              .filter((project) => project.Status === activeTab)
              .map((project) => (
                <div
                  key={project.ProjectID}
                  className="project-item"
                  onClick={() => handleProjectClick(project)}
                >
                  <div className="project-details">
                    <p className="project-name">{project.ProjectName}</p>
                    <p className="project-deadline">
                            {project.Deadline}
                    </p>
                  </div>
                  <span className={`priority-tag ${project.Priority.toLowerCase()}`}>
                    {t(project.Priority)}
                  </span>
                </div>
              ))}
            {projects.filter((project) => project.Status === activeTab).length === 0 && (
              <p className="no-projects">{t('No projects found')}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectOverviewWidget;
