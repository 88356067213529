import { useEffect, useState } from 'react';
import './LawFirm.css';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import SuggestedFilesContainer from '../../Documents/SuggestedFilesContainer';
import { FileProps } from '../../../pages/DocumentsPage';
import { RootState, useAppDispatch } from '../../../store';
import { selectDocumentForEditing } from '../../../store/actions/DocumentsAction';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { ICaseItem } from '../../Marketplace/Interfaces/ICaseItem';
import { IAdminCaseItem } from '../../Marketplace/Interfaces/IAdminCaseItem';
import { getUserType, setSelectedModel } from '../../../store/actions/ChatAction';
import { completeLawfirmAssignedTask, deleteLawfirmAssignedTask, getAllLawfirmAssignedTask } from '../../../store/actions/LawfirmActions';
import { hideModal, showAddTaskModal, showSignModal } from '../../../store/actions/modalActions';
import TaskDeadlineTracker from '../../shared/TaskDeadlineTracker';
import { fetchAllClients, fetchAllExternalCases, fetchClient } from '../../../store/actions/ClientAction';
import { Tooltip } from '@mui/material';
import { showClientViewSidePanel } from '../../../store/actions/sidePanelActions';

const LawyerFirm = () => {
    const { t } = useTranslation()
    const CaseList = useSelector((state: RootState) => state.client.allExternalCases);
    const [isCasesLoading, setIsCasesLoading] = useState(false);
    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const dispatch = useAppDispatch()
    const history = useHistory();
    const userType = getUserType();
    const assignedTasks = useSelector((state: RootState) =>
      state.lawfirm.tasks.filter((task: any) => task.Status === 'Active')
    );
    const clients = useSelector((state: RootState) => state.client.allClients);
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');

    useEffect(() => {
        const fetchData = async () => {
            setIsCasesLoading(true);
            setIsTasksLoading(true);
            try {
                await dispatch(getAllLawfirmAssignedTask(userType));
                await dispatch(fetchAllExternalCases());
                await dispatch(fetchAllClients());
            } finally {
                setIsCasesLoading(false);
                setIsTasksLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleMarketClick = () => {
        // dispatch(setActiveView('market'));
        history.push('/marketplace/lawyer/market');
    };

    const handleCaseClick = (caseItem: any) => {
      const clientData = clients.find(client => client.UserID === caseItem.client_id);
      if (clientData) {
        dispatch(showClientViewSidePanel(clientData, caseItem));
      } else {
        // Fallback to fetching client data if not found
        dispatch(fetchClient(caseItem.client_id)).then((clientData) => {
          dispatch(showClientViewSidePanel(clientData, caseItem));
        });
      }
    };

    const handleCompleteTask = async (taskId: string) => {
        try {
          await dispatch(completeLawfirmAssignedTask(userType, taskId));
          // Refresh task list or update UI accordingly
          dispatch(getAllLawfirmAssignedTask(userType));
        } catch (error) {
          console.error('Error completing task:', error);
        }
      };
    

    const handleDocumentClick = (document: FileProps) => {
        dispatch(selectDocumentForEditing(document));
        history.push('/documents');
    };

    const handleDeleteTask = async (taskId: string) => {
        try {
          await dispatch(deleteLawfirmAssignedTask(userType, taskId));
          // Refresh task list or update UI accordingly
          dispatch(getAllLawfirmAssignedTask(userType));
        } catch (error) {
          console.error('Error deleting task:', error);
        }
      };

  const handleAddTask = () => {
    dispatch(showAddTaskModal())
  };

  const handleServiceClick = (selectedModel: string) => {
    dispatch(setSelectedModel(selectedModel));
    localStorage.setItem('selectedModel', selectedModel);
    history.push("/ai-assistant", { selectedModel });
  };
  

  const handleSignContract = () => {
    dispatch(showSignModal((signature) => {
        dispatch(hideModal());
    }));
  };

  const sortedTasks = assignedTasks
  .map((task: any) => {
      const taskDeadline = parse(task.TaskDeadline, 'dd-MM-yyyy HH:mm', new Date());
      const timeDifference = taskDeadline.getTime() - new Date().getTime(); 
      return {
          ...task,
          timeDifference: isNaN(timeDifference) ? Infinity : timeDifference,
          formattedDeadline: !isNaN(taskDeadline.getTime()) ? format(taskDeadline, 'dd-MM-yyyy HH:mm') : 'Invalid date'
      };
  })
  .sort((a: any, b: any) => a.timeDifference - b.timeDifference); 

 const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Options for formatting
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long', // Change to 'short' for abbreviated month names
    day: 'numeric',
  };

  return date.toLocaleDateString(undefined, options);
};

    return (
        <div className="firm-main-container">
            <div className="firm-employee-container">
            <div className="dashboard-lawyer__services">
            <div className="dashboard-lawyer__services--header">
              <h2>{t('My Cases')}</h2>
              {!isDemoEnvironment && (
                <button className='secondary-button disabled' disabled onClick={() => handleMarketClick()}>{t('Browse Market')}</button>
              )}
            </div>
              {!isDemoEnvironment ? (
            <div className="todo-list">
              {isCasesLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <ClipLoader color="#fff" loading={isCasesLoading} size={30} />
                </div>
              ) : (
                CaseList && CaseList.length > 0 ? CaseList.map((item: any, index: number) => (
                  <Tooltip key={index} title={item.fields.main_issue || 'No issue provided'}>
                  <div key={index} className="marketplace-client-item" 
                    onClick={() => handleCaseClick(item)}
                  >
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="dashboard-lawyer__case-item--casetype">{item.case_type}</p>
                      <p className="caseUrgency">{item.case_status}</p>
                    </div>
                    <div className='dashboard-lawyer__case-item__row'>
                      <p className="casePrice">{formatDate(item.created_at)}</p>
                      {/* <p className="caseCountry">{item.CountryOfJurisdiction}</p> */}
                    </div>
                  </div>
                  </Tooltip>
                )) : (
                  <p className="non-access">{t('No cases')}</p>
                )
              )}
            </div>
            ) : (
              <div className='non-access'>{t('Coming Q4')}</div>
            )}
          </div>
          <div className='firm-container' style={{ flex: 0.8, backgroundColor: 'transparent', border: '1px solid #64d2ff' }}>
          <TaskDeadlineTracker />
                </div>
            </div>
            <div className="firm-employee-container">
            <div className='firm-container' style={{ flex: 1 }}>
                    <div className='firm-container-body'>
                    {/* <div className='firm-container-header'>
                            <span>{t('My Activity')}</span>
                        </div> */}

                        {/* <WorkActivityChart height={190} /> */}
                        <div className="dashboard-client__services">
                    <h1>
                        {t('Services')}
                    </h1>
                    <div className="dashboard-client__services--list">
                        <div className="dashboard-client__services--list--row">
                            <button onClick={() => handleServiceClick("Law Research")}>{t('Law Research')}</button>
                            <button onClick={() => handleServiceClick("Case Research")}>{t('Case Research')}</button>
                        </div>
                        <div className="dashboard-client__services--list--row">
                            <Tooltip title='Coming soon'>
                            <button style={{ backgroundColor: '#444', color: '#ddd', cursor: 'not-allowed' }} disabled onClick={() => handleServiceClick("Custom Agent")}>{t('Custom Agent')}</button>
                            </Tooltip>
                            <Tooltip title='Coming soon'>
                            <button style={{ backgroundColor: '#444', color: '#ddd', cursor: 'not-allowed' }} disabled onClick={() => handleServiceClick('Company Agent')}>{t('Company Agent')}</button>
                            </Tooltip>
                        </div>
                        <div className="dashboard-client__services--list--row">
                        <Tooltip title='Coming soon'>
                            <button style={{ backgroundColor: '#444', color: '#ddd', cursor: 'not-allowed' }} disabled onClick={() => handleServiceClick("Contract Analysis")}>{t('Contract Analysis')}</button>
                            </Tooltip>
                            <Tooltip title='Coming soon'>
                            <button style={{ backgroundColor: '#444', color: '#ddd', cursor: 'not-allowed' }} disabled onClick={handleSignContract}>{t('Tax Research')}</button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                        </div>
                </div>

                <div className='firm-container' style={{ flex: 1 }}>
                    <SuggestedFilesContainer style={{ height: '95%', alignSelf: 'flex-end' }} setSelectedFile={handleDocumentClick} />
                </div>
            </div>
        </div>
    )
}


export default LawyerFirm;