import React, { useState, useRef, useEffect } from 'react';
import './PromptBar.css';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material'; 
import ClipLoader from 'react-spinners/ClipLoader';
import useMicrophone from './MicrophoneServiceTest';

interface PromptBarProps {
    onSend: (input: string, file?: File) => void;
    isDocumentVisible: boolean;
    isFolderStructureVisible?: boolean;
    uploadedFile: File | null;
    handleFileDrop: (file: File) => void;
    selectedService: string;
    isLoading?: boolean;
}

const PromptBar: React.FC<PromptBarProps> = ({ onSend, isLoading }) => {
    const [input, setInput] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
    const indexExistsCompany = useSelector((state: RootState) => state.companyAgent.indexExists);
    const indexExistsCustom = useSelector((state: RootState) => state.customAgent.indexExists);
    const indexExists = selectedModel === "Company Agent" ? indexExistsCompany : selectedModel === "Custom Agent" ? indexExistsCustom : false;
    const { transcript, isListening, startListening, stopListening, hasRecognitionSupport } = useMicrophone();
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');

    useEffect(() => {
      if (transcript) {
          setInput(prev => prev + transcript);
      }
  }, [transcript]);
  

    useEffect(() => {
      if (isListening && textareaRef.current) {
          textareaRef.current.focus();
      }
  }, [isListening]);
  

    useEffect(() => {
        adjustTextareaHeight();
    }, [input]);

    const handleSend = () => {
        if (input.trim()) {
            let fileToSend;

            try {
                if (uploadedFile) {
                    fileToSend = uploadedFile ? uploadedFile : undefined;
                }
                onSend(input, fileToSend);
            } catch (error) {
                console.error('Error:', error);
            }

            setInput('');
            setUploadedFile(null);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(e.target.value);
    };

    const handleMicrophoneToggle = () => {
        if (isListening) {
            stopListening();
        } else {
            if (hasRecognitionSupport) {
                startListening();
            } else {
                console.warn("Speech Recognition not supported");
                // Optionally, inform the user via UI
            }
        }
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current && wrapperRef.current) {
            textareaRef.current.style.height = '5px';
            const maxHeight = 24 * 8; // 8 lines * line height (assuming 24px per line)
            if (textareaRef.current.scrollHeight > maxHeight) {
                textareaRef.current.style.height = `${maxHeight}px`;
                textareaRef.current.style.overflowY = 'scroll';
                wrapperRef.current.classList.add('textarea-max-height');
                textareaRef.current.classList.add('textarea-max-height--area');
            } else {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
                textareaRef.current.style.overflowY = 'hidden';
                wrapperRef.current.classList.remove('textarea-max-height');
                textareaRef.current.classList.remove('textarea-max-height--area');
            }
        }
    };

    const removeUploadedFile = () => {
        setUploadedFile(null);
    };

    return (
        <>
            {uploadedFile && (
                <div className="file-preview__prompt">
                    <span>{uploadedFile.name}</span>
                    <span onClick={removeUploadedFile}><i className='bi bi-x-square'></i></span>
                </div>
            )}
            <div className={`prompt-bar-container ${isLoading ? 'disabled' : ''}`} style={{ alignItems: isListening ? 'center' : 'flex-end'}}>
                <Tooltip title={!isDemoEnvironment ? (isListening ? t('Stop Recording') : t('Start Recording')) : t('Coming soon')} arrow>
                    <button
                        className={`microphone-button ${isListening ? 'listening' : ''}`}
                        onClick={handleMicrophoneToggle}
                        disabled={isLoading || (!indexExists && (selectedModel === "Company Agent" || selectedModel === "Custom Agent")) || !hasRecognitionSupport || isDemoEnvironment}
                    >
                        {/* Animated Mic Icon */}
                        <div className={`microphone-icon ${isListening ? 'active' : ''}`}>
                            {isListening ? (
                                // <svg
                                //     xmlns="http://www.w3.org/2000/svg"
                                //     width="24"
                                //     height="24"
                                //     viewBox="0 0 24 24"
                                //     fill="none"
                                //     stroke="#64d2ff"
                                //     strokeWidth="2"
                                //     strokeLinecap="round"
                                //     strokeLinejoin="round"
                                //     className="feather feather-mic"
                                // >
                                //     <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                //     <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                //     <line x1="12" y1="19" x2="12" y2="23"></line>
                                //     <line x1="8" y1="23" x2="16" y2="23"></line>
                                // </svg>
                                <div className="waveform">
                                <div className="wave bar1"></div>
                                <div className="wave bar2"></div>
                                <div className="wave bar3"></div>
                                <div className="wave bar4"></div>
                                <div className="wave bar5"></div>
                            </div>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#64d2ff"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-mic"
                                >
                                    <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                    <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                    <line x1="12" y1="19" x2="12" y2="23"></line>
                                    <line x1="8" y1="23" x2="16" y2="23"></line>
                                </svg>
                            )}
                        </div>
                    </button>
                </Tooltip>
                <div ref={wrapperRef} className={`textarea-wrapper ${(!indexExists && (selectedModel === "Company Agent" || selectedModel === "Custom Agent")) ? 'disabled' : ''} ${isLoading ? 'disabled' : ''}`}>
                    <textarea
                        ref={textareaRef}
                        style={{ overflow: 'hidden' }}
                        placeholder={`${t('Message PONS')}`}
                        value={input}
                        onKeyPress={handleKeyPress}
                        onChange={handleInputChange}
                        onInput={adjustTextareaHeight}
                        disabled={isLoading || (!indexExists && (selectedModel === "Company Agent" || selectedModel === "Custom Agent"))}
                    />
                      {/* {isListening && (
                    <div className="waveform">
                        <div className="wave bar1"></div>
                        <div className="wave bar2"></div>
                        <div className="wave bar3"></div>
                        <div className="wave bar4"></div>
                        <div className="wave bar5"></div>
                    </div>
                )} */}
                    <button
                        className={`send-button embedded`}
                        onClick={handleSend}
                        disabled={isLoading || (!indexExists && (selectedModel === "Company Agent" || selectedModel === "Custom Agent")) || input.trim().length === 0}
                    >
                        <i className={`fa-solid fa-paper-plane-top ${isLoading ? 'disabled' : ''} ${(!indexExists && (selectedModel === "Company Agent" || selectedModel === "Custom Agent")) ? 'disabled' : ''}`}></i>
                    </button>
                </div>
            </div>
        </>
    );
};

export default PromptBar;
