import { useEffect } from "react";
import { RootState, useAppDispatch } from "../../../../store";
import { getUserID, getUserType } from "../../../../store/actions/ChatAction";
import { getCompanySettings } from "../../../../store/actions/CompanyAction";
import { useSelector } from "react-redux";
import './AdminLawFirm.css'
import { getAllLawyers, getLegalSubjects, getLegalTeams } from "../../../../store/actions/LawfirmActions";
import { Lawyer } from "../../../Dashboard/AdminFirm/interface/IFirmLawyer";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { setDetailsContent } from "../../../../store/actions/marketplace/activeViewActions";

interface AdminLawFirmProps {
    selectedEmployee?: Lawyer | null;
    handleSelectEmployee: (employee: Lawyer) => void;
}

const AdminLawFirm: React.FC<AdminLawFirmProps> = ({ selectedEmployee, handleSelectEmployee }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const userType = getUserType();
    const userId = getUserID();
    const companySettings = useSelector((state: RootState) => state.company.settings);
    const { loading, lawyers } = useSelector((state: RootState) => state.lawfirm);

    useEffect(() => {
        dispatch(getCompanySettings(userId, userType));
    }, [dispatch, userType]);

    useEffect(() => {
        if (userType) {
            dispatch(getAllLawyers(userId));
            dispatch(getLegalSubjects());
            dispatch(getLegalTeams(userId));
        }
    }, [dispatch, userId, userType]);

    const renderEmployee = (employee: Lawyer, index: number) => (
        <div key={employee.LawfirmLawyerID} className="admin-marketplace--employee" onClick={() => handleSelectEmployee(employee)}>
            <div className="admin-marketplace--employee--header">
                {employee.ProfilePicture === "" ? (

                    <img src={employee.ProfilePicture!} alt="Profile" className="admin-marketplace--employee--img" />
                ) : (
                    <img src={`${employee.ProfilePicture && employee.ProfilePicture}  `} alt="Profile" className="admin-marketplace--employee--img" />
                )}
                <div className="admin-marketplace--employee--column">
                    <span className="admin-marketplace--employee--name">{`${employee.FirstName} ${employee.LastName}`}</span>
                    <span className="admin-marketplace--employee--expertise">{(employee.PracticeArea !== " ") ? employee.PracticeArea : 'None'}</span>
                </div>
            </div>
            <div className="admin-marketplace--employee--column">
                <span className="admin-marketplace--employee--ratings">{(employee.Ratings !== null) ? employee.Ratings : '0'}</span>
                <span className="admin-marketplace--employee--cases">
                    {(employee.CaseSolved !== null) ? employee.CaseSolved : '0'}</span>
            </div>
        </div>
    );

    return (
        <div className="marketplace-lawfirm--section">
            <div className="marketplace-lawfirm--section-top">
                <div className="marketplace-lawfirm--row">
                    <div className="marketplace-lawfirm--top-left">
                        <p className="marketplace-lawfirm--name">{companySettings.CompanyName}</p>
                        <a href={`${companySettings.Website}`}>{companySettings.Website}</a>
                        <p className="marketplace-lawfirm--description">{companySettings.Description}</p>
                    </div>
                    <img
                        src={companySettings?.Logo || 'https://via.placeholder.com/150'}
                        alt="Company"
                        className="marketplace-lawfirm--top-img"
                    />
                </div>
            </div>

            <div className="marketplace-lawfirm--section-bottom">
                <div className="team-container-header">
                    <span>{t('Employees')}</span>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
                    </div>
                </div>
                <div className="marketplace-lawfirm--employee-list">

                    {/* <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('Role')}</th>
                                    <th>{t('Practice Area')}</th>
                                    <th>{t('Email')}</th>
                                </tr>
                            </thead> */}
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <ClipLoader size={20} color='#fff' />
                        </div>
                    ) : lawyers.length > 0 ? (
                        lawyers.map(renderEmployee)
                    ) : (
                        <tr>
                            <td colSpan={4} className="no-teams--placeholder">{t('No employees')}</td>
                        </tr>
                    )}
                </div>
            </div>
        </div>
    )

}

export default AdminLawFirm;