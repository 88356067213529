import apis from "../../../utils/apis";
import axios, { AxiosError } from "axios";
import { ErrorResponse } from "../SignInAction";
import { AppThunk, FETCH_ACTIVE_TODO_LIST, FETCH_TASK_DETAILS, FETCH_TODO_LIST } from "../../types";
import { showFeedbackModal } from "../UserFeedbackActions";
import { lawfirmAssignTask } from "../LawfirmActions";



interface TaskResponse {
    TaskID: string;
  }

export const CreateTask = (dataValues: { UserType: string, TaskTitle: string, TaskDescription: string, TaskDeadline: string, CaseID?: string, Assignees?: string[] }): AppThunk => async (dispatch) => {

    try {
        let response = await apis({
            method: 'POST',
            url: "market/createTask",
            data: dataValues
        })
        if (response && response.status === 200) {
            dispatch(showFeedbackModal({
              showModal: true,
              message: 'Task created',
              modalType: 'success',
              duration: 3000
            }));
      
            const taskData = response.data as TaskResponse;
      
            if (dataValues.UserType === 'LawfirmAdmin' && taskData.TaskID) {
              await dispatch(lawfirmAssignTask({
                ...dataValues,
                TaskID: taskData.TaskID
              }));
            }
      
            return taskData;
          } else {
            if (axios.isAxiosError(response)) {
              const axiosError = response as AxiosError;
              if (axiosError.response && axiosError.response.data) {
                let errorMessage = axiosError.response.data as ErrorResponse;
                dispatch(showFeedbackModal({
                  showModal: true,
                  message: (errorMessage.message || 'An unknown error occurred'),
                  modalType: 'error',
                  duration: 3000
                }));
              } else {
                dispatch(showFeedbackModal({
                  showModal: true,
                  message: 'Network error, please try again',
                  modalType: 'error',
                  duration: 3000
                }));
              }
            }
            return;
          }
        } catch (err) {
          console.error(err);
          return;
        }
      }

export const EditTask = (dataValues: { UserID: string, UserType: string, TaskTitle: string, TaskDescription: string, TaskDeadline: string, TaskID: string }): AppThunk => async (dispatch) => {

    try {
        let response = await apis({
            method: 'POST',
            url: "market/editTask",
            data: dataValues
        })

        if (response && response.status === 200) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: 'Task modified',
                modalType: 'success',
                duration: 3000
              }));
            return response
        } else {
            if (axios.isAxiosError(response)) {
                const axiosError = response as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    let erroMessage = axiosError.response.data as ErrorResponse
                    dispatch(showFeedbackModal({
                        showModal: true,
                        message: (erroMessage.message || 'An unknown error occurred'),
                        modalType: 'error',
                        duration: 3000
                      }));
                } else {
                    dispatch(showFeedbackModal({
                        showModal: true,
                        message: 'Network error, please try again',
                        modalType: 'error',
                        duration: 3000
                      }));
                }
            }
        }
    } catch (err) {
        console.error(err);

    }
}


export const ActiveTasks = (UserID: string, UserType: string): AppThunk => async (dispatch) => {
    try {
        let response = await apis({
            method: 'POST',
            url: "market/getActiveTasks",
            data: {
                UserID,
                UserType
            }
        })

        if (response && response.status === 200) {
            if (response.data.Tasks && response.data.Tasks.length > 0) {
                await dispatch({ type: FETCH_ACTIVE_TODO_LIST, payload: response.data.Tasks })
            } else {
                await dispatch({ type: FETCH_ACTIVE_TODO_LIST, payload: [] })
            }
            dispatch(showFeedbackModal({
                modalType:'success',
                message: (response.data.message),
                showModal: true,
                duration: 3000
            }))
            return
        } else {
            if (axios.isAxiosError(response)) {
                const axiosError = response as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    let erroMessage = axiosError.response.data as ErrorResponse
                    dispatch(showFeedbackModal({
                        modalType:'error',
                        message: (erroMessage.message || 'An unknown error occurred'),
                        showModal: true,
                        duration: 3000
                    }))
                } else {
                    dispatch(showFeedbackModal({
                        modalType:'error',
                        message: ('Network error, please try again'),
                        showModal: true,
                        duration: 3000
                    }))
                }
            }
        }
    } catch (err) {
        console.error(err);

    }
    // }
}

export const GetAllTasks = (UserID: string, UserType: string): AppThunk => async (dispatch) => {
    try {
        let response = await apis({
            method: 'POST',
            url: "market/getAllTask",
            data: {
                UserID,
                UserType
            }
        })

        if (response && response.status === 200) {
            if (response.data.Tasks && response.data.Tasks.length > 0) {
                await dispatch({ type: FETCH_TODO_LIST, payload: response.data.Tasks })
            } else {
                await dispatch({ type: FETCH_TODO_LIST, payload: [] })
            }
            dispatch(showFeedbackModal({
                modalType:'success',
                message: (response.data.message),
                showModal: true,
                duration: 3000
            }))
            return
        } else {
            if (axios.isAxiosError(response)) {
                const axiosError = response as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    let erroMessage = axiosError.response.data as ErrorResponse
                    dispatch(showFeedbackModal({
                        modalType:'error',
                        message: (erroMessage.message || 'An unknown error occurred'),
                        showModal: true,
                        duration: 3000
                    }))
                } else {
                    dispatch(showFeedbackModal({
                        modalType:'error',
                        message: ('Network error, please try again'),
                        showModal: true,
                        duration: 3000
                    }))
                }
            }
        }
    } catch (err) {
        console.error(err);

    }
    // }
}

export const GetTasksDetail = (UserID: string, UserType: string, TaskID: string): AppThunk => async (dispatch) => {
    try {
        let response = await apis({
            method: 'POST',
            url: "market/getTask",
            data: {
                UserID,
                UserType,
                TaskID
            }
        })

        if (response && response.status === 200) {
            await dispatch({ type: FETCH_TASK_DETAILS, payload: response.data })
            dispatch(showFeedbackModal({
                modalType:'success',
                message: (response.data.message),
                showModal: true,
                duration: 3000
            }))
            return
        } else {
            if (axios.isAxiosError(response)) {
                const axiosError = response as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    let erroMessage = axiosError.response.data as ErrorResponse
                    dispatch(showFeedbackModal({
                        modalType:'error',
                        message: (erroMessage.message || 'An unknown error occurred'),
                        showModal: true,
                        duration: 3000
                    }))
                } else {
                    dispatch(showFeedbackModal({
                        modalType:'error',
                        message: ('Network error, please try again'),
                        showModal: true,
                        duration: 3000
                    }))
                }
            }
        }
    } catch (err) {
        console.error(err);

    }
    // }
}



export const CompleteTask = (UserType: string, UserID: string, TaskID: string): AppThunk => async (dispatch) => {

    try {
        let response = await apis({
            method: 'POST',
            url: "market/completeTask",
            data: {
                UserType, UserID, TaskID
            }
        })

        if (response && response.status === 200) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: 'Task completed',
                modalType: 'success',
                duration: 3000
              }));
            return response
        } else {
            if (axios.isAxiosError(response)) {
                const axiosError = response as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    let erroMessage = axiosError.response.data as ErrorResponse
                    dispatch(showFeedbackModal({
                        showModal: true,
                        message: (erroMessage.message || 'An unknown error occurred'),
                        modalType: 'error',
                        duration: 3000
                      }));
                } else {
                    dispatch(showFeedbackModal({
                        showModal: true,
                        message: ('Network error, please try again'),
                        modalType: 'error',
                        duration: 3000
                      }));
                }
            }
        }
    } catch (err) {
        console.error(err);

    }
}


export const DeleteTask = (UserType: string, UserID: string, TaskID: string): AppThunk => async (dispatch) => {

    try {
        let response = await apis({
            method: 'POST',
            url: "market/deleteTask",
            data: {
                UserType, UserID, TaskID
            }
        })

        if (response && response.status === 200) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: 'Task deleted',
                modalType: 'success',
                duration: 3000
              }));
            return response
        } else {
            if (axios.isAxiosError(response)) {
                const axiosError = response as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    let erroMessage = axiosError.response.data as ErrorResponse
                    dispatch(showFeedbackModal({
                        showModal: true,
                        message: (erroMessage.message || 'An unknown error occurred'),
                        modalType: 'error',
                        duration: 3000
                      }));
                } else {
                    dispatch(showFeedbackModal({
                        showModal: true,
                        message: ('Network error, please try again'),
                        modalType: 'error',
                        duration: 3000
                      }));
                }
            }
        }
    } catch (err) {
        console.error(err);

    }
}

