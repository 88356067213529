import React, { useEffect, useState } from 'react';
import './ChatBot.css';
import { useSelector } from 'react-redux';
import {
  AIModel,
  getAiModels,
  getUserType,
  setChatGroupIDStore,
  setSelectedModel,
} from '../../store/actions/ChatAction';
import { RootState, useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';

const DropdownMenu = () => {
  const [menuOptions, setMenuOptions] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(selectedModel);
  const { t } = useTranslation();
  const userType = getUserType();
  const userServiceMapping: { [key: string]: string[] } = {
    shared: ['Law Research'],
  
    lawyer: ['Case Research',],
    lawfirmlawyer: ['Case Research'],
    lawfirmAdmin: ['Case Research', 'Company Agent'],
    client: ['Legal Case Mapping (Clients)'],
    employee: ['Legal Case Mapping (Business)', 'Custom Agent'],
    businessAdmin: ['Legal Case Mapping (Business)', 'Custom Agent'],
  };
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleMenuClick = (service: string) => {
    dispatch(setSelectedModel(service));
    dispatch(setChatGroupIDStore(''));
    localStorage.setItem('selectedModel', service);
    // localStorage.setItem('chatGroupID', '');
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await dispatch(getAiModels());
        if (response) {
          const allServices = response.map((model: AIModel) => model.model_display_name);

          // Get shared services
          let services = [...userServiceMapping.shared];

          // Get additional services based on user type
          switch (userType) {
            case 'Lawyer':
              services = services.concat(userServiceMapping.lawyer);
              break;
            case 'Lawfirmlawyer':
              services = services.concat(userServiceMapping.lawfirmlawyer);
              break;
            case 'LawfirmAdmin':
              services = services.concat(userServiceMapping.lawfirmAdmin);
              break;
            case 'Client':
              services = services.concat(userServiceMapping.client);
              break;
            case 'Employee':
              services = services.concat(userServiceMapping.employee);
              break;
            case 'BusinessAdmin':
              services = services.concat(userServiceMapping.businessAdmin);
              break;
            default:
              break;
          }

          // Filter the services to include only those available from the API response
          services = services.filter(service => allServices.includes(service));

          setMenuOptions(services);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [userType, dispatch]);

  useEffect(() => {
    setSelectedService(selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    const storedModel = localStorage.getItem('selectedModel');
    if (storedModel) {
      dispatch(setSelectedModel(storedModel));
    }
  }, [dispatch]);
  
  // useEffect(() => {
  //   if (!menuOptions.includes(selectedModel)) {
  //     const defaultService = menuOptions[0] || 'Law Research';
  //     dispatch(setSelectedModel(defaultService));
  //     localStorage.setItem('selectedModel', defaultService);
  //   }
  // }, [menuOptions, dispatch]);

  const getTooltipText = (option: string) => {
    const tooltipMapping: { [key: string]: string } = {
      'Law Research': t('Provides legal advising services on various matters.'),
      'Legal Case Mapping (Clients)': t('Helps in mapping out legal cases for better understanding for clients.'),
      'Legal Case Mapping (Business)': t('Helps in mapping out legal cases for business and employees.'),
      'Cross-Jurisdictional Assistance': t('Assists with legal issues across different jurisdictions.'),
      'Contract Generation': t('Automates the process of contract generation.'),
      'Contract Analysis': t('Offers advanced contract analysis and provides insight into legal contracts.'),
      'Case Research': t('Offers Case Research.'),
      'Company Agent': t('Acts as a digital agent for corporate legal matters, utilizing AI for comprehensive assistance.'),
      'Custom Agent': t('Provides customizable AI agent capabilities tailored for specific legal needs.'),
    };

    return tooltipMapping[option] || '';
  };

  return (
    <div className="dropdown-container__chat">
      <button onClick={toggleDropdown} className={`dropdown-btn__chat ${isDropdownOpen ? 'selected' : ''}`}>
        <h2>{t(`${selectedModel}`)}</h2> 
        <i style={{ fontSize: 16 }} className={`fa ${isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} ></i>
      </button>
      {isDropdownOpen && (
        <div className="dropdown-menu__chat">
          {menuOptions.map((option, index) => {
            const tooltipText = getTooltipText(option);
            return (
              <div
                key={index}
                className={`dropdown-item__chat ${selectedService === option ? 'selected' : ''}`}
                onClick={() => handleMenuClick(option)}
              >
                <p title={tooltipText}>{t(option)}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
