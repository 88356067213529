import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import './Suggested.css';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { FileProps } from '../../pages/DocumentsPage';
import { useTranslation } from 'react-i18next';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { fetchRecommendedDocuments } from '../../store/actions/DocumentsAction';
import { fetchFullCase } from '../../store/actions/marketplace/caseActions';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
interface FileComponentProps {
  file: FileProps;
  caseInfo: string | null;
  onFileClick: (file: FileProps) => void;
  style?: CSSProperties;
  t: any;
}


const FileComponent: React.FC<FileComponentProps> = ({
  file, caseInfo, onFileClick, t, style = {}
}) => {
  const fileTypeIcon = getFileTypeIcon(file.FileType);
  dayjs(file.Expiry, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')
  const renderEmailCircles = (emails: string[] | string | undefined) => {
      if (!emails || emails.length === 0) return <span>{t('None')}</span>;
      const emailArray = typeof emails === 'string' ? emails.split(',').map(email => email.trim()) : emails;
      return emailArray.map((email, index) => (
        <Tooltip title={email} key={index} content={email}>
          <div className="email-circle">
            {email[0]?.toUpperCase() || "?"}
          </div>
        </Tooltip>
      ));
    };
    
  return (
    <div style={style} className="file" onClick={() => onFileClick(file)}>
      <div className="file-info">
        <div className='file-info--row'>
          <div className="file-title">
            <h2>{file.FileName}</h2>
            {/* <p className='file-info__type'>{t(file.FileType)}</p> */}
          </div>
          {fileTypeIcon}
        </div>
        {(file.FileType === 'General' || file.FileType === 'Legal_Document') && (
        <p className='file-info__modified'>
        <span>{t('Date Created')}</span> <span className='file-info--value'>{new Intl.DateTimeFormat('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
              }).format(new Date(file.Creation)) || t('N/A')}</span>
      </p>
        )}
        <p className='file-info__modified'>
          <span>{t('Last Modified')}</span> <span className='file-info--value'>{new Intl.DateTimeFormat('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
              }).format(new Date(file.Modified)) || t('N/A')}</span>
        </p>
        <p className='file-info__expiry'>
          <span>{t('Expiry Date')}</span>  <span className='file-info--value'>
    {file.Expiry ? (
      dayjs(file.Expiry, 'YYYY-MM-DD HH:mm:ss').isValid() ? (
        dayjs(file.Expiry, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm')
      ) : (
        t('Invalid date')
      )
    ) : (
      t('No expiry')
    )}
  </span>
</p>
        {/* {caseInfo && (
          <div className='file-info__case'>
            <span>{t('Case Type')}</span> <span>{caseInfo}</span>
          </div>
        )} */}
        {(file.FileType === 'General' || file.FileType === 'Legal_Document') && (
          <>
            <div className='file-info__assignees'>
              <span>{t('Shared with')}</span> <span>{renderEmailCircles(file.SharedWith)}</span>
            </div>
            </>
      )}
        {file.FileType === 'Contract' && (
          <>
            <div className='file-info__assignees'>
              <span>{t('Assignees')}</span> <span>{renderEmailCircles(file.Assignees)}</span>
            </div>
            <div className='file-info__approvers'>
              <span>{t('Approvers')}</span> <span>{renderEmailCircles(file.Approvers)}</span>
            </div>
            <div className='file-info__parties'>
              <span>{t('Parties Involved')}</span> <span>{renderEmailCircles(file.PartiesInvolved)}</span>
            </div>
          </>
        )}
        {file.FileType === 'Legal_Document' && (
          <div className='file-info__legal'>
            <span>{t('Legal Type')}</span> <span className='file-info--value'>{file.LegalType || t('N/A')}</span>
          </div>
        )}
      </div>
    </div>
  );
};


const getFileTypeIcon = (fileType: string): JSX.Element => {
  switch (fileType) {
    case 'Legal_Document':
      return <i className='fa-solid fa-gavel'></i>;
    case 'Contract':
      return <i className='fa-solid fa-file-contract'></i>;
    case 'General':
    case 'Template':
      return <i className='fa-solid fa-file-lines'></i>;
    default:
      return <i className='fa-solid fa-file'></i>;
  }
};

interface SuggestedFilesContainerProps {
  setSelectedFile: (file: FileProps) => void;
  style?: CSSProperties;
}

const SuggestedFilesContainer: React.FC<SuggestedFilesContainerProps> = ({
  setSelectedFile, style = {}
}) => {
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();
  const [documents, setDocuments] = useState<FileProps[]>([]);
  const [caseDetails, setCaseDetails] = useState<{ [key: string]: any }>({});
  const [isSortByOpen, setIsSortByOpen] = useState(false);
  const { t } = useTranslation();
  const fileContainerRef = useRef<HTMLDivElement>(null);
  const recommendedDocuments = useSelector((state: RootState) => state.documents.recommendedDocuments);

  useEffect(() => {
    dispatch(fetchRecommendedDocuments());
  }, [dispatch, userId, userType]);

  useEffect(() => {
    setDocuments(recommendedDocuments);
    recommendedDocuments.forEach((doc: FileProps) => {
      if (doc.CaseID) {
        dispatch(fetchFullCase(doc.CaseID)).then(data => {
          setCaseDetails(prevDetails => ({ ...prevDetails, [doc.CaseID!]: data }));
        });
      }
    });
  }, [recommendedDocuments, dispatch]);

  const handleFileClick = (file: FileProps) => {
    setSelectedFile(file);
  };

  const toggleDropdown = () => {
    setIsSortByOpen(!isSortByOpen);
  };

  return (
    <div className="file-container">
      <div className='file-container__header'>
        <h2>{t('Recommended')}</h2>
        {/* <div className='sort-by__container'>
          <button onClick={toggleDropdown} className='sort-by__btn'>
            <p>{t('Filter')}</p><i className='fa-regular fa-bars-filter'></i>
          </button>
          {isSortByOpen && (
            <div className='sort-by__doc--content'>
              <li>{t('Recently Modified')}</li>
              <li>{t("Awaiting Signature")}</li>
            </div>
          )}
        </div> */}
      </div>

      <div className="files" ref={fileContainerRef}>
        {documents.length > 0 ? (
          documents
            .filter((doc: FileProps) => doc.Status === 'Active').slice(0, 10)
            .map((doc: FileProps) => (
              <FileComponent
                key={doc.FileID}
                file={doc}
                caseInfo={doc.CaseID ? `${caseDetails[doc.CaseID]?.CaseType || 'Loading...'}` : null}
                onFileClick={handleFileClick}
                t={t}
                style={style}
              />
            ))
        ) : (
          <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }} className="non-access">{t('No active documents available')}</div>
        )}
      </div>
    </div>
  );
};

export default SuggestedFilesContainer;
