import React from 'react';
import './AddTaskPanel.css';
import AddTask from '../../../Marketplace/Lawyer/AddTaskComponent';

interface AddTaskPanelProps {
  selectedEmployeeEmail: string;
  onCancel: () => void;
  onAdd: () => void;
}

const AddTaskPanel: React.FC<AddTaskPanelProps> = React.memo(({ selectedEmployeeEmail, onCancel, onAdd }) => {
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onCancel();
    }
  };

  return (
    <>
      <div className="add-task-backdrop" onClick={handleBackdropClick}></div>
      <div className="add-task-panel open">
        <button className="close-add-task-panel-btn" onClick={onCancel} aria-label="Close Add Task Panel">
          <i className='fas fa-chevron-right'></i>
        </button>
        <AddTask selectedEmployeeEmail={selectedEmployeeEmail} onCancel={onCancel} onAdd={onAdd} style={{ height: '90%' }} />
      </div>
    </>
  );
});

export default AddTaskPanel;
