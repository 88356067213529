import React, { useEffect, useState } from 'react';
import './ManageTeam.css';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../store';
import { getUserID, getUserType } from '../../../../store/actions/ChatAction';
import { createTeam, getLegalTeams } from '../../../../store/actions/LawfirmActions';
import AddTeam from './AddTeam';
import ManageTeamDetails from './ManageTeamDetails';
import { useSelector } from 'react-redux';
import { collapseSidePanel, expandSidePanel } from '../../../../store/actions/sidePanelActions';
import { Avatar } from '@mui/material';

export interface Member {
  MemberID: string;
  MemberFirstName: string;
  MemberLastName: string;
  MemberRole: string;
  MemberType: string;
}

export interface Team {
  LegalTeamID: string;
  TeamName: string;
  TeamLeadID: string;
  TeamLeadFirstName: string;
  TeamLeadLastName: string;
  TeamLeadType: string;
  Members: Member[];
  LegalSubjects: { LegalSubjectID: string; LegalSubjectName: string }[];
  Cases: string[];
  CreationDate: string;
  LawfirmID: string;
}

interface ManageTeamProps {
  allMembers: Member[];
  subjects: any[];
}

const ManageTeam: React.FC<ManageTeamProps> = ({ allMembers, subjects }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userID = getUserID();
  const teams = useSelector((state: RootState) => state.lawfirm.teams);
  const [activePanel, setActivePanel] = useState<'addTeam' | 'viewTeam' | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const userType = getUserType();

  useEffect(() => {
    if (userType) {
      dispatch(getLegalTeams(userID));
    }
  }, [dispatch, userID]);

  // const handleSelectTeam = (team: Team) => {

  //   setSelectedTeam({
  //     ...team,
  //     Members: team.Members || [],
  //     LegalSubjects: team.LegalSubjects || [],
  //     Cases: team.Cases || [],
  //   });
  //   setActivePanel('viewTeam'); // Open View Team panel
  //   setTimeout(() => {
  //     endOfComponentRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }, 0);
  // };

  const handleSelectTeam = (team: Team) => {
    setSelectedTeam(team);
    setActivePanel('viewTeam');
    dispatch(expandSidePanel());
  };

  const handleAddTeamClick = () => {
    setSelectedTeam(null);
    setActivePanel('addTeam');
    dispatch(expandSidePanel());
  };

  const handleClosePanel = () => {
    setActivePanel(null);
    setSelectedTeam(null);
    dispatch(collapseSidePanel());
  };

  const addTeam = (teamName: string, teamLead: string, members: { UserID: string, UserType: string }[], legalSubjects: { LegalSubjectID: string; LegalSubjectName: string }[]) => {
    if (teamName.trim() === '') return;
    const newTeam: Team = {
      LegalTeamID: `team-${Date.now()}`, 
      TeamName: teamName,
      TeamLeadID: teamLead,
      TeamLeadFirstName: members.find(m => m.UserID === teamLead)?.UserType || '',
      TeamLeadLastName: '',
      TeamLeadType: 'LawfirmLawyer',
      Members: members.map(m => ({
        MemberID: m.UserID,
        MemberFirstName: '',
        MemberLastName: '',
        MemberRole: '',
        MemberType: m.UserType,
      })),
      LegalSubjects: legalSubjects,
      Cases: [],
      CreationDate: new Date().toISOString(),
      LawfirmID: userID || '',
    };
    dispatch(createTeam(newTeam));
    setActivePanel(null);
    dispatch(getLegalTeams(userID));
  };

  return (
    <div className='manage-team-container'>
      <div className={`manage-team-container--wrapper ${(activePanel === 'viewTeam' || activePanel === 'addTeam') ? 'shrunk' : ''}`}>
        <div className='manage-team-container--header'>
          <h2 style={{ color: '#fff', paddingLeft: 20 }}>{t('Manage existing teams')}</h2>
          <button onClick={handleAddTeamClick} className='primary-button'>{t('Add Team')}</button>
        </div>
        <div className='team-list'>
          {teams && teams.length > 0 ? (
            teams.map((team) => (
              <div
                key={team.LegalTeamID}
                className={`team-item ${selectedTeam && selectedTeam.LegalTeamID === team.LegalTeamID ? 'selected' : ''}`}
                onClick={() => handleSelectTeam(team)}
              >

                <div className='teams-flex-section'>
                  <div>
                  {/* <Avatar
              // src={employee.ProfilePicture ?? undefined}
              alt={`${team.TeamName}'s profile`}
              className="profile-picture"
              sx={{ width: 'fit-content', fontSize: '1rem', borderRadius: 2, background: '#111', color: '#fff', border: '1px solid #444', padding: 1 }} 
              variant="square" 
            >
              {team.TeamName} 
            </Avatar> */}
                  <h3 className="team-name">{team.TeamName}</h3>
                <div>
                <h4>Team Lead</h4>
                    <p className="team-lead">{team.TeamLeadFirstName} {team.TeamLeadLastName}</p>
                  </div>
                  {/* <div className="team-subjects">
                    <h4>Legal Subjects</h4>
                    {team.LegalSubjects && team.LegalSubjects.length > 0 ? (
                      <ul>
                        {team.LegalSubjects.map((LegalSubject: any) => (
                          <li key={LegalSubject.LegalSubjectID}>{LegalSubject.LegalSubjectName}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>No Subjects Selected</p>
                    )}
                  </div> */}
                  </div>
                  <div className="team-members-list">
                    <h4>Members</h4>
                    {team.Members && team.Members.length > 0 ? (
                      <ul>
                        {team.Members.map((member: any) => (
                          <li key={member.MemberID}>{member.MemberFirstName} {member.MemberLastName}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>No members</p>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div style={{ height: '50vh' }} className='non-access'>{t('No teams')}</div>
          )}
        </div>
      </div>
      {/* Side Panel */}
      {(activePanel === 'viewTeam' || activePanel === 'addTeam') && (


        <div className={`add-task-panel ${(activePanel === 'viewTeam' || activePanel === 'addTeam') ? 'open' : ''}`}>
          <button className="close-btn--sidepanel" onClick={handleClosePanel}>
            <i className='fas fa-chevron-right'></i>
          </button>
          <div className="side-panel-content">
            {activePanel === 'addTeam' && (
              <AddTeam
                subjects={subjects}
                newTeam={addTeam}
                setIsPanelOpen={setActivePanel}
              />
            )}
            {activePanel === 'viewTeam' && selectedTeam && (
              <ManageTeamDetails
                team={selectedTeam}
                allMembers={allMembers}
                subjects={subjects}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageTeam;
