import React, { useEffect, useRef, useState } from 'react';
import './TeamManagementWidget.css';
import { useTranslation } from 'react-i18next';
import { Member, Team } from './FirmComponents/ManageTeam';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { FetchLicenseRequests, getAllLawfirmAssignedTask, getAllLawyers, getLegalSubjects, getLegalTeams, getRoles} from '../../../store/actions/LawfirmActions';
import { Lawyer } from './interface/IFirmLawyer';
import { ClipLoader } from 'react-spinners';
import { IAdminCaseItem } from '../../Marketplace/Interfaces/IAdminCaseItem';
import { getPreferences } from '../../../store/actions/DataAction';
import { showSidePanel, hideSidePanel } from '../../../store/actions/sidePanelActions';

export interface User {
    id: number;
    name: string;
    role: string;
}

const TeamManagementWidget: React.FC = () => {
    const userID = getUserID();
    const dispatch = useAppDispatch();
    const subjects = useSelector((state: RootState) => state.lawfirm.subjects);
    const teams = useSelector((state: RootState) => state.lawfirm.teams || []);
    const lawFirmCases = useSelector((state: RootState) => state.lawfirm.cases || []);
    const { t } = useTranslation();
    const { loading } = useSelector((state: RootState) => state.lawfirm);
    const lawyers = useSelector((state: RootState) => state.lawfirm.lawyers || []);
    const employeeLimit = 50;
    const [isOngoing, setIsOngoing] = useState(true);
    const userType = getUserType();
    const preferences = useSelector((state: RootState) => state.lists.preferences)
      const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');

    const onToggle = () => {
        setIsOngoing(!isOngoing);
    };
    
    useEffect(() => {
        dispatch(getAllLawfirmAssignedTask(userType));
        dispatch(getPreferences(userType))
        dispatch(getRoles())
        dispatch(FetchLicenseRequests())
        dispatch(getLegalSubjects())
    }, [dispatch, userType]);

    useEffect(() => {
        if (userType) {
            dispatch(getAllLawyers(userID));
            dispatch(getLegalTeams(userID));
        }
    }, [dispatch, userID]);

    const handleOnboardEmployeeClick = () => {
        dispatch(
          showSidePanel({
            type: 'onboarding',
            showSidePanel: true,
            width: '50%',
            onCancel: () => dispatch(hideSidePanel()),
            addEmployee: (employee: Member) => {
              dispatch(getAllLawyers(userID));
            },
            employeeCount: lawyers.length,
            employeeLimit: employeeLimit,
          })
        );
      };
    
      const handleSelectEmployee = (employee: Lawyer) => {
        dispatch(
          showSidePanel({
            type: 'employeeView',
            showSidePanel: true,
            width: '50%',
            onCancel: () => dispatch(hideSidePanel()),
            employee: employee,
          })
        );
      };
    
      const handleSelectTeam = (team: Team) => {
        dispatch(
          showSidePanel({
            type: 'teamView',
            showSidePanel: true,
            width: '50%',
            onCancel: () => dispatch(hideSidePanel()),
            team: team,
          })
        );
      };
    
      const handleSelectCase = (caseItem: IAdminCaseItem) => {
        dispatch(
          showSidePanel({
            type: 'caseView',
            showSidePanel: true,
            width: '50%',
            onCancel: () => dispatch(hideSidePanel()),
            caseItem: caseItem,
          })
        );
      };

      const handleManageTeamClick = () => {
        dispatch(
          showSidePanel({
            type: 'manageTeam',
            showSidePanel: true,
            width: '50%',
            onCancel: () => dispatch(hideSidePanel()),
            allMembers: lawyers, // Pass necessary data
            subjects: subjects,
          })
        );
      };

    const formatNumber = (value: string): string => {
        return value.replace(/\B(?=(\d{3})+\b)/g, " ");
    };

    const renderEmployee = (employee: Lawyer, index: number) => (
        <tr key={index} className="team-management--employee-item" onClick={() => handleSelectEmployee(employee)}>
            <td>{`${employee.FirstName} ${employee.LastName}`}</td>
            <td className="tagvalue">{(employee.CompanyRole !== " ") ? employee.CompanyRole : t('None')}</td>
            <td className="tagvalue">{(employee.PracticeArea !== " ") ? t(employee.PracticeArea) : t('None')}</td>
            <td>{employee.Email}</td>
        </tr>
    );

    const renderTeam = (team: Team, index: number) => (
        <tr key={index} className="team-management--employee-item" onClick={() => handleSelectTeam(team)}>
            <td>{team.TeamName}</td>
            <td className="tagvalue">{team.Members ? team.Members.length : 0}</td>
            <td className="tagvalue">{team.Cases ? team.Cases.length : 0}</td>
            <td>{team.TeamLeadFirstName} {team.TeamLeadLastName}</td>
        </tr>
    );

    const renderCase = (clientCase: IAdminCaseItem, index: number) => (
        <tr key={index} className="team-management--employee-item" onClick={() => handleSelectCase(clientCase)}>
            <td className="tagvalue">{t(clientCase.CaseType)}</td>
            <td className="tagvalue">
                {clientCase.LawyerFirstName && clientCase.LawyerLastName
                    ? `${clientCase.LawyerFirstName} ${clientCase.LawyerLastName}`
                    : clientCase.TeamName}
            </td>
            <td className="tagvalue caseprice" style={{ color: 'rgb(66, 212, 66)' }}>{formatNumber(clientCase.ClientMinBudget)} - {formatNumber(clientCase.ClientMaxBudget)}</td>
        </tr>
    );

    return (
        <div className="team-management-container">
            <div className="team-container">
                <div className="team-container-header">
                    <span>{t('Employees')}</span>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
                        <button className='primary-button' onClick={handleOnboardEmployeeClick}>{t('Onboard Employee')} <i className="fa-duotone fa-people-group"></i></button>
                    </div>
                </div>
                <div className="team-container-body">
                    <div className="team-container--wrapper">
                        <table className="team-table">
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('Role')}</th>
                                    <th>{t('Practice Area')}</th>
                                    <th>{t('Email')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <td colSpan={4} style={{ textAlign: 'center', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <ClipLoader size={20} color='#fff' />
                                        </td>
                                    </tr>
                                ) : lawyers.filter(((lawyer: any) => lawyer.CompanyRole !== "Admin")).length > 0 ? (
                                    lawyers.filter(((lawyer: any) => lawyer.CompanyRole !== "Admin")).map(renderEmployee)
                                ) : (

                                    <div className='non-access'><td className="non-access">{t('No employees')}</td></div>

                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='management-container'>
                <div className='team-container'>
                    <div className='team-container-header'>
                        <span>{t('Legal Teams')}</span>
                        <button className={`primary-button ${isDemoEnvironment ? 'disabled' : ''} `} disabled={isDemoEnvironment} onClick={handleManageTeamClick}>{t('Manage Team')}</button>
                    </div>
                    {isDemoEnvironment ? (
                        <div className='non-access'>{t('Coming in Q4')}</div>
                    ) : (

                        <div className='team-container-body'>
                        <div className='team-container--wrapper'>
                            <table className="team-table">
                                <thead>
                                    <tr>
                                        <th>{t('Name')}</th>
                                        <th>{t('Members')}</th>
                                        <th>{t('Cases')}</th>
                                        <th>{t('Team Lead')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teams && teams.length > 0 ? (
                                        teams.map(renderTeam)
                                    ) : (
                                        <div className='non-access'><td className='non-access'>{t('No teams')}</td></div>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                                    )}
                </div>
                <div className='team-container'>
                    <div className='team-container-header'>
                        <span>{t('Cases')}</span>
                        {isDemoEnvironment && (
                        <div className="toggle-switch__status">
                            <button className={`button left ${isOngoing ? '' : 'active'}`} onClick={onToggle}>{t('Ongoing')}</button>
                            <button className={`button right ${!isOngoing ? 'active' : ''}`} onClick={onToggle}>{t('Completed')}</button>
                            <div className={`slider-status ${isOngoing ? 'right' : ''}`}></div>
                        </div>
                        )}
                    </div>
                    <div className='team-container-body'>
                        {isDemoEnvironment ? (
                            <div className='non-access'>{t('Coming in Q4')}</div>
                        ) : (
                            <div className='team-container--wrapper'>
                                <table className="team-table">
                                    <thead>
                                        <tr>
                                            <th>{t('Case Type')}</th>
                                            <th>{t('Assigned To')}</th>
                                            <th>  {t('Price')} {preferences?.Currency ? preferences.Currency : ''}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lawFirmCases.length > 0 ? (
                                            lawFirmCases.map(renderCase)
                                        ) : (
                                            <div className='non-access'><td className='non-access'>{t('No Cases')}</td></div>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamManagementWidget;
