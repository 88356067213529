import React, { CSSProperties, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { t } from 'i18next';
import { CreateTask, ActiveTasks } from '../../../store/actions/marketplace/TasksActions';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { hideModal } from '../../../store/actions/modalActions';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import '../Marketplace.css';
import { ICaseItem } from '../Interfaces/ICaseItem';
import { getAllCases, getAllLawfirmAssignedTask, getLawfirmLawyerTasks, lawfirmAssignTask } from '../../../store/actions/LawfirmActions';
import { lawfirmLawyerAddTask } from '../../../store/actions/LawfirmLawyerActions';
import { hideSidePanel } from '../../../store/actions/sidePanelActions';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import CustomDateTimePicker from '../../shared/CustomDateTimePicker';

interface AddTaskProps {
  selectedEmployeeEmail?: string; 
  onCancel?: () => void;
  onAdd?: () => void;
  style?: CSSProperties;
}

const AddTask: React.FC<AddTaskProps> = ({ selectedEmployeeEmail, onCancel, onAdd, style }) => {
  const userType = getUserType();
  const caseList = useSelector((root: RootState) => {
    return userType === "LawfirmAdmin" ? root.lawfirm.cases : root.case.caseList || [];
  }); 
  const dispatch = useAppDispatch();
  const userId = getUserID();

  // useEffect(() => {

  //   if(userType === "LawfirmAdmin") {
  //     dispatch(getAllCases(userId))
  //   }

  // }, [caseList])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const validationSchema = Yup.object().shape({
    TaskTitle: Yup.string().required(t("Title for the task required")),
    TaskDescription: Yup.string().required(t("Description needed")),
    TaskDeadline: Yup.string().required(t("Deadline for the task required")),
  });

  const formik = useFormik({
    initialValues: {
      TaskTitle: "",
      TaskDescription: "",
      TaskDeadline: "",
      CaseID: ""
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const dataValues: any = {
        UserType: userType,
        ...values
      };
  
      // Remove CaseID if not selected
      if (!dataValues.CaseID) {
        delete dataValues.CaseID;
      }
  
      // Only include Assignees if an employee email is provided
      if (selectedEmployeeEmail && userType === 'LawfirmAdmin') {
        dataValues.Assignees = [selectedEmployeeEmail];
      }
  
      try {
        if (userType === "Lawfirmlawyer") {
          // Extract required fields
          const { UserType, TaskTitle, TaskDescription, TaskDeadline } = dataValues;
          // Dispatch with individual arguments
          const result = await dispatch(
            lawfirmLawyerAddTask(UserType, TaskTitle, TaskDescription, TaskDeadline)
          );
  
          if (result) {
            dispatch(getAllLawfirmAssignedTask(UserType));
            if (onAdd) {
              onAdd();
            }
          }
        } 
          if (userType === "LawfirmAdmin") {
            // Extract required fields
              await dispatch(lawfirmAssignTask(dataValues));
              dispatch(getLawfirmLawyerTasks(userType, selectedEmployeeEmail as string))
              if (onAdd) {
                onAdd();
              }
          }
        if (userType === 'Lawyer') {
          await dispatch(CreateTask(dataValues));
          dispatch(ActiveTasks(userId, userType));
        }
        dispatch(hideModal());
        dispatch(hideSidePanel())
        resetForm();
      } catch (error) {
        // console.error('Error creating task:', error);
          dispatch(showFeedbackModal({
            modalType: 'error',
            duration: 3000,
            message: ('Could not add task. Please contact support.'),
            showModal: true
          }))
      }
    }
  });

  const cancelTask = () => {
    if (onCancel) {
      onCancel();  
    }
    dispatch(hideModal());
  };

  const formattedCaseOptions = caseList
  // .filter((caseItem: ICaseItem | IAdminCaseItem) => caseItem.Status === "Ongoing")
  .map((caseItem: ICaseItem) => ({
    label: `${caseItem.CaseType} - ${caseItem.MaxBudget}`,
    id: caseItem.CaseID
  }));

  

  return (
    <div className="add-task-container" style={style}>
      <div className='add-task__wrapper'>
        <h2>{t('Add New Task')}</h2>
          <div className='add-task-div'>
            <label style={{ textAlign: 'left' }} htmlFor="title">{t('Title')}</label>
            <input
              type="text"
              name="TaskTitle"
              placeholder={t("Enter task title...")}
              value={formik.values.TaskTitle}
              onChange={formik.handleChange("TaskTitle")}
              onBlur={formik.handleBlur("TaskTitle")}
              className="new-task-input"
            />
            {formik.errors?.TaskTitle && formik.touched.TaskTitle && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.TaskTitle}</div>}
          </div>
        <div className='add-task__bottom'>
          <div className='add-task-div'>
            <label style={{ textAlign: 'left' }} htmlFor="case">{t('Case')}</label>
            <GlobalDropdown
              options={formattedCaseOptions}
              onSelect={(id) => formik.setFieldValue("CaseID", id)}
              selectedLabel={formattedCaseOptions.find((option: any) => option.id === formik.values.CaseID)?.label || ''}
              style={{ width: '90%', height: '100%', position: 'relative' }}
              headerStyle={{ width: '100%' }}
              name='CaseID'
              isStatic={true}
            />
            {formik.errors?.CaseID && formik.touched.CaseID && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.CaseID}</div>}
          </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }}>
          <label style={{ textAlign: 'left' }} htmlFor="deadline">{t('Deadline')}</label>
              <ThemeProvider theme={darkTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomDateTimePicker
                    value={
                      formik.values.TaskDeadline
                        ? dayjs(formik.values.TaskDeadline, "DD-MM-YYYY HH:mm")
                        : null
                    }
                    onChange={(newValue: Dayjs | null) => {
                      if (newValue) {
                        formik.setFieldValue('TaskDeadline', newValue.format("DD-MM-YYYY HH:mm"));
                      }
                    }}
                    minDateTime={dayjs()}
                    width='100%'
                  />
                </LocalizationProvider>
              </ThemeProvider>
          {formik.errors?.TaskDeadline && formik.touched.TaskDeadline && (
            <div style={{ textAlign: 'left' }} className="error-message-display">
              {formik.errors?.TaskDeadline}
            </div>
          )}
        </div>
        </div>

        <label style={{ textAlign: 'left' }} htmlFor="description">{t('Description')}</label>
        <textarea
          name="TaskDescription"
          placeholder={t("Enter task description...")}
          value={formik.values.TaskDescription}
          onChange={formik.handleChange("TaskDescription")}
          className="new-task-input description"
        />
        {formik.errors?.TaskDescription && formik.touched.TaskDescription && <div style={{ textAlign: 'left' }} className="error-message-display">{formik.errors?.TaskDescription}</div>}
      </div>
      <div style={{ display: "flex", alignItems: "center", width: "100%", gap: 10, margin: '10px 0px' }}>
        <button type='submit' onClick={() => { formik.handleSubmit(); }} className='primary-button'>
          {t('Add')}
        </button>
        {/* <button onClick={cancelTask} className='add-task__action--button cancel'>
          {t('Cancel')}
        </button> */}
      </div>
    </div>
  );
};

export default AddTask;
