import { useFormik } from 'formik';
import React, { useState } from 'react'
import { UserProps } from '../../../Settings/AccountSettings/PersonalInfoAndProfile';
import * as Yup from "yup"
import { RootState, useAppDispatch } from '../../../../store';
import { getUserID, getUserType } from '../../../../store/actions/ChatAction';
import "../../Marketplace.css"
import { showPasswordProfileModal } from '../../../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import imageMyProfile from '../../../../resources/dummy-profile-pic-male1.jpg'
import { Stars } from '../../Client/LawyerProfileModal';
import { useSelector } from 'react-redux';
import { userInformation } from '../../../../store/actions/DataAction';
import { showFeedbackModal } from '../../../../store/actions/UserFeedbackActions';
import AdminLawFirm from './AdminLawFirm';
import { Lawyer } from '../../../Dashboard/AdminFirm/interface/IFirmLawyer';
interface FileProps {
    handleViewListClick: () => void,
    handleReviewsClick: () => void,
    handleDocumentsClick: () => void,
    handleFinanceClick: () => void,
    userInfo: any;
    handleSelectEmployee:(employee: Lawyer) => void;
}

const AdminProfileSection: React.FC<FileProps> = ({ handleViewListClick, handleReviewsClick, handleDocumentsClick, handleFinanceClick, userInfo, handleSelectEmployee }) => {
    const [editMode, setEditMode] = useState(false);
    const dispatch = useAppDispatch();
    const userType = getUserType()
    const userId = getUserID()
    const preferances = useSelector((state: RootState) => state.lists.preferences)
    const { t } = useTranslation()
    const rating = useSelector((state: RootState) => state.lawyers.ratingsData)


    const validationSchemaProfile = Yup.object({
        FirstName: Yup.string()
            .required('First name is required')
            .max(50, 'First name cannot be longer than 50 characters'),
        LastName: Yup.string()
            .required('Last name is required')
            .max(50, 'Last name cannot be longer than 50 characters'),
        PhoneNumber: Yup.string()
            .required('Phone number is required')
            .matches(/^\+?[1-9]\d{1,14}$/, 'Phone number is not valid'),
        StreetAddress: Yup.string()
            .required('Street address is required'),
        Email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        Biography: Yup.string()
            .max(500, 'Biography cannot exceed 500 characters'),
        HourlyRate: Yup.number()
            .positive('Hourly rate must be positive')
            .required('Hourly rate is required'),
        ProfilePicture: Yup.mixed()
            .nullable()
            .notRequired()
    })


    const [initialValue, setInitialValue] = useState(userInfo)

    const formikProfile = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchemaProfile,
        onSubmit: async (values: UserProps) => {
            const formData = new FormData();
            formData.append("UserID", userId)
            formData.append("UserType", userType)
            formData.append("Country", userInfo?.Country ?? "")

            Object.entries(values).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== "" && key !== 'ProfilePicture') {
                    formData.append(key, value.toString());
                }
            });

            if (values.ProfilePicture && values.ProfilePicture.length > 0) {
                formData.append("ProfilePicture", values.ProfilePicture);
            }
        },
    });


    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        formikProfile.setFieldValue('ProfilePicture', file);
    };

    const onSuccess = () => {
        setEditMode(false);
        dispatch(showFeedbackModal({
            showModal: true,
            message: t('Account updated'),
            modalType: 'success',
            duration: 3000
        }));
        dispatch(userInformation(userType))
    };
    
    const handleEditSubmit = () => {

        dispatch(showPasswordProfileModal(formikProfile.values, userId, userType, onSuccess));
    };

    const getOccupationColor = () => {
        if (!userInfo.LawfirmID) {

            return '#7338a0';
        } else if (userInfo.LawfirmID) {

            return '#0f4f73';
        } else {

            return '#ccc';
        }
    };
    return (
        <div className='lawyer-right__section'>
            <div className='lcm-modal-details__top'>
                <div onClick={handleViewListClick}  >
                    <h2>{t('To-Do List')}</h2>
                    <i className='fa-solid fa-list-check'></i>
                </div>
                <div onClick={handleReviewsClick} >
                    <h2>{t('Reviews')}</h2>
                    <i className='fa-solid fa-star-sharp-half-stroke'></i>
                </div>
                <div onClick={handleDocumentsClick} >
                    <h2>{t('My Documents')}</h2>
                    <i className='fa-solid fa-briefcase-blank'></i>
                </div>
                <div onClick={handleFinanceClick} >
                    <h2>{t('Finance')}</h2>
                    <i className='fa-solid fa-money-check-dollar'></i>
                </div>
            </div>

            {userType === 'LawfirmAdmin' ? (
                <AdminLawFirm handleSelectEmployee={handleSelectEmployee} />
            ) : (
            
            <div className="lawyer-profile">

                {editMode ? (
                    <form className="edit-lawyer-profile-form">
                        <div className="lawyer-profile__form-group pic">
                            <label>{t('Profile Picture')}</label>
                            <div className="profile-picture__edit">

                                {
                                    formikProfile.values.ProfilePicture ? (<div className='profile-picture-container '>
                                        <img src={(formikProfile.values.ProfilePicture as any) instanceof Blob ? URL.createObjectURL((formikProfile.values.ProfilePicture as any)) : formikProfile.values. ProfilePicture}
                                            alt="Profile" className="profile-picture" />

                                        <span className="profile-picture__change-btn">{t('Change Picture')}</span>
                                        <input
                                            id="file"
                                            type="file"
                                            name="profilePicture"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            className="profile-picture"

                                        />

                                    </div>) : (<>
                                        <input
                                            id="file"
                                            type="file"
                                            name="profilePicture"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                        />
                                        <span className="profile-picture__button">{t('Choose File')}</span>

                                    </>

                                    )
                                }
                            </div>
                        </div>

                        <div className="lawyer-profile__form-group">
                            <label>{t('Hourly Rate')}</label>
                            <input type="text" className="lawyer-profile__input-text" name="HourlyRate" value={formikProfile.values.HourlyRate} onChange={formikProfile.handleChange("HourlyRate")} />
                        </div>

                        <div className="lawyer-profile__form-group">
                            <label>{t('Biography')}</label>
                            <textarea name="Biography" value={formikProfile.values.Biography} onChange={formikProfile.handleChange("Biography")}></textarea>
                        </div>
                        <div className='edit-lawyer-profile__actions'>
                            <button type="button" onClick={handleEditSubmit} className="lawyer-profile__form-button">{t('Save')}</button>
                            <button type="button" className="lawyer-profile__form-button cancel" onClick={() => setEditMode(false)}>{t('Cancel')}</button>
                        </div>
                    </form>
                ) : (
                    <>
                        <div>
                            <div className="profile-header">
                                {
                                    formikProfile.values.ProfilePicture ? (<img src={formikProfile.values.ProfilePicture}
                                        alt="Profile" className="profile-picture" />) : (<img src={imageMyProfile}
                                            alt="Profile" className="profile-picture" />)
                                }
                                <div className="profile-info">
                                    <div className='lawyer-profile__preview'>
                                        <div className='lawyer-profile__preview--row'>
                                            <div className='lawyer-profile__name-location'>
                                                <h2 style={{ color: '#fff', fontWeight: '500' }}>{userInfo?.FirstName} {userInfo?.LastName}</h2>
                                                <p style={{ fontWeight: '400', fontStyle: 'italic' }}>{userInfo?.City}, {userInfo?.Country}</p>
                                        <div className='lawyer-profile__preview--row hourly-rate'>
                                            <p>{t('Hourly Rate')}</p>
                                            <div className='profile-details__pricetag'>
                                                <p>{userInfo.HourlyRate} {preferances?.Currency ? preferances?.Currency : ''}</p>
                                            </div>
                                        </div>
                                            </div>
                                                <p className='profile-details__occupation' style={{ background: getOccupationColor() }}>
                                                    {t(userInfo.LawfirmID ? `${userInfo.LawfirmName}` : "Independent")}
                                                </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>

                                <div className='lawyer-profile__details--wrapper'>
                                    <div style={{ flex: 1 }}>
                                        <h2 style={{ color: '#fff', fontSize: 18, margin: 0, marginBottom: 10, fontWeight: '500' }}>{t('Stats')}</h2>
                                        <div className='profile-details__stats'>

                                            <div className='profile-details__row' >
                                                <p>{t('Rating')}</p>

                                                <Stars rating={rating.AverageRating} />

                                            </div>

                                            <div className='profile-details__row'>
                                                <p>{t('Cases Handled')}</p>
                                                <p className='lawyer-profile__stat'>
                                                    {rating.TotalCases}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='profile-details__expertise--container' style={{ flex: 1 }}>
                                        <p style={{ color: '#fff', fontSize: 18, margin: 0, marginBottom: 0 }}>{t('Law Expertises')}</p>
                                        <div className='profile-details__row--expertise'>
                                            <p className='profile-details__expertise'>{userInfo.PracticeArea}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='lawyer-profile__details'>
                            <div className='lawyer-profile__bio--container'>
                                <div className='lawyer-profile__bio--container--header'>
                                    <h2>{t("Biography")}</h2>
                                    <button className='lawyer-profile__editbtn' onClick={toggleEditMode}>{t('Edit Profile')}</button>
                                </div>
                                <p>{userInfo.Biography}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            )}
        </div>
    )
}

export default AdminProfileSection
