import React, { useState, useEffect } from 'react';
import './Projects.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';

import { getUserType, getUserID } from '../../store/actions/ChatAction';
import { addProject, addProjectAssignee } from '../../store/actions/BusinessActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';

interface Employee {
    id: number;
    name: string;
}

interface Project {
    id: number;
    name: string;
    employees: Employee[];
    projectLead?: Employee;
    deadline: string;
}

const dummyProjects = {
    active: [
        { id: 1, name: 'Project A', employees: [{ id: 1, name: 'Alice Johnson' }, { id: 2, name: 'Bob Smith' }], projectLead: { id: 1, name: 'Alice Johnson' }, deadline: '2024-12-01' },
        { id: 2, name: 'Project B', employees: [{ id: 3, name: 'Carol White' }, { id: 4, name: 'David Brown' }], deadline: '2024-11-15' }
    ],
    awaitingApproval: [
        { id: 3, name: 'Project C', employees: [{ id: 5, name: 'Eva Green' }, { id: 6, name: 'Frank Wilson' }], deadline: '2024-12-30' }
    ],
    completed: [
        { id: 4, name: 'Project D', employees: [{ id: 7, name: 'Grace Lee' }, { id: 8, name: 'Hank Turner' }], projectLead: { id: 7, name: 'Grace Lee' }, deadline: '2024-10-01' }
    ]
};

const Projects = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  
    // Form state variables
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [projectDeadline, setProjectDeadline] = useState('');
    const [projectLeadEmail, setProjectLeadEmail] = useState('');
    const [assignees, setAssignees] = useState<string>(''); // Comma-separated emails
  
    const userType = getUserType();
    const userId = getUserID();
  
    // Projects from Redux store
    // const projects = useSelector((state: RootState) => state.projects.projectList) || [];
  
    // Categorize projects based on status
    // const activeProjects = projects.filter(project => project.Status === 'Active');
    // const awaitingApprovalProjects = projects.filter(project => project.Status === 'AwaitingApproval');
    // const completedProjects = projects.filter(project => project.Status === 'Completed');
  
    // Fetch projects on component mount
    // useEffect(() => {
    // //   dispatch(fetchProjects(userType));
    // }, [dispatch, userType]);
  
    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const renderProject = (project: Project) => (
        <tr key={project.id} className="project-item">
            <td>{project.name}</td>
            <td>{project.id}</td>
            <td>{project.employees.map(e => e.name).join(', ')}</td>
            <td>{project.projectLead ? project.projectLead.name : 'N/A'}</td>
            <td>{project.deadline}</td>
        </tr>
    );

    const handleCreateProject = async () => {
        try {
          // Create the project
          const projectId = await dispatch(addProject(userType, projectName, projectDescription, projectLeadEmail));
      
          if (projectId) {
            // Parse assignees into an array
            const assigneeEmails = assignees.split(',').map(email => email.trim());
      
            // Assign employees to the project
            // await dispatch(addProjectAssignee(userType, projectId, assigneeEmails, projectDeadline));
      
            // Show success message
            dispatch(showFeedbackModal({
              showModal: true,
              message: t('New Project Created and Assignees Added!'),
              modalType: 'success',
              duration: 3000,
            }));
      
            // Close the side panel and reset form
            setIsPanelOpen(false);
            resetForm();
      
            // Refresh the projects list
            // await dispatch(fetchProjects(userType));
          } else {
            // Handle failure
            dispatch(showFeedbackModal({
              showModal: true,
              message: t('Failed to create project.'),
              modalType: 'error',
              duration: 3000,
            }));
          }
        } catch (error) {
          console.error('Error creating project:', error);
          dispatch(showFeedbackModal({
            showModal: true,
            message: t('Failed to create project.'),
            modalType: 'error',
            duration: 3000,
          }));
        }
      };
      
      const resetForm = () => {
        setProjectName('');
        setProjectDescription('');
        setProjectDeadline('');
        setProjectLeadEmail('');
        setAssignees('');
      };
      

    return (
        <div className="projects-main__container">
            <div className={`backdrop ${isPanelOpen ? 'show' : ''}`} onClick={togglePanel}></div>
            <div className="projects-container--section-column">
                <div className="projects-container top">
                    <div className='projects-header-container'>
                        <span>{t('Active Projects')}</span>
                        <button onClick={togglePanel}>{t('Create New Project')} +</button>
                    </div>
                    <table className="projects-table">
                        <thead>
                            <tr>
                                <th>{t('Name')}</th>
                                <th>{t('ID')}</th>
                                <th>{t('Employees')}</th>
                                <th>{t('Lead')}</th>
                                <th>{t('Deadline')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dummyProjects.active.map(renderProject)}
                        </tbody>
                    </table>
                </div>
                <div className="projects-container bottom">
                    <div className="projects-container left">
                        <div className='projects-header-container'>
                            <span>{t('Projects Awaiting Approval')}</span>
                        </div>
                        <table className="projects-table">
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('ID')}</th>
                                    <th>{t('Employees')}</th>
                                    <th>{t('Lead')}</th>
                                    <th>{t('Deadline')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dummyProjects.awaitingApproval.map(renderProject)}
                            </tbody>
                        </table>
                    </div>
                    <div className="projects-container right">
                        <div className='projects-header-container'>
                            <span>{t('Completed Projects')}</span>
                        </div>
                        <table className="projects-table">
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('ID')}</th>
                                    <th>{t('Employees')}</th>
                                    <th>{t('Lead')}</th>
                                    <th>{t('Deadline')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dummyProjects.completed.map(renderProject)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className={`side-panel ${isPanelOpen ? 'open_panel' : ''}`}>
                <button className="close-btn" onClick={togglePanel}><i className='fas fa-chevron-right'></i></button>
                <h1 className="panel-title">{t('Create New Project')}</h1>
                <div className="panel-content">
                                <div className="form-group--project">
                                    <label>{t('Project Name')}</label>
                                    <input
                                    type="text"
                                    placeholder={t('Enter project name')}
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group--project">
                                    <label>{t('Project Description')}</label>
                                    <textarea
                                    placeholder={t('Enter project description')}
                                    value={projectDescription}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                    />
                                </div>
                                <div className="form-group--project">
                                    <label>{t('Deadline')}</label>
                                    <input
                                    type="date"
                                    value={projectDeadline}
                                    onChange={(e) => setProjectDeadline(e.target.value)}
                                    />
                                </div>
                                <div className="form-group--project">
                                    <label>{t('Project Lead Email')}</label>
                                    <input
                                    type="email"
                                    placeholder={t('Enter project lead email')}
                                    value={projectLeadEmail}
                                    onChange={(e) => setProjectLeadEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group--project">
                                    <label>{t('Assignees')}</label>
                                    <input
                                    type="text"
                                    placeholder={t('Enter assignee emails, separated by commas')}
                                    value={assignees}
                                    onChange={(e) => setAssignees(e.target.value)}
                                    />
                                </div>
                                <button className="create-button" onClick={handleCreateProject}>{t('Create')}</button>
                                </div>

            </div>
        </div>
    );
};

export default Projects;
