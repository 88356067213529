import apis from "../../utils/apis";
import { AnyAction, Dispatch } from 'redux';
import axios, { AxiosError } from "axios";
import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { showFeedbackModal } from "./UserFeedbackActions";
import { userInformation, getPreferences } from './DataAction';
import SocketServices from "../../utils/SocketServices";
import { CLEAR_TOKEN, FORGOT_PASSWORD, SET_QR_CODE, SET_TOKEN } from "../types";



export const setToken = (token: string | null, userId: string | null) => {
  return {
    type: SET_TOKEN,
    token,
    userId
  };
};

export const clearToken = () => {
  return {
    type: CLEAR_TOKEN,
  };
};

export const newPasswordToken = (uuid: string | null) => {
  return {
    type: FORGOT_PASSWORD,
    uuid
  }
}

export const setQrCode = (qr: string | null) => {
  return {
    type: SET_QR_CODE,
    qr
  }
}
export interface ErrorResponse {
  message: string;
  error?: string
}

export const SignIn = (credentials: { Email: string; Password: string, RememberMe: number }) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      let response = await apis({
        method: 'POST',
        url: "user/signin",
        data: credentials
      });

      if (response && response.status === 200) {
        const token = response?.data?.Data?.AccessToken;
        const userId = response?.data?.Data?.UserID;
        const userType = response?.data?.Data?.UserType;
        const refreshToken = response?.data?.Data?.RefreshToken;


        if (credentials.RememberMe === 0) {
          sessionStorage.setItem('token', JSON.stringify({ token: token, userType: userType, userId: userId, refreshToken: refreshToken}));
        } else {
          localStorage.setItem("token", JSON.stringify({ token: token, userType: userType, userId: userId,refreshToken: refreshToken}));
        }
        // dispatch(setToken(token, userId));
        dispatch(userInformation(userType))
        dispatch(getPreferences(userType));
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Logged in"),
          modalType: 'success',
          duration: 3000
        }));

        return response.data
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (error) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: 'Failed to sign in',
        showModal: true,
        duration: 3000
      }))
    }
  };
};

export const signOut = () => {

  return async (dispatch: Dispatch<any>) => {
    try {
      const session_id = sessionStorage.getItem('session_id')
      localStorage.clear()
      sessionStorage.clear()
      if(session_id){
        SocketServices.emit('logout_session', { "session_id": session_id })
      }
      dispatch(showFeedbackModal({
        showModal: true,
        message: ("Logged out"),
        modalType: 'success',
        duration: 3000
      }));
    } catch (err) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: 'Failed to sign out',
        showModal: true,
        duration: 3000
      }))
    }
  }

}

export const forgotPassword = (email: string | File) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      let response: any = await apis({
        method: 'POST',
        url: "user/forgotPassword",
        data: { Email: email }
      })

      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: (response.data.message),
          modalType: 'success',
          duration: 3000
        }));
        return response.data
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (err) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        showModal: true,
        duration: 3000,
        message: 'An error occured'
      }))

    }
  }
}

export const resetPassword = (values: { UID: string; Password: string }) => {
  return async (dispatch: any) => {
    try {
      let response = await apis({
        method: 'POST',
        url: "user/resetPassword",
        data: values
      })

      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Password reset"),
          modalType: 'success',
          duration: 3000
        }));
        return response
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (err) {
    }
  }
}



export const loginGoogle = (token: Blob) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      let response = await apis({
        "method": 'POST',
        url: "sso/google",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: token,
      })

      if (response && response.status === 200) {
        const token = response?.data?.accessToken;
        const userId = response?.data?.UserID;
        // dispatch(setToken(token, userId));
        localStorage.setItem('token', JSON.stringify({ token: token, userId: userId }));
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Logged in"),
          modalType: 'success',
          duration: 3000
        }));
        return token
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}


export const loginMsal = (token: string) => {
  return async (dispatch: any) => {
    try {
      let response = await apis({
        "method": 'POST',
        url: "auth/msal",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: { token: token },
      })


      if (response && response.status === 200) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ("Logged in"),
          modalType: 'success',
          duration: 3000
        }));
      } else {
        if (axios.isAxiosError(response)) {
          const axiosError = response as AxiosError;
          if (axiosError.response && axiosError.response.data) {
            let errorMessage = axiosError.response.data as ErrorResponse
            dispatch(showFeedbackModal({
              showModal: true,
              message: (errorMessage.message || 'An unknown error occurred'),
              modalType: 'error',
              duration: 3000
            }));
          } else {
            dispatch(showFeedbackModal({
              showModal: true,
              message: ('Network error, please try again'),
              modalType: 'error',
              duration: 3000
            }));
          }
        }
      }

    } catch (err) {
      console.error(err);

    }
  }
}

export const check2fa = (Email: string, Password: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "/settings/check2FA",
      data: {
        Email,
        Password,
      }
    });
    if (response && response.status === 200) {
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export const generateQr = (UserID: string, UserType: string, Email: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "settings/initiate2FA",
      data: {
        UserID,
        UserType,
        Email
      }
    });
    if (response) {
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (err) {
    console.error(err);

  }
}

interface OtpResponse {
  OTPVerified: string;
  message: string;
}

export const verify2faQr = (
  OTP: string | File,
  UserID?: string,
  UserType?: string,
  Email?: string,
  Password?: string,
  RememberMe?: number
): ThunkAction<Promise<OtpResponse>, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "settings/validate2FA",
      data: Email && Password ? { Email, Password, RememberMe, OTP } : { UserID, UserType, OTP }
    });


    if (response) {
      return response.data;
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (err) {
    console.error(err);
    return { OTPVerified: 'NO', message: 'Error occurred' };
  }
};

export const setOtpErrorMessage = (message: string) => ({
  type: 'SET_OTP_ERROR_MESSAGE',
  payload: message,
});

export const disable2FA = (UserID: string, UserType: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const response = await apis({
      method: "POST",
      url: "settings/disable2FA",
      data: {
        UserID,
        UserType,
      }
    });
    if (response) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: ("2FA disabled"),
        modalType: 'success',
        duration: 3000
      }));
      
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (err) {
    console.error(err);

  }
}



export const checkSessionValidation = (UID: string) => {
  return async (dispatch: any) => {
    try{
      const response = await apis({
        method: "POST",
        url: "user/checkValidSession",
        data: {
          UID
        }
      });

      if(response && response.status === 200){
        return response.data
      }
    }catch(e){
      console.error(e);
    }
  }
}