import React, { useState, useEffect, useRef, useMemo, ChangeEvent } from 'react';
import './MessageCenter.css';
import dayjs from 'dayjs';
import { useSwipeable } from 'react-swipeable';
import { getUserID } from '../../../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../../shared/OutsideClick';
import { ChatImages } from '../../../Marketplace/Client/ChatInterface';
import { Form } from 'formik';
import { ClipLoader } from 'react-spinners';
import { deleteMesageChat, fetchCHatList } from '../../../../store/actions/DataAction';
import { useAppDispatch } from '../../../../store';

interface Message {
  id: string,
  sender: string;
  content: string;
  timestamp: Date;
}

interface ChatWindowProps {
  messages: Message[];
  selectedChat: any;
  deadline?: string;
  jurisdiction?: string;
  price?: string;
  onSendMessage: (message: string, attachments: any) => void;
  chatId: number | string;
  isLoading: boolean
}


export const formatTime = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }); // Customize the time format as needed
};

const ChatWindow: React.FC<ChatWindowProps> = ({
  messages,
  selectedChat,
  onSendMessage,
  isLoading
}) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const userID = getUserID()
  const { t } = useTranslation()
  const [openOtions, setOpenOptions] = useState(false)
  const [attachments, setAttachments] = useState<File[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const dispatch = useAppDispatch()
  const [isDeleting,setIsDeleting] = useState(false)

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const message = { sender: 'You', content: newMessage, timestamp: currentTime, attachments };
      onSendMessage(newMessage, images.length > 0 ? images : attachments);
      setNewMessage('');
      setImages([])
      setAttachments([])
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  function normalizeToMidnight(date: Date, timeZone: string): Date {
    const localizedDate = new Date(date.toLocaleString('en-US', { timeZone }));
    localizedDate.setHours(0, 0, 0, 0);
    return localizedDate;
  }
  function formatDate(timestamp: Date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date: any = new Date(timestamp);
    const now: any = new Date();

    const dateInTimeZone = normalizeToMidnight(date, timeZone);
    const nowInTimeZone = normalizeToMidnight(now, timeZone);

    const differenceInDays = Math.floor(
      (nowInTimeZone.getTime() - dateInTimeZone.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays === 0) {
      return 'Today';
    } else if (differenceInDays === 1) {
      return 'Yesterday';
    } else if (differenceInDays <= 3) {
      const options: Intl.DateTimeFormatOptions = { weekday: 'long', timeZone };
      return dateInTimeZone.toLocaleDateString('en-GB', options);
    } else {
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', timeZone };
      return `${dateInTimeZone.toLocaleDateString('en-GB', options)}, ${dateInTimeZone.getFullYear()}`;
    }
  }

  const groupedMessages = useMemo(() => {
    const groups: { [date: string]: any[] } = {};
    messages.forEach((msg: any) => {
      const date = formatDate(msg.timestamp);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
    });
    return groups;
  }, [messages]);





  const Message: React.FC<any> = ({ msg }) => {
    const [isSwiping, setIsSwiping] = useState(false);
    const [swipeDirection, setSwipeDirection] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const messageLimit = 100;
    const [duration, setDuration] = useState("0s")
    useEffect(() => {
      if (msg.Type === "Call") {
        const start = dayjs(msg.callStartedAt);
        const end = dayjs(msg.callEndedAt);

        const callDuration = dayjs.duration(end.diff(start));
        let formattedDuration = "";
        if (callDuration.hours() > 0) {
          formattedDuration += `${callDuration.hours()}h `;
        }
        if (callDuration.minutes() > 0) {
          formattedDuration += `${callDuration.minutes()}m `;
        }
        formattedDuration += `${callDuration.seconds()}s`;

        setDuration(formattedDuration);
      }
    }, [msg.Type, msg.callStartedAt, msg.callEndedAt]);


    const handlers = useSwipeable({
      onSwipedLeft: () => {
        setSwipeDirection('left');
        setIsSwiping(true);
        // setMessageReply(msg)

      },
      onSwipedRight: () => {
        // setSwipeDirection('right');
        // setIsSwiping(true);
        // setMessageReply(msg)
      },
      onSwiping: (eventData) => {
        setSwipeDirection(eventData.dir);
        setIsSwiping(true);
      },
      onSwiped: () => {
        setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
      },
      delta: 10,
      preventScrollOnSwipe: false,
      trackMouse: true,
    });

    useEffect(() => {
      if (isSwiping) {
        const timer = setTimeout(() => {
          setIsSwiping(false);
          setSwipeDirection('');
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [isSwiping]);
    const messageClass = msg.sender && msg.sender ? 'self' : 'other'

    return (
      <>
        {
          msg.Type ? (

            msg.Type === "Text" ? (
              <div
                key={msg.id}
                {...handlers}
                className={`message__marketplace ${messageClass} ${isSwiping ? 'swiping' : ''} ${swipeDirection}`}
              >
                {msg.attachments && msg.attachments.length > 0 && (
                  <ChatImages attachments={msg.attachments} />
                  // <></>
                )}
                <div className="lawyers--message-content">
                  {isExpanded || msg.content.length <= messageLimit
                    ? msg.content
                    : `${msg.content.substring(0, messageLimit)}...`
                  }
                  {!isExpanded && msg.content.length > messageLimit && (
                    <span className="read-more" onClick={() => setIsExpanded(true)}>
                      {t('Read more')}
                    </span>
                  )}
                  {isExpanded && <span className="read-more" onClick={() => setIsExpanded(false)}>
                    {t('Read less')}
                  </span>
                  }
                </div>
                <div className="message-timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px", marginBottom: "10px" }}>
                <i className='fa-regular fa-video' style={{ color: "grey", fontSize: '16px' }}></i>
                <span style={{ color: "grey", fontSize: '16px' }}>{msg.content}</span>
                <span style={{ color: "grey", fontSize: '16px' }}>{duration}</span>

              </div>
            )
          )
            : (
              <div
                key={msg.id}
                {...handlers}
                className={`chat-message ${messageClass} ${isSwiping ? 'swiping' : ''} ${swipeDirection}`}
              >
                {msg.attachments && <ChatImages attachments={msg.attachments} />}
                <div className="lawyers--message-content">
                  {isExpanded || msg.content.length <= messageLimit
                    ? msg.content
                    : `${msg.content.substring(0, messageLimit)}...`
                  }
                  {!isExpanded && msg.content.length > messageLimit && (
                    <span className="read-more" onClick={() => setIsExpanded(true)}>
                      {t('Read more')}
                    </span>
                  )}
                  {isExpanded && <span className="read-more" onClick={() => setIsExpanded(false)}>
                    (t{'Read less'})
                  </span>
                  }
                </div>
                <div className="message-timestamp">{formatTime(msg.timestamp)}</div>
              </div>
            )
        }
      </>
    );
  };

  const handleOptionClick = () => {
    setOpenOptions(!openOtions)
  }

  const folderOptionsRef = useOutsideClick(() => {
    setOpenOptions(false)
  });


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      filesArray.forEach((file) => {
        if (file.type.startsWith('image/')) {
          setImages([...images, file]);
        } else {

          setAttachments([...attachments, file]);
        }

      })
    }

  }

  const handleRemoveAttachment = (index: number) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };


  const handleDeleteChat = async (chatId: string) => {
    setIsDeleting(true)
    if(chatId){
      const resp: any = await dispatch(deleteMesageChat(chatId))

      if(resp === 200){
        await dispatch(fetchCHatList())
        setIsDeleting(true)
      }
    }
  }


  return (
    <div className="chat-window-content">
      <div className="chat-window-header">
        <div className='chat-window-subheaders'>
          <h3>{selectedChat && selectedChat.userData ? selectedChat.userData.FullName ? selectedChat.userData.FullName : selectedChat.userData.FirstName + " " + selectedChat.userData.LastName : selectedChat.FirstName + " " + selectedChat.LastName}</h3>
          <h5>{selectedChat && selectedChat.userData ? selectedChat.userData.CompanyRole ? selectedChat.userData.CompanyRole : " " : selectedChat.CompanyRole}</h5>
          <h6>{selectedChat && selectedChat.userData ? selectedChat.userData.PracticeArea ? selectedChat.userData.PracticeArea : " " : selectedChat.PracticeArea}</h6>
        </div>
        <div onClick={handleOptionClick} style={{ position: "relative", flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <button type='button' onClick={() => handleOptionClick()} style={{ background: "treansparent !important" }} className='ellipsis-btn'>
            <i className="fa-regular fa-ellipsis-v ignore-outside-click" style={{ fontSize: '20px' }}></i>
          </button>
          {openOtions && (
            <div className="employee-options" ref={folderOptionsRef} onClick={(e) => e.stopPropagation()}>
              {/* <div>
                <i className="fa-duotone fa-solid fa-broom-wide" style={{ color: '#aaa' }}></i>
                <p>{t('Clear Chat')}</p>
              </div> */}
              <button type='button' className={`destructive-button ${isDeleting ? "disabled": ""}`}  onClick={() => handleDeleteChat(selectedChat.chatID)}>
                <i className="fa-sharp fa-solid fa-trash" style={{ color: '#ccc' }}></i>
                <p style={{ margin: 0 }}>{t('Delete Chat')}</p>
                {
                  isDeleting && <ClipLoader size={10} color='#fff' />
                }
              </button>
            </div>
          )}
          </div>
        </div>
      {
        isLoading ? (<>
          <div className="chat-messages">
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", height: '100%  ' }}>

              <ClipLoader size={20} color='#fff' />
            </div>
          </div>
        </>) : (<>
          <div className="chat-messages">

            {Object.keys(groupedMessages).map((date) => (
              <React.Fragment key={date}>
                <div className="message-date">{date}</div>
                {groupedMessages[date].map((msg: any) => (
                  <Message key={msg.id} msg={msg} />
                ))}
              </React.Fragment>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </>)
      }


      <div className='form-chat-input'>
        {attachments.length > 0 && (
          <div className="attachments-preview__marketplace">
            {attachments.map((file, index) => (
              <div key={index} className="attachment-preview-item__marketplace">
                <span>{file.name}</span>
                <i onClick={() => handleRemoveAttachment(index)} className='fa-solid fa-square-xmark'></i>
              </div>
            ))}

          </div>
        )}
        {
          images && images.length > 0 && (
            <ChatImages attachments={images} />
          )
        }
        <div className="chat-input">
          <label htmlFor="file-upload" className="file-upload-label">
            <i className='fa-light fa-paperclip-vertical'></i>
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={t('Type a message...')}
          />
          <button onClick={handleSendMessage}>
            <i className="fas fa-paper-plane"></i> {t('Send')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
