import React from 'react';
import { useTimer } from './TimeProvider';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

const TimeTracker: React.FC = () => {
  const {
    time,
    isRunning,
    isPaused,
    selectedItems,
    tasks,
    cases,
    timeEntries,
    startTimer,
    stopTimer,
    pauseTimer,
    resumeTimer,
    setActiveList,
    handleItemClick,
    activeList,
  } = useTimer();
  const { t } = useTranslation();

  const formatTime = (seconds: number | string | null) => {
    if (seconds === null) return 'N/A';
    const totalSeconds = parseFloat(seconds as string);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = Math.floor(totalSeconds % 60);
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const formatNumber = (value: string) => {
    return value.toString().replace(/\B(?=(\d{3})+\b)/g, ' ');
  };

  return (
    <div className="time-tracker">
      <div className="time-tracker__header">
        <span className="time-tracker__time" style={{ color: isRunning ? '#fff' : '#aaa' }}>
          {formatTime(time)}
        </span>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <button
            className={`time-tracker__toggle-btn ${!isRunning && !isPaused ? 'disabled' : ''}`}
            onClick={() => stopTimer('Task completed')}
            disabled={!isRunning && !isPaused}
          >
            <i className="fas fa-stop"></i>
          </button>
          <button
            className={`time-tracker__toggle-btn ${
              selectedItems.length === 0 && !isRunning ? 'disabled' : ''
            }`}
            onClick={() =>
              isRunning && !isPaused
                ? pauseTimer()
                : isPaused
                ? resumeTimer()
                : startTimer()
            }
            disabled={selectedItems.length === 0 && !isRunning}
          >
            <i className={`fas ${isRunning && !isPaused ? 'fa-pause' : 'fa-play'}`}></i>
          </button>
        </div>
      </div>

      {!isRunning && !isPaused ? (
        <div className="time-tracker__list">
          {(activeList === 'tasks' ? tasks : cases).length === 0 ? (
            <div className="non-access" style={{ marginTop: 50 }}>
              {activeList === 'tasks' ? 'No tasks available' : 'No cases available.'}
            </div>
          ) : (
            (activeList === 'tasks' ? tasks : cases).map((item: any) => {
              const itemID = item.TaskID || item.CaseID;
              const isSelected = selectedItems.some((selectedItem) => {
                const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
                return selectedItemID === itemID;
              });

              return (
                <div
                  key={itemID}
                  className={`time-tracker__item ${isSelected ? 'selected' : ''}`}
                  onClick={() => handleItemClick(item)}
                >
                  {activeList === 'tasks' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 8, flex: 1 }}>
                      <span className="item-deadline">{item.TaskDeadline}</span>
                      <div className={`task-item--wrapper ${isSelected ? 'selected' : ''}`}>
                        <div className="item-header">
                          <p className="item-title">{item.TaskTitle}</p>
                          <p className="item-status">{item.Status}</p>
                        </div>
                          <Tooltip title={item.TaskDescription} arrow>
                            <p className="item-description">{item.TaskDescription}</p>
                          </Tooltip>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="item-header">
                        <div>
                          <p className="item-title">{`${item.CaseType} - ${formatNumber(
                            item.MaxBudget
                          )} ${item.Currency}`}</p>
                          <p className="item-country">{item.CountryOfJurisdiction}</p>
                        </div>
                        <div>
                          <p className="item-urgency">
                            {t('Urgency')}: {item.Urgency}
                          </p>
                        </div>
                      </div>
                      <p className="item-status">{item.Status}</p>
                    </>
                  )}
                </div>
              );
            })
          )}
        </div>
      ) : (
        <div className="time-tracker__entries">
          {timeEntries.length > 0 ? (
            <ul>
              {timeEntries.map((entry: any) => (
                <li key={entry.entry_id}>
                  <div className="time-tracker__entries--row">
                    <p>{t('Start time')}</p>
                    <p>{`${entry.start_time || 'N/A'}`}</p>
                  </div>
                  <div className="time-tracker__entries--row">
                    <p>{t('End time')}</p>
                    <p className="time-tracker--progress">
                      {`${entry.end_time || 'In Progress'}`}
                    </p>
                  </div>
                  <p className="time-tracker--desc">
                    {`${entry.description || 'No description provided'}`}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <div style={{ marginTop: 50 }} className="non-access">
              {t('No time entries available')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeTracker;
