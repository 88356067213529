import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EmployeeView.css';
import { Lawyer } from '../interface/IFirmLawyer';
import { useAppDispatch } from '../../../../store';
import { getLawfirmLawyerTasks, getLawfirmLawyerCases, completeLawfirmAssignedTask, deleteLawfirmAssignedTask } from '../../../../store/actions/LawfirmActions';
import AddTaskPanel from './AddTaskPanel'; 
import { getUserType } from '../../../../store/actions/ChatAction';
import { ClipLoader } from 'react-spinners';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCaseForViewing, setDetailsContent } from '../../../../store/actions/marketplace/activeViewActions';
import { ICaseItem } from '../../../Marketplace/Interfaces/ICaseItem';
import { Task } from '../../../Marketplace/Lawyer/LawyerSection';
import { IAdminCaseItem } from '../../../Marketplace/Interfaces/IAdminCaseItem';
import dayjs from 'dayjs';
import { Avatar } from '@mui/material';
import { collapseSidePanel, expandSidePanel } from '../../../../store/actions/sidePanelActions';

interface EmployeeViewProps {
  employee: Lawyer;
  setSelectedMyCase?: (value: string | null) => void; 
  isAddingTaskPanelOpen: boolean;
  setIsAddingTaskPanelOpen: (isAddingTaskPanelOpen: boolean) => void;
}

const EmployeeView: React.FC<EmployeeViewProps> = ({ employee, setSelectedMyCase, isAddingTaskPanelOpen, setIsAddingTaskPanelOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [cases, setCases] = useState<ICaseItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const endRef = useRef<HTMLDivElement>(null);
  const userType = getUserType();
  const location = useLocation();
  const history = useHistory();
  const [employeeOptions, setEmployeeOptions] = useState(false);
  
  useEffect(() => {
    if (!employee) return; 
    if (location.pathname === '/marketplace/lawyer/profile') {
      fetchCases();
    } else {
      fetchTasks();
    }
  }, [dispatch, employee?.Email, userType, location.pathname]); 

  const fetchTasks = async () => {
    if (!employee) return; 
    setIsLoading(true);
    const response = await dispatch(getLawfirmLawyerTasks(userType, employee.Email));
    if (response) {
      setTasks(response);
      setIsLoading(false);
    }
  };

  const fetchCases = async () => {
    if (!employee) return; 
    setIsLoading(true);
    const response = await dispatch(getLawfirmLawyerCases(userType, employee.Email));
    if (response) {
      setCases(response);
      setIsLoading(false);
    }
  };

  const handleCompleteTask = async (taskID: string) => {
    await dispatch(completeLawfirmAssignedTask(userType, taskID));
    fetchTasks();
  };

  const handleDeleteTask = async (taskID: string) => {
    await dispatch(deleteLawfirmAssignedTask(userType, taskID));
    fetchTasks();
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Available':
        return 'green';
      case 'Busy':
        return 'orange';
      case 'Unavailable':
        return 'red';
      default:
        return 'grey';
    }
  };

  const handleAddTaskClick = () => {
    dispatch(expandSidePanel());
    setIsAddingTaskPanelOpen(true);
  };

  const handleAddTaskCancel = () => {
    dispatch(collapseSidePanel());
    setIsAddingTaskPanelOpen(false);
  };

  const handleAddTaskSuccess = () => {
    dispatch(collapseSidePanel());
    setIsAddingTaskPanelOpen(false);
    fetchTasks();
  };

  const isIAdminCaseItem = (item: ICaseItem | IAdminCaseItem): item is IAdminCaseItem => {
    return (item as IAdminCaseItem).ClientMaxBudget !== undefined;
  };

  const handleMyCaseClick = (caseItem: ICaseItem | IAdminCaseItem) => {
    const caseId = isIAdminCaseItem(caseItem) ? caseItem.CaseID : caseItem.id;
    if (location.pathname !== '/marketplace/lawyer/cases') {
      history.push('/marketplace/lawyer/cases');
    }
    if (setSelectedMyCase) {
      setSelectedMyCase(caseId);
    }
    dispatch(selectCaseForViewing(caseItem));
    dispatch(setDetailsContent('MY_CASE_DETAILS'));
  };

  const formatDate = (dateString: string): string => {
    const date = dayjs(dateString, 'DD-MM-YYYY HH:mm:ss', true); 
    if (!date.isValid()) {
      return 'Invalid Date';
    }
    return date.format('DD MMMM YYYY'); 
  };

  const formatDeadline = (dateString: string): string => {
    const inputFormat = 'DD-MM-YYYY HH:mm';
  
    const date = dayjs(dateString, inputFormat, true);
  
    if (!date.isValid()) {
      return 'Invalid Date';
    }
  
    const outputFormat = 'DD MMMM YYYY HH:mm'; 
  
    return date.format(outputFormat);
  };

  const getInitials = (firstName?: string, lastName?: string): string => {
    const firstInitial = firstName?.charAt(0).toUpperCase() || '';
    const lastInitial = lastName?.charAt(0).toUpperCase() || '';
    return `${firstInitial}${lastInitial}`;
  };

  const handleOptions = () => { 
    setEmployeeOptions(!employeeOptions);
  }
  
  return (
    <div className={`employee-view-container ${isAddingTaskPanelOpen ? 'shrunk' : ''}`}>
      <div className='employee-view-container--header'>
      <h2>{t('Employee Details')}</h2>
      <div className='employee-options--wrapper'>
      <button className={`employee-options-btn ${employeeOptions === true ? 'open' : ''}`} onClick={handleOptions}>
      <i className='fas fa-ellipsis-vertical'></i>
      </button>
      {employeeOptions === true && (
        <div className='employee-options'>
        <button style={{ fontSize: '0.8rem' }} className='destructive-button'>{t('Revoke License')}</button>
        </div>
      )}
      </div>
      </div>
      <div className="employee-profile">
        <div className="employee-profile--header">
          <div className='employee-profile--header__first'>
          <Avatar
              src={employee.ProfilePicture ?? undefined}
              alt={`${employee.FirstName} ${employee.LastName}'s profile`}
              className="profile-picture"
              sx={{ width: 80, height: 80, fontSize: '1.5rem', borderRadius: 2, background: '#111', color: '#64d2ff', border: '1px solid #444' }} 
              variant="square" 
            >
              {getInitials(employee.FirstName, employee.LastName)} 
            </Avatar>
            <div className="employee-view--column">
              <p className="employeename">{`${employee.FirstName} ${employee.LastName}`}</p>
              <p className="employeeemail">{employee?.Email}</p>
              <p className="employeephone">{employee.PhoneNumber}</p>
            </div>
          </div>
          <div className="availability-container">
            {/* <div>
              <span className="status-indicator--firm-employee" style={{ backgroundColor: getStatusColor('Available') }}></span>
              {t('Available')}
            </div> */}
            <p className='employee--comapny-role'>{t(employee.CompanyRole)}</p>
          </div>
        </div>

        <div className="employee-view--row">
          <div className="employee-view--column">
            <p>{t('Practice Area')}</p>
            <div className="employee-profile__practicearea--wrapper">
              <span>{(employee.PracticeArea !== " ") ? t(employee.PracticeArea) : t('None')}</span>
            </div>
          </div>
          <div className="employee-view--column">
            <div>
              <p>{t('Onboarding Date')}</p>
              <p className="hire-date">{formatDate(employee.CreationDate)}</p>
            </div>
            <p>{employee.City}</p>
          </div>
        </div>
      </div>

      <div className="firm-tasks-container">
        <div className="firm-tasks--header">
          <h3>{location.pathname === '/marketplace/lawyer/profile' ? t('Assigned Cases') : t('Assigned Tasks')}</h3>
          {location.pathname !== '/marketplace/lawyer/profile' && (
            <button onClick={handleAddTaskClick} className="primary-button">
              {t('Add Task')}
            </button>
          )}
        </div>

        {location.pathname === '/marketplace/lawyer/profile' ? (
          // Display cases if URL is '/marketplace/lawyer/profile'
          cases && cases.length > 0 ? (
            <ul className='firm-tasks--ul'>
              {cases.map((caseItem: ICaseItem | IAdminCaseItem) => (
                <li className='firm-cases--item' key={caseItem.CaseID}>
                  <div className='firm-cases--details'>
                    <h2 className='firm-case--title'>{t(caseItem.CaseType)}</h2>
                    <p className='firm-case--budget'>{caseItem.MaxBudget}</p>
                  </div>
                  <div className='firm-cases--status'>
                    <p className='firm-case--status'>{t(caseItem.Status as string)}</p>
                    <button onClick={() => handleMyCaseClick(caseItem)} className='firm-case--cta-button'>{t('View case')}</button>
                  </div>
                </li>
              ))}
            </ul>
          ) : isLoading ? (
            <div style={{ height: '20vh' }} className='non-access'>
              <ClipLoader size={20} color='#fff' />
            </div>
          ) : (
            <div className='non-access'>{t('No cases assigned')}</div>
          )
        ) : (
          // Display tasks if URL is not '/marketplace/lawyer/profile'
          tasks && tasks.length > 0 ? (
            <ul className='firm-tasks--ul'>
              {tasks.map(task => (
                <li key={task.TaskID} className='firm-tasks--item'>
                  <div className='firm-tasks--item--wrapper'>
                  <div className='firm-tasks--details'>
                    <h2 className='firm-task--title'>{task.TaskTitle}</h2>
                    <p className='firm-task--deadline'>{formatDeadline(task.TaskDeadline)}</p>
                  </div>
                  <div className="firm-tasks-actions">
                    {/* <button className='secondary-button' onClick={() => handleCompleteTask(task.TaskID)}>{t('Complete')}</button> */}
                    <button className='destructive-button' onClick={() => handleDeleteTask(task.TaskID)}>{t('Delete')}</button>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='firm-task--desc'>{task.TaskDescription}</p>
                    </div>
                </li>
              ))}
            </ul>
          ) : isLoading ? (
            <div style={{ height: '20vh' }} className='non-access'>
              <ClipLoader size={20} color='#fff' />
            </div>
          ) : (
            <div className='non-access'>{t('No tasks assigned')}</div>
          )
        )}

        {/* Nested Add Task Side Panel */}
        {isAddingTaskPanelOpen && (
          <AddTaskPanel 
            selectedEmployeeEmail={employee.Email} 
            onCancel={handleAddTaskCancel} 
            onAdd={handleAddTaskSuccess} 
          />
        )}

        {/* Existing Add Task Section - Remove if not needed */}
        {/* 
        {showAddTask && (
          <div className="add-task-section">
            <AddTaskPanel selectedEmployeeEmail={employee.Email} onCancel={() => setShowAddTask(false)} onAdd={handleAddTaskSuccess} />
          </div>
        )}
        */}

        <div ref={endRef}></div>
      </div>
    </div>
  );
};

export default EmployeeView;
