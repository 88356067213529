import React, { useEffect,useState } from 'react';
import './Header.css';
import { NavLink, useHistory, useLocation} from 'react-router-dom';
import { signOut } from '../../store/actions/SignInAction';
import { useDispatch } from 'react-redux';
import { RootState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import DropdownMenu from '../AIAssistant/DropdownMenuModel';
import RoleSelection from '../Marketplace/RoleSelection';
import { getUserType } from '../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setActiveRole, setSliderPosition } from '../../store/actions/settingsRoleAction';
import ChatInterface from '../SupportChat/ChatInterface';
import { config } from '../../utils/config';
import { getAISettings } from '../../store/actions/DataAction';
import RoleDropdown from './RoleDropdown';
import TimeTracker from '../TimeTracker/TimeTracker';
import { isWindows } from 'react-device-detect';
import { Tooltip } from '@mui/material';

type HeaderProps = {
  activeView: string;
  setActiveView: (activeView: string) => void;
}

const Header: React.FC<HeaderProps> = ({ activeView, setActiveView }) => {
  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [isChatAgentVisible, setChatAgentVisible] = useState(false);
  const [isNotificationDropdownVisible, setNotificationDropdownVisible] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const history = useHistory();
  const { activeRole } = useSelector((state: RootState) => state.role);
  const handleLogout = async () => {
    
    history.push("/")
    await dispatch(signOut());
  }
  const [activeRoute, setActiveRoute] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  // const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings)
  const { t } = useTranslation()
  const [isClockDropdownVisible, setClockDropdownVisible] = useState(false);
  // const isRunning = useSelector((state: RootState) => state.time.isRunning)
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
  || window.location.hostname.includes('beta.pons.io');
  let isRunning = false
  const savedTimerState = localStorage.getItem('timerState');
  if(savedTimerState){
    isRunning = JSON.parse(savedTimerState)
  }

  useEffect(() => {
    {!aiSettings && (
      dispatch(getAISettings(userType))
    )}
  }, [aiSettings])

  const dummyDocsData = [
    "Annual Financial Report 2022",
    "Employee Handbook 2023",
    "Project Alpha Overview",
    "Marketing Strategy Plan Q1",
    "Customer Feedback Analysis Q3",
    "Software Development Guidelines",
    "Data Security Compliance Policy",
    "Meeting Minutes: Executive Session Jan",
    "Employee Performance Reviews 2022",
    "Quarterly Sales Forecast Report",
    "Corporate Sustainability Strategy",
    "User Interface Design Standards",
    "IT Infrastructure Upgrade Proposal",
    "Product Launch Checklist",
    "Market Research Summary 2023"
  ];

  const dummyPlatformData = [
    "Orion Project Management",
    "Atlas HR Portal",
    "Zeus Accounting System",
    "Hermes Communication Hub",
    "Athena Learning Center",
    "Apollo Sales Tracker",
    "Poseidon Inventory Management",
    "Demeter Environmental Compliance",
    "Artemis Customer Insights",
    "Dionysus Event Planning",
    "Hades Risk Assessment Tool",
    "Helios Energy Dashboard",
    "Nyx Night Operations Monitor",
    "Eros Client Relationship Manager",
    "Chronos Timekeeping Suite"
  ];



  const filteredDocsData = dummyDocsData.filter(item =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredPlatformData = dummyPlatformData.filter(item =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const combinedData = [...filteredDocsData, ...filteredPlatformData];

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        setSelectedIndex(prevIndex => Math.min(prevIndex + 1, combinedData.length - 1));
      } else if (e.key === 'ArrowUp') {
        setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0));
      } else if (e.key === 'Enter' && selectedIndex >= 0 && selectedIndex < combinedData.length) {
        setSearchQuery(combinedData[selectedIndex]);
        saveToSearchHistory(combinedData[selectedIndex]);
      }
    };

    const inputElement = document.querySelector('.header-search-bar') as HTMLInputElement;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [selectedIndex, combinedData]);



  const saveToSearchHistory = (query: string) => {
    // setSearchHistory(prevHistory => {
    //   const newHistory = [...prevHistory, query];
    //   return newHistory.slice(Math.max(newHistory.length - 5, 0));
    // });
  };





  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!isProfileDropdownVisible);
    setNotificationDropdownVisible(false)
    setChatAgentVisible(false);
    setClockDropdownVisible(false)
    if (searchQuery !== "") {
      setProfileDropdownVisible(false)
    }
  };

  const toggleChatAgentDropdown = () => {
    setChatAgentVisible(!isChatAgentVisible);
    setNotificationDropdownVisible(false)
    setProfileDropdownVisible(false);
    setClockDropdownVisible(false)
    if (searchQuery !== "") {
      setProfileDropdownVisible(false)
    }
  };

  const toggleNotificationDropdown = () => {
    setNotificationDropdownVisible(!isNotificationDropdownVisible);
    setProfileDropdownVisible(false)
    setChatAgentVisible(false);
    setClockDropdownVisible(false)
    if (searchQuery !== "") {
      setNotificationDropdownVisible(false)
    }
  };

  const toggleClockDropdown = () => {
    setClockDropdownVisible(!isClockDropdownVisible)
    setNotificationDropdownVisible(false);
    setProfileDropdownVisible(false)
    setChatAgentVisible(false);
    if (searchQuery !== "") {
      setNotificationDropdownVisible(false)
    }
  };

  const getInitials = (firstName = '', lastName = '') => {
    const firstInitial = firstName.length > 0 ? firstName[0] : 'U';
    const lastInitial = lastName.length > 0 ? lastName[0] : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };


  const userInitials = getInitials(userInfo.FirstName, userInfo.LastName);

  const location = useLocation()

  const determineActiveIndex = (): number => {
    if (location.pathname.startsWith("/marketplace")) {
      return 2;
    }
    if (location.pathname.startsWith("/settings/")) {
      return 5
    }
    switch (location.pathname) {
      case "/dashboard": return 0;
      case "/ai-assistant": return 1;
      case "/documents": return 3;
      case "/analytics": return 4;
      case "/help": return 6;
      case "/feedback": return 7;
      default: return 0;
    }
  }

  const [index, setIndex] = useState(determineActiveIndex());




  const getRoleFromPath = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    if (pathSegments[0] === "settings" && pathSegments.length > 1) {
      return pathSegments[1];
    }
    return 'account'; 
  };
  

  const handleToggle = (selectedRole: string) => {
    dispatch(setActiveRole(selectedRole));
    history.push(`/settings/${selectedRole}`);
    updateSliderPosition(selectedRole);
};

const updateSliderPosition = (role: string) => {
    const roles = ['account', 'security', 'finance'];
    const roleIndex = roles.indexOf(role);
    const optionWidth = 250 / roles.length;
    const sliderLeftPosition = optionWidth * roleIndex;

    dispatch(setSliderPosition(sliderLeftPosition));
};

useEffect(() => {
  const roleFromUrl = getRoleFromPath();
  dispatch(setActiveRole(roleFromUrl));
  updateSliderPosition(roleFromUrl);
}, [location, dispatch]);



  useEffect(() => {
    const newIndex = determineActiveIndex();
    if (index !== newIndex) {
      setIndex(newIndex);
    }
  }, [location, index]);

  const sliderClass = () => {
    const options = ['account', 'security', 'finance'];
    const activeIndex = options.indexOf(activeRole);
    return `settings-slider settings-${activeIndex}`;
  };

  const upgradeInfo = (userType: string) => {
    switch (userType) {
      case "Client":
        return { text: t('Upgrade Plan'), iconClass: 'fas fa-signal-bars' };
      case "BusinessAdmin":
        return { text: t('Manage Licenses'), iconClass: 'fas fa-layer-plus' };
      case "Employee":
        return { text: t('Request Access'), iconClass: 'fas fa-user-plus' };
      case "LawfirmAdmin":
        return { text: t('Manage Licenses'), iconClass: 'fas fa-layer-plus' };
      case "Lawyer":
        return { text: t('Upgrade License'), iconClass: 'fas fa-user-tie' };
      case "Lawfirmlawyer":
        return { text: t('Request Plan Upgrade'), iconClass: 'fas fa-clipboard-list' };
      default:
        return { text: '', iconClass: '' };
    }
  }  
  const { text: upgradeText, iconClass: upgradeIconClass } = upgradeInfo(userType);

  return (
    <div className="header">
      <div>
        {index === 1 && <div className='sidebar-identifier__h1'> <DropdownMenu /> </div>}
        {/* {(index === 2) && (aiSettings?.CountryOfJurisdiction === "Norway") && <RoleSelection
          userType={userType}

        />} */}
        {(index === 4) && (config.baseUrl === "https://dev.api.pons.io/" || !isDemoEnvironment) && 
            <button className='header-download--analytics primary-button'><span>{t('Download Analytics')}</span><i className='fas fa-cloud-arrow-down'></i></button>
        }
        {/* {index === 5 && <div className=''>

          <div className="settings-header">
            <div className={`settings-option ${activeRole === 'account' ? 'active' : ''}`} onClick={() => handleToggle('account')}>{t('Account')}</div>
            <div className={`settings-option ${activeRole === 'security' ? 'active' : ''}`} onClick={() => handleToggle('security')}>{t('Security')}</div>
            {(userType === "LawfirmAdmin" || userType === "BusinessAdmin") ? (
              <div className={`settings-option ${activeRole === 'finance' ? 'active' : ''}`} onClick={() => handleToggle('finance')}>{t('Company')}</div>
            ) : (
            <div className={`settings-option ${activeRole === 'finance' ? 'active' : ''}`} onClick={() => handleToggle('finance')}>{t('Finance')}</div>
          )}
            <div className={sliderClass()}></div>
          </div>

        </div>
        } */}
      </div>
      <div className='header-end'>
        <div className='header-icons'>
              {(userType === "Lawfirmlawyer") && (
                <Tooltip title={t('Time keeper')}>
                <div className="header-dropdown ">
                <div onClick={toggleClockDropdown} className={`header-icon-wrapper ${isClockDropdownVisible ? 'active' : ''}`}>
          <i style={{ fontSize: 28, color: isRunning ? '#fff' : '#444', borderBottom: '2px transparent solid', transition: '0.3s ease all' }} className='far fa-clock'></i>
          </div>
          {isClockDropdownVisible && (
          <div className={`header-dropdown__content--clock ${isClockDropdownVisible ? 'visible' : ''}`}>
            <TimeTracker />
          </div>
          )}
          </div>
          </Tooltip>
        )}
        <div className="header-dropdown ">
        <Tooltip title={t('Platform bot is coming soon')}>
        {/* onClick={toggleChatAgentDropdown} */}
            <div className={`header-icon-wrapper bot ${isChatAgentVisible ? 'active' : ''}`}>
              <i style={{ fontSize: 28, color: '#666',  borderRadius: 100  }} className={`fas fa-message-bot`}></i>
              </div>
              </Tooltip>
              {isChatAgentVisible && (
                <div className='header-dropdown__content-chatbot'>
                          <ChatInterface />
              </div>
              )}
              </div>
              
              {1 > 2 && (

          <div className="header-dropdown " onClick={toggleNotificationDropdown}>
            <div className={`header-icon-wrapper bell ${isNotificationDropdownVisible ? 'active' : ''}`}>

              <i className={`bi bi-bell-fill header-bell-icon ${isNotificationDropdownVisible ? 'active' : ''}`}></i>
            </div>
            {/* {isNotificationDropdownVisible &&
              <div className='header-seperator'></div>
            } */}
            <div className={`header-dropdown__content-notifications ${isNotificationDropdownVisible ? 'visible' : ''}`}>
              <a href="http://localhost:3000">Signatue awaiting <i className="bi bi-pen"></i></a>
              <a href="http://localhost:3000">Notication <i className="bi bi-exclamation-lg"></i></a>
              <a href="http://localhost:3000">Pons 1.12 Upgrade Available <i className="bi bi-gear-fill"></i></a>
              <a href="http://localhost:3000">Notication <i className="fa-regular fa-file-contract"></i></a>
              <a href="http://localhost:3000">Notication</a>
              <a href="http://localhost:3000">Notication</a>
              <a href="http://localhost:3000">Notication</a>
              <a href="http://localhost:3000">Notication</a>
              <a href="http://localhost:3000">Notication</a>
            </div>
          </div>
              )}

          {(userType === "LawfirmAdmin" || userType === "BusinessAdmin" || userInfo.SSO === "switch") && (
          <RoleDropdown  />
        )}

<div className="header-dropdown" onClick={toggleProfileDropdown}>
      <div className={`header-icon-wrapper user ${isProfileDropdownVisible ? 'active' : ''}`}>
        <div className={`user-initials-circle ${isProfileDropdownVisible ? 'active' : ''}`}>
          {userInitials}
        </div>
      </div>
      <div className={`header-dropdown__content ${isProfileDropdownVisible ? 'visible' : ''}`}>
        <NavLink
          to="/settings/account"
          activeClassName="selected"
          onClick={() => setActiveRoute('/settings/account')}
        >
          <i
            style={{ cursor: 'pointer' }}
            className={`bi bi-person ${isProfileDropdownVisible || activeRoute === '/settings/account' ? 'active' : ''}`}
          ></i>
          <p>{t('Account')}</p>
        </NavLink>

        <NavLink
          to="/settings/security"
          activeClassName="selected"
          onClick={() => setActiveRoute('/settings/security')}
        >
          <i
            style={{ cursor: 'pointer' }}
            className={`bi bi-shield-lock ${isProfileDropdownVisible || activeRoute === '/settings/security' ? 'active' : ''}`}
          ></i>
          <p>{t('Security')}</p>
        </NavLink>

        <NavLink onClick={handleLogout} to="/">
          <i style={{ cursor: 'pointer' }} className="bi bi-box-arrow-left"></i>
          <p>{t('Log out')}</p>
        </NavLink>

        <div style={{ paddingTop: 10, borderTop: '1px solid #64d2ff' }}>
          <Tooltip title={t('Coming soon')}>
          <a
            // to="/plans"
            // activeClassName="selected"
            // onClick={() => setActiveRoute('/settings/finance')}
          >
            <i
              style={{ cursor: 'pointer' }}
              className={`${upgradeIconClass} ${isProfileDropdownVisible || activeRoute === '/settings/finance' ? 'active' : ''}`}
            ></i>
            <p>{upgradeText}</p>
          </a>
          </Tooltip>
        </div>
      </div>
    </div>
    </div>

      </div>
    </div>
  );
};

export default Header;
