import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
  ChartData,
} from 'chart.js';

import './CreditTracker.css';

import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

interface CreditTrackerProps {
  totalCredits: number;
  initialUsedCredits?: number;
  onBuyMoreCredits?: () => void;
}

const CreditTracker: React.FC<CreditTrackerProps> = ({ totalCredits, initialUsedCredits = 0 }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const data: ChartData<"bar", number[], string> = {
    labels: [`${t('Legal Case Mapping')}`, `${t('Law Research')}`, `${t('Contract Generation')}`, `${t('Contract Analysis')}`, `${t('digitalSignature')}`, `${t('Shared Access')}`],
    datasets: [
      {
        label:`${t('Services Used')}`,
        data: [12, 19, 3, 5, 25, 3, 10, initialUsedCredits],
        backgroundColor: '#64d2ff',  
        borderColor: 'rgba(100, 210, 255, 0.2)',
        borderRadius: 4,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: '#0D0D12'
        },
        ticks: {
          color: '#fff'
        }
      },
      x: {
        grid: {
          color: '#0D0D12'
        },
        ticks: {
          color: '#fff',
          display: false
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
    }
  };

  const generateTags = () => {
    return data.labels?.map((label, index) => {
      const value = data.datasets[0].data[index];
      return (
        <div key={label} className="credit-tracker__tag">
          <span style={{ color: '#fff' }}>{label}</span> <p>{value}</p>
        </div>
      );
    });
  };

  return (
    <div className="token-tracker__container">
      <div className="token-tracker__chart" key={windowWidth}>
        <Bar data={data} options={options} />
      </div>
      <div className="token-tracker__header">
        <h3>{t('Legal Service Usage')}</h3>
        <div className='credit-counter__wrapper'>
          {generateTags()}
        </div>
      </div>
    </div>
  );
};

export default CreditTracker;
