
import { useEffect, useState } from 'react';
import './AdminBusiness.css'
import { useTranslation } from 'react-i18next';
import SuggestedFilesContainer from '../../Documents/SuggestedFilesContainer';
import { FileProps } from '../../../pages/DocumentsPage';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { selectDocumentForEditing } from '../../../store/actions/DocumentsAction';
import TaskDeadlineTracker from '../../shared/TaskDeadlineTracker';

const PanelBusiness = () => {
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const [isServicePanelOpen, setIsServicePanelOpen] = useState<boolean>(false);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const history = useHistory();
    const {t} = useTranslation()
    
    

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const toggleServicePanel = () => {
        setIsServicePanelOpen(!isServicePanelOpen);
    };

    const handleDocumentClick = (document: FileProps) => {
        dispatch(selectDocumentForEditing(document));
        history.push('/documents');
    };


    return (
        <div className="business-admin-main__container">
            <div className={`backdrop ${(isEditPanelOpen || isPanelOpen || isServicePanelOpen) ? 'show' : ''}`} onClick={() => togglePanel()}></div>
            <div className='business-container--section-row'>
                <div className="business-container" style={{ flex: 1, backgroundColor: 'transparent' }}>
                    <TaskDeadlineTracker />
                    </div>
                <div style={{ flex: 1, height: 'calc(100vh / 2.5)', backgroundColor: '#1e1f21', borderRadius: 8 }}>
                <SuggestedFilesContainer setSelectedFile={handleDocumentClick} />
                </div>
                </div>
        </div>
    )


}


export default PanelBusiness;