import {jwtDecode} from 'jwt-decode';
let baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
let socketUrl = process.env.REACT_APP_SOCKET_URL
let paymentUrl = process.env.REACT_APP_PAYMENTS_URL

export const config = {
    baseUrl:baseUrl,
    payment_url: paymentUrl,
    socket_url: socketUrl
};


export const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };