import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { anonymizeDocument } from '../../store/actions/DocumentsAction';
import { hideModal } from '../../store/actions/modalActions';
import { RootState, useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';
import './AnonymizeDocumentModal.css';
import { useTranslation } from 'react-i18next';

interface AnonymizeDocumentModalProps {
  fileID: string | undefined;
}

const AnonymizeDocumentModal: React.FC<AnonymizeDocumentModalProps> = ({ fileID }) => {
  const dispatch = useAppDispatch();
  const datasets: string[] = useSelector((state: RootState) => state.documents.anonymizationDatasets);
  const loading = useSelector((state: RootState) => state.documents.loading);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    }
  };

  const handleSubmit = () => {
    dispatch(anonymizeDocument(fileID!, selectedOptions));
    dispatch(hideModal());
  };

  return (
    <div className="anonymize-modal">
      {loading ? (
        <div className="loader-container">
          <ClipLoader size={20} color="#fff" loading={true} />
        </div>
      ) : (
        <>
          <div className="options-list">
            {datasets && datasets.length > 0 ? (
              datasets.map((field, index) => (
                <label key={index} className="custom-checkbox-container">
                  <span>{field}</span>
                  <input
                    type="checkbox"
                    value={field}
                    checked={selectedOptions.includes(field)}
                    onChange={handleOptionChange}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              ))
            ) : (
              <p>{t('No fields available for anonymization.')}</p>
            )}
          </div>
          <div className="modal-actions">
            <button className="primary-button" onClick={handleSubmit} disabled={selectedOptions.length === 0}>
              {t('Anonymize Document')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AnonymizeDocumentModal;
