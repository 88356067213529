import React from 'react';
import './RevenueDetailsTable.css';
import { useTranslation } from 'react-i18next';

const revenueData = [
    { id: 1, month: 'January', revenue: 15000, expenses: 5000, profit: 10000 },
    { id: 2, month: 'February', revenue: 16000, expenses: 6000, profit: 10000 },
    { id: 3, month: 'March', revenue: 20000, expenses: 7000, profit: 13000 },
    { id: 4, month: 'April', revenue: 22000, expenses: 8000, profit: 14000 },
    { id: 5, month: 'May', revenue: 18000, expenses: 5500, profit: 12500 },
    { id: 6, month: 'June', revenue: 21000, expenses: 7500, profit: 13500 },
];

const RevenueDetailsTable = () => {
    const {t} = useTranslation()
    return (
        <div className="revenue-table">
            <div className="revenue-header">
                <span>{t('Month')}</span>
                <span>{t('Revenue')}</span>
                <span>{t('Expenses')}</span>
                <span>{t('Profit')}</span>
            </div>
            {revenueData.map((item) => (
                <div key={item.id} className="revenue-row">
                    <span>{t(item.month)}</span>
                    <span className='revenue-revenue-tag'>${item.revenue.toLocaleString()}</span>
                    <span className='revenue-expenses-tag'>${item.expenses.toLocaleString()}</span>
                    <span className='revenue-profit-tag'>${item.profit.toLocaleString()}</span>
                </div>
            ))}
        </div>
    );
};

export default RevenueDetailsTable;
