import React, { useEffect, useState } from "react";
import './SupportTicketSystem.css';
import { useTranslation } from "react-i18next";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { createTicket, fetchAllUserTickets, fetchCategories, fetchTicket } from "../../store/actions/HelpAction"; 
import { Formik } from "formik";
import * as Yup from "yup";
import GlobalDropdown, { DropdownOption } from "../shared/GlobalDropdown";
import { ClipLoader } from "react-spinners";
import { getUserType, getUserID } from "../../store/actions/ChatAction";
import TicketDetail from "./TicketDetail"; 
import { expandSidePanel, collapseSidePanel } from "../../store/actions/sidePanelActions"; 

export interface TicketItem {
  category: string;
  client_email: string;
  created_at: string;
  description: string;
  resolved: boolean;
  status: string;
  subject: string;
  ticket_id: string;
  updated_at: string;
  user_id: string;
  user_type: string;
}

const SupportTicketSystem: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const userId = getUserID();
  const [categoryOptions, setCategoryOptions] = useState<DropdownOption[]>([]);
  const categories = useSelector((state: RootState) => state.help.categories);
  const userTickets = useSelector((state: RootState) => state.help.allUserTickets);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(false);
  
  const [selectedTicket, setSelectedTicket] = useState<TicketItem | null>(null);
  const [isTicketLoading, setIsTicketLoading] = useState(false);

  useEffect(() => {
    setIsCategoriesLoading(true);
    dispatch(fetchCategories()).finally(() => setIsCategoriesLoading(false));

    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  }, [dispatch]);


  useEffect(() => {
    if (categories) {
      const options = categories.map((category: { id: string; label: string }) => ({
        id: category.id,
        label: category.label,
      }));
      setCategoryOptions(options);
    }
  }, [categories]);

  const initialValues = {
    Subject: '',
    Description: '',
    Category: ''
  };

  const validationSchema = Yup.object({
    Subject: Yup.string().required(t('Subject is required')),
    Description: Yup.string().required(t('Description is required')),
    Category: Yup.string().required(t('Category is required')),
  });

  const onSubmit = async (values: any, { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void }) => {
    const ticketData = {
      // UserID: userId,
      UserType: userType,
      ...values
    };
    await dispatch(createTicket(ticketData)).then(() => {
      resetForm(); 
    }).finally(() => setSubmitting(false));
    setIsTicketsLoading(true);
    dispatch(fetchAllUserTickets()).finally(() => setIsTicketsLoading(false));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Open":
        return "rgb(66, 212, 66)"; 
      case "Resolved":
        return "#64d2ff"; 
      default:
        return "#ccc"; 
    }
  };

  // Handler for ticket click
  const handleTicketClick = async (ticketId: string) => {
    setIsTicketLoading(true);
    try {
      const resp = await dispatch(fetchTicket(ticketId));
      const fetchedTicket = resp.payload as TicketItem;
      if (fetchedTicket) {
        setSelectedTicket(fetchedTicket);
        dispatch(expandSidePanel()); // Expand the side panel
      } else {
        alert(t('Failed to fetch ticket details.'));
      }
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      alert(t('An error occurred while fetching ticket details.'));
    } finally {
      setIsTicketLoading(false);
    }
  };

  // Handler to close the ticket detail view
  const handleCloseTicketDetail = () => {
    setSelectedTicket(null);
    dispatch(collapseSidePanel()); // Collapse the side panel
  };

  return (
    <div className="support-ticket-system">
                {selectedTicket && (
            <div className="ticket-detail-container open">
              <TicketDetail ticket={selectedTicket} onClose={handleCloseTicketDetail} isLoading={isTicketLoading} />
            </div>
          )}
          <div className="support-ticket-system--wrapper">
      <h2 className="header-support">{t('Support Ticket')}</h2>
      <span className="subheader-support">{t('Submit your support ticket and we will get back to you within 24 hours.')}</span>
      <div className="help-support--row">
        {/* Formik Form for Submitting Tickets */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit} className="support-ticket-form">
              <div className="support-ticket-form--row">
                <div className="form-group--support">
                  <label htmlFor="Subject">{t('Subject')}</label>
                  <input
                    type="text"
                    id="Subject"
                    name="Subject"
                    value={values.Subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.Subject && touched.Subject ? 'input-error' : ''}
                  />
                  {errors.Subject && touched.Subject && <div className="error-message">{errors.Subject}</div>}
                </div>
                <div className="form-group--support">
                  <label htmlFor="Category">{t('Category')}</label>
                  <GlobalDropdown
                    options={categories && categories.length > 0 ? categories : []}
                    onSelect={(id) => setFieldValue('Category', id)}
                    selectedLabel={values.Category ? (categories &&  categories.find((option: {id: string, label: string}) => option.id === values.Category)?.label) || '' : ''}
                    style={{ width: '100%' }}
                    headerStyle={{ width: '90%', height: 30 }}
                  />
                  {errors.Category && touched.Category && <div className="error-message">{errors.Category}</div>}
                </div>
              </div>
              <div className="form-group--support">
                <label htmlFor="Description">{t('Description')}</label>
                <textarea
                  id="Description"
                  name="Description"
                  value={values.Description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.Description && touched.Description ? 'input-error' : ''}
                />
                {errors.Description && touched.Description && <div className="error-message">{errors.Description}</div>}
              </div>
              <button className="primary-button" type="submit" disabled={isSubmitting}>
                {isSubmitting ? t('Submitting...') : t('Submit Ticket')}
              </button>
            </form>
          )}
        </Formik>

          <div className={`ticket-list-container ${selectedTicket ? 'ticket-list-shrink' : ''}`}>
            <h3>{t('Your Tickets')}</h3>
            <ul className="ticket-list">
              {isTicketsLoading ? (
                <div className="loader-container">
                  <ClipLoader size={10} color={"#fff"} />
                </div>
              ) : (
                userTickets && userTickets.length > 0 ? (
                  userTickets.map((ticket: TicketItem) => (
                    <li
                      key={ticket.ticket_id}
                      className={`ticket-item ${selectedTicket && selectedTicket.ticket_id === ticket.ticket_id ? 'active' : ''}`}
                      onClick={() => handleTicketClick(ticket.ticket_id)}
                    >
                      <div className="ticket-subject">{ticket.subject}</div>
                      <div className="ticket-status" style={{ color: getStatusColor(ticket.status) }}>
                        {t(ticket.status)}
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="non-access">
                    {t('No tickets')}
                  </div>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketSystem;
