import { useState } from 'react';
import '../../Marketplace.css';
import { useTranslation } from 'react-i18next';


type Document = {
  name: string;
  size: string;
};

type Documents = {
  registration: Document[];
  expertises: Document[];
  cases: Document[];
};

type ExpandedSections = {
  [key: string]: boolean;
};


const MyLegalDocuments = () => {
  const documents: Documents = {
    registration: Array.from({ length: 3 }, (_, i) => ({ name: `Registration Document ${i + 1}.pdf`, size: `${i + 2}MB` })),
    expertises: Array.from({ length: 7 }, (_, i) => ({ name: `Expertise Document ${i + 1}.pdf`, size: `${i + 1}MB` })),
    cases: Array.from({ length: 55 }, (_, i) => ({ name: `Case Document ${i + 1}.pdf`, size: `${i + 3}MB` })),
  };
  const { t } = useTranslation();

  const [expandedSections, setExpandedSections] = useState<ExpandedSections>({});

  const toggleSection = (section: string) => {
    setExpandedSections(prev => ({ ...prev, [section]: !(prev[section] ?? false) }));
  };

  return (
    <div className="legal-documents-container">
      <div className='legal-documents-container__header'>
      <div></div>
      <h2>{t('My Legal Documents')}</h2>
      <div className='add-document__icon'>
        <i className='fa-solid fa-plus'></i>
      </div>
      </div>
      <div className="search-bar-wrapper">
        <input
          className="legal-documents__search-bar"
          placeholder={t('Search for documents...')}
        />
      </div>
      <div className="documents-scroll-container__lawyer" style={{ overflowY: 'auto' }}>
        {Object.entries(documents).map(([section, docs]) => (
          <div key={section} className="legal-documents__section-container">
            <button className="legal-documents__section-header" onClick={() => toggleSection(section)}>
              <span className="legal-documents__section-title">{t(section.charAt(0).toUpperCase() + section.slice(1))}</span>

              {expandedSections[section] ? <i className='fa-regular fa-chevron-up'></i> : <i className='fa-regular fa-chevron-down'></i>}
            </button>
            {expandedSections[section] && docs.map((doc, index) => (
              <div key={index} className="legal-document__item">
                <span className="document-text">{doc.name} - {doc.size}</span>
                <div className="document-actions">
                  <button className='download' onClick={() => alert('Download document')}>{t('Download')}</button>
                  <button className='cancel' onClick={() => alert('Delete document')} style={{ marginLeft: '10px' }}>{t('Delete')}</button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default MyLegalDocuments;