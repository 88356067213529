import {useEffect, useState } from 'react';
import '../Settings.css';
import ConfirmationDialog from '../ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { hideModal, showModal } from '../../../store/actions/modalActions';
import { addSignature } from '../../../store/actions/DocumentsAction';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { useSelector } from 'react-redux';
import { disable2FA, forgotPassword, generateQr, signOut, verify2faQr } from '../../../store/actions/SignInAction';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import VerifyButton from './StripeVerificationButton';
import { ClipLoader } from 'react-spinners';
import { userInformation } from '../../../store/actions/DataAction';
import SignatureModal from './DigitalSignature';
import { generateCode, getActiveSessions } from '../../../store/actions/SecurityActions';
import SocketServices from '../../../utils/SocketServices';

const stripePromise = loadStripe('pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw');


const SecuritySettings = () => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deviceToSignOut, setDeviceToSignOut] = useState(null);
    const dispatch = useAppDispatch();
    const [inputData, setInputData] = useState('');
    const [signature, setSignature] = useState<string | null>(null);
    const userId = getUserID()
    const userType = getUserType()
    const { t } = useTranslation()
    const userInfo = useSelector((state: RootState) => state.user.userInfo)
    const [isVerified, setIsVerified] = useState(false)
    const [message, setMessage] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [smsLoading, setSMSLoading] = useState(false);
    const devices = useSelector((state: RootState) => state.security.sessions)
    const own_session_id = sessionStorage.getItem("session_id")

    useEffect(() => {
        dispatch(getActiveSessions())
        if (isVerified) {
            const timeoutId = setTimeout(() => {
                dispatch(signOut()).then(() => {
                    history.push('/');
                });
            }, 5000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

    }, [isVerified, dispatch, history])

    const confirmSignOut = () => {
        if (deviceToSignOut !== null) {
        }
        setConfirmModalOpen(false);
    };

    const cancelSignOut = () => {
        setConfirmModalOpen(false);
    };

    const handleSignOutConfirm = (session_id: string) => {
        dispatch(showModal({
            type: 'alert',
            message: t('Are you sure you want to sign out from this device?'),
            onConfirm: async (session_id: File | string) => {
                if (session_id) {
                    
                    SocketServices.emit('logout_session', { "session_id": session_id })
                    if(own_session_id !== session_id){
                        dispatch(getActiveSessions())
                    }
                    
                }
            },
            onCancel: handleCancel,
            inputData: session_id,
            showModal: true,
        }));
    };



    const handleEkyc = async () => {
        setLoading(true)
        try {

            const response: any = await dispatch(generateQr(userId, userType, userInfo.Email))

            if (response) {
                setLoading(false)
                dispatch(showModal({
                    type: "2fa-qrcode",
                    message: t("Enable Two-Factor Authentication (2FA)"),
                    onConfirm: async (verificationCode: File | string) => {
                        if (verificationCode) {
                            const resp: any = await dispatch(verify2faQr(verificationCode, userId, userType))
                            if (resp) {
                                if (resp.code === "E0044") {
                                    setMessage(`${resp.message}! Please ensure you have scan the qr and entered code correctly!`)
                                    setConfirmModalOpen(true);

                                } else {
                                    setIsVerified(true)
                                    dispatch(hideModal())
                                    setMessage(`${resp.message}! You will be automatically signed out in 5 sec !`)
                                    setConfirmModalOpen(true);
                                }
                            }

                        } else {
                            setConfirmModalOpen(true);
                            setMessage(`Please enter verification code to enable two factor authentication (2FA)!`)
                        }
                    },
                    onCancel: handleCancel,
                    showModal: true,
                    inputData: response.QR
                }))
            }

        } catch (err) {
            setLoading(false)
        } finally {
            setLoading(false)
        }

    }


    const handleSignatureClick = () => {
        dispatch(showModal({
            type: 'addSignature',
            message: t('Your signature'),
            onConfirm: (signatureFile: File | string) => {
                if (typeof signatureFile === 'object') {
                    dispatch(addSignature(userId, userType, signatureFile));
                }
            },
            onCancel: handleCancel,
            showModal: true,
        }));
    };

    const handleCancel = () => {
        dispatch(hideModal());
    };


    const handleDisable2fa = async () => {
        dispatch(disable2FA(userId, userType))
        dispatch(userInformation(userType))
    }



    const handleSaveSignature = (signature: string) => {
        setSignature(signature);
    };

    const handleConfirm = () => {
        const signatureBlob = new Blob([inputData], { type: 'image/svg+xml' });
        const signatureFile = new File([signatureBlob], 'signature.svg', { type: 'image/svg+xml' });
    }

    const handleEmailMobileVerification = async (method: string) => {
        if (method === 'email') {
            setEmailLoading(true)
        } else {
            setSMSLoading(true)
        }
        let resp: any = await dispatch(generateCode(method,t))
        if (resp) {
            setEmailLoading(false)
            setSMSLoading(false)
        }
    }

    const handleResetPassword = () => {
        const email = userInfo.Email;
        dispatch(forgotPassword(email))
        dispatch(hideModal())
    }

    const handleResetPasswordClick = () => {
        dispatch(showModal({
            type: 'forgotPassword',
            message: t('Reset Password'),
            subMsg: t('Are you sure you want to reset your password?'),
            onConfirm: () => handleResetPassword(), 
            showModal: false,
            onCancel: handleCancel,
            inputData: userInfo.Email
        }));
     }

     const handleSignOutAllAcounts = async () => {
        dispatch(showModal({
            type: 'alert',
            message: t('Are you sure you want to sign out from all devices?'),
            onConfirm: async (userID: File | string) => {
                if (userID){
                    SocketServices.emit('sign_out_all_accounts', { "userId": userID })
                }
            },
            onCancel: handleCancel,
            inputData: userId,
            showModal: true,
        }));
     }


    return (
        <>
            <ConfirmationDialog
                isOpen={confirmModalOpen}
                onClose={cancelSignOut}
                onConfirm={confirmSignOut}
                message={t(`${message}`)}
            />
            <div className="security-settings-container">
                <div className="security-verification-wrapper">
                    <div className="security-container">

                        <h2 style={{ padding: 15 }}>{t('Security')}</h2>
                        <span style={{ fontSize: '0.9rem', padding: '0px 15px' }} className='device-management-container--p'>{t('Manage and enhance the overall protection of your account and data.')}</span>
                        <div className='verification-options'>
                            <div className='settings-options--wrapper security'>
                                <div className="security-option">
                                    <div>
                                        <p className="option-title">{t('Password')}</p>
                                        <span className="option-description">{t('Create a unique, strong password for account protection.')}</span>
                                    </div>

                                    <button className="primary-button" onClick={handleResetPasswordClick}>{t('Reset')}</button>
                                </div>
                                <div className="security-option">
                                    <div>
                                        <p className="option-title">{t('Two-factor Authentication [2FA]')}</p>
                                        <span className="option-description">{t('Enhanced security layer with two-step verification process.')}</span>

                                    </div>
                                    {
                                        userInfo.Enable2FA === "Yes" ? (<button className="primary-button" onClick={() => handleDisable2fa()}>{t('Disable')}</button>) : (<button className="primary-button" onClick={() => handleEkyc()}>{t('Enable')} {loading && <ClipLoader color={"#fff"} size={10} />}</button>)
                                    }
                                </div>
                            </div>
                            <h2 style={{ marginTop: 15, marginBottom: 10 }}>{t('Verification')}</h2>
                            <span style={{ fontSize: '0.9rem', paddingLeft: 0 }} className='device-management-container--p'>{t('Ensure identity confirmation through secure verification processes.')}</span>
                            <div className='settings-options--wrapper verification'>
                                <div className="verification-option">
                                    <div className='data-section'>
                                        <p className='option-title'>{t('Verify your identity')}</p>
                                        <span>{t('Identity verification for secure and compliant platform access.')}</span>
                                    </div>
                                    <VerifyButton stripePromise={stripePromise} />
                                </div>
                                <div className="verification-option">
                                    <div className='data-section'>
                                        <p className='option-title'>{t('Email')}</p>
                                        <span>{t('Confirm your email for secure communication and account recovery.')}</span>
                                    </div>
                                    <button className="primary-button disabled" disabled onClick={() => handleEmailMobileVerification("email")}>{t('Verify')} {emailLoading && <ClipLoader color={"#fff"} size={10} />}</button>
                                </div>
                                <div className="verification-option">
                                    <div className='data-section'>
                                        <p className='option-title'>{t('SMS Verification')}</p>
                                        <span>{t('Link mobile number for additional security and alerts.')}</span>
                                    </div>
                                    <button className="primary-button disabled" disabled onClick={() => handleEmailMobileVerification("sms")}>{t('Verify')} {smsLoading && <ClipLoader color={"#fff"} size={10} />}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 10, flex: 0.5 }}>
                        <div className="verification-container">
                            <SignatureModal
                                onSave={handleSaveSignature}
                                userId={userId}
                                userType={userType}
                                onConfirm={handleConfirm}
                            />
                        </div>
                        <div className="device-management-container">
                            <div className="device-management-container--header">
                                <h2>{t('Device Management')}</h2>
                                <button
                                    className="destructive-button"
                                    onClick={handleSignOutAllAcounts}
                                >
                                    {t('Sign out of all devices')}
                                </button>
                            </div>
                            <span style={{ fontSize: '0.9rem' }} className='device-management-container--p'>{t('Control and monitor all devices connected to your account.')}</span>
                            <div className='dummy-devices'>
                                {devices && devices.length > 0 ? (
                                    devices.map((device: any, index: any) => (
                                        <div key={index} className="dummy-device">
                                            <div className="dummy-device-info">
                                                <span>{device.os ? device.os : device.mobileModel}</span>
                                                <p>{device.browser}</p>
                                                <p className='device-tag'>{device.status}</p>
                                                {/* <p>{device.location}</p> */}
                                                {/* <p>{device.userAgent}</p> */}

                                            </div>
                                            <button
                                                className="destructive-button"
                                                onClick={() => handleSignOutConfirm(device.session_id)}
                                            >
                                                {t('Sign out')}
                                            </button>
                                        </div>
                                    ))

                                ) : (<></>)

                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default SecuritySettings;
