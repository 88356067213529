import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './DigitalSignature.css';
import { addSignature } from '../../../store/actions/DocumentsAction';
import { useAppDispatch } from '../../../store';
import { useTranslation } from 'react-i18next';
import apis from '../../../utils/apis';

interface SignatureModalProps {
  onSave: (signature: string) => void;
  onConfirm: (signatureFile: File) => void;
  userId: string;
  userType: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  onSave,
  onConfirm,
  userId,
  userType,
}) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(false);
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');

  const handleClear = () => {
    signatureCanvasRef.current?.clear();
  };

  const loadImage = async () => {
    if (signatureCanvasRef.current) {
      try {
        const response = await apis({
          method: 'POST',
          url: 'document/getSignature',
        });

        if (response.data.signature) {
          const sign_url = response.data.signature;
          setIsEditable(true);
          signatureCanvasRef.current.fromDataURL(sign_url);
        }
      } catch (error) {
        console.error('Error fetching the signature image:', error);
      }
    }
  };

  useEffect(() => {
    loadImage();
  }, []);

  const handleSave = async () => {
    if (!signatureCanvasRef.current) {
      alert('Signature pad not initialized.');
    } else if (signatureCanvasRef.current.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const canvas = signatureCanvasRef.current.getCanvas();
      const canvasDataUrl = canvas.toDataURL('image/png');

      const svgString = `
        <svg width="${canvas.width}" height="${canvas.height}" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="black"/>
          <image href="${canvasDataUrl}" x="0" y="0" height="${canvas.height}" width="${canvas.width}"/>
        </svg>
      `;

      const blob = new Blob([svgString], { type: 'image/svg+xml' });
      const signatureFile = new File([blob], `${userId}_signature.svg`, {
        type: 'image/svg+xml',
      });

      onSave(svgString);
      onConfirm(signatureFile);

      localStorage.setItem('userSignature', svgString);

      try {
        await dispatch(addSignature(userId, userType, signatureFile));
        setIsEditable(true);
      } catch (error) {
        console.error('Dispatch Error:', error);
      }
    }
  };

  return (
    <div className="signature__modal-content">
      <div className="signature__modal-header">
        <h2>{t('Digital Signature')}</h2>
        <div className="signature__modal-actions">
          <button className="destructive-button disabled" disabled onClick={handleClear}>
            {t('Clear')}
          </button>
          <button className="primary-button disabled" disabled onClick={handleSave}>
            {isEditable ? t('Edit') : t('Save')}
          </button>
        </div>
      </div>
      <span
        style={{ fontSize: '0.9rem', padding: '10px 15px 0px 15px' }}
        className="device-management-container--p"
      >
        {t('Set your digital signature.')}
      </span>
        {/* <div className='non-access'>{t('Coming in Q4')}</div> */}
      <div className="signature-container">
        <SignatureCanvas
          penColor="white"
          ref={signatureCanvasRef}
          canvasProps={{
            width: 500,
            height: 180,
            className: 'signature-canvas',
          }}
          minWidth={1}
          maxWidth={3}
          throttle={16}
        />
      </div>
    </div>
  );
};

export default SignatureModal;
