import axios, { AxiosError } from "axios"
import { Dispatch } from "react"
import apis from "../../utils/apis"
import { ErrorResponse } from "./SignInAction"
import { AnyAction } from "redux"
import { setFontSize } from "./FontSizeAction"
import { setVoice } from "./SpeechAction"
import { AISettings } from "../reducer/DataReducer"
import { showFeedbackModal } from "./UserFeedbackActions"
import { Message } from '../../components/AIAssistant/lcm/CustomMessageList';
import { getUserID } from "./ChatAction"
import { GET_AI_SETTINGS_SUCCESS, GET_PREFERENCES_SUCCESS, GET_USER_MANUAL_FAILURE, GET_USER_MANUAL_REQUEST, GET_USER_MANUAL_SUCCESS, SET_AI_SETTINGS, SET_AI_VOICES, SET_CHAT_LIST, SET_CITY, SET_COUNTRY, SET_CURRENCY, SET_MESSAGE_LIST, SET_PREFERENCES, SET_STATE } from "../types"
import { HelpData } from "../../components/Help/types"
import { showModal } from "./modalActions"



export interface UserInfo {
    City: string;
    Country: string;
    DateOfBirth: string;
    Email: string;
    Employer: string;
    FirstName: string;
    KYCStatus: string;
    LastName: string;
    LawfirmID?: string;
    Nationality: string;
    Occupation: string;
    PhoneNumber: string;
    ProfilePicture: File | string;
    State: string;
    StreetAddress: string;
    UserID: string;
    UserType: string;
    ZipCode: string;
}

interface GetUserManualRequestAction {
    type: typeof GET_USER_MANUAL_REQUEST;
}

interface GetUserManualSuccessAction {
    type: typeof GET_USER_MANUAL_SUCCESS;
    payload: HelpData;
}

interface GetUserManualFailureAction {
    type: typeof GET_USER_MANUAL_FAILURE;
    payload: string;
}

export type HelpActionTypes =
    | GetUserManualRequestAction
    | GetUserManualSuccessAction
    | GetUserManualFailureAction;

export const setCountry = (countries: string) => {
    return {
        type: SET_COUNTRY,
        countries
    }

}

export const setCurrency = (currencies: any) => {
    return {
        type: SET_CURRENCY,
        currencies
    };
};
export const setState = (states: string) => {
    return {
        type: SET_STATE,
        states
    }
}

export const setCity = (cities: string) => {
    return {
        type: SET_CITY,
        cities
    }
}

export const setPreferences = (preferences: any) => {
    return {
        type: SET_PREFERENCES,
        preferences,
    };
};

export const getPreferencesSuccess = (preferences: any) => {
    return {
        type: GET_PREFERENCES_SUCCESS,
        preferences,
    };
};

export const setAISettings = (aiSettings: any) => ({
    type: SET_AI_SETTINGS,
    aiSettings,
});

export const getAISettingsSuccess = (aiSettings: any) => ({
    type: GET_AI_SETTINGS_SUCCESS,
    aiSettings,
});


export const setChatList = (chat_list: any[]) => ({
    type: SET_CHAT_LIST,
    chat_list
})

export const setMessageList = (message_list: any[]) => ({
    type: SET_MESSAGE_LIST,
    message_list
})

const initialState = {
    userInfo: {}
};

export const userReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case 'STORE_USER_INFO':
            return {
                ...state,
                userInfo: action.payload,
            };
        default:
            return state;
    }
};


export const countryList = (name?: string | null) => {
    return async (dispatch: any) => {
        try {
            let response = await apis({
                method: "GET",
                url: "territory/getAllCountries",
                data: {
                    country_name: name ? name : "",
                }
            })
            if (response && response.data) {
                dispatch(setCountry(response.data))
            }
        } catch (err) {
            dispatch(showFeedbackModal({
                modalType: 'error',
                showModal: true,
                message: (`${err}`),
                duration: 3000
            }))
        }
    }
}

export const currencyList = () => {
    return async (dispatch: any) => {
        try {
            let response = await apis({
                method: "GET",
                url: "/getCurrencyList"
            });
            if (response && response.data) {
                dispatch(setCurrency(response.data));
            }
        } catch (err) {
            dispatch(showFeedbackModal({
                modalType: 'error',
                showModal: true,
                message: (`${err}`),
                duration: 3000
            }))
        }
    };
};


export const userInformation = (userType: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            let response = await apis({
                method: "POST",
                url: "user/userInfo",
                data: {
                    UserType: userType
                }
            });
            if (response && response.status === 200) {
                let userData = response.data;

                // if ((userType.toLowerCase() === 'businessadmin' || userType.toLowerCase() === 'lawfirmadmin') && userData.FullName) {
                //     const names = userData.FullName.split(' ');
                //     userData.FirstName = names[0];
                //     userData.LastName = names.slice(1).join(' ');
                // }

                sessionStorage.setItem("userInfo", JSON.stringify({ userId: userData.UserID, userType: userData.UserType }));
                dispatch({ type: 'STORE_USER_INFO', payload: userData });
                return userData;
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse;
                        dispatch(showFeedbackModal({
                            modalType: 'error',
                            message: (errorMessage.message || 'An unknown error occurred'),
                            showModal: true,
                            duration: 3000
                        }))
                    } else {
                        dispatch(showFeedbackModal({
                            modalType: 'error',
                            message: ('Network error, please try again'),
                            showModal: true,
                            duration: 3000
                        }))
                    }
                }
            }
        } catch (err) {
        }
    }
};

export const EditUser = (formdata: FormData, onSuccess: () => void) => {

    return async (dispatch: Dispatch<any>) => {
        try {
            let response = await apis({
                method: 'POST',
                url: "user/editUserInfo",
                data: formdata
            })

            if (response && response.status === 200) {
                onSuccess()
                return response
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let erroMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            modalType: 'error',
                            message: (erroMessage.message || 'An unknown error occurred'),
                            showModal: true,
                            duration: 3000
                        }))
                    } else {
                        dispatch(showFeedbackModal({
                            modalType: 'error',
                            message: ('Network error, please try again'),
                            showModal: true,
                            duration: 3000
                        }))
                    }
                }
            }
        } catch (err) {

        }
    }
}


export const getPreferences = (userType: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await apis({
                method: 'POST',
                url: 'settings/getPreference',
                data: { UserType: userType },
            });
            if (response.status === 200) {
                dispatch(getPreferencesSuccess(response.data));
            }
        } catch (error) {
            dispatch(showFeedbackModal({
                modalType: 'error',
                showModal: true,
                message: ('Failed to fetch preferences'),
                duration: 3000
            }))
        }
    };
};

export const updatePreferences = (preferences: any) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await apis({
                method: 'POST',
                url: 'settings/setPreference',
                data: preferences,
            });
            if (response.status === 200) {
                dispatch(setPreferences(response.data));
            }
        } catch (error) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: 'Failed to update preference.',
                modalType: 'error',
                duration: 3000
            }));
        }
    };
};

export const getAISettings = (userType: string) => async (dispatch: Dispatch<any>) => {
    try {
        const response = await apis({
            method: 'POST',
            url: 'settings/getAISettings',
            data: { UserType: userType },
        });
        if (response.status === 200) {
            const settings: AISettings = response.data;
            localStorage.setItem('aiSettings', JSON.stringify(settings));
            dispatch(setAISettings(settings));
            dispatch(setFontSize(settings.FontSize));
            dispatch(setVoice(settings.AIVoice));
        }
    } catch (error) {
        dispatch(showFeedbackModal({
            showModal: true,
            message: ('Failed to fetch AI settings'),
            modalType: 'error',
            duration: 3000
        }));
    }
};

export const getAIVoices = () => async (dispatch: Dispatch<any>) => {
    const userId = getUserID();
    try {
        const response = await apis({
            method: 'POST',
            url: 'settings/getAIVoices',
            data: { UserID: userId },
        });
        if (response.status === 200) {
            dispatch({
                type: SET_AI_VOICES,
                voices: response.data.voices,
            });
        }
    } catch (error) {
        dispatch(showFeedbackModal({
            showModal: true,
            message: ('Failed to fetch AI Voices'),
            modalType: 'error',
            duration: 3000
        }));
    }
};


export const updateAISettings = (aiSettings: AISettings) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const response = await apis({
                method: 'POST',
                url: 'settings/setAISettings',
                data: aiSettings,
            });
            if (response.status === 200) {
                localStorage.setItem('aiSettings', JSON.stringify(aiSettings));
                dispatch(setAISettings(aiSettings));
                dispatch(setFontSize(aiSettings.FontSize));
                dispatch(setVoice(aiSettings.AIVoice));
                // dispatch(showFeedbackModal({
                //     showModal: true,
                //     message: 'AI settings updated',
                //     modalType: 'success',
                //     duration: 3000
                // }));
            }
        } catch (error) {
            dispatch(showFeedbackModal({
                showModal: true,
                message: 'Failed to update AI settings. Please contact support.',
                modalType: 'error',
                duration: 3000
            }));
        }
    };
};

export const fetchCHatList = () => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "GET",
                url: "chats/chatList",
            })

            if (resp && resp.status === 200) {
                dispatch(setChatList(resp.data.chats_list))
            }
        } catch (e) {

        }
    }


}

export const fetchMessageList = (chat_id: string | number) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "chats/MessageList",
                data: {
                    ChatID: chat_id
                }
            })

            if (resp && resp.status === 200) {
                dispatch(setMessageList(resp.data.message_list))
                return resp.data.message_list
            }
        } catch (e) {

        }
    }

}

export const deleteMesageChat = (chat_id: string | number) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "chats/DeleteChat",
                data: {
                    ChatID: chat_id
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: resp.data.message,
                    modalType: 'success',
                    duration: 3000,
                }))
                return resp.status
            }
        } catch (e) {

        }
    }

}

export const getUserManual = (userType: string, language: string) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_USER_MANUAL_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: '/settings/getUserManual',
            data: { UserType: userType, Language: language },
        });
        if (response.status === 200) {
            dispatch({
                type: GET_USER_MANUAL_SUCCESS,
                payload: response.data, // Ensure the API response matches HelpData
            });
        } else {
            dispatch({
                type: GET_USER_MANUAL_FAILURE,
                payload: 'Failed to fetch help data',
            });
            dispatch(showFeedbackModal({
                showModal: true,
                message: 'Failed to fetch help data',
                modalType: 'error',
                duration: 3000,
            }));
        }
    } catch (error) {
        dispatch({
            type: GET_USER_MANUAL_FAILURE,
            payload: 'Failed to fetch help data',
        });
        dispatch(showFeedbackModal({
            showModal: true,
            message: 'Failed to fetch help data',
            modalType: 'error',
            duration: 3000,
        }));
    }
};