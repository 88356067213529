import axios, { AxiosError } from "axios";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ErrorResponse } from "./SignInAction";
import { START_TIMER, STOP_TIMER, PAUSE_TIMER, RESUME_TIMER, LOG_TIME_ENTRY, SET_TIME_ENTRIES, EDIT_TIME_ENTRY, DELETE_TIME_ENTRY, SET_TIMER_RUNNING_STATE, SET_PAUSED_STATE, SET_TIMER } from "../types";

const prepareData = (case_id: string | null, task_id: string | null) => ({
  case_id: case_id || "",
  task_id: task_id || ""
});

export const startTimerApi = (case_id: string | null, task_id: string | null) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/startTimer",
        data: prepareData(case_id, task_id),
      });

      if (resp && resp.status === 200) {
        dispatch({ type: START_TIMER });
        dispatch(setTimerRunningState(true));
        dispatch(setPausedState(false));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer started successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const stopTimerApi = (case_id: string | "", task_id: string | "", description: string = "") => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/stopTimer",
        data: {
          ...prepareData(case_id, task_id),
          description
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: STOP_TIMER });
        dispatch(setTimerRunningState(false));
        dispatch(setPausedState(false));
        dispatch(setTimer(0));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer stopped successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const pauseTimerApi = (case_id: string | null, task_id: string | null) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/pauseTimer",
        data: prepareData(case_id, task_id),
      });

      if (resp && resp.status === 200) {
        dispatch({ type: PAUSE_TIMER });
        dispatch(setTimerRunningState(false));
        dispatch(setPausedState(true));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer paused successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const resumeTimerApi = (case_id: string | null, task_id: string | null) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/resumeTimer",
        data: prepareData(case_id, task_id),
      });

      if (resp && resp.status === 200) {
        dispatch({ type: RESUME_TIMER });
        dispatch(setTimerRunningState(true));
        dispatch(setPausedState(false));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer resumed successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const setTimerRunningState = (isRunning: boolean) => {
  return {
    type: SET_TIMER_RUNNING_STATE,
    payload: isRunning,
  };
};

export const setTimer = (time: number) => {
  return {
    type: SET_TIMER,
    payload: time,
  };
};

export const setPausedState = (isPaused: boolean) => {
  return {
    type: SET_PAUSED_STATE,
    payload: isPaused,
  };
};

export const logTimeEntryApi = (timeEntryData: any) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/logTimeEntry",
        data: timeEntryData,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: LOG_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry logged successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const getTimeEntriesApi = (case_id: string | null, task_id: string | null) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/getTimeEntries",
        data: prepareData(case_id, task_id),
      });

      if (resp && resp.status === 200) {
        dispatch({ type: SET_TIME_ENTRIES, payload: resp.data.time_entries });
        return resp.data;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const editTimeEntryApi = (entry_id: string, updates: any) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/editTimeEntry",
        data: { entry_id, updates },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: EDIT_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry edited successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const deleteTimeEntryApi = (entry_id: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/deleteTimeEntry",
        data: { entry_id },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: DELETE_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry deleted successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

const handleError = (dispatch: any, error: any) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.data) {
      let errorMessage = axiosError.response.data as ErrorResponse;
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorMessage.message || errorMessage.error || "An unknown error occurred",
          modalType: "error",
          duration: 3000,
        })
      );
    } else {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: "Network error, please try again",
          modalType: "error",
          duration: 3000,
        })
      );
    }
  }
};


export const fetchTimeData = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchTimeData",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};


export const fetchDayWeekData = (mode: string, date: string, from_date: string, to_date: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchDayDetails",
        data: { mode, date, from_date, to_date },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };
};


export const fetchChartsData = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchChartData",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };
};