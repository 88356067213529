import React, { useEffect, useState } from 'react';
import './TimeTracker.css';
import { useTranslation } from 'react-i18next';
import { fetchTimeData } from '../../store/actions/TimeManagementActions';
import { useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';

interface TimekeepingTableProps {
  onDayClick: (
    mode: string,
    date: string,
    from_date: string,
    to_date: string,
    stats: any
  ) => void;
  formatDecimalHours: (decimalHours: number) => string;
}


const TimekeepingTable: React.FC<TimekeepingTableProps> = ({ onDayClick }) => {
  const [viewMode, setViewMode] = useState<'weekly' | 'monthly'>('weekly');
  const [timekeepingData, setTimekeepingData] = useState<any[]>([]);
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    const fun = async () => {
      setIsloading(true)
      const data: any = await dispatch(fetchTimeData(viewMode))

      if (data) {
        setTimekeepingData(data.data)
        setIsloading(false)
      } else {
        setIsloading(false)
      }
    }
    fun()
  }, [viewMode, dispatch])

  // Function to handle toggle between weekly and monthly views
  const handleToggleView = (mode: 'weekly' | 'monthly') => {
    setViewMode(mode);
    // setTimekeepingData(generateTimekeepingData(mode));
  };

  const formatDecimalHours = (decimalHours: number): string => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="timekeeping-management-container">
      <div className="timekeeping-container">
        <div className="timekeeping-container-header">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <span>{viewMode === 'weekly' ? t('Weekly Timekeeping') : t('Monthly Timekeeping')}</span>
            <div className="view-toggle-buttons">
              <button
                className={`toggle-btn ${viewMode === 'weekly' ? 'active' : ''}`}
                onClick={() => handleToggleView('weekly')}
              >
                {t('Weekly')}
              </button>
              <button
                className={`toggle-btn ${viewMode === 'monthly' ? 'active' : ''}`}
                onClick={() => handleToggleView('monthly')}
              >
                {t('Monthly')}
              </button>
            </div>
          </div>
          <button className="primary-button disabled">{t('Print Report')}</button>
        </div>
        <div className="timekeeping-container-body">
          <div className="timekeeping-table-wrapper">
            <table className="timekeeping-table">
              <thead>
                {
                  viewMode === "weekly" ? (
                    <tr>
                      <th>{viewMode === 'weekly' ? t('Day') : t('Week')}</th>
                      <th>{t('Date')}</th>
                      <th>{t('Clocked Hours')}</th>
                      <th>{t('Negative Hours')}</th>
                      <th>{t('Overtime')}</th>
                      <th>{t('Total Hours Worked')}</th>
                      <th>{t('Tasks')}</th>
                    </tr>
                  ) : (
                    <tr>
                      <th>{t('week')}</th>
                      <th>{t('from Date')}</th>
                      <th>{t('to Date')}</th>
                      <th>{t('Clocked Hours')}</th>
                      <th>{t('Negative Hours')}</th>
                      <th>{t('Overtime')}</th>
                      <th>{t('Total Hours Worked')}</th>
                      <th>{t('Tasks')}</th>
                    </tr>
                  )
                }
              </thead>
              <tbody>
                {
                  isLoading ? <>
                    <div style={{ width: '100%', alignItems: "center" }} className='non-access'>
                      <ClipLoader size={20} color='#fff' />
                    </div>
                  </> : (

                    timekeepingData && timekeepingData.length > 0 ? (
                      timekeepingData.map((dayData) => {
                        return (
                          <>
                            {viewMode === 'weekly' ? (
                              <tr key={dayData.date} onClick={() => onDayClick(viewMode,dayData.date,"","", dayData)}>
                                <td>{t(dayData.day)}</td>
                                <td>{dayData.date}</td>
                                <td>{formatDecimalHours(dayData.clocked_hours)}</td>
                                <td>{formatDecimalHours(dayData.negative_hours)}</td>
                                <td>{formatDecimalHours(dayData.overtime)}</td>
                                <td>{formatDecimalHours(dayData.total_hours_worked)}</td>
                                <td>{dayData.tasks.length}</td>
                              </tr>
                            ) : (
                              <tr key={dayData.week} onClick={() => onDayClick(viewMode,"",dayData.from_date, dayData.to_date, dayData)}>
                                <td>{t(dayData.week)}</td>
                                <td>{dayData.from_date}</td>
                                <td>{dayData.to_date}</td>
                                <td>{formatDecimalHours(dayData.clocked_hours)}</td>
                                <td>{formatDecimalHours(dayData.negative_hours)}</td>
                                <td>{formatDecimalHours(dayData.overtime)}</td>
                                <td>{formatDecimalHours(dayData.total_hours_worked)}</td>
                                <td>{dayData.tasks.length}</td>
                              </tr>
                            )}
                          </>
                        )
                      })
                    ) : (
                      <div className='non-access'>{t('No Data available')}</div>
                    )
                  )

                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimekeepingTable;
