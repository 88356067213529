import { useCallback, useEffect, useRef, useState } from "react";
import { FileProps } from "../../pages/DocumentsPage";
import './Editor.css'
import { getUserID, getUserType } from "../../store/actions/ChatAction";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editDocument, fetchDocumentContent, deleteDocument, resetSelectedDocument, fetchDocumentVersion, anonymizeDocument, translateDocument, addDocumentAssignees, addDocumentSharedAccess, addDocumentDeadline, uploadNewDocument, fetchAnonymizationDatasets, fetchFileDetails, addDocumentApprover, signFile } from '../../store/actions/DocumentsAction';
import { RootState, useAppDispatch } from "../../store";
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { showModal, hideModal } from "../../store/actions/modalActions";
import { useTranslation } from "react-i18next";
import React from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";
import { fetchStructure } from "../../store/actions/FolderAction";
import { ClipLoader } from "react-spinners";
import AnonymizeDocumentModal from "./AnonymizeDocumentModal";
import { Tooltip } from "@mui/material";
import CustomDateTimePicker from "../shared/CustomDateTimePicker";

dayjs.extend(customParseFormat);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'times-new-roman',
  'sans-serif',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
  'cursive',
  'monospace',
  'serif',
  'tahoma',
  'verdana',
  'impact',
  'palatino',
  'trebuchet-ms',
  'garamond',
  'brush-script-mt',
  'copperplate',
  'marker-felt',
];
Quill.register(Font, true);

// Interfaces
interface EditorProps {
  file: FileProps;
  onClose: () => void;
  setEditorOpen: (value: boolean) => void;
  onFileDeleted: () => void;
}

interface HeaderStructure {
  level: number;
  title: string;
}


export const ToggleSwitchTheme: React.FC<{ isOn: boolean; onToggle: () => void }> = ({ isOn, onToggle }) => {
    const { t } = useTranslation()
    return (
        <div className="toggle-switch__theme">
            <button className={`button left ${isOn ? '' : 'active'}`} onClick={onToggle}>{t('Light')}</button>
            <button className={`button right ${isOn ? 'active' : ''}`} onClick={onToggle}>{t('Dark')}</button>
            <div className={`slider-theme ${isOn ? 'right' : ''}`}></div>
        </div>
    );
};

interface DocumentViewerProps {
    documentUrl: any;
}


const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
    if (!documentUrl) return <div>No document to display</div>;

    const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(documentUrl)}&embedded=true`;

    return (
        <iframe
            src={viewerUrl}
            onError={() => alert('Failed to load the document')}
            style={{ width: '100%', height: '100vh' }}
            frameBorder="0"
            title="test"
        ></iframe>
    );
};




const Editor: React.FC<EditorProps> = ({ file, onClose, setEditorOpen, onFileDeleted }) => {
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
        file.Deadline && dayjs(file.Deadline, 'DD-MM-YYYY HH:mm').isValid() 
          ? dayjs(file.Deadline, 'DD-MM-YYYY HH:mm') 
          : null
    );    
    const [isOutlineOpen, setIsOutlineOpen] = useState(true);
    const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
    const [showShareDropdown, setShowShareDropdown] = useState(false);
    const [showApproverDropdown, setApproverDropdown] = useState(false);
    const [showVersionControlDropdown, setShowVersionControlDropdown] = useState(false);
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen)
    const [newFile, setNewFile] = useState<File | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [content, setContent] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [comfortView, setComfortView] = useState(false);
    const dispatch = useAppDispatch()
    const userType = getUserType()
    const [outline, setOutline] = useState<HeaderStructure[]>([]);
    const [fileDetails, setFileDetails] = useState<FileProps | null>(null);
    const [emailShareInput, setEmailShareInput] = useState('');
    const [emailAssigneeInput, setEmailAssigneeInput] = useState('');
    const [emailApproverInput, setEmailApproverInput] = useState('');
    const [isDeadlineOpen, setIsDeadlineOpen] = useState(false);
    const [fileVersions, setFileVersions] = useState<any[]>([]); 
    const { t } = useTranslation()
    const sanitizeEmails = (emails: string[]) => {
        return emails.map(email => email.replace(/^['"]|['"]+$/g, '').trim());
      };
      
      const [assignees, setAssignees] = useState<string[]>(
        typeof fileDetails?.Assignees === 'string'
          ? sanitizeEmails(fileDetails?.Assignees.split(','))
          : sanitizeEmails(fileDetails?.Assignees || [])
      );
      
      const [sharedAccess, setSharedAccess] = useState<string[]>(
        typeof fileDetails?.SharedWith === 'string'
          ? sanitizeEmails(fileDetails?.SharedWith.split(','))
          : sanitizeEmails(fileDetails?.SharedWith || [])
      );

      const [approvers, setApprovers] = useState<string[]>(
        typeof fileDetails?.Approvers === 'string'
          ? sanitizeEmails(fileDetails?.Approvers.split(','))
          : sanitizeEmails(fileDetails?.Approvers || [])
      );
    const fileId = file.FileID;
    const selectedFile = useSelector((state: RootState) => state.documents.selectedFile);
    const [loadingAssignee, setLoadingAssignee] = useState(false);
    const [loadingAccess, setLoadingAccess] = useState(false);
    const [loadingApprover, setLoadingApprover] = useState(false);
    const [loadingDeadline, setLoadingDeadline] = useState(false);
    const editorRef = useRef<HTMLDivElement>(null); 
    const [currentVersionID, setCurrentVersionID] = useState<string | null>('latest');

    useEffect(() => {
        if (selectedFile) {
          setFileDetails(selectedFile);
      
          // Update assignees
          const updatedAssignees = Array.isArray(selectedFile.Assignees)
            ? selectedFile.Assignees
            : typeof selectedFile.Assignees === 'string'
            ? selectedFile.Assignees.split(',').map((email: string) => email.trim())
            : [];
          setAssignees(sanitizeEmails(updatedAssignees));
      
          // Update sharedAccess
          const updatedSharedAccess = Array.isArray(selectedFile.SharedWith) 
            ? selectedFile.SharedWith
            : typeof selectedFile.SharedWith === 'string' 
            ? selectedFile.SharedWith.split(',').map((email: string) => email.trim())
            : [];
          setSharedAccess(sanitizeEmails(updatedSharedAccess));

          const updatedApprovers = Array.isArray(selectedFile.Approvers) 
            ? selectedFile.Approvers
            : typeof selectedFile.Approvers === 'string' 
            ? selectedFile.Approvers.split(',').map((email: string) => email.trim())
            : [];
          setApprovers(sanitizeEmails(updatedApprovers));
      
          // Update deadline
          if (selectedFile.Deadline && dayjs(selectedFile.Deadline, 'DD-MM-YYYY HH:mm').isValid()) {
            setSelectedDate(dayjs(selectedFile.Deadline, 'DD-MM-YYYY HH:mm'));
        } else {
            setSelectedDate(null);
        }        
        }
      }, [selectedFile]);
    
          

      useEffect(() => {
        const getFileDetails = async () => {
          await dispatch(fetchFileDetails(userType, file.FileID));
        };
        getFileDetails();
      }, [dispatch, userType, file.FileID]);
      

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClose = () => {
        dispatch(resetSelectedDocument());
        onClose();
    };


    const handleFetchVersions = useCallback(async () => {
        const resp: any = await dispatch(fetchDocumentVersion(file.FileID, userType));
        if (resp && Array.isArray(resp)) { // Since fetchDocumentVersion returns resp.data
            setFileVersions(resp);
        }
    }, [file.FileID, dispatch, userType]);
    

    useEffect(() => {
        handleFetchVersions();
        if (file && file.FileUrl) {
            const urls = typeof file.FileUrl === 'string' ? [file.FileUrl] : file.FileUrl;
            const docs = urls.map(url => ({ uri: url }));
            // setDocumentUrls(docs);
        }
    }, [file, handleFetchVersions]);

    useEffect(() => {
        handleFetchVersions();
      }, [file, handleFetchVersions]);

    const handleDeleteClick = () => {
        dispatch(showModal({
            type: 'confirmation',
            message: t('Are you sure you want to delete this document?'),
            onConfirm: handleConfirmDelete,
            onCancel: handleCancel,
            showModal: true,
        }));
    };

    const handleConfirmDelete = () => {
        if (!file) {
            console.error("No file selected");
            return;
        }
        const userId = getUserID();

        dispatch(deleteDocument(file.FileID, userId, userType, () => {
            setEditorOpen(false)
            onFileDeleted();
        }));

        dispatch(hideModal());
    };

    const handleCancel = () => {
        dispatch(hideModal());
        setEditorOpen(true)
    };
    const toggleAssigneeDropdown = () => {
        setShowAssigneeDropdown(!showAssigneeDropdown);
    };

    const toggleShareDropdown = () => {
        setShowShareDropdown(!showShareDropdown)
    }

    const toggleDeadline = () => {
        setIsDeadlineOpen(!isDeadlineOpen)
    }

    const toggleVersionControl = () => {
        setShowVersionControlDropdown(!showVersionControlDropdown)
    }

    const toggleApproverDropdown = () => {
        setApproverDropdown(!showApproverDropdown)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
          setNewFile(event.target.files[0]);
        }
      };

    useEffect(() => {

        updateOutline(content);
    }, [content]);

    const toggleOutline = () => {
        setIsOutlineOpen(!isOutlineOpen);
    };


    const updateOutline = (editorContent: string) => {
        const headers = extractHeaders(editorContent);
        setOutline(headers);
    };

    const extractHeaders = (content: string): HeaderStructure[] => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const elements = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');
    
        const headerStructure: HeaderStructure[] = [];
    
        elements.forEach(element => {
          const level = parseInt(element.tagName.substring(1));
          headerStructure.push({
            level: level,
            title: element.textContent || ''
          });
        });
    
        return headerStructure;
      };
    


    useEffect(() => {
        if (file && file.FileType === "Template") {
            const urls = Array.isArray(file.FileUrl) ? file.FileUrl : [file.FileUrl];
            // setDocumentUrls(urls.map(url => ({ uri: url })));
        } else if (file && file.FileType !== "Template") {
            DocumentContent();
        }
    }, [file]);


    const toggleComfortView = () => {
        setComfortView(!comfortView);
        if (!comfortView) {
          if (editorRef.current) {
            openFullscreen(editorRef.current); 
          }
        } else {
          closeFullscreen();
        }
      };
    
      useEffect(() => {
        function handleFullscreenChange() {
          if (!document.fullscreenElement) {
            setComfortView(false);
          }
        }
      
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange); 
        document.addEventListener("mozfullscreenchange", handleFullscreenChange); 
        document.addEventListener("MSFullscreenChange", handleFullscreenChange); 
      
        return () => {
          document.removeEventListener("fullscreenchange", handleFullscreenChange);
          document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
          document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
          document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
        };
      }, []);

    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };


    const scrollToHeader = (headerText: string) => {
        const editor = document.querySelector('.ql-editor');
        if (editor) {
            const headers = editor.querySelectorAll('h1, h2, h3, h4, h5, h6');
            for (let header of headers) {
                if (header.textContent === headerText) {
                    header.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    break;
                }
            }
        }
    };



    function openFullscreen(elem: HTMLElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if ((elem as any).mozRequestFullScreen) {
          (elem as any).mozRequestFullScreen();
        } else if ((elem as any).webkitRequestFullscreen) {
          (elem as any).webkitRequestFullscreen();
        } else if ((elem as any).msRequestFullscreen) {
          (elem as any).msRequestFullscreen();
        }
      }
    
      function closeFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
          (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
          (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
          (document as any).msExitFullscreen();
        }
      }
      
    const DocumentContent = async () => {
        const userId = getUserID();
        try {
            let resp: any = await dispatch(fetchDocumentContent(userId, file.FileID, userType))
            if (resp) {
                setContent(resp.content);
                setCurrentVersionID('latest');
                const base64Content = resp.content;
                const blob = convertBase64ToBlob(base64Content);
                const url = URL.createObjectURL(blob);
                // setDocumentUrls([{ uri: url }]);
            }
        } catch (error) {
            console.error('Error fetching document content:', error);
        }
    };

    const convertBase64ToBlob = (base64: string) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    };

    const handleSave = async () => {
        if (newFile) {
            dispatch(showModal({
                type: "confirm-upload",
                message: t('Upload File, Choose what this file will do!'),
                onConfirm: (selectedOption?: string | File) => {

                },
                showModal: true,
            }))
        } else {
            await dispatch(editDocument(file.FileID, file.FileName, content, userType));
            setIsModified(false); 
            await handleFetchVersions();
            setCurrentVersionID('latest'); 
          }
    };

    const handleContent = (text: string) => {
        setIsModified(true)
        setContent(text)
    }

    <div id="toolbar">
        <span className="ql-formats">
            <select className="ql-font">
                <option value="arial">{t('Arial')}</option>
                <option value="comic-sans">{t('Comic Sans')}</option>
                <option value="courier-new">{t('Courier New')}</option>
                <option value="georgia">{t('Georgia')}</option>
                <option value="helvetica">{t('Helvetica')}</option>
                <option value="lucida">{t('Lucida')}</option>
                <option value="cursive">{t('Cursive')}</option>



            </select>
            <select className="ql-size">
                <option value="10px">10{t('px')}</option>
                <option value="12px">12{t('px')}</option>
                <option value="14px">14{t('px')}</option>
                <option value="16px">16{t('px')}</option>
                <option value="18px">18{t('px')}</option>
                <option value="20px">20{t('px')}</option>
                <option value="24px">24{t('px')}</option>
                <option value="30px">30{t('px')}</option>
            </select>

        </span>
    </div>

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    // const handleTranslateDocument = async () => {
    //     if (!file || !file.FileUrl) {
    //       console.error("No file or file URL available for translation");
    //       return;
    //     }
        
    //     const desiredLanguage = prompt("Enter the desired language for translation:");
    //     if (desiredLanguage) {
    //       const fileUrls = Array.isArray(file.FileUrl) ? file.FileUrl : [file.FileUrl];
    //       const fileUrl = fileUrls.find((url: string) => url.endsWith('.pdf') || url.endsWith('.docx') || url.endsWith('.rtf'));
      
    //       if (!fileUrl) {
    //         console.error("No valid file URL available for translation");
    //         return;
    //       }
      
    //       const translated = await dispatch(translateDocument(fileUrl, desiredLanguage));
    //       console.log(translated);
    //     }
    //   };

    const handleTranslateDocument = async () => {
        if (!file.FileID) {
          console.error("No file ID available for translation");
          return;
        }
        dispatch(showModal({
          type: 'translate',
          showModal: true,
          message: "Translate Document",
          fileID: file.FileID,
          width: '30%',
          subMsg: t('Select which language you would like to translate your document to.')
        }));
      };
      
      // Update handleAnonymizeDocument
      const handleAnonymizeDocument = async () => {
        if (!file.FileID) {
          console.error("No file ID available for anonymization");
          return;
        }
      
        try {
          await dispatch(fetchAnonymizationDatasets());
          dispatch(showModal({
            type: 'anonymize',
            showModal: true,
            message: "Anonymize Document",
            fileID: file.FileID,
            width: '50%',
            subMsg: t('Select which parts of the document you want to anonymize.')
          }));
        } catch (error) {
          console.error('Error fetching anonymization datasets:', error);
        }
      };

    const addAssignee = () => {
        if (emailAssigneeInput.trim()) {
          setLoadingAssignee(true);
          const updatedAssignees = sanitizeEmails([...assignees, emailAssigneeInput]);
      
          dispatch(addDocumentAssignees(userType, fileId, updatedAssignees))
            .then(() => {
              setEmailAssigneeInput('');
              // Fetch the updated file details
              dispatch(fetchFileDetails(userType, fileId));
            })
            .finally(() => setLoadingAssignee(false));
        }
      };
      
      const removeAssignee = (assignee: string) => {
        setLoadingAssignee(true);
        const updatedAssignees = sanitizeEmails(assignees.filter(item => item !== assignee));
      
        dispatch(addDocumentAssignees(userType, fileId, updatedAssignees))
          .then(() => {
            // Fetch the updated file details
            dispatch(fetchFileDetails(userType, fileId));
          })
          .finally(() => setLoadingAssignee(false));
      };
      

      const addAccess = () => {
        if (emailShareInput.trim()) {
          setLoadingAccess(true);
          const updatedSharedAccess = sanitizeEmails([...sharedAccess, emailShareInput]);
      
          dispatch(addDocumentSharedAccess(userType, fileId, updatedSharedAccess))
            .then(() => {
                setEmailShareInput('');
              dispatch(fetchFileDetails(userType, fileId));
            })
            .finally(() => setLoadingAccess(false));
        }
      };      
      
      
      const removeAccess = (email: string) => {
        setLoadingAccess(true);
        const updatedSharedAccess = sanitizeEmails(sharedAccess.filter(item => item !== email));
      
        dispatch(addDocumentSharedAccess(userType, fileId, updatedSharedAccess))
          .then(() => {
            dispatch(fetchFileDetails(userType, fileId));
          })
          .finally(() => setLoadingAccess(false));
      };

      const addApprover = () => {
        if (emailApproverInput.trim()) {
          setLoadingApprover(true);
          const updatedApprovers = sanitizeEmails([...approvers, emailApproverInput]);
      
          dispatch(addDocumentApprover(userType, fileId, updatedApprovers))
            .then(() => {
                setEmailApproverInput('');
              dispatch(fetchFileDetails(userType, fileId));
            })
            .finally(() => setLoadingApprover(false));
        }
      };      
      
      
      const removeApprover = (email: string) => {
        setLoadingApprover(true);
        const updatedApprovers = sanitizeEmails(approvers.filter(item => item !== email));
      
        dispatch(addDocumentApprover(userType, fileId, updatedApprovers))
          .then(() => {
            dispatch(fetchFileDetails(userType, fileId));
          })
          .finally(() => setLoadingApprover(false));
      };
      
      

    const handleDeadlineChange = () => {
        if (selectedDate) {
          setLoadingDeadline(true);
          const deadlineString = selectedDate.format('DD-MM-YYYY HH:mm'); 
          dispatch(addDocumentDeadline(userType, fileId, deadlineString))
            .finally(() => setLoadingDeadline(false)); 
        }
      };

      const handleDownload = () => {
        if (file && file.FileUrl) {
          const urlToOpen = Array.isArray(file.FileUrl) ? file.FileUrl[0] : file.FileUrl;
          window.open(urlToOpen, '_blank');
        }
      };
    
      const handleUploadDocument = async () => {
        if (!newFile) return;
        await dispatch(uploadNewDocument(fileId, userType, newFile));
        setNewFile(null); 
      };

      const handleSignClick = () => {
        dispatch(showModal({
            type: 'confirmation',
            message: t('Sign Document'),
            onConfirm: handleConfirmSign,
            onCancel: handleCancel,
            showModal: true,
            subMsg: t('Are you sure you want to sign this document?<br />Other people with access to this document will be notified.')
        }));
    };

    const handleConfirmSign = () => {
        if (!file) {
            console.error("No file selected");
            return;
        }
        const fileId = file.FileID;
        dispatch(signFile(userType, fileId))
        dispatch(hideModal());
    };

    const handleVersionClick = (version: any) => {
        setContent(version.Content);
        setCurrentVersionID(version.VersionID);
        setIsModified(false); 
      };
      
      const handleLatestVersionClick = async () => {
        await DocumentContent(); 
        setCurrentVersionID('latest');
      };
      
    
    return (
        <>
                    <div className={`editor-overlay`}>
                    <div className={`editor ${!isSidebarOpen ? 'expanded' : ''}`}>
                        {file.FileType ? (
                        <div className={`editor-sidebar__wrapper ${isOutlineOpen ? 'open' : ''}`}>
                            {!isOutlineOpen && (
                            <i onClick={toggleOutline} className={`fa-regular fa-list-radio`}></i>
                            )}
                            <div className={`editor-sidebar ${isOutlineOpen ? 'open' : ''}`}>
                            <div className="editor-sidebar-content">
                                <div className="editor-sidebar__header">
                                <h1>{t('Outline')}</h1>
                                <i onClick={toggleOutline} className="fa-regular fa-chevron-left"></i>
                                </div>
                                {outline.map((header, index) => (
                                <div
                                    key={index}
                                    className="outline-item"
                                    style={{ marginLeft: `${(header.level - 1) * 15}px` }}
                                >
                                    <div onClick={() => scrollToHeader(header.title)}>
                                    <h2 className={`outline-header h${header.level}`}>{header.title}</h2>
                                    </div>
                                </div>
                                ))}
                            </div>
                            </div>
                        </div>
                        ) : (
                        <div title="Close Reader" onClick={handleClose} className="reader-lcm__close">
                            <i className="fas fa-xmark"></i>
                        </div>
                        )}



                    <div className={`editor-content `} ref={editorRef}>
                        {file.FileType === "Template" ? (
                            <DocumentViewer documentUrl={file.FileUrl} />
                        ) : (
                                    <div className="editor-main">
                                <div id="toolbar" style={{ justifyContent: comfortView ? 'center' : 'flex-start' }}>
                                    <span className="ql-formats">
                                        <select className="ql-font">
                                            <option value="arial">{t('Arial')}</option>
                                            <option value="times-new-roman">{t('Times New Roman')}</option>
                                            <option value="comic-sans">{t('Comic Sans')}</option>
                                            <option value="courier-new">{t('Courier New')}</option>
                                            <option value="georgia">{t('Georgia')}</option>
                                            <option value="helvetica">{t('Helvetica')}</option>
                                            <option selected value="lucida">{t('Lucida')}</option>
                                            <option value="cursive">{t('Cursive')}</option>

                                            <option value="grey-qo">{t('Grey Qo')}</option>
                                        </select>
                                        <select className="ql-size">
                                            <option value="10px">10{t('px')}</option>
                                            <option value="12px">12{t('px')}</option>
                                            <option value="14px">14{t('px')}</option>
                                            <option value="16px">16{t('px')}</option>
                                            <option value="18px">18{t('px')}</option>
                                            <option value="20px">20{t('px')}</option>
                                            <option value="24px">24{t('px')}</option>
                                            <option value="30px">30{t('px')}</option>
                                        </select>
                                        <select className="ql-header" defaultValue="">
                                                <option value="1">{t('Heading 1')}</option>
                                                <option value="2">{t('Heading 2')}</option>
                                                <option value="3">{t('Heading 3')}</option>
                                                <option value="4">{t('Heading 4')}</option>
                                                <option value="5">{t('Heading 5')}</option>
                                                <option value="6">{t('Heading 6')}</option>
                                                <option value="">{t('Normal')}</option>
                                                </select>
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-bold" />
                                        <button className="ql-italic" />
                                        <button className="ql-underline" />
                                        <button className="ql-strike" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-list" value="ordered" />
                                        <button className="ql-list" value="bullet" />
                                        <button className="ql-indent" value="-1" />
                                        <button className="ql-indent" value="+1" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-script" value="super" />
                                        <button className="ql-script" value="sub" />
                                        <button className="ql-blockquote" />
                                        <button className="ql-direction" />
                                    </span>
                                    <span className="ql-formats">
                                        <select className="ql-align" />
                                        <select className="ql-color" />
                                        <select className="ql-background" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-link" />
                                        <button className="ql-image" />
                                        <button className="ql-video" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-formula" />
                                        <button className="ql-code-block" />
                                        <button className="ql-clean" />
                                    </span>
                                    {/* <span className="ql-formats">
                                        <button className="ql-undo">
                                            <i style={{ color: '#fff', fontSize: 16, marginTop: 2 }} className="fas fa-rotate-left"></i>
                                        </button>
                                        <button className="ql-redo">
                                            <i style={{ color: '#fff', fontSize: 16, marginTop: 2 }} className="fas fa-rotate-right"></i>
                                        </button>
                                    </span> */}
                                </div>

                                    <div className="editor-quill-container">
                                <div style={{ position: 'relative', height: '100%' }} className={comfortView ? 'editor-comfort-view' : ''}>
                                    <div className={`${comfortView ? 'editor-comfort-view-content' : ''} ${comfortView ? 'editor-fullscreen' : ''}`} style={{ position: 'relative', height: '100%' }}>

                                        <ReactQuill
                                            theme="snow"
                                            value={content}
                                            // readOnly={!isEditing} 
                                            onChange={handleContent}
                                            modules={{ toolbar: "#toolbar" }}
                                            className={`editor-textarea ${isDarkTheme ? 'dark' : 'light'} ${comfortView ? 'comfort' : ''}`}
                                        />
                                    </div>
                                    {comfortView && (
                                        <button className="exit-comfort-view-btn" onClick={toggleComfortView}><i className="fa-regular fa-chevron-left"></i></button>
                                    )}

                                </div>
                                </div>
                                </div>
                        )}
                    </div>

                    <div className="editor-actions" style={{ display: file.FileType === "Template" ? 'none' : '' }}>

                        <div className="editor-actions__top--section">
                            <header className="editor-header">
                                <h2 className="editor-title">{fileDetails?.FileName}</h2>
                                {currentVersionID !== 'latest' && (
                                    <div className="version-warning">
                                    {t('Old version')}
                                    </div>
                                )}
                            </header>
                                                    <div className="editor-actions-bottom">
                                                        <div style={{ display: 'flex', gap: 5 }}>
                                <button title="Delete file" className="action-btn delete" onClick={handleDeleteClick}>
                                    <i className="fa-regular fa-trash-can"></i>
                                </button>
                                <button title="Download file" onClick={handleDownload} className="action-btn">
                                    <i className="fa-solid fa-cloud-arrow-down"></i>
                                </button>
                                </div>
                                <button title="Close file manager" onClick={handleClose} className="action-btn close">
                                    <span>{t('Close')}</span>
                                    <i className="fa-regular fa-xmark-circle"></i>
                                </button>
                            </div>
                            <button
                                onClick={handleSave}
                                className="action-btn save"
                                disabled={!isModified}
                            >
                                {t('Save')}
                            </button>

                        <div className="editor-actions--wrapper">
                        <Tooltip title={t('Coming soon')}>
                            <button className="action-btn disabled" disabled onClick={toggleAssigneeDropdown}>
                        <span>{t('Assignees')}</span>
                        <i className="fa-solid fa-file-signature"></i>
                    </button>
                    </Tooltip>

                    {showAssigneeDropdown && (
                        <div className="assignee-dropdown">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="search-assignee"
                                    placeholder={t("Assignee E-Mail...")}
                                    value={emailAssigneeInput}
                                    onChange={e => setEmailAssigneeInput(e.target.value)}
                                />
                                <button className="add-assignee-btn" onClick={addAssignee}>
                                    {loadingAssignee ? <ClipLoader size={15} color="#ffffff" /> : <i className="fa-solid fa-plus"></i>}
                                </button>
                            </div>
                            <ul className="assignee-list">
                                {assignees.length > 0 ? (
                                    assignees.map((assignee: string, index: number) => (
                                        <li key={index} className="assignee-item">
                                            <p title={assignee}>{assignee}</p>
                                            <button className="remove-assignee-btn" onClick={() => removeAssignee(assignee)}>
                                                {loadingAssignee ? <ClipLoader size={12} color="#ffffff" /> : <i className="fa-solid fa-xmark"></i>}
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <div style={{ fontSize: '0.9rem' }} className="non-access">{t('No assignees')}</div>
                                )}
                            </ul>
                        </div>
                    )}

                    {/* Add Shared Access Section */}
                    <Tooltip title={t('Coming soon')}>
                    <button className="action-btn disabled" disabled onClick={toggleShareDropdown}>
                        <span>{t('Access')}</span>
                        <i className="fa-regular fa-share-nodes"></i>
                    </button>
                    </Tooltip>

                    {showShareDropdown && (
                        <div className="assignee-dropdown">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="search-assignee"
                                    placeholder={t("Enter E-Mail...")}
                                    value={emailShareInput}
                                    onChange={e => setEmailShareInput(e.target.value)}
                                />
                                <button className="add-assignee-btn" onClick={addAccess}>
                                    {loadingAccess ? <ClipLoader size={15} color="#ffffff" /> : <i className="fa-solid fa-plus"></i>}
                                </button>
                            </div>
                            <ul className="assignee-list">
                                {sharedAccess.length > 0 ? (
                                    sharedAccess.map((email, index) => (
                                        <li key={index} className="assignee-item">
                                            <p title={email}>{email}</p>
                                            <button className="remove-assignee-btn" onClick={() => removeAccess(email)}>
                                                {loadingAccess ? <ClipLoader size={12} color="#ffffff" /> : <i className="fa-solid fa-xmark"></i>}
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <div style={{ fontSize: '0.9rem' }} className="non-access">{t('No shared access')}</div>
                                )}
                            </ul>
                        </div>
                    )}
                <Tooltip title={t('Coming soon')}>
                    <button className="action-btn disabled" disabled onClick={toggleApproverDropdown}>
                        <span>{t('Approvers')}</span>
                        <i className="fa-regular fa-person-circle-check"></i>
                    </button>
                    </Tooltip>

                    {showApproverDropdown && (
                        <div className="assignee-dropdown">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="search-assignee"
                                    placeholder={t("Enter E-Mail...")}
                                    value={emailApproverInput}
                                    onChange={e => setEmailApproverInput(e.target.value)}
                                />
                                <button className="add-assignee-btn" onClick={addApprover}>
                                    {loadingApprover ? <ClipLoader size={15} color="#ffffff" /> : <i className="fa-solid fa-plus"></i>}
                                </button>
                            </div>
                            <ul className="assignee-list">
                                {approvers.length > 0 ? (
                                    approvers.map((email, index) => (
                                        <li key={index} className="assignee-item">
                                            <p title={email}>{email}</p>
                                            <button className="remove-assignee-btn" onClick={() => removeApprover(email)}>
                                                {loadingApprover ? <ClipLoader size={12} color="#ffffff" /> : <i className="fa-solid fa-xmark"></i>}
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <div style={{ fontSize: '0.9rem' }} className="non-access">{t('No approvers')}</div>
                                )}
                            </ul>
                        </div>
                    )}
                            <button className="action-btn" onClick={toggleVersionControl}>
                                <span>
                                    {t('Version Control')}
                                </span>
                                <i className="fa-regular fa-code-compare"></i>
                            </button>

                            {showVersionControlDropdown && (
                                <div className="assignee-dropdown">
                                    <li
                                        className={`version-item ${currentVersionID === 'latest' ? 'active' : ''}`}
                                        onClick={handleLatestVersionClick}
                                    >
                                        <span className="version-date">{t('Latest Version')}</span>
                                    </li>
                                    <ul className="assignee-list">
                                    {fileVersions && fileVersions.length > 0 ? (
                                            fileVersions.map((version: any, index) => (
                                            <li
                                                key={index}
                                                className={`version-item ${currentVersionID === version.VersionID ? 'active' : ''}`}
                                                onClick={() => handleVersionClick(version)}
                                            >
                                                <span className="version-date">
                                                {dayjs(version.LastModified).format('DD MMM YYYY, HH:mm')}
                                                </span>
                                            </li>
                                            ))
                                        ) : (
                                            <div style={{ fontSize: '0.9rem' }} className="non-access">
                                            {t('No versions available')}
                                         </div>
                                        )}
                                        </ul>
                                </div>
                            )}
                            <Tooltip title={t('Coming soon')}>
                                <button disabled className="action-btn disabled" onClick={handleButtonClick}>
                                        <input id="fileUploadInput" ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                                        {newFile ? (
                                        <div className="uploaded-new-file--docs">
                                            <span>{newFile.name}</span>
                                            <button type="button" onClick={() => setNewFile(null)}>
                                            <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                        ) : (
                                        <>
                                            <span>{t('Insert Document')}</span>
                                            <i className="fa-solid fa-cloud-arrow-up"></i>
                                        </>
                                        )}
                                    </button>
                                    </Tooltip>
                                    {newFile && (
                                        <button className="primary-button" style={{ margin: '0px auto' }} onClick={handleUploadDocument}>
                                        {t('Upload')}
                                        </button>
                                    )}

                        <button className="action-btn deadline" onClick={toggleDeadline}>
                                        <span>{t('Set Deadline')}</span>
                                        <i className="fa-regular fa-calendar-check"></i>
                                    </button>

                                    {isDeadlineOpen && (
                                        <div className='date-picker-container'>
                                        <ThemeProvider theme={darkTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            {/* <DateTimePicker
                                            ampm={false}
                                            value={selectedDate} // Correctly handle Dayjs or null
                                            onChange={(newValue) => setSelectedDate(newValue)}
                                            //   renderInput={(params) => <TextField {...params} />}
                                            /> */}
                                            <CustomDateTimePicker
                                                value={selectedDate}
                                                onChange={(newValue) => setSelectedDate(newValue)}
                                                minDateTime={dayjs()}
                                                width='100%'
                                            />
                                        </LocalizationProvider>
                                        </ThemeProvider>

                                            <button onClick={handleDeadlineChange} className="primary-button">
                                            <i className="fas fa-check"></i>
                                            </button>
                                        </div>
                                    )}

                                        <button onClick={toggleComfortView} className="action-btn">
                                {comfortView ? 'Exit Reading Mode' : <><span>{t('Focus Mode')}</span> <i className="fa-regular fa-presentation-screen"></i></>}
                            </button>
                            <Tooltip title={t('Coming soon')}>
                            <button className="action-btn disabled" disabled onClick={handleTranslateDocument}>
                    <span>{t('Translate')}</span>
                    <i className="fa-solid fa-globe"></i>
                </button>
                </Tooltip>

                <Tooltip title={t('Coming soon')}>
                <button className="action-btn disabled" disabled onClick={handleAnonymizeDocument}>
                    <span>{t('Anonymize')}</span>
                    <i className="far fa-brackets-curly"></i>
                </button>
                </Tooltip>
                            <Tooltip title='Coming soon'>
                            <button onClick={handleSignClick} disabled className="action-btn action-btn__sign">
                                <span>{t('Sign Document')}</span>
                                <i className="fa-kit fa-sign-document"></i>
                            </button>
                            </Tooltip>
                        </div>
                        <div className="editor-actions__bottom--section">
                            <div className="theme-toggle">
                                <ToggleSwitchTheme isOn={isDarkTheme} onToggle={toggleTheme} />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Editor;