import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import "./SignUpSection.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { SignUp, SignUpProps, getCountryOfJurisdictionList } from "../../store/actions/SignUpAction";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store";
import Select, { SingleValue } from 'react-select';
import PricingPlans, { UserType } from "../PlanCard/PricingPlans";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from "react-redux";
import { Pricing } from '../PlanCard/PricingPlans';
import PaymentModal from "../Modal/PaymentModal";
import { showFeedbackModal } from "../../store/actions/UserFeedbackActions";
import { hideModal, showModal } from "../../store/actions/modalActions";
import getStripePricingTable from "./getPricingTable";
import StripePricingTable from "./getPricingTable";
import PricingPage from "../PlanCard/PricingPage";

interface Props {
  changeSection: (section: string) => void;
  setPaymentModalVisible: (value: boolean) => void;
  setIsExpanded: (section: boolean) => void;
}

interface OptionType {
  value: string;
  label: string;
}

interface CountryListProps {
  country: string,
  country_code: string,
  id: string,
  international_dialing: string
}

interface CountryOFJurisdictionProps {
  CountryName: string,
  CountryOfJurisdictionID: string
}


export const useStyles = makeStyles({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
      margin: '0',
      '& .MuiInputBase-root': {
        border: 'none',
        outline: 'none',
        '& input': {
          border: 'none',
          outline: '1px solid #222',
          transition: 'outline 0.3s ease !important',
          '&:focus': {
            outline: '1px solid #64d2ff !important',
            transition: 'outline 0.3s ease !important',
          },
        },
        '& fieldset': {
          display: 'none',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        }
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none !important',
        },
        '&:hover fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
        },
      }
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    }
  }
});


const SignUpSection: React.FC<Props> = ({ changeSection, setPaymentModalVisible, setIsExpanded }) => {
  const [selectedPlan, setSelectedPlan] = useState<string | "">("")
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const [userType, setUserType] = useState<UserType | ''>('');
  const [countryCode, setCountryCode] = useState<string | undefined>('');
  const classes = useStyles();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const countries = useSelector((state: RootState) => state.lists.countries)
  const countryOfJurisdiction = useSelector((state: RootState) => state.lists.CountryOfJurisdictionList)
  const [planDetails, setPlanDetails] = useState<Pricing | null>(null)
  const [interval, setInterval] = useState<boolean>(false)
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([{ value: '', label: '' }]);

  const scrollToBottomImmediate = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const initialValues: SignUpProps = {
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    CPassword: "",
    AgreeToTerms: "No",
    PhoneNumber: "",
    Country: "",
    State: "",
    City: "",
    ZipCode: "",
    StreetAddress: "",
    CountryOfJurisdiction: "",
    KycDocumentType: "",
    DeviceId: "",
    DeviceName: "",
    PracticeArea: "",
    DateOfBirth: "",
    LawyerLicenseCode: "",
    KycDocument: null,
    image: null,
    LawyerLicense: null,
    BusinessName: "",
    ContactName: "",
    OrganizationNumber: "",
    InterestedSolutions: "",
    UserType: "",
    EstdYear: "",
    OfficeAddress: "",
    CountryOfRegistration: "",
    BusinessIdentificationNumber: "",
    BusinessContact: "",
    BusinessEmail: "",
    Industry: "",
    RegistrationDocument: null,
    NumberofUsers: 0,
    FullName: "",
    Address: "",
    PrimaryContact: "",
    LawfirmName: "",
    LawfirmPhoneNumber: "",
    LawfirmEmail: "",
    LicenseNumber: "",
    LegalStructure: "",
    LicenseDocument: null,
    Website: "",
    LawfirmOfficeAddress: "",
    PractiseArea: "",
    NumberOfLicenses: 0
  };

  useEffect(() => {
    if (countryOfJurisdiction && countryOfJurisdiction.length > 0) {
      const options = countryOfJurisdiction.map((country: CountryOFJurisdictionProps) => {
        return {
          value: country.CountryOfJurisdictionID,
          label: country.CountryName
        }
      })
      setOptions(options)
    }
  }, [countryOfJurisdiction])

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_FILE_FORMATS = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

  const calculateAge = (dob: Date) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getValidationSchema = (userType: string | null) => {
    let schemaFields = {
      FirstName: Yup.string(),
      LastName: Yup.string(),
      Email: Yup.string()
      ,
      Password: Yup.string()
        .max(255)
        .min(8, "8+ characters, uppercase, lowercase, number & special (!?&..)")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})/,
          "8+ characters, uppercase, lowercase, number & special (!?&..)"
        )
        .required("Password"),
      CPassword: Yup.string().oneOf(
        [Yup.ref("Password")],
        "Password must be same as new password"
      ),
      PhoneNumber: Yup.string(),
      Country: Yup.string(),
      State: Yup.string(),
      City: Yup.string(),
      ZipCode: Yup.string(),
      StreetAddress: Yup.string(),
      CountryOfJurisdiction: Yup.string(),
      KYCSelfie: Yup.string(),
      PracticeArea: Yup.string(),
      LawyerLicenceCode: Yup.string(),
      DateOfBirth: Yup.string(),
      BusinessName: Yup.string(),
      ContactName: Yup.string(),
      OrganizationNumber: Yup.string(),
      NumberOfLicences: Yup.string(),
      InterestedSolutions: Yup.string(),
      KYCDocument: Yup.mixed(),
      EstdYear: Yup.string(),
      OfficeAddress: Yup.string(),
      CountryOfRegistration: Yup.string(),
      BusinessIdentificationNumber: Yup.string(),
      BusinessContact: Yup.string(),
      BusinessEmail: Yup.string(),
      Industry: Yup.string(),
      NumberofUsers: Yup.number().notRequired(),
      FullName: Yup.string(),
      Address: Yup.string(),
      PrimaryContact: Yup.string(),
      LawfirmName: Yup.string(),
      LawfirmPhoneNumber: Yup.string(),
      LawfirmEmail: Yup.string(),
      LicenseNumber: Yup.string(),
      LegalStructure: Yup.string(),
      Website: Yup.string(),
      LawfirmOfficeAddress: Yup.string(),
      PractiseArea: Yup.string(),
      NumberOfLicenses: Yup.number().notRequired(),
      RegistrationDocument: Yup.mixed().notRequired(),
      LicenseDocument: Yup.mixed().notRequired(),
      LawyerLicense: Yup.mixed().notRequired()

    };

    if (userType === "client" || userType === "lawyer") {
      schemaFields = {
        ...schemaFields,
        FirstName: Yup.string().required("Please enter a First Name"),
        LastName: Yup.string().required("Please enter a Last Name"),
        Email: Yup.string()
          .required("Please enter a E-Mail")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter a valid email address"
          ),

        PhoneNumber: Yup.string().min(8).max(10).required("Please enter a valid phone number"),
        Country: Yup.string().required("Please enter a valid country"),
        State: Yup.string().required("Please enter a valid state"),
        City: Yup.string().required("Please enter a valid city"),
        ZipCode: Yup.string().required("Please enter a valid zip code"),
        StreetAddress: Yup.string().required("Please enter a valid street address"),
        CountryOfJurisdiction: Yup.string(),
        DateOfBirth: Yup.string()
          .matches(
            /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-(19|20)\d\d$/,
            'Date of Birth must be in the format DD-MM-YYYY'
          )
          .test(
            'future-date',
            'Date of birth cannot be in the future',
            function (value) {
              if (!value) return;
              const date = new Date(value.split('-').reverse().join('-'));
              return date <= new Date();
            }
          )
          .test(
            'min-age',
            'You must be at least 18 years old',
            function (value) {
              if (!value) return;
              const age = calculateAge(new Date(value.split('-').reverse().join('-')));
              return age >= 18;
            }
          )
          .test(
            'max-age',
            'Your age cannot exceed 100 years',
            function (value) {
              if (!value) return;
              const age = calculateAge(new Date(value.split('-').reverse().join('-')));
              return age <= 100;
            }
          )
          .required('Date of Birth is required'),
      }
    }
    if (userType === 'lawyer') {
      schemaFields = {
        ...schemaFields,
        PracticeArea: Yup.string().required('Please fill practice area'),
        LawyerLicense: Yup.mixed()
          .required("A file is required")
          .test(
            "fileSize",
            "File too large",
            (value: any) => value && value?.size <= MAX_FILE_SIZE
          )
          .test(
            "fileType",
            "Unsupported file format",
            (value: any) => value && ALLOWED_FILE_FORMATS.includes(value?.type)
          )
      }
    }
    if (userType === 'business') {
      schemaFields = {
        ...schemaFields,
        BusinessName: Yup.string().required("Please enter business name"),
        EstdYear: Yup.string().required("Year of establishment required"),
        OfficeAddress: Yup.string().required("office Address required"),
        CountryOfRegistration: Yup.string().required("Country of registration required"),
        BusinessIdentificationNumber: Yup.string().required("Business Identification Number required"),
        BusinessContact: Yup.string().min(8).max(10).required("Business Contact required"),
        BusinessEmail: Yup.string().required("Business Email required")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter a valid email address"
          ),
        Industry: Yup.string().required("Enter type of industry your business belongs"),
        RegistrationDocument: Yup.mixed()
          .required("A file is required")
          .test(
            "fileSize",
            "File too large",
            (value: any) => value && value?.size <= MAX_FILE_SIZE
          )
          .test(
            "fileType",
            "Unsupported file format",
            (value: any) => value && ALLOWED_FILE_FORMATS.includes(value?.type)
          ),
        NumberofUsers: Yup.number().required("Number of users of your business required"),
        FullName: Yup.string().required("Admin name of your business required"),
        Address: Yup.string().required("Admin address of your business required"),
        Email: Yup.string().required("Please enter a E-Mail")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter a valid email address"
          ),
        PrimaryContact: Yup.string().min(8).max(10).required("Admin primary contact of your business required"),
      }
    }

    if (userType === "lawFirm") {
      schemaFields = {
        ...schemaFields,
        FullName: Yup.string().required("Full name required"),
        Address: Yup.string().required("Address required"),
        PrimaryContact: Yup.string().min(8).max(10).required("Primary contact required"),
        LawfirmName: Yup.string().required("Firm name required"),
        LawfirmPhoneNumber: Yup.string().min(8).max(10).required("Firm phone number required"),
        LawfirmEmail: Yup.string().required("Please enter a E-Mail")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter a valid email address"
          ),
        LicenseNumber: Yup.string().required("Please enter a valid license number"),
        LegalStructure: Yup.string().required("Please enter a legal structure"),
        Website: Yup.string()
          .url('Invalid website URL')
          .required('Website is required'),
        LawfirmOfficeAddress: Yup.string().required("Office address required"),
        PractiseArea: Yup.string().required("Practise area required"),
        NumberOfLicenses: Yup.number().required("Number of licenses required"),
        Email: Yup.string().required("Please enter a  law firm E-Mail")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Please enter a valid email address"
          ),
        LicenseDocument: Yup.mixed()
          .required("A file is required")
          .test(
            "fileSize",
            "File too large",
            (value: any) => value && value?.size <= MAX_FILE_SIZE
          )
          .test(
            "fileType",
            "Unsupported file format",
            (value: any) => value && ALLOWED_FILE_FORMATS.includes(value?.type)
          ),
      }
    }

    return Yup.object().shape(schemaFields);
  };

  const validationSchema = getValidationSchema(userType);


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values: SignUpProps, { resetForm }) => {
      if (!values.AgreeToTerms) {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ('You must accept the terms and conditions to sign up.'),
          modalType: 'error',
          duration: 3000
        }));
        return
      } else {
        values.AgreeToTerms = "yes"
      }

      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          if (typeof value === 'boolean') {
            formData.append(key, value ? 'yes' : 'no');
          } else {
            if (key !== "Subscription_type") {
              if (key === "LawyerLicense" || key === "RegistrationDocument" || key === "LicenseDocument") {
                formData.append(key, value)
              } else {
                if (key === "NumberofUsers" || key === "NumberOfLicenses") {

                  const numberOfUsersInt = parseInt(value, 10);

                  if (!isNaN(numberOfUsersInt)) {
                    formData.append(key, numberOfUsersInt.toString());
                  }
                } else {
                  formData.append(key, value);

                }
              }
            }

          }
        }
      });

      const resp: any = await dispatch(SignUp(formData, userType))
      if (resp) {
        dispatch(showModal({
          type: 'subscription-modal',
          message: '',
          onConfirm: () => { },
          onCancel: () => hideModal(),
          showModal: true,
        }))
      }

      // .then(() => {
      //   setIsOpen(true)
      //   // resetForm();
      //   // window.location.reload();
      // }).catch((error) => {
      //   console.error("Error submitting form:", error);
      // });
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


  let mobileCodes = [
    { value: 'IND +91', label: 'IND +91', },
  ];

  useEffect(() => {
    dispatch(getCountryOfJurisdictionList())
  }, [dispatch])



  if (countries && countries.length > 0) {
    mobileCodes = countries.map((country: CountryListProps) => {
      return {
        value: country.id,
        label: country.country_code + ' ' + country.international_dialing
      }
    })
  }

  useEffect(() => {
    let activeStream: MediaStream | null = null;
    if (isModalOpen) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          activeStream = stream;
          if (videoRef.current) videoRef.current.srcObject = stream;
        })
        .catch(err => {
          console.error('Error accessing the camera:', err);
        });
    }
    return () => {
      if (activeStream) {
        activeStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [isModalOpen]);


  const handleCapture = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = videoRef?.current.videoWidth;
      canvas.height = videoRef?.current.videoHeight;
      context?.drawImage(videoRef?.current, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(blob => {
        if (blob) {
          const imageFile = new File([blob], "userImage.jpg", { type: "image/jpeg" });
          formik.setFieldValue('KYCSelfie', imageFile);
        }
      }, 'image/jpeg');
    }
  };






  // useEffect(() => {
  //   formik.setFieldValue("image", "");
  //   formik.setFieldValue("KycDocument", "");
  //   formik.setFieldValue("LawyerLicence", "");
  // }, [userType])



  const handleName = (name: string) => {

    if (['client', 'business', 'lawyer', 'lawFirm'].includes(name)) {
      setUserType(name as UserType);
      setSelectedPlan("");
      setIsExpanded(true);
      setPlanDetails(null)
      setTimeout(() => { scrollToBottomImmediate() }, 300)
    }
  };

  const uploadLawyerLicence = (event: React.ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      formik.setFieldValue("LawyerLicense", file);
    } else {
      formik.setFieldValue("LawyerLicense", null);
    }
  };

  const handleChangeCountryOfJurisdiction = (
    newValue: SingleValue<OptionType>,
  ) => {


    formik.setFieldValue('CountryOfJurisdiction', newValue?.label);
  };

  const handleCountryCode = (newValue: SingleValue<OptionType>) => {

    setCountryCode(newValue?.value);
  };

  const handleDOBChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('DD-MM-YYYY');
      formik.setFieldValue('DateOfBirth', formattedDate);
    } else {
      formik.setFieldValue('DateOfBirth', '');
    }
  }


  const onClose = () => {
    setIsOpen(false)
  }

  const handleSubscriptionAndSignUp = () => {
    // setIsOpen(true)

    formik.handleSubmit()
  }


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="sign-up-section">
      {isModalOpen && (
        <div style={{ position: 'fixed', top: '10%', left: '10%', backgroundColor: 'white', padding: '20px', zIndex: 100 }}>
          <video ref={videoRef} autoPlay playsInline style={{ width: '100%' }}>
            <button className="create-account-btn" type="button" onClick={() => { handleCapture(); setIsModalOpen(false) }}>Capture Image</button>
          </video>
        </div>
      )}
      {/* {React.createElement('stripe-pricing-table', {
        'pricing-table-id': 'prctbl_1Pqd71CKZInHFLiaa9nH9i9X',
        'publishable-key': 'pk_live_51NUAIpCKZInHFLiaMGTspIzhw1mnR0josNTWmiNx8i5FB6h4Hb9t1mi3defTQBXbuA5Ec9OxsdDjnXOopaHnK0Hn00sFvOvFQu'
      })} */}
      <h1 className="header-create">Create an account</h1>
      <p className="get-started">
        Simplify legal complexities with the power of AI
      </p>

      <div style={{ marginBottom: 30 }}>
        <div className="select-userType">

          <span>PONS is designed to provide a tailored experience, ensuring you find the solutions you seek.</span>
        </div>

        <div className="grid-container-signup">
          <div className={`grid-item ${userType === "client" ? "active" : ""}`} onClick={() => handleName("client")}>
            <h3>Individual Clients</h3>
            <i className="fa-solid fa-gavel card-icons"></i>
            <p className="card-description">Tailored legal guidance for individuals seeking legal serices and guidance.</p>
          </div>
          <div className={`grid-item ${userType === "business" ? "active" : ""}`} onClick={() => handleName("business")}>
            <h3>Business & Enterprise</h3>
            <i className="fa-solid fa-building-columns card-icons"></i>
            <p className="card-description">Secure and enhance your business operations with our suite of legal services</p>
          </div>
          <div className={`grid-item ${userType === "lawyer" ? "active" : ""}`} onClick={() => handleName("lawyer")}>
            <h3>Independent Lawyers</h3>
            <i className="fa-solid fa-scale-balanced card-icons"></i>
            <p className="card-description">Broaden your reach and simplify your workflow with our suite of AI tools.</p>
          </div>
          <div className={`grid-item ${userType === "lawFirm" ? "active" : ""}`} onClick={() => handleName("lawFirm")}>
            <h3>Law Firms</h3>
            <i className="fa-duotone fa-people-group card-icons"></i>
            <p className="card-description">Transform your firm's productivity and service offerings with tailored solutions.</p>
          </div>
        </div>
      </div>
      {
        userType === "" ? (
          <></>
        ) : (
          <>
            <span className="fill-form__text">{`${userType && userType === 'lawFirm' ? 'Please fill out the contact form and our sales team will reach out to you shortly.' : 'Fill in your details below to create a new account and start enjoying our services.'}`}</span>

            <form className="signup--form" noValidate onSubmit={formik.handleSubmit} >
              <div className="input-container">
                {
                  userType === "client" || userType === "lawyer" ? (<>
                    <div className="form-group">
                      <label htmlFor="first-name" className="form-label">First Name</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="FirstName"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.FirstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.FirstName && formik.touched.FirstName ? 'select-box-error' : ''}`}
                        id="first-name"
                      />
                      {formik.errors?.FirstName && formik.touched.FirstName && <div className="error-message-display">{formik.errors?.FirstName}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="last-name" className="form-label">Last Name</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="LastName"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.LastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.LastName && formik.touched.LastName ? 'select-box-error' : ''}`}
                        id="last-name"
                      />
                      {formik.errors?.LastName && formik.touched.LastName && <div className="error-message-display">{formik.errors?.LastName}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="phone-number" className="form-label">Phone Number</label>
                      <div style={{ display: 'flex' }}>
                        <Select
                          id="phone-number"
                          tabIndex={1}
                          options={mobileCodes}
                          classNamePrefix="ng-select"
                          placeholder="Select"
                          onChange={handleCountryCode}
                          value={mobileCodes.find(option => option.value === countryCode ? countryCode : option.value)}
                          className={`code-input`}
                        />
                        <input
                          autoComplete="off"
                          type="text"
                          name="PhoneNumber"
                          maxLength={40}
                          tabIndex={1}
                          value={formik.values?.PhoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input phone-input ${formik.errors?.PhoneNumber && formik.touched.PhoneNumber ? 'select-box-error' : ''}`}
                          id="phone-number"
                        />
                      </div>
                      {formik.errors?.PhoneNumber && formik.touched.PhoneNumber && <div className="error-message-display">{formik.errors?.PhoneNumber}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="email" className="form-label">Email Address</label>
                      <input
                        autoComplete="off"
                        type="email"
                        name="Email"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.Email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.Email && formik.touched.Email ? 'select-box-error' : ''}`}
                        id="email"
                      />
                      {formik.errors?.Email && formik.touched.Email && <div className="error-message-display">{formik.errors?.Email}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="street-address" className="form-label">Street Address</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="StreetAddress"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.StreetAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.StreetAddress && formik.touched.StreetAddress ? 'select-box-error' : ''}`}
                        id="street-address"
                      />
                      {formik.errors?.StreetAddress && formik.touched.StreetAddress && <div className="error-message-display">{formik.errors?.StreetAddress}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="city" className="form-label">City</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="City"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.City}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.City && formik.touched.City ? 'select-box-error' : ''}`}
                        id="city"
                      />
                      {formik.errors?.City && formik.touched.City && <div className="error-message-display">{formik.errors?.City}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="country" className="form-label">Country</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="Country"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.Country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.Country && formik.touched.Country ? 'select-box-error' : ''}`}
                        id="country"
                      />
                      {formik.errors?.Country && formik.touched.Country && <div className="error-message-display">{formik.errors?.Country}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="state" className="form-label">State</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="State"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.State}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.State && formik.touched.State ? 'select-box-error' : ''}`}
                        id="state"
                      />
                      {formik.errors?.State && formik.touched.State && <div className="error-message-display">{formik.errors?.State}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="zip-code" className="form-label">ZipCode</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name="ZipCode"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.ZipCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.ZipCode && formik.touched.ZipCode ? 'select-box-error' : ''}`}
                        id="zip-code"
                      />
                      {formik.errors?.ZipCode && formik.touched.ZipCode && <div className="error-message-display">{formik.errors?.ZipCode}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="password" className="form-label">Password</label>
                      <input
                        autoComplete="off"
                        type="password"
                        name="Password"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.Password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.Password && formik.touched.Password ? 'select-box-error' : ''}`}
                        id="password"
                      />
                      {formik.errors?.Password && formik.touched.Password && <div className="error-message-display">{formik.errors?.Password}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                      <input
                        autoComplete="off"
                        type="password"
                        name="CPassword"
                        maxLength={70}
                        tabIndex={1}
                        value={formik.values?.CPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control-input ${formik.errors?.CPassword && formik.touched.CPassword ? 'select-box-error' : ''}`}
                        id="confirm-password"
                      />
                      {formik.errors?.CPassword && formik.touched.CPassword && <div className="error-message-display">{formik.errors?.CPassword}</div>}
                    </div>

                    <div className="form-group">
                      <label htmlFor="country-of-jurisdiction" className="form-label">Country Of Jurisdiction</label>
                      <Select
                        id="country-of-jurisdiction"
                        tabIndex={1}
                        options={options}
                        classNamePrefix="ng-select"
                        placeholder="Select"
                        onChange={handleChangeCountryOfJurisdiction}
                        value={options.find(option => option.label === formik.values.CountryOfJurisdiction)}
                        className={`${formik.errors?.CountryOfJurisdiction && formik.touched.CountryOfJurisdiction ? 'select-box-error' : ''}`}
                      />

                      {formik.errors?.CountryOfJurisdiction && formik.touched.CountryOfJurisdiction && (
                        <div className="error-message-display">{formik.errors?.CountryOfJurisdiction}</div>
                      )}
                    </div>

                    <ThemeProvider theme={darkTheme}>
                      <div className="form-group">
                        <label htmlFor="date-of-birth" className="form-label">Date of Birth</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div className={classes.root}>
                            <DatePicker
                              value={formik.values.DateOfBirth ? dayjs(formik.values.DateOfBirth) : null}
                              onChange={date => handleDOBChange(date)}
                              format="DD-MM-YYYY"
                              name="DateOfBirth"

                            />
                          </div>
                        </LocalizationProvider>
                        {formik.errors?.DateOfBirth && formik.touched.DateOfBirth && <div className="error-message-display">{formik.errors?.DateOfBirth}</div>}
                      </div>
                    </ThemeProvider>

                    {
                      userType === 'client' ? (
                        <>
                        </>
                      ) : (
                        userType === "lawyer" ? (
                          <>

                            <div className="form-group">
                              <label htmlFor="practice-area" className="form-label">Practice Area</label>
                              <input
                                autoComplete="off"
                                type="text"
                                name="PracticeArea"
                                maxLength={70}
                                tabIndex={1}
                                value={formik.values?.PracticeArea}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`form-control-input ${formik.errors?.PracticeArea && formik.touched.PracticeArea ? 'select-box-error' : ''}`}
                                id="practice-area"
                              />
                              {formik.errors?.PracticeArea && formik.touched.PracticeArea && <div className="error-message-display">{formik.errors?.PracticeArea}</div>}
                            </div>

                            <div className="form-group">
                              <label htmlFor="upload-lawyer-licence" className="form-label">Upload Licence</label>
                              <input
                                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                id="upload-lawyer-licence"
                                type="file"
                                onChange={uploadLawyerLicence}
                                onBlur={formik.handleBlur}
                                className={`form-control-input ${formik.errors?.LawyerLicense && formik.touched.LawyerLicense ? 'select-box-error' : ''}`}
                                maxLength={70}
                                tabIndex={1}
                                required
                                style={{ display: 'none' }}
                              />
                              <label htmlFor="upload-lawyer-licence" className="custom-file-label">
                                Choose file
                              </label>
                              {formik.errors?.LawyerLicense && formik.touched.LawyerLicense && <div className="error-message-display">{formik.errors?.LawyerLicense}</div>}
                            </div>
                          </>
                        ) : (<></>)
                      )
                    }
                  </>) : (
                    userType === 'business' ? (<>
                      <div className="form-group">
                        <label htmlFor="business-name" className="form-label">Business Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="BusinessName"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.BusinessName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.BusinessName && formik.touched.BusinessName ? 'select-box-error' : ''}`}
                          id="business-name"
                        />
                        {formik.errors?.BusinessName && formik.touched.BusinessName && <div className="error-message-display">{formik.errors?.BusinessName}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="estd-year" className="form-label">Established Year</label>
                        <input
                          type="text"
                          id="estd-year"
                          name="EstdYear"
                          value={formik.values.EstdYear}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors.EstdYear && formik.touched.EstdYear ? 'select-box-error' : ''}`}
                        />
                        {formik.errors.EstdYear && formik.touched.EstdYear && <div className="error-message-display">{formik.errors.EstdYear}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="office-address" className="form-label">Office Address</label>
                        <input
                          type="text"
                          id="office-address"
                          name="OfficeAddress"
                          value={formik.values.OfficeAddress}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors.OfficeAddress && formik.touched.OfficeAddress ? 'select-box-error' : ''}`}
                        />
                        {formik.errors.OfficeAddress && formik.touched.OfficeAddress && <div className="error-message-display">{formik.errors.OfficeAddress}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="country-of-registration" className="form-label">Country of Registration</label>
                        <input
                          type="text"
                          id="country-of-registration"
                          name="CountryOfRegistration"
                          value={formik.values.CountryOfRegistration}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors.CountryOfRegistration && formik.touched.CountryOfRegistration ? 'select-box-error' : ''}`}
                        />
                        {formik.errors.CountryOfRegistration && formik.touched.CountryOfRegistration && <div className="error-message-display">{formik.errors.CountryOfRegistration}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="business-identification-number" className="form-label">Business Identification Number</label>
                        <input
                          type="text"
                          id="business-identification-number"
                          name="BusinessIdentificationNumber"
                          value={formik.values.BusinessIdentificationNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors.BusinessIdentificationNumber && formik.touched.BusinessIdentificationNumber ? 'select-box-error' : ''}`}
                        />
                        {formik.errors.BusinessIdentificationNumber && formik.touched.BusinessIdentificationNumber && <div className="error-message-display">{formik.errors.BusinessIdentificationNumber}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="business-contact" className="form-label">Business Contact</label>
                        <input
                          type="text"
                          id="business-contact"
                          name="BusinessContact"
                          value={formik.values.BusinessContact}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors.BusinessContact && formik.touched.BusinessContact ? 'select-box-error' : ''}`}
                        />
                        {formik.errors.BusinessContact && formik.touched.BusinessContact && <div className="error-message-display">{formik.errors.BusinessContact}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="business-email" className="form-label">Business Email</label>
                        <input
                          autoComplete="off"
                          type="email"
                          name="BusinessEmail"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.BusinessEmail}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.BusinessEmail && formik.touched.BusinessEmail ? 'select-box-error' : ''}`}
                          id="business-email"
                        />
                        {formik.errors?.BusinessEmail && formik.touched.BusinessEmail && <div className="error-message-display">{formik.errors?.BusinessEmail}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="industry" className="form-label">Industry</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="Industry"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Industry}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Industry && formik.touched.Industry ? 'select-box-error' : ''}`}
                          id="industry"
                        />
                        {formik.errors?.Industry && formik.touched.Industry && <div className="error-message-display">{formik.errors?.Industry}</div>}
                      </div>
                      {/* Registration Document Upload */}
                      <div className="form-group">
                        <label htmlFor="registration-document" className="form-label">Registration Document</label>
                        <input
                          type="file"
                          id="registration-document"
                          name="RegistrationDocument"
                          onChange={(event) => {
                            if (event.currentTarget.files) {
                              formik.setFieldValue("RegistrationDocument", event.currentTarget.files[0]);
                            }
                          }}
                          style={{ display: 'none' }}
                          className={`form-control-input ${formik.errors.RegistrationDocument && formik.touched.RegistrationDocument ? 'select-box-error' : ''}`}
                        />
                        <label htmlFor="registration-document" className="custom-file-label">
                          Choose file
                        </label>
                        {formik.errors.RegistrationDocument && formik.touched.RegistrationDocument && <div className="error-message-display">{formik.errors.RegistrationDocument}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="NumberofUsers" className="form-label">Number of Users</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="NumberofUsers"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.NumberofUsers}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.NumberofUsers && formik.touched.NumberofUsers ? 'select-box-error' : ''}`}
                          id="NumberofUsers"
                        />
                        {formik.errors?.NumberofUsers && formik.touched.NumberofUsers && <div className="error-message-display">{formik.errors?.NumberofUsers}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="FullName" className="form-label">Full Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="FullName"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.FullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.FullName && formik.touched.FullName ? 'select-box-error' : ''}`}
                          id="FullName"
                        />
                        {formik.errors?.FullName && formik.touched.FullName && <div className="error-message-display">{formik.errors?.FullName}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="Address" className="form-label">Address</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="Address"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Address && formik.touched.Address ? 'select-box-error' : ''}`}
                          id="Address"
                        />
                        {formik.errors?.Address && formik.touched.Address && <div className="error-message-display">{formik.errors?.Address}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Email" className="form-label">Email</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="Email"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Email && formik.touched.Email ? 'select-box-error' : ''}`}
                          id="Email"
                        />
                        {formik.errors?.Email && formik.touched.Email && <div className="error-message-display">{formik.errors?.Email}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="PrimaryContact" className="form-label">Primary Contact</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="PrimaryContact"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.PrimaryContact}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.PrimaryContact && formik.touched.PrimaryContact ? 'select-box-error' : ''}`}
                          id="PrimaryContact"
                        />
                        {formik.errors?.PrimaryContact && formik.touched.PrimaryContact && <div className="error-message-display">{formik.errors?.PrimaryContact}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Password" className="form-label">Password</label>
                        <input
                          autoComplete="off"
                          type="password"
                          name="Password"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Password && formik.touched.Password ? 'select-box-error' : ''}`}
                          id="Password"
                        />
                        {formik.errors?.Password && formik.touched.Password && <div className="error-message-display">{formik.errors?.Password}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="CPassword" className="form-label">Confirm Password</label>
                        <input
                          autoComplete="off"
                          type="password"
                          name="CPassword"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.CPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.CPassword && formik.touched.CPassword ? 'select-box-error' : ''}`}
                          id="CPassword"
                        />
                        {formik.errors?.CPassword && formik.touched.CPassword && <div className="error-message-display">{formik.errors?.CPassword}</div>}
                      </div>

                    </>) : (<>
                      {/* <div className="form-group">
                        <label htmlFor="LawfirmName" className="form-label">Firm Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="LawfirmName"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.LawfirmName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.LawfirmName && formik.touched.LawfirmName ? 'select-box-error' : ''}`}
                          id="LawfirmName"
                        />
                        {formik.errors?.LawfirmName && formik.touched.LawfirmName && <div className="error-message-display">{formik.errors?.LawfirmName}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="LawfirmPhoneNumber" className="form-label">Firm phone number</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="LawfirmPhoneNumber"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.LawfirmPhoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.LawfirmPhoneNumber && formik.touched.LawfirmPhoneNumber ? 'select-box-error' : ''}`}
                          id="LawfirmPhoneNumber"
                        />
                        {formik.errors?.LawfirmPhoneNumber && formik.touched.LawfirmPhoneNumber && <div className="error-message-display">{formik.errors?.LawfirmPhoneNumber}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="LawfirmEmail" className="form-label">Firm official email </label>
                        <div style={{ display: 'flex' }}>
                          <input
                            autoComplete="off"
                            type="email"
                            name="LawfirmEmail"
                            maxLength={40}
                            tabIndex={1}
                            value={formik.values?.LawfirmEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control-input ${formik.errors?.LawfirmEmail && formik.touched.LawfirmEmail ? 'select-box-error' : ''}`}
                            id="phone-number"
                          />
                        </div>
                        {formik.errors?.LawfirmEmail && formik.touched.LawfirmEmail && <div className="error-message-display">{formik.errors?.LawfirmEmail}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="LicenseNumber" className="form-label">License Number</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="LicenseNumber"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.LicenseNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.LicenseNumber && formik.touched.LicenseNumber ? 'select-box-error' : ''}`}
                          id="LicenseNumber"
                        />
                        {formik.errors?.LicenseNumber && formik.touched.LicenseNumber && <div className="error-message-display">{formik.errors?.LicenseNumber}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="LegalStructure" className="form-label">Legal Structure</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="LegalStructure"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.LegalStructure}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.LegalStructure && formik.touched.LegalStructure ? 'select-box-error' : ''}`}
                          id="LegalStructure"
                        />
                        {formik.errors?.LegalStructure && formik.touched.LegalStructure && <div className="error-message-display">{formik.errors?.LegalStructure}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="Website" className="form-label">Website</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="Website"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Website}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Website && formik.touched.Website ? 'select-box-error' : ''}`}
                          id="Website"
                        />
                        {formik.errors?.Website && formik.touched.Website && <div className="error-message-display">{formik.errors?.Website}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="LawfirmOfficeAddress" className="form-label">firm office address</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="LawfirmOfficeAddress"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.LawfirmOfficeAddress}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.LawfirmOfficeAddress && formik.touched.LawfirmOfficeAddress ? 'select-box-error' : ''}`}
                          id="LawfirmOfficeAddress"
                        />
                        {formik.errors?.LawfirmOfficeAddress && formik.touched.LawfirmOfficeAddress && <div className="error-message-display">{formik.errors?.LawfirmOfficeAddress}</div>}
                      </div>

                      {/* Registration Document Upload
                      <div className="form-group">
                        <label htmlFor="registration-document" className="form-label">License Document</label>
                        <input
                          type="file"
                          id="registration-document"
                          name="LicenseDocument"
                          onChange={(event) => {
                            if (event.currentTarget.files) {
                              formik.setFieldValue("LicenseDocument", event.currentTarget.files[0]);
                            }
                          }}
                          className={`form-control-input ${formik.errors.LicenseDocument && formik.touched.LicenseDocument ? 'select-box-error' : ''}`}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor="registration-document" className="custom-file-label">
                          Choose file
                        </label>
                        {formik.errors.LicenseDocument && formik.touched.LicenseDocument && <div className="error-message-display">{formik.errors.LicenseDocument}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="FullName" className="form-label">Full Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="FullName"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.FullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.FullName && formik.touched.FullName ? 'select-box-error' : ''}`}
                          id="FullName"
                        />
                        {formik.errors?.FullName && formik.touched.FullName && <div className="error-message-display">{formik.errors?.FullName}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Address" className="form-label">Address</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="Address"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Address && formik.touched.Address ? 'select-box-error' : ''}`}
                          id="Address"
                        />
                        {formik.errors?.Address && formik.touched.Address && <div className="error-message-display">{formik.errors?.Address}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Email" className="form-label">Email</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="Email"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Email && formik.touched.Email ? 'select-box-error' : ''}`}
                          id="Email"
                        />
                        {formik.errors?.Email && formik.touched.Email && <div className="error-message-display">{formik.errors?.Email}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="PrimaryContact" className="form-label">Primary Contact</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="PrimaryContact"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.PrimaryContact}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.PrimaryContact && formik.touched.PrimaryContact ? 'select-box-error' : ''}`}
                          id="PrimaryContact"
                        />
                        {formik.errors?.PrimaryContact && formik.touched.PrimaryContact && <div className="error-message-display">{formik.errors?.PrimaryContact}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="PractiseArea" className="form-label">Practice Area</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="PractiseArea"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.PractiseArea}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.PractiseArea && formik.touched.PractiseArea ? 'select-box-error' : ''}`}
                          id="PractiseArea"
                        />
                        {formik.errors?.PractiseArea && formik.touched.PractiseArea && <div className="error-message-display">{formik.errors?.PractiseArea}</div>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="NumberOfLicenses" className="form-label">Number of licenses</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="NumberOfLicenses"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.NumberOfLicenses}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.NumberOfLicenses && formik.touched.NumberOfLicenses ? 'select-box-error' : ''}`}
                          id="NumberOfLicenses"
                        />
                        {formik.errors?.NumberOfLicenses && formik.touched.NumberOfLicenses && <div className="error-message-display">{formik.errors?.NumberOfLicenses}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Password" className="form-label">Password</label>
                        <input
                          autoComplete="off"
                          type="password"
                          name="Password"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.Password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.Password && formik.touched.Password ? 'select-box-error' : ''}`}
                          id="Password"
                        />
                        {formik.errors?.Password && formik.touched.Password && <div className="error-message-display">{formik.errors?.Password}</div>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="CPassword" className="form-label">Confirm Password</label>
                        <input
                          autoComplete="off"
                          type="password"
                          name="CPassword"
                          maxLength={70}
                          tabIndex={1}
                          value={formik.values?.CPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={`form-control-input ${formik.errors?.CPassword && formik.touched.CPassword ? 'select-box-error' : ''}`}
                          id="CPassword"
                        />
                        {formik.errors?.CPassword && formik.touched.CPassword && <div className="error-message-display">{formik.errors?.CPassword}</div>}
                      </div> */}
                    </>)
                  )
                }
              </div>
              {userType && userType !== 'lawFirm' && (
              <div className="checkbox-container">
                <input
                  id="agreeToTerms"
                  className="checkbox-terms-conditions"
                  type="checkbox"
                  name="AgreeToTerms"
                  onChange={formik.handleChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="agreeToTerms" className="check-terms-conditions">
                  <span className="custom-checkbox"></span>
                  I agree to the{"\u00A0"}
                  <Link onClick={() => changeSection("terms")} to="#">
                    Terms & Conditions
                  </Link>
                  {"\u00A0"}and{"\u00A0"}
                  <Link onClick={() => changeSection("privacy")} to="#">
                    Privacy & Policy
                  </Link>
                </label>
              </div>
              )}
              
              {
                <div style={{ width: '100%' }}>
                <StripePricingTable userType={userType} />
                </div>
               
              }
            {userType && userType !== 'lawFirm' && (
              <div className="create-account-btn--wrapper">
                <button
                  className="create-account-btn"
                  // type="submit"
                  // disabled={true}
                  onClick={() => handleSubscriptionAndSignUp()}
                  >
                  Continue
                </button>
              </div>
            )}
            </form>

            <PricingPage />

            {
              planDetails &&
              <PaymentModal isOpen={isOpen} onClose={onClose} data={{
                plan: planDetails,
                interval: interval ? 'year' : 'month',
                email: formik.values.Email
              }} />
            }
          </>
        )
      }
      <div ref={messageEndRef}></div>
    </div >
  );
};


export default SignUpSection;