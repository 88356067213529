import { RootState, useAppDispatch } from '../../../store';
import { getUserID, setSelectedModel } from '../../../store/actions/ChatAction';
import { useHistory } from 'react-router-dom';
import './ClientBusiness.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SuggestedFilesContainer from '../../Documents/SuggestedFilesContainer';
import { FileProps } from '../../../pages/DocumentsPage';
import { selectDocumentForEditing } from '../../../store/actions/DocumentsAction';
import TaskDeadlineTracker from '../../shared/TaskDeadlineTracker';
import ProjectOverviewWidget from '../../Projects/ProjectsOverviewWidget';

interface LegalDeadline {
    id: number;
    title: string;
    dueDate: Date;
    description: string;
}

const ClientBusiness = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory();
    const TodoList = useSelector((state: RootState) => state.toDo.activeTasks)
    const userId = getUserID()
    const userType = getUserID()

    // useEffect(() => {
    //     dispatch(ActiveTasks(userId, userType))
    // }, [])

    const handleServiceClick = (selectedModel: string) => {
        dispatch(setSelectedModel(selectedModel));
        history.push("/ai-assistant");
    };

    const deadlines: LegalDeadline[] = [
        { id: 1, title: 'Contract Renewal ABC Corp.', dueDate: new Date('2024-05-01'), description: 'Renewal of service contract with ABC Corporation.' },
        { id: 2, title: 'Submit Patent Application', dueDate: new Date('2024-06-15'), description: 'Submission deadline for patent application XYZ.' },
        { id: 3, title: 'Contract Renewal ABC Corp.', dueDate: new Date('2024-05-01'), description: 'Renewal of service contract with ABC Corporation.' },
        { id: 4, title: 'Submit Patent Application', dueDate: new Date('2024-06-15'), description: 'Submission deadline for patent application XYZ.' },
    ];

    const sortedDeadlines = deadlines.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());

    const handleDocumentClick = (document: FileProps) => {
        dispatch(selectDocumentForEditing(document));
        history.push('/documents');
    };

    
    return (
        <div className="client-business-main__container">
            <div className="client-business-container">
                <div style={{ height: '100%', flex: 0.7 }}>
            <TaskDeadlineTracker />
            </div>
                <div className="business-client-container" style={{ flex: 1 }}>
                <ProjectOverviewWidget />
                </div>
            </div >

            <div className="client-business-container">
                <div className="business-client-container">
                    <div className='bussiness-client-header-container'>
                        <span>{t('AI Services')}</span>
                    </div>
                    <div className="client-business-services--list">
                        <button onClick={() => handleServiceClick("Legal Case Mapping")}>{t('Legal Case Mapping')}</button>
                        <button onClick={() => handleServiceClick("Law Research")}>{t('Law Research')}</button>
                        <button onClick={() => handleServiceClick("Contract Generation")}>{t('Contract Generation')}</button>
                        <button onClick={() => handleServiceClick("Contract Analysis")}>{t('Contract Analysis')}</button>
                        <button onClick={() => handleServiceClick("Company Agent")}>{t('Company Agent')}</button>
                        <button onClick={() => handleServiceClick("Company Agent")}>{t('Custom Agent')}</button>
                    </div>
                </div >
                <div className="business-client-container">
                    <SuggestedFilesContainer setSelectedFile={handleDocumentClick} />
                </div>
            </div>
        </div>
    )

}


export default ClientBusiness;