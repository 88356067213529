import { useEffect, useState } from 'react';
import LoginSection from '../components/LoginSection/LoginSection';
import SignUpSection from '../components/SignUpSection/SignUpSection';
import TermsConditions from '../components/SignUpRequirements/TermsConditions';
import PrivacyPolicy from '../components/SignUpRequirements/PrivacyPolicy';
import PaymentModal from '../components/Modal/PaymentModal';
import './FirstPage.css'
import SocketServices from '../utils/SocketServices';
import VisualizationCanvas from '../components/SignUpSection/VisualizationCanva';

const FirstPage = () => {
  const [currentSection, setCurrentSection] = useState("signup");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isLoginExpanded, setIsLoginExpanded] = useState(false);
  const isDevEnvironment = window.location.hostname.includes('dev.pons.io');
  const isDemoEnvironment = window.location.hostname.includes('demo.pons.io')
    || window.location.hostname.includes('beta.pons.io');


  const [isLoginExpanded, setIsLoginExpanded] = useState(isDemoEnvironment);

  const changeSection = (section: string) => {
    setCurrentSection(section);
  };

  const handlePaymentModalClose = () => {
    setPaymentModalVisible(false);
  };
  // SocketServices.initializeSocket()
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', transition: 'all 0.3s ease' }} className="first-page">
      {isExpanded && !isDemoEnvironment && (
        <i onClick={() => setIsExpanded(false)} className='fas fa-chevron-right icon-hover-effect'></i>
      )}
      <div style={{ width: (isLoginExpanded || isDemoEnvironment) ? '100%' : (isExpanded ? '0%' : '43%'), transition: 'width 0.3s ease', overflowY: 'hidden' }} className="first-page__left">
        <LoginSection isLoading={isLoading} setIsLoading={setIsLoading} setIsLoginExpanded={setIsLoginExpanded} />
      </div>
      {!isDemoEnvironment && (
        <div
          style={{ width: isLoginExpanded ? '0%' : (isExpanded ? '100%' : '57%'), transition: 'width 0.3s ease', overflowY: 'auto', background: '#0D0D12' }}
          className="first-page__right"
        >
                    <VisualizationCanvas />
          {/* {currentSection === "signup" && <SignUpSection changeSection={changeSection} setPaymentModalVisible={setPaymentModalVisible} setIsExpanded={setIsExpanded} />}
          {currentSection === "terms" && <TermsConditions changeSection={changeSection} />}
          {currentSection === "privacy" && <PrivacyPolicy changeSection={changeSection} />} */}
        </div>
      )}
      <PaymentModal isOpen={paymentModalVisible} onClose={handlePaymentModalClose} />
    </div>
  );
};

export default FirstPage;