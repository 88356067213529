import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Account from './AccountSettings/AccountSettings';
import Security from './Security/SecuritySettings';
import Finance from './FinanceSettings';
import './Settings.css';
import PrivateRoute from '../../PrivateRoutes';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect } from 'react';
import SocketServices from '../../utils/SocketServices';
import CompanySettings from './CompanySettings';

const Settings = () => {
    let { path } = useRouteMatch();
    const userInfo = useSelector((state: RootState) => state.user.userInfo)

    useEffect(() => {
        // SocketServices.initializeSocket()
    }, [])

    return (
        <div className="settings-container-header">

            <Switch>
                <Route exact path={path} render={() => <Redirect to={`${path}/account`} />} />
                <Route exact path={`${path}/account`} component={Account} />
                <PrivateRoute path={`${path}/security`} component={Security} subscriptionId={userInfo.stripe_subscription_id} restricted={false} />
                <PrivateRoute path={`${path}/finance`} component={Finance} subscriptionId={userInfo.stripe_subscription_id} restricted={true} />
                <PrivateRoute path={`${path}/company`} component={CompanySettings} subscriptionId={userInfo.stripe_subscription_id} restricted={false} />
            </Switch>
        </div>
    );
}

export default Settings;
