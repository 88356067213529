import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.css';
import { signOut } from "../../store/actions/SignInAction";
import { RootState, useAppDispatch } from "../../store";
import { getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { closeSidebar, toggleSidebar } from '../../store/actions/SidebarActions';
import SocketServices from '../../utils/SocketServices';
import { Tooltip } from '@mui/material';

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const userType = getUserType();
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [isMarketplaceOpen, setIsMarketplaceOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/marketplace')) {
      setIsMarketplaceOpen(true);
    } else {
      setIsMarketplaceOpen(false);
    }

    if (location.pathname.startsWith('/settings')) {
      setIsSettingsOpen(true);
    } else {
      setIsSettingsOpen(false);
    }

    if (
      location.pathname.startsWith('/dashboard/admin') ||
      location.pathname.startsWith('/dashboard/finance') ||
      location.pathname.startsWith('/dashboard/message-center') ||
      location.pathname.startsWith('/dashboard/panel') ||
      location.pathname.startsWith('/dashboard/time-keeping') ||
      location.pathname.startsWith('/dashboard/time-tracking')
    ) {
      setIsDashboardOpen(true);
    } else {
      setIsDashboardOpen(false);
    }
  }, [location.pathname]);

  const determineActiveIndex = (): number => {
    if (location.pathname.startsWith("/dashboard/marketplace-page")) {
      return 2;
    }
    switch (location.pathname) {
      case "/dashboard": return 0;
      case "/ai-assistant": return 1;
      case "/documents": return 3;
      case "/analytics": return 4;
      case "/settings": return 5;
      case "/help": return 6;
      case "/feedback": return 7;
      default: return 0;
    }
  };

  const [index, setIndex] = useState(determineActiveIndex());

  const handleLogout = async () => {
    await dispatch(signOut());
  };

  useEffect(() => {
    const newIndex = determineActiveIndex();
    if (index !== newIndex) {
      setIndex(newIndex);
    }
  }, [location, index]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'b') {
      event.preventDefault();
      handleToggleSidebar();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        dispatch(closeSidebar());
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setIsTextVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsTextVisible(true);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleMarketplaceToggle = () => {
    setIsMarketplaceOpen(!isMarketplaceOpen);
    setIsDashboardOpen(false)
    setIsSettingsOpen(false)
  };

  const handleSettingsToggle = () => {
    setIsSettingsOpen(!isSettingsOpen);
    setIsMarketplaceOpen(false)
    setIsDashboardOpen(false)
  };

  const handleDashboardToggle = () => {
    setIsDashboardOpen(!isDashboardOpen);
    setIsMarketplaceOpen(false)
    setIsSettingsOpen(false)
  };

  const resetToggles = () => {
    setIsDashboardOpen(false)
    setIsMarketplaceOpen(false)
    setIsSettingsOpen(false)
  }
  // SocketServices.initializeSocket()
  // useEffect(() => {
  //   SocketServices.initializeSocket(); // This will initializeSocket the socket connection

  //   // Optional: If you want to perform clean-up when the component unmounts,
  //   // you could return a function here that closes the connection, like so:
  //   return () => {
  //     SocketServices.disconnect(); // Disconnect socket on unmount if necessary
  //   };
  // }, []);


  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="logo-section">
          <NavLink onClick={resetToggles} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', paddingLeft: 0 }} exact to={`${(userType === 'Lawfirmlawyer' || userType === 'BusinessAdmin' ? '/dashboard/panel' : userType === "LawfirmAdmin" ? '/dashboard/admin' : '/dashboard' )}`}>
            <i style={{ fontSize: '3rem', color: '#64d2ff' }} className="fa-kit fa-logo company-logo-sidebar-header"></i>
            {isOpen && <h1 className={`sidebar-title ${!isTextVisible ? 'fade-out' : ''}`}>PONS</h1>}
          </NavLink>
        </div>

        <div className='sidebar-wrapper'>
          <div className="nav-section">
            <ul className="sidebar-list">
              {(userType === "LawfirmAdmin" || userType === "Lawfirmlawyer" || userType === "BusinessAdmin") ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <li>
                    <Tooltip title={`${!isOpen ? t('Dashboard') : ''}`}>
                      <a onClick={handleDashboardToggle} className={`${isDashboardOpen && 'active'}`}>
                        <i className="bi bi-grid-fill"></i>
                        {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Dashboard')}</span>}
                        {isOpen && (
                          <i className={`fa-solid fa-chevron-${isDashboardOpen ? 'up' : 'down'}`} style={{ marginLeft: 'auto' }}></i>
                        )}
                      </a>
                    </Tooltip>
                  </li>

                  {isDashboardOpen && (
                    <ul className="sidebar-submenu">
                      {userType === "LawfirmAdmin" ? (
                        <>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Admin') : ''}`}>
                              <NavLink to="/dashboard/admin" activeClassName="active">
                                <i className="fa-solid fa-user-shield"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Admin')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Finance') : ''}`}>
                              <NavLink to="/dashboard/finance" activeClassName="active">
                                <i className="fa-solid fa-coins"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Finance')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Message Center') : ''}`}>
                              <NavLink to="/dashboard/message-center" activeClassName="active">
                                <i className="fa-solid fa-comments"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Message Center')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Time Tracking') : ''}`}>
                              <NavLink to="/dashboard/time-tracking" activeClassName="active">
                                <i className="fas fa-clock"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Time Tracking')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                        </>
                      ) : userType === "Lawfirmlawyer" ? (
                        <>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Panel') : ''}`}>
                              <NavLink to="/dashboard/panel" activeClassName="active">
                                <i className="fa-solid fa-square-dashed"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Panel')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Time Keeping') : ''}`}>
                              <NavLink to="/dashboard/time-keeping" activeClassName="active">
                                <i className="fas fa-clock"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Time Keeping')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Message Center') : ''}`}>
                              <NavLink to="/dashboard/message-center" activeClassName="active">
                                <i className="fa-solid fa-comments"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Message Center')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                        </>
                      ) : userType === "BusinessAdmin" && (
                        <>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Panel') : ''}`}>
                              <NavLink to="/dashboard/panel" activeClassName="active">
                                <i className="fa-solid fa-square-dashed"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Panel')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Admin') : ''}`}>
                              <NavLink to="/dashboard/admin" activeClassName="active">
                                <i className="fas fa-user-shield"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Admin')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                            <Tooltip title={`${!isOpen ? t('Message Center') : ''}`}>
                              <NavLink to="/dashboard/message-center" activeClassName="active">
                                <i className="fa-solid fa-comments"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Message Center')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                        </>
                        )}
                    </ul>
                  )}
                </div>
              ) : (
                <li>
                  <Tooltip title={`${!isOpen ? t('Dashboard') : ''}`}>
                    <NavLink onClick={resetToggles} exact to="/dashboard" activeClassName="active">
                      <i className="bi bi-grid-fill"></i>
                      {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Dashboard')}</span>}
                    </NavLink>
                  </Tooltip>
                </li>
              )}

                {(userType === "Lawyer") && (
                          <>
                            <li>
                            <Tooltip title={`${!isOpen ? t('Time Keeping') : ''}`}>
                              <NavLink to="/time-keeping" activeClassName="active">
                                <i className="fas fa-clock"></i>
                                <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Time Keeping')}</span>
                              </NavLink>
                            </Tooltip>
                          </li>
                          <li>
                          <Tooltip title={`${!isOpen ? t('Message Center') : ''}`}>
                            <NavLink to="/message-center" activeClassName="active">
                              <i className="fa-solid fa-comments"></i>
                              <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Message Center')}</span>
                            </NavLink>
                          </Tooltip>
                        </li>
                        </>
          )}

              {(userType === "Lawfirmlawyer" || userType === "LawfirmAdmin" || userType === "Lawyer") && (
                <li>
                  <Tooltip title={`${!isOpen ? t('Client Portal') : ''}`}>
                    <NavLink onClick={resetToggles} exact to="/client-portal" activeClassName="active">
                      <i className="fa-duotone fa-solid fa-chart-network"></i>
                      {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Client Portal')}</span>}
                    </NavLink>
                  </Tooltip>
                </li>
              )}

              {(userType === "BusinessAdmin" || userType === "Employee") && (
                <li>
                  <Tooltip title={`${!isOpen ? t('Projects') : ''}`}>
                    <NavLink onClick={resetToggles} exact to="/projects" activeClassName="active">
                      <i className="fa-duotone fa-solid fa-diagram-project"></i>
                      {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Projects')}</span>}
                    </NavLink>
                  </Tooltip>
                </li>
              )}

              <li>
                <Tooltip title={`${!isOpen ? t('AI Assistant') : ''}`}>
                  <NavLink onClick={resetToggles} to="/ai-assistant" activeClassName="active">
                    <i className="bi bi-stars"></i>
                    {isOpen && <span style={{ whiteSpace: 'nowrap' }} className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('AI Assistant')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <li>
                  <Tooltip title={`${!isOpen ? t('Marketplace') : ''}`}>
                    <a onClick={handleMarketplaceToggle} className={`${isMarketplaceOpen && 'active'}`}>
                      <i style={{ marginRight: '1rem' }} className="fa-regular fa-scale-balanced"></i>
                      {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Marketplace')}</span>}
                      {isOpen && (
                        <i className={`fa-solid fa-chevron-${isMarketplaceOpen ? 'up' : 'down'}`} style={{ marginLeft: 'auto' }}></i>
                      )}
                    </a>
                  </Tooltip>
                </li>

                {isMarketplaceOpen && (
                  <ul className="sidebar-submenu">
                    {userType === 'Client' && (
                      <>
                        <li>
                          <Tooltip title={`${!isOpen ? t('Cases') : ''}`}>
                            <NavLink to="/marketplace/client/cases" activeClassName="active">
                              <i className="fa-solid fa-briefcase"></i>
                              <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Cases')}</span>
                            </NavLink>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title={`${!isOpen ? t('Lawyers') : ''}`}>
                            <NavLink to="/marketplace/client/lawyers" activeClassName="active">
                              <i className="fa-solid fa-gavel"></i>
                              <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Lawyers')}</span>
                            </NavLink>
                          </Tooltip>
                        </li>
                      </>
                    )}
                    {(userType === 'Lawyer' || userType === 'LawfirmAdmin' || userType === 'Lawfirmlawyer') && (
                      <>
                        <li>
                          <Tooltip title={`${!isOpen ? t('Market') : ''}`}>
                            <NavLink to="/marketplace/lawyer/market" activeClassName="active">
                              <i className="fa-solid fa-globe"></i>
                              <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Market')}</span>
                            </NavLink>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title={`${!isOpen ? t('Cases') : ''}`}>
                            <NavLink to="/marketplace/lawyer/cases" activeClassName="active">
                              <i className="fa-solid fa-briefcase"></i>
                              <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Cases')}</span>
                            </NavLink>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title={`${!isOpen ? t('Profile') : ''}`}>
                            <NavLink to="/marketplace/lawyer/profile" activeClassName="active">
                              <i className="fa-solid fa-square-user"></i>
                              <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Profile')}</span>
                            </NavLink>
                          </Tooltip>
                        </li>
                      </>
                    )}
                  </ul>
                )}
              </div>

              <li>
                <Tooltip title={`${!isOpen ? t('Documents') : ''}`}>
                  <NavLink onClick={resetToggles} to="/documents" activeClassName="active">
                    <i className="fas fa-folders"></i>
                    {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Documents')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip title={`${!isOpen ? t('Analytics') : ''}`}>
                  <NavLink onClick={resetToggles} to="/analytics" activeClassName="active">
                    <i className="bi bi-bar-chart-fill"></i>
                    {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Analytics')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <li>
                  <Tooltip title={`${!isOpen ? t('Settings') : ''}`}>
                    <a onClick={handleSettingsToggle} className={`${isSettingsOpen && 'active'}`}>
                      <i className="bi bi-gear-fill"></i>
                      {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Settings')}</span>}
                      {isOpen && (
                        <i className={`fa-solid fa-chevron-${isSettingsOpen ? 'up' : 'down'}`} style={{ marginLeft: 'auto' }}></i>
                      )}
                    </a>
                  </Tooltip>
                </li>

                {isSettingsOpen && (
                  <ul className="sidebar-submenu">
                    <li>
                      <Tooltip title={`${!isOpen ? t('Account') : ''}`}>
                        <NavLink to="/settings/account" activeClassName="active">
                          <i className="fa-solid fa-user-cog"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Account')}</span>
                        </NavLink>
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title={`${!isOpen ? t('Security') : ''}`}>
                        <NavLink to="/settings/security" activeClassName="active">
                          <i className="fa-solid fa-shield-alt"></i>
                          <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Security')}</span>
                        </NavLink>
                      </Tooltip>
                    </li>
                    {(userType === "LawfirmAdmin" || userType === "BusinessAdmin") ? (
                      <li>
                        <Tooltip title={`${!isOpen ? t('Company') : ''}`}>
                          <NavLink to="/settings/company" activeClassName="active">
                            <i className="fa-solid fa-building"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Company')}</span>
                          </NavLink>
                        </Tooltip>
                      </li>
                    ) : (
                      <li>
                        <Tooltip title={`${!isOpen ? t('Finance') : ''}`}>
                          <NavLink to="/settings/finance" activeClassName="active">
                            <i className="fa-solid fa-wallet"></i>
                            <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Finance')}</span>
                          </NavLink>
                        </Tooltip>
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <li>
                <Tooltip title={`${!isOpen ? t('Feedback') : ''}`}>
                  <NavLink onClick={resetToggles} to="/feedback" activeClassName="active">
                    <i style={{ fontSize: '0.9rem' }} className="fa-solid fa-comment-dots"></i>
                    {isOpen && <span className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Feedback')}</span>}
                  </NavLink>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>

        <li className='sidebar-help--section'>
          <div style={{ borderTop: '1px solid #64d2ff', marginRight: 5, paddingTop: 10 }}>
            <Tooltip title={`${!isOpen ? t('Help') : ''}`}>
              <NavLink onClick={resetToggles} to="/help" activeClassName="active">
                <i className="bi bi-question-circle"></i>
                {isOpen && <span style={{ paddingRight: 5 }} className={`${!isTextVisible ? 'fade-out' : ''}`}>{t('Help & get started')}</span>}
              </NavLink>
            </Tooltip>
          </div>
        </li>
        <div title={isOpen ? 'Collapse sidebar' : 'Expand sidebar'} className={`sidebar-clickable-area ${isOpen ? '' : 'closed'}`} onClick={handleToggleSidebar}>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
