import './AiAssistant.css';
import { useEffect, useRef, useState } from 'react';
import FolderStructure from './FolderStructure';
import ChatBot from './ChatBot';
import MiniMyDocument from './MiniMyDocument';
import {
  ServiceAPIResponse,
  callServiceAPI,
  chatGroupApi,
  chatHistoryApi,
  fetchContracts,
  getUserID,
  getUserType,
  setChatGroupIDStore,
  setFolderVisibility,
  setSelectedModel,
} from '../../store/actions/ChatAction';
import { RootState, useAppDispatch } from '../../store';
import { fetchStructure } from '../../store/actions/FolderAction';
import { useSelector } from 'react-redux';
import PromptBar from './PromptBar';
import { editDocument, fetchDocumentContent, fetchFileDetails } from '../../store/actions/DocumentsAction';
import { Link, useLocation } from 'react-router-dom';
import { Message } from './MessageList';
import { FileProps } from '../../pages/DocumentsPage';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { setVoice } from '../../store/actions/SpeechAction';
import { setFontSize } from '../../store/actions/FontSizeAction';
import { useTranslation } from 'react-i18next';
import { getAISettings, updateAISettings } from '../../store/actions/DataAction';
import { ContractType } from './Contract Generator/ContractGenerator';
import { getBlobDocuments, getIndexInfo } from '../../store/actions/CompanyAgentAction';

type AiAssistantLocationState = {
  selectedModel?: string;
};

const AiAssistant = () => {
  const { t } = useTranslation();

  // Redux State Selectors
  const isFolderStructureVisible = useSelector((state: RootState) => state.model.isFolderStructureVisible);
  const [isDocumentVisible, setDocumentVisible] = useState(false);
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const [folderState, setFolderState] = useState('FOLDERS');
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [isDocumentExpanded, setDocumentExpanded] = useState(false);
  const [contractTypeID, setContractTypeID] = useState<number | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [originalContent, setOriginalContent] = useState('');
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const structure = useSelector((state: RootState) => state.folders.structure);
  const [chatName, setChatName] = useState('');
  const location = useLocation<AiAssistantLocationState>();
  const { selectedModel: selectedServiceFromDashboard } = location.state || {};
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const [chatGroupID, setChatGroupID] = useState('');
  const [Document1, setDocument1] = useState<File | null>(null);
  const [Document2, setDocument2] = useState<File | null>(null);
  const [startNewChat, setStartNewChat] = useState(false);
  const userType = getUserType();
  const [isEditing, setIsEditing] = useState(false);
  const aiSettings = useSelector((state: RootState) => state.lists.aiSettings);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [selectedAILanguage, setSelectedAILanguage] = useState('');
  const [selectedVoice, setSelectedVoiceState] = useState('');
  const [selectedFontSize, setSelectedFontSizeState] = useState('');
  const fontSize = useSelector((state: RootState) => state.fontSize.fontSize);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const chatGroupss = useSelector((state: RootState) => state.model.chatGroups);
  const currentFetch = useRef<AbortController | null>(null);
  const [latestResponse, setLatestResponse] = useState<ServiceAPIResponse | null>(null);
  const [loaderText, setLoaderText] = useState(t('Please wait...'));
  const { speechKey, serviceRegion, voice } = useSelector((state: RootState) => state.speechSettings);
  const hasSentFirstMessage = messages.length > 0;
  const groupID = useSelector((state: RootState) => state.model.chatGroupID)

  useEffect(() => {
    if (location.pathname === '/ai-assistant') {
      if (selectedModel) {
        localStorage.setItem('selectedModel', selectedModel);
      } else {
        localStorage.removeItem('selectedModel');
      }
  
      if (chatGroupID) {
        localStorage.setItem('chatGroupID', chatGroupID);
      } else {
        localStorage.removeItem('chatGroupID');
      }
    }
  }, [selectedModel, chatGroupID, dispatch]);  

useEffect(() => {
  const storedModel = localStorage.getItem('selectedModel');
  const { selectedModel: selectedServiceFromDashboard } = location.state || {};

  const modelToSet = selectedServiceFromDashboard || storedModel || 'Law Research';

  dispatch(setSelectedModel(modelToSet));

  if (userType) {
    dispatch(chatGroupApi(userId, removeSpaces(modelToSet), userType));
  }
}, [dispatch, userId, location.state]);

  


  // Fetch contracts and folder structure on mount
  useEffect(() => {
    dispatch(fetchContracts());
    dispatch(fetchStructure());
    dispatch(getIndexInfo());
  }, [dispatch]);

  // Fetch additional data based on user type
  useEffect(() => {
    if (userType !== 'Client' && userType !== 'Lawyer') {
      dispatch(getIndexInfo());
      dispatch(getBlobDocuments());
    }
  }, [dispatch, userType]);

  // Fetch AI settings based on user type
  useEffect(() => {
    dispatch(getAISettings(userType));
  }, [dispatch, userId, userType]);

  // Set AI settings state
  useEffect(() => {
    if (aiSettings) {
      setSelectedAILanguage(aiSettings.AILanguageResponse);
      setSelectedVoiceState(aiSettings.AIVoice);
      setSelectedFontSizeState(aiSettings.FontSize);
      setIsDarkTheme(aiSettings.ThemeColor === 'dark');
    }
  }, [aiSettings]);

  // Handlers for AI settings
  const handleSelectAIVoice = (value: string) => {
    setSelectedVoiceState(value);
    dispatch(setVoice(value));
  };

  const handleSelectAILanguage = (value: string) => {
    setSelectedAILanguage(value);
  };

  const handleSelectFontSize = (value: string) => {
    setSelectedFontSizeState(value);
    dispatch(setFontSize(value));
  };

  const handleThemeToggle = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  // Save AI settings changes
  const handleSaveChanges = () => {
    const updatedAISettings = {
      UserID: userId,
      UserType: userType,
      AIVoice: selectedVoice,
      AILanguageResponse: selectedAILanguage,
      FontSize: fontSize,
      ThemeColor: isDarkTheme ? 'dark' : 'light',
      CountryOfJurisdiction: aiSettings?.CountryOfJurisdiction || '',
      FontFamily: 'Poppins',
    };
    dispatch(updateAISettings(updatedAISettings));
  };

  // Text-to-speech function
  const speakText = (textToSpeak: string): void => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(speechKey, serviceRegion);
    speechConfig.speechSynthesisVoiceName = voice;
    const speechSynthesizer = new sdk.SpeechSynthesizer(speechConfig);

    speechSynthesizer.speakTextAsync(
      textToSpeak,
      (result: sdk.SpeechSynthesisResult) => {
        speechSynthesizer.close();
      },
      (error: any) => {
        console.error('Error synthesizing speech:', error);
        speechSynthesizer.close();
      }
    );
  };

  // Editing handlers
  const handleEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    switch (selectedModel) {
      case 'Contract Generation':
        setLoaderText(t('Generating Contract...'));
        break;
      case 'Law Research':
        setLoaderText(t('Inspecting your concern...'));
        break;
      case 'Contract Analysis':
        setLoaderText(t('Analysing your contract...'));
        break;
      case 'Case Research':
        setLoaderText(t('Analysing your case...'));
        break;
      case 'Legal Case Mapping (Clients)':
        setLoaderText(t('Mapping client-related legal cases...'));
        break;
      case 'Legal Case Mapping (Business)':
        setLoaderText(t('Mapping business-related legal cases...'));
        break;
      case 'Company Agent':
        setLoaderText(t('Activating Company Agent...'));
        break;
      case 'Custom Agent':
        setLoaderText(t('Activating Custom Agent...'));
        break;
      default:
        setLoaderText(t('Please wait...'));
    }

    if (isLoading) {
      timer = setTimeout(() => {
        setLoaderText(t('Almost complete...'));
      }, 30000);
    }

    return () => {
      clearTimeout(timer);
      if (!isLoading) {
        setLoaderText(t('Please wait...'));
      }
    };
  }, [selectedModel, isLoading, t]);

  // Fetch document content
  const DocumentContent = async (file: FileProps) => {
    try {
      let resp: any = await dispatch(fetchDocumentContent(userId, file.FileID, userType));
      if (resp) {
        setFileContent(resp.content);
      }
    } catch (error) {
      console.error('Error fetching document content:', error);
    }
  };

  // Save document changes
  const handleSave = async () => {
    if (selectedFile) {
      dispatch(editDocument(selectedFile.FileID, selectedFile.FileName, fileContent, userType));
    }
  };

  // Clear messages and reset chat group ID when model or new chat starts
  useEffect(() => {
    setMessages([]);
    setChatGroupID('');
    if (startNewChat) {
      setStartNewChat(false);
    }
  }, [selectedModel, startNewChat]);

  // Theme toggle handler
  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  // Initialize selected model from dashboard
  useEffect(() => {
    if (selectedServiceFromDashboard) {
      dispatch(setSelectedModel(selectedServiceFromDashboard));
    }
    // Removed redundant function call commented out
  }, [dispatch, selectedServiceFromDashboard]);

  // Close file and view folders
  const closeFileAndViewFolders = () => {
    setDocumentVisible(false);
    dispatch(setFolderVisibility(isFolderStructureVisible));
    setFolderState('FOLDERS');
  };

  // Utility function to remove spaces
  function removeSpaces(str: string): string {
    return str.replace(/\s+/g, '');
  }

  // Editing handlers
  const handleEditClick = () => {
    if (!isEditing) {
      setOriginalContent(fileContent);
    }
    setIsEditing(!isEditing);
  };

  const handleRevertClick = () => {
    setFileContent(originalContent);
    setIsEditing(false);
  };

  const toggleFolderStructure = () => {
    dispatch(setFolderVisibility(!isFolderStructureVisible));
  };

  const openFile = () => {
    setDocumentVisible(!isDocumentVisible);
    setFolderState('FILE');
    if (selectedFile) {
      setDocumentVisible(isDocumentVisible);
    }
  };

  const onFileClick = async (file: FileProps) => {    const fileDetails = await dispatch(fetchFileDetails(userType, file.FileID));
  
    if (fileDetails) {
      setSelectedFile(fileDetails);
      openFile();
      DocumentContent(fileDetails);
    } else {
      // Handle the case where file details couldn't be fetched
      console.error('Failed to fetch file details.');
    }
  };

  const toggleDocumentExpansion = () => {
    setDocumentExpanded(!isDocumentExpanded);
    dispatch(setFolderVisibility(!isFolderStructureVisible));
  };

  const onConfirm = (confirmation: string) => {
    // Handle confirmation if needed
  };

  const toggleViewMode = () => {
    setDocumentVisible((prevVisible) => !prevVisible);
  };

const onSend = async (input: string) => {
  setLoaderText(t('Processing your request...'));
  setIsLoading(true);

  // Add user message and a placeholder assistant message
  setMessages((prevMessages) => [
    ...prevMessages,
    { text: input, sender: 'user', action: null, file1: Document1, file2: Document2 },
    { text: t('Loading...'), sender: 'assistant', action: null },
  ]);

  // Declare response outside the try-catch to access it later
  let response: ServiceAPIResponse | null = null;

  try {
    switch (selectedModel) {
      case 'Law Research':
        response = await callServiceAPI('LawResearch', { Command: input }, undefined, undefined, chatGroupID);
        break;
      case 'Case Research':
        response = await callServiceAPI('CaseResearch', { Command: input }, Document1 || undefined, undefined, chatGroupID);
        break;
      case 'Contract Analysis':
        response = await callServiceAPI('ContractAdvisor', { Command: input }, Document1, Document2, chatGroupID);
        break;
      case 'Contract Generation':
        response = await callServiceAPI(
          'ContractGenerator',
          { ContractTypeID: contractTypeID, Command: input },
          undefined,
          undefined,
          chatGroupID
        );
        break;
      case 'Company Agent':
        response = await callServiceAPI('CompanyAgent', { UserInput: input }, undefined, undefined, chatGroupID);
        break;
      case 'Legal Case Mapping (Clients)':
      case 'Legal Case Mapping (Business)':
        response = await callServiceAPI(selectedModel, { Command: input }, undefined, undefined, chatGroupID);
        break;
      case 'Custom Agent':
        response = await callServiceAPI('CustomAgent', { Command: input }, undefined, undefined, chatGroupID);
        break;
      default:
        throw new Error(`Service ${selectedModel} not recognized`);
    }

    // Handle successful responses
    if (response?.ChatGroupID && !chatGroupID) {
      setChatGroupID(response.ChatGroupID);
      dispatch(setChatGroupIDStore(response.ChatGroupID));
    }

    // Determine the appropriate response text based on the model
    let responseText = '';
    switch (selectedModel) {
      case 'Law Research':
        responseText = response?.LawResearchResponse || t('No response');
        break;
      case 'Case Research':
        responseText = response?.CaseResearchResponse || t('No response');
        break;
      case 'Contract Analysis':
        responseText = response?.ContractAnalysisResponse || t('No response');
        break;
      case 'Contract Generation':
        responseText = response?.ContractGenerationResponse || t('No response');
        break;
      case 'Company Agent':
      case 'Legal Case Mapping (Clients)':
      case 'Legal Case Mapping (Business)':
      case 'Custom Agent':
        responseText = response?.ChatResponse || t('No response');
        break;
      default:
        responseText = t('No response');
    }

    // Check if responseText is undefined or empty
    if (!responseText) {
      // Set a default error message
      responseText = t('No response received. Please try again later.');
    }

    // Replace the 'Loading...' message with the actual response
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastIndex = newMessages.length - 1;
      if (newMessages[lastIndex].sender === 'assistant') {
        newMessages[lastIndex] = { text: responseText, sender: 'assistant', action: null };
      } else {
        newMessages.push({ text: responseText, sender: 'assistant', action: null });
      }
      return newMessages;
    });

    setLatestResponse(response);
    setLoaderText(t('Please wait...'));
    setIsLoading(false);
    setDocument1(null);
    setDocument2(null);
  } catch (err: any) {

    // Initialize a default error message
    let errorMessage: string = t('An error occurred. Please try again.');

    // Check if the error is a 400 Bad Request
    if (err.response && err.response.status === 400) {
      errorMessage = t('No preferred settings are set for this user. Please go to AI settings at /settings/account and set your preferences to chat with the AI assistant.');
    }

    // Replace the last assistant message ('Loading...') with the error message and include a link
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastIndex = newMessages.length - 1;
      const messageContent = {
        text: t('An error has occured. Please refresh and try again. If the error presists, please contact support.'),
        link: {
          href: '/settings/account',
          text: t('AI settings'),
        },
        sender: 'assistant' as const,
        action: null,
      };
      if (newMessages[lastIndex].sender === 'assistant') {
        newMessages[lastIndex] = messageContent;
      } else {
        newMessages.push(messageContent);
      }
      return newMessages;
    });

    setLoaderText(t('Please wait...'));
    setIsLoading(false);
  }

  // After the try-catch, check if response is undefined or null
  if (typeof response === 'undefined' || response === null) {
    // Replace the last assistant message ('Loading...') with the error message and include a link
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastIndex = newMessages.length - 1;
      const messageContent = {
        text: t('An error has occured. Please refresh and try again. If the error presists, please contact support.'),
        link: {
          href: '/settings/account',
          text: t('AI settings'),
        },
        sender: 'assistant' as const,
        action: null,
      };
      if (newMessages[lastIndex].sender === 'assistant') {
        newMessages[lastIndex] = messageContent;
      } else {
        newMessages.push(messageContent);
      }
      return newMessages;
    });

    setLoaderText(t('Please wait...'));
    setIsLoading(false);
  }
};

  // Initiate Contract Generation
  const initiateContractGeneration = async (contract: ContractType) => {
    try {
      setIsLoading(true);
      setContractTypeID(contract.ContractTypeID);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: `${t('Initiating')} ${contract.ContractType}...`, sender: 'user', action: null },
        { text: t('Loading...'), sender: 'assistant', action: null },
      ]);

      const response = await callServiceAPI(
        'ContractGenerator',
        { ContractTypeID: contract.ContractTypeID, Command: '', GroupID: '' },
        undefined,
        undefined,
        chatGroupID
      );

      if (response?.ChatGroupID && !chatGroupID) {
        setChatGroupID(response.ChatGroupID);
      }

      const responseText = response?.ContractGenerationResponse || t('No response');

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[newMessages.length - 1] = { text: responseText, sender: 'assistant', action: null };
        return newMessages;
      });

      setIsLoading(false);
    } catch (err) {
      console.error('Error in initiateContractGeneration:', err);
      setIsLoading(false);
    }
  };

  const uploadedFile = null;
  const handleFileDrop = () => {
    // Implement file drop handling if needed
  };

  // Handle fetching chat history
  const handleChatHistory = async (chatId: string) => {
    setMessages([]);
    setChatGroupID(chatId);
    dispatch(setChatGroupIDStore(chatId));
    setMessages((prevMessages) => [...prevMessages, { text: t('Loading...'), sender: 'assistant', action: null }]);

    const abortController = new AbortController();
    const signal = abortController.signal;

    if (currentFetch.current) {
      currentFetch.current.abort();
    }
    currentFetch.current = abortController;

    if (chatId) {
      try {
        const resp = await dispatch(chatHistoryApi(userId, selectedModel, chatId, getUserType(), signal));
        if (resp && resp.length > 0) {
          const newMessages = resp.slice().reverse().flatMap((message: { Prompt: string; Reply: string }) => [
            { text: message.Prompt, sender: "user" as const, action: null, oldChat: true },
            { text: message.Reply, sender: "assistant" as const, action: null, oldChat: true }
          ]);
          setMessages(newMessages);
        } else {
          setMessages([{ text: t('No chat history found.'), sender: 'assistant', action: null }]);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error('Failed to fetch chat history:', error);
          setMessages([{ text: t('Failed to load chat history.'), sender: 'assistant', action: null }]);
        }
      }
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (currentFetch.current) {
        currentFetch.current.abort();
      }
    };
  }, []);

  return (
    <>
      <div
        className={`ai-assistant-container 
          ${!isSidebarOpen && !isFolderStructureVisible ? 'expanded' : ''} 
          ${isSidebarOpen && !isFolderStructureVisible ? 'expand-w-sidebar' : ''}`}
      >
        <div
          onClick={toggleFolderStructure}
          className={`reopen-folder-arrow ${isFolderStructureVisible ? 'hide' : 'show'} ${
            selectedModel.includes('Legal Case Mapping') || selectedModel === 'Contract Generation' ? 'hide' : ''
          }`}
        >
          <i className="fas fa-chevron-left"></i>
        </div>

        <div
          className={`ai-assistant__chatbot-container ${
            selectedModel === 'Law Research' || selectedModel === 'Case Research' ? '' : 'expanded'
          } ${!isSidebarOpen && !isFolderStructureVisible && 'expanded'} ${
            (isDocumentVisible &&
              !isFolderStructureVisible &&
              (selectedModel === 'Law Research' || selectedModel === 'Case Research')) ||
            (isDocumentVisible &&
              !isFolderStructureVisible &&
              selectedModel === 'Company Agent')
              ? ''
              : 'shrink'
          }`}
        >
          <ChatBot
            selectedModel={selectedModel}
            isDocumentVisible={isDocumentVisible}
            isFolderStructureVisible={isFolderStructureVisible}
            messages={messages}
            onConfirm={onConfirm}
            isLoading={isLoading}
            setMessages={setMessages}
            setChatName={setChatName}
            setDocument1={setDocument1}
            setDocument2={setDocument2}
            Document1={Document1}
            Document2={Document2}
            loaderText={loaderText}
            speakText={speakText}
            latestResponse={latestResponse}
            initiateContractGeneration={initiateContractGeneration}
            hasSentFirstMessage={hasSentFirstMessage}
          />

          {/* Render PromptBar for relevant models */}
          {selectedModel !== 'Legal Case Mapping (Clients)' &&
            selectedModel !== 'Legal Case Mapping (Business)' &&
            // selectedModel !== 'Custom Agent' &&
            (selectedModel !== 'Contract Generation' || (selectedModel === 'Contract Generation' && hasSentFirstMessage)) && (
              <PromptBar
                onSend={onSend}
                isDocumentVisible={isDocumentVisible}
                uploadedFile={uploadedFile}
                handleFileDrop={handleFileDrop}
                selectedService={selectedModel}
                isFolderStructureVisible={isFolderStructureVisible}
              />
            )}
        </div>

        {/* Render MiniMyDocument for relevant models */}
        {((selectedFile && isDocumentVisible && selectedModel === 'Law Research') ||
          (selectedFile && isDocumentVisible && selectedModel === 'Case Research') ||
          (selectedFile && isDocumentVisible && selectedModel === 'Company Agent') ||
          (selectedFile && isDocumentVisible && selectedModel.includes('Legal Case Mapping')) ||
          (selectedFile && isDocumentVisible && selectedModel === 'Custom Agent')) && (
          <div className={`mini-mydoc-container ${isFolderStructureVisible ? 'expanded' : ''}`}>
            <MiniMyDocument
              isFolderStructureVisible={isFolderStructureVisible}
              fileContent={fileContent}
              setFileContent={setFileContent}
              toggleDocumentExpansion={toggleDocumentExpansion}
              isDocumentVisible={isDocumentVisible}
              toggleFolderStructure={toggleFolderStructure}
              closeFileAndViewFolders={closeFileAndViewFolders}
              toggleViewMode={toggleViewMode}
              isEditing={isEditing}
              isDarkTheme={isDarkTheme}
              onEdit={handleEdit}
            />
          </div>
        )}

        {/* Render FolderStructure for relevant models */}
        <div className={`folder-chatbot ${isFolderStructureVisible ? '' : 'hidden'}`}>
          {(selectedModel === 'Law Research' ||
            selectedModel === 'Case Research' ||
            selectedModel === 'Contract Analysis' ||
            selectedModel === 'Company Agent' ||
            selectedModel.includes('Legal Case Mapping') ||
            selectedModel === 'Custom Agent') && (
            <FolderStructure
              toggleFolderStructure={toggleTheme}
              openFile={openFile}
              folderState={folderState}
              isFolderStructureVisible={isFolderStructureVisible}
              onFileClick={onFileClick}
              selectedFile={selectedFile}
              isDocumentVisible={isDocumentVisible}
              fileContent={fileContent}
              setFileContent={setFileContent}
              onEditSaveClick={handleEditClick}
              onRevertClick={handleRevertClick}
              isEditing={isEditing}
              folders={structure}
              chatName={chatName}
              chatGroups={chatGroupss}
              handleChatHistory={handleChatHistory}
              setStartNewChat={setStartNewChat}
              userId={userId}
              handleSave={handleSave}
              setIsDarkTheme={toggleTheme}
              isDarkTheme={isDarkTheme}
              handleSelectAIVoice={handleSelectAIVoice}
              handleSelectAILanguage={handleSelectAILanguage}
              handleSelectFontSize={handleSelectFontSize}
              toggleTheme={handleThemeToggle}
              selectedFontSize={selectedFontSize}
              selectedAILanguage={selectedAILanguage}
              selectedVoice={selectedVoice}
              handleSaveChanges={handleSaveChanges}
              setDocumentVisible={toggleViewMode}
              selectedModel={selectedModel}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AiAssistant;
