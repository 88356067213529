import React, { useEffect, useState } from 'react';
import { Employee, ClientCase } from '../data/dummyData';
import './MessageCenter.css';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { formatTime } from './ChatWindow';
import { Lawyer } from '../interface/IFirmLawyer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface ChatListProps {
  chatList: (Employee | ClientCase)[];
  selectedChat: number | null | string;
  onChatSelect: (id: string) => void;
  unreadMessages: any;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  handleSelectEmployee: (employee: Lawyer) => void;
}

const ChatList: React.FC<ChatListProps> = ({
  chatList,
  selectedChat,
  onChatSelect,
  unreadMessages,
  isLoading,
  setIsLoading,
  handleSelectEmployee,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const { loading, lawyers } = useSelector((state: RootState) => state.lawfirm);

  useEffect(() => {
    setIsLoading(false);
  }, [chatList, setIsLoading]);

  // Get emails of users in chatList
  const chatListEmails = chatList.map((chat: any) => chat.userData.Email);

  // Filter lawyers to get employees who are not in chatList
  const newEmployees = lawyers.filter(
    (employee: Lawyer) => !chatListEmails.includes(employee.Email)
  );

  // Combine chatList and newEmployees
  const combinedList = [
    ...chatList.map((chat: any) => ({ type: 'chat', data: chat })),
    ...newEmployees.map((employee: Lawyer) => ({ type: 'employee', data: employee })),
  ];

  // Filtering function
  const filterCombinedList = (list: any[]) => {
    if (!searchTerm.trim()) {
      return list;
    }

    return list.filter((item: any) => {
      if (item.type === 'chat') {
        const chat = item.data;
        const fullName =
          ((chat.userData?.FirstName || '') + ' ' + (chat.userData?.LastName || '')).toLowerCase();
        const email = (chat.userData?.Email || '').toLowerCase();
        return (
          fullName.includes(searchTerm.toLowerCase()) ||
          email.includes(searchTerm.toLowerCase())
        );
      } else if (item.type === 'employee') {
        const employee = item.data;
        const fullName =
          ((employee?.FirstName || '') + ' ' + (employee?.LastName || '')).toLowerCase();
        const email = (employee?.Email || '').toLowerCase();
        return (
          fullName.includes(searchTerm.toLowerCase()) ||
          email.includes(searchTerm.toLowerCase())
        );
      }
      return false;
    });
  };

  const filteredCombinedList = filterCombinedList(combinedList);

  // Separate filtered lists
  const filteredChats = filteredCombinedList.filter((item) => item.type === 'chat');
  const filteredEmployees = filteredCombinedList.filter((item) => item.type === 'employee');

  return (
    <ul className="chat-list">
      <input
        type="text"
        placeholder={t('Search...')}
        className="employee-search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {isLoading ? (
        <li key={1} className={`chat-list-item }`}>
          <ClipLoader size={20} color="#fff" />
        </li>
      ) : (
        <>
          {filteredChats.length > 0 && (
            <>
              {/* <div className="list-divider">{t('Existing Chats')}</div> */}
              {filteredChats.map((item: any) => {
                const chat = item.data;
                return (
                  <li
                    key={chat.chatID}
                    className={`chat-list-item ${selectedChat === chat.chatID ? 'selected' : ''}`}
                    onClick={() => onChatSelect(chat.chatID)}
                  >
                    <div className="chat-item-header">
                      <div className="chat-item-title">
                        {chat.userData.FullName
                          ? chat.userData.FullName
                          : chat.userData.FirstName + ' ' + chat.userData.LastName}
                      </div>
                      <div className="chat-item-role">{chat.userData.CompanyRole}</div>
                      <div
                        className={`chat-item-last-message ${
                          chat.unread_count > 0 || unreadMessages[chat.chatID] > 0
                            ? 'unread_msg_color'
                            : ''
                        }`}
                      >
                        {chat.last_message_content}
                      </div>
                    </div>
                    <div className="system-msg-bubble-div">
                      <div
                        className={`chat-item-last-message ${
                          chat.unread_count > 0 || unreadMessages[chat.chatID] > 0
                            ? 'unread_msg_color'
                            : ''
                        }`}
                      >
                        {formatTime(chat.last_message_at)}
                      </div>
                      {!selectedChat &&
                        (unreadMessages[chat.chatID] > 0 || chat.unread_count > 0) && (
                          <div className="msg-bubble">
                            <span className={`unread-msg-count`}>
                              {unreadMessages[chat.chatID] > 0
                                ? chat.unread_count > 0
                                  ? chat.unread_count + unreadMessages[chat.chatID]
                                  : unreadMessages[chat.chatID]
                                : chat.unread_count}
                            </span>
                          </div>
                        )}
                    </div>
                  </li>
                );
              })}
            </>
          )}
          {filteredEmployees.length > 0 && (
            <>
              <div className="list-divider">{t('Start new chat')}</div>
              {filteredEmployees.map((item: any) => {
                const employee = item.data;
                return (
                  <li
                    key={employee.LawfirmLawyerID}
                    className={`emp-list-item ${
                      selectedChat === employee.LawfirmLawyerID ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectEmployee(employee)}
                  >
                    <div className="emp-item-header">
                      <div className="chat-item-title">
                        {userInfo.Email === employee.Email ? (
                          <>
                            {t('You')}
                            <div className="chat-item-role">{employee.CompanyRole}</div>
                          </>
                        ) : (
                          <>
                            {employee.FirstName + ' ' + employee.LastName}
                            <div className="chat-item-role">{employee.CompanyRole}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </>
          )}
          {filteredChats.length === 0 && filteredEmployees.length === 0 && (
            <div className="non-access">{t('No Chats or Employees')}</div>
          )}
        </>
      )}
    </ul>
  );
};

export default ChatList;
