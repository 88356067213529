import React, { useEffect } from 'react';
import './Help.css';
import { Section, Description } from './types';
import { useAppDispatch, RootState } from '../../store';
import { showHelpContentSidePanel } from '../../store/actions/sidePanelActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserType } from '../../store/actions/ChatAction';
import { getUserManual } from '../../store/actions/DataAction';
import { ClipLoader } from 'react-spinners';

const Help: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  
  const helpDataState = useSelector((state: RootState) => state.lists); 

  useEffect(() => {
    if (userType) {
      dispatch(getUserManual(userType, 'en'));
    }
  }, [dispatch, userType]);

  const handleDescriptionClick = (section: Section, selectedDescription: Description) => {
    dispatch(showHelpContentSidePanel(section, selectedDescription));
  };

  if (helpDataState.loading) {
    return (
      <div style={{ width: '95%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader size={30} color='#fff' />
      </div>
    );
  }

  if (helpDataState.error) {
    return (
      <div className="help-container">
        <div className="non-access">
          {t('Help content is coming soon.')}
        </div>
      </div>
    );
  }

  if (!helpDataState.data) {
    return (
      <div className="help-container">
        <div className="non-access">
          {t('Help content is coming soon.')}
        </div>
      </div>
    );
  }

  const currentHelpData = helpDataState.data;

  const gettingStartedSection = currentHelpData.sections.find(
    (section: Section) => section.main_component === 'Get Started'
  );

  const otherSections = currentHelpData.sections.filter(
    (section: Section) => section.main_component !== 'Get Started'
  );

  return (
    <div className="help-container">
      {gettingStartedSection && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: 20,
          }}
        >
          <div style={{ flex: '1' }}>
            <h2>{t('What is PONS?')}</h2>
            <span
              style={{
                color: '#ccc',
                fontSize: '0.9rem',
                whiteSpace: 'pre-line',
              }}
            >
              {currentHelpData.message}
            </span>
          </div>
          <button
            className="secondary-button"
            onClick={() =>
              handleDescriptionClick(gettingStartedSection, gettingStartedSection.descriptions[0])
            }
            style={{ marginRight: 15 }}
          >
            <i className="fas fa-rocket"></i>
            {t('Get Started')}
          </button>
        </div>
      )}
      <div className="help-grid">
        {otherSections.map((section: Section, index: number) => (
          <div key={index} className="help-section">
            <div className="icon-container">
              <i
                className={`fas ${section.icon || 'fa-question-circle'}`}
                style={{ color: '#64d2ff', fontSize: '24px' }}
              ></i>
              {section.status === 'Coming soon' && (
                <span className={`status-label ${section.status.replace(/\s+/g, '-').toLowerCase()}`}>
                  {t(section.status)}
                </span>
              )}
            </div>
            <h3 className="section-title">{t(section.main_title)}</h3>
            <ul className="description-list">
              {section.descriptions.map((desc: Description, idx: number) => (
                <li
                  key={idx}
                  className="description-item"
                  onClick={() => handleDescriptionClick(section, desc)}
                >
                  {t(desc.title)}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Help;
