import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { hideModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import './ShareContractModal.css';
import GlobalDropdown, { DropdownOption } from '../shared/GlobalDropdown';

interface FileProps {
  FileID: string;
  FileName: string;
  FileType: string;
}

const ShareContractModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const structure = useSelector((state: RootState) => state.folders.structure);

  const [contractOptions, setContractOptions] = useState<DropdownOption[]>([]);
  const [selectedContractId, setSelectedContractId] = useState<string>('');
  const [selectedContractLabel, setSelectedContractLabel] = useState<string>('');
  const [recipientEmail, setRecipientEmail] = useState<string>('');

  useEffect(() => {
    const contracts = buildContractOptions(structure);
    setContractOptions(contracts);
  }, [structure]);

  const buildContractOptions = (folders: any[]): DropdownOption[] => {
    const options: DropdownOption[] = [];

    folders.forEach(folder => {
      // Build subOptions for this folder
      const subOptions: DropdownOption[] = [];

      // Add contract documents in this folder
      if (folder.Documents && folder.Documents.length > 0) {
        const contractDocs = folder.Documents.filter((doc: FileProps) => doc.FileType === 'Contract');
        contractDocs.forEach((doc: FileProps) => {
          subOptions.push({
            id: doc.FileID,
            label: doc.FileName,
          });
        });
      }

      // Recurse into subfolders
      if (folder.SubFolders && folder.SubFolders.length > 0) {
        const subFolderOptions = buildContractOptions(folder.SubFolders);
        if (subFolderOptions.length > 0) {
          subOptions.push(...subFolderOptions);
        }
      }

      // Include this folder only if it has subOptions (i.e., contains contracts or subfolders with contracts)
      if (subOptions.length > 0) {
        options.push({
          id: folder.FolderID,
          label: folder.FolderName,
          subOptions: subOptions,
        });
      }
    });

    return options;
  };

  const handleContractSelect = (id: string) => {
    setSelectedContractId(id);
  
    const label = findLabelById(contractOptions, id);
    if (label) {
      setSelectedContractLabel(label);
    }
  };
  
  const handleShare = () => {
    if (!selectedContractId || !recipientEmail) {
      alert(t('Please select a contract and enter recipient email.'));
      return;
    }

    // Dispatch action to share the document
    // dispatch(shareDocument(selectedContractId, recipientEmail));

    // Close the modal
    dispatch(hideModal());
  };

  const findLabelById = (options: DropdownOption[], id: string): string | null => {
    for (const option of options) {
      if (option.id === id) {
        return option.label;
      }
      if (option.subOptions && option.subOptions.length > 0) {
        const label = findLabelById(option.subOptions, id);
        if (label) {
          return label;
        }
      }
    }
    return null;
  };
  

  return (
    <div className="share-contract-modal">
      <h2>{t('Share Contract')}</h2>
      <div className="form-group--share">
        <label>{t('Select Contract')}</label>
        <GlobalDropdown
          options={contractOptions}
          onSelect={handleContractSelect}
          selectedLabel={selectedContractLabel}
          placeholder={t('Select a contract')}
        />
      </div>
      <div className="form-group--share">
        <label>{t('Recipient Email')}</label>
        <input
          type="email"
          value={recipientEmail}
          onChange={(e) => setRecipientEmail(e.target.value)}
          placeholder={t('Enter recipient email')}
        />
      </div>
      <div className="modal-actions">
        <button className="btn-share" onClick={handleShare}>{t('Share')}</button>
        <button className="btn-cancel" onClick={() => dispatch(hideModal())}>{t('Cancel')}</button>
      </div>
    </div>
  );
};

export default ShareContractModal;
