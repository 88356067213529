import React from 'react';
import { useSelector } from 'react-redux';
import '../../Marketplace.css';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store';
import { Stars } from '../../Client/LawyerProfileModal';

export type Review = {
  CaseID: string;
  Rating: string;
  Review: string;
};

const ReviewsComponent: React.FC = () => {
  const { t } = useTranslation();
  const reviews = useSelector((state: RootState) => state.lawyers.ratingsData?.AllReviews) || [];
  const getBorderColor = (rating: number): string => {
    switch (Math.round(rating)) { 
      case 5: return '#4CAF50';
      case 4: return '#2196F3';
      case 3: return '#FFEB3B';
      case 2: return '#FF9800';
      case 1: default: return '#f44336';
    }
  };

  return (
    <div className='lawyer-reviews__container'>
      <h1>{t('Reviews')}</h1>
    <div className="reviews-list">
      {reviews.length > 0 ? (
        reviews.map((review: Review, index: number) => (
          <div key={index} className="review-item" style={{ borderLeft: `4px solid ${getBorderColor(parseFloat(review.Rating))}` }}>
            <div className="review-rating"><Stars rating={parseInt(review.Rating)}  /></div>
            <div className="review-feedback">{t(review.Review)}</div>
            <div className="review-date"></div>
          </div>
        ))
      ) : (
        <div className="no-reviews">
          <p className='non-access'>{t('No reviews available.')}</p>
        </div>
      )}
    </div>
    </div>
  );
};

export default ReviewsComponent;
