import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './GlobalModal.css';
import { RootState, useAppDispatch } from '../../store';
import { hideModal } from '../../store/actions/modalActions';
import NewFileModal, { NewFileData } from '../Documents/NewFileModal';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import { useTranslation } from 'react-i18next';
import SignatureModal from '../Settings/Security/DigitalSignature';
import AddTask from '../Marketplace/Lawyer/AddTaskComponent';
import OtpInput from '../LoginSection/OtpInput';
import { createNewDocument } from '../../store/actions/DocumentsAction';
import BankFormItems from '../Settings/Security/BankFormItems';
import { fetchStructure } from '../../store/actions/FolderAction';
import PricingPage from '../PlanCard/PricingPage';
import { ClipLoader } from 'react-spinners';
import AnonymizeDocumentModal from '../Documents/AnonymizeDocumentModal';
import ShareContractModal from '../Documents/ShareContractModal';
import TranslationModal from '../Documents/TranslationModal';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import RequestFormComponent from '../Dashboard/AdminFirm/FirmComponents/RequestFormComponent';
import ResetPassword from '../LoginSection/ResetPassword';

const GlobalModal: React.FC = () => {
  const modalState = useSelector((state: RootState) => state.modal);
  const folders = useSelector((state: RootState) => state.folders.folders);
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation()
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);
  const userId = getUserID()
  const userType = getUserType()
  const [verificationCode, setVerificationCode] = useState('')
  const [completeTask, setCompleteTask] = useState(false);
  const [inputData, setInputData] = useState('');


  const handleClose = () => {
    setInputData('')
    setCompleteTask(false)
    dispatch(hideModal());
    if (modalState.onCancel) {
      modalState.onCancel();
    }
    setTimeout(() => {
      dispatch(hideModal());
      if (modalState.onCancel) {
        modalState.onCancel();
      }
    }, 500);
    // setInputData('')
  };

  if (!modalState.showModal) {
    return null;
  }

  const handleBackdropClick = () => {

    handleClose();
  };

  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

    e.stopPropagation();
  };

  const handleConfirmCreateFolder = () => {
    if (modalState.type === 'create' && modalState.onConfirm) {
      modalState.onConfirm(inputData);
    }
    handleClose();
    setInputData('')
  };
  const handleConfirmCreateFile = async (newFileData: NewFileData) => {
    const { folderId, fileName, fileType, file, additionalFields } = newFileData;

    if (!userId) {
      console.error('User ID is null. Cannot create document.');
      return;
    }

    let additionalFieldsData: { [key: string]: string | string[] } = {};
    switch (fileType) {
      case 'Contract':
        additionalFieldsData = {
          FileName: fileName,
          PartiesInvolved: additionalFields.PartiesInvolved || [],
          ContractStart: additionalFields.StartDate || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          Expiry: additionalFields.Expiry || '',
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      case 'Legal Document':
        additionalFieldsData = {
          FileName: fileName,
          LegalType: additionalFields.LegalType || '',
          CaseID: additionalFields.CaseID || '',
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      case 'General':
      case 'Template':
        additionalFieldsData = {
          FileName: fileName,
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Assignees: additionalFields.Assignees || [],
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      default:
        console.error('Unknown file type:', fileType);
        return;
    }

    try {
      const resp: any = await dispatch(createNewDocument(fileType, folderId.toString(), file, additionalFieldsData));
      if (resp) {
        dispatch(fetchStructure());
        dispatch(showFeedbackModal({
          showModal: true,
          message: t('File created'),
          modalType: 'success',
          duration: 3000
        }));
      }
    } catch (error) {
      console.error("Error creating document:", error);
      dispatch(showFeedbackModal({
        showModal: true,
        message: t('Failed to create file'),
        modalType: 'error',
        duration: 3000
      }));
    }
  };


  const handleOpenModal = () => {
    setIsSignatureModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSignatureModalOpen(false);
  };


  const handleSaveSignature = (signature: string) => {
    setSignature(signature);
  };




  if (!modalState.showModal) return null;

  const getIcon = () => {
    switch (modalState.type) {
      case 'success':
        return <i className='fa-solid fa-check-circle success'></i>;
      case 'error':
        return <i className='fa-solid fa-times-circle error'></i>;
      case 'warning':
        return <i className='fa-solid fa-exclamation-circle warning'></i>;

      default:
        return null;
    }
  };



  const handleConfirm = () => {
    if (modalState.onConfirm) {
      if (modalState.type === 'timeStop') {
        modalState.onConfirm(inputData, completeTask);
      } else if (modalState.type === 'addSignature') {
        const signatureBlob = new Blob([inputData], { type: 'image/svg+xml' });
        const signatureFile = new File([signatureBlob], 'signature.svg', { type: 'image/svg+xml' });
        modalState.onConfirm(signatureFile);
      } else if (modalState.type === 'share' || modalState.type === 'sign' || modalState.type === "password" || modalState.type === "promptfeedback" || modalState.type === "confirmation") {
        modalState.onConfirm(inputData);
      } else if (modalState.type === 'forgotPassword') {
        modalState.onConfirm(modalState.inputData ? modalState.inputData : email);
        setEmail("")
      } else if (modalState.type === '2fa-qrcode') {
        modalState.onConfirm(verificationCode)
        setVerificationCode("")
      } else if (modalState.type === 'alert') {

        modalState.onConfirm(modalState.inputData ? modalState?.inputData : "")
      } else if (modalState.type === 'confirm-upload') {
        modalState.onConfirm(inputData)
      }
    }

    if (modalState.type !== '2fa-qrcode') {
      handleClose();
    }
  };

  return (

    <div className={`global-modal-backdrop ${modalState.showModal && ['share', 'success', 'error', 'sign', 'create', 'addFile', 'confirmation', 'forgotPassword', 'password', 'payment-options', 'tips', 'info', 'receiver-options', "addSignature", 'promptfeedback', '2fa-qrcode', 'warning', 'alert', 'otp-verification', 'addTask', 'confirm-upload', 'subscription-modal', 'timeStop', 'redirect', 'anonymize', 'translate', 'license-request', 'resetPassword'].includes(modalState.type) ? 'backdrop-visible' : ''}`} onClick={handleBackdropClick}>
      <div
       className={`${modalState.showModal ? 'modal-visible' : ''} ${modalState.type === 'subscription-modal' ? 'global-subscription-modal__pons' : 'global-modal__pons'}`} style={{ width: modalState.width }} onClick={handleModalContentClick}>

        <div
        style={{ gap: modalState.gap  }}
         className={`${modalState.type === 'subscription-modal' ? 'modal-subscription-content__pons' : modalState.type === 'license-request' ? "not-modal-content__pons" : "modal-content__pons"}`}>
          <div className='global-modal--header' style={{ marginBottom: modalState.type === 'create' ? 0 : 20 }}>
            {modalState.type !== "info" && modalState.type !== "subscription-modal" && modalState.type !== "timeStop" && (
              <p className='global-modal__headertext'>{`${modalState.message}`}</p>
            )}
            {modalState.subMsg && (
              <span className='global-modal--submsg' dangerouslySetInnerHTML={{ __html: modalState.subMsg }} ></span>
            )}
          </div>
          {getIcon()}
          {(modalState.type === 'create') && (
            <>
              <input
                type="text"
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
                placeholder={t("Folder name")}
                className='modal-content__pons--input'
                style={{ margin: 20 }}
              />
              <button className="primary-button" onClick={handleConfirmCreateFolder}>{t('Create')}</button>
            </>
          )}

          {(modalState.type === 'addFile') && (
            <>
              <NewFileModal
                folders={modalState.folderOptions ? modalState.folderOptions : folders}
                onSave={handleConfirmCreateFile}
                currentUserId={getUserID()}
                preSelectedFolderId={modalState.preSelectedFolderId}
              />
            </>
          )}

          {(modalState.type === 'forgotPassword') && (
            <>
              <input
                type="email"
                placeholder={t("Enter your email")}
                value={modalState.inputData ? modalState.inputData : email}
                onChange={(e) => setEmail(e.target.value)}
                className='modal-content__pons--input'
              />

              <button style={{ marginTop: 20 }} className='primary-button' onClick={handleConfirm}>{t('Send Reset Link')}</button>
            </>
          )}

          {(modalState.type === 'resetPassword') && (
            <>
            <ResetPassword />
            </>
          )}

          {(modalState.type === 'tips') && (
            <>
              <span>{modalState.info}</span>

              <button className='primary-button' onClick={handleClose}>{t('Close')}</button>
            </>
          )}

          {(modalState.type === 'info') && (
            <>
              <h1 style={{ fontWeight: '500', marginTop: 0 }}>{modalState.title}</h1>
              <div className='modal-help-text' dangerouslySetInnerHTML={{ __html: modalState.info! }}></div>
            </>
          )}

          {(modalState.type === 'addTask') && (
            <>
              <AddTask />
            </>
          )}

          {(modalState.type === 'addSignature') && (
            <>
              {!isSignatureModalOpen && (
                <>
                  {signature && <img src={signature} alt="User Signature" />}
                </>
              )}

              {/* <SignatureModal
                isOpen={isSignatureModalOpen}
                onClose={handleCloseModal}
                onSave={handleSaveSignature}
                userId={userId}
                userType={userType}
                onConfirm={handleConfirm}
              /> */}
              {!isSignatureModalOpen && (
                <button className='primary-button' onClick={handleOpenModal}>{signature ? t("Change Signature") : ("Add Signature")}</button>
              )}
            </>
          )}

          {(modalState.type === 'password') && (
            <>
              <input
                type="password"
                placeholder={`${t('Enter your password')}`}
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
                className='modal-content__pons--input'
              />

        <button style={{ marginTop: 20 }} className='primary-button' onClick={handleConfirm}>{t('Save Changes')}</button>
            </>
          )}

          {(modalState.type === 'promptfeedback') && (
            <>
              <p>{t('Please let us know what went wrong.')}</p>
              <textarea
                className='promptfeedback-textarea'
                placeholder={`${t('Please provide a feedback on what went wrong')}`}
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
              />

              <button className='primary-button' onClick={handleConfirm}>{t('Send feedback')}</button>
            </>
          )}


          {(modalState.type === 'share' || modalState.type === 'sign') && (
            <>
              <ShareContractModal />
            </>

          )}
          {modalState.type === 'confirmation' && (
            <>
              <div className='global-modal__pons--buttons'>
                <button className="primary-button" onClick={() => modalState.onConfirm && modalState.onConfirm(inputData)}>{t('Yes')}</button>
                <button className="destructive-button" onClick={handleClose}>{t('No')}</button>
              </div>
            </>
          )}
          {['success', 'error', 'warning', 'feedback'].includes(modalState.type) && (
            <><span className='global-modal-spacer'></span></>
          )}

          {
            (modalState.type === "alert") && (<>

              <div>
                <button className="destructive-button" onClick={handleClose}>{t('Cancel')}</button>
                <button className="primary-button" onClick={handleConfirm}>{t('Yes, Sign out')}</button>
              </div>
            </>)
          }

          {
            (modalState.type === "otp-verification") && (<>
              <OtpInput
                onSubmit={modalState.onConfirm ? (data: File | string) => modalState.onConfirm!(data) : () => { }} handleClose={handleClose} method={modalState.inputData} />
            </>)
          }

          {
            modalState.type === 'subscription-modal' && (
              <div style={{ width: '100%' }}>
                <PricingPage />
              </div>
            )
          }


          {
            (modalState.type === "2fa-qrcode") && (<>
              <div className="qr">
                <p className="screen-content"> {t('Enhance the security of your account by enabling 2FA. To set up 2FA, please follow the steps below:')}</p>
                <div className="qr-code-scanner">
                  <div className="instructions">
                    <ol>
                      <li>{t("Open your mobile device's camera or QR code scanner app.")}</li>
                      <li>{t("Point your camera at the QR code displayed on the screen.")}</li>
                      <li>{t("Wait for the QR code to be recognized and follow the on-screen instructions.")}</li>
                      <li>{t("Enter the passcode displayed on your device into the input field below the QR code.")}</li>
                    </ol>

                  </div>
                  <div className='qr-code-display--wrapper'>
                    <div className="qr-code-display">

                      {modalState.inputData && <img src={`data:image/png;base64,${modalState.inputData}`} alt="QR Code" />}
                    </div>
                  </div>
                </div>
                <div className='qr-actions--row'>
                  <div className="qr-form-group">
                    <label htmlFor="verification-code" className="qr-form-label">{t("Enter code for verification")}</label>
                    <input
                      autoComplete="off"
                      type="text"
                      name="verificationCode"
                      maxLength={6}
                      tabIndex={1}
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      className={`qr-form-control-input `}
                      id="verification-code"
                      placeholder={t('Code')}
                    />
                  </div>
                  <div style={{ alignItems: "flex-end", flexDirection: 'row', display: 'flex', gap: 10 }}>
                    <button className="destructive-button" onClick={handleClose}>{t('Cancel')}</button>
                    <button className="primary-button" onClick={handleConfirm}>{t('Enable')} </button>
                  </div>
                </div>
              </div>
            </>)
          }

          {modalState.type === 'receiver-options' && (
            <>
              <BankFormItems />
            </>
          )}


          {modalState.type === "anonymize" && (
            <AnonymizeDocumentModal fileID={modalState.fileID} />
          )}
          {modalState.type === "translate" && (
            <TranslationModal fileID={modalState.fileID} />
          )}

        {modalState.type === 'timeStop' && (
          <>
            <p style={{ margin: 0 }}>{modalState.message}</p>
            <textarea
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              placeholder={t("Optional description of time spent")}
              className='promptfeedback-textarea'
            />
            {modalState.taskId && (
              <div className='complete-task-checkbox'>
                <label htmlFor="completeTaskCheckbox">
                  <input
                    type="checkbox"
                    id="completeTaskCheckbox"
                    onChange={(e) => setCompleteTask(e.target.checked)}
                  />
                  <span></span>
                  {t('Complete the task')}
                </label>
              </div>
            )}
            <div className='global-modal__pons--buttons'>
              <button className="primary-button" onClick={handleConfirm}>{t('Yes')}</button>
              <button className="destructive-button" onClick={handleClose}>{t('No')}</button>
            </div>
          </>
        )}
        </div>


        {modalState.type === 'payment-options' && (
          <>
            <div className='payment-options-list'>
              <button className='payment-option'>
                <p>Credit Card</p>
                <i className='fab fa-stripe'></i>
              </button>
              <button className='payment-option'>
                <p>PayPal</p>
                <i className='fab fa-paypal'></i>
              </button>
              <button className='payment-option'>
                <p >Apple Pay</p>
                <i className='fab fa-apple'></i>
              </button>
              <button className='payment-option'>
                <p>Vipps</p>
                <i className='fas fa-phone'></i>
              </button>
            </div>
            <button className='primary-button payment'>{t('Confirm Payment')}</button>
          </>
        )}

        {
          modalState.type === 'confirm-upload' && (
            <>
              <div>
                <button className="primary-button" onClick={() => { setInputData('Add content below'); handleConfirm() }}>{t('Add content below')}</button>
                <button className="primary-button" onClick={() => { setInputData('Replace excisting file!'); handleConfirm() }}>{t('Replace excisting file!')}</button>
              </div>
            </>
          )
        }

        {modalState.type === 'redirect' && (
          <>
            {/* <div className="animated-paragraphs">
              <ClipLoader color={"#64d2ff"} size={30} />

            </div> */}
            <div className="animated-paragraphs">
              <p className="fade-in">.</p>
              <p className="fade-in">.</p>
              <p className="fade-in">.</p>
            </div>
          </>
        )

        }


        {(modalState.type !== "success") && (modalState.type !== "error") && (modalState.type !== "warning") && (
          <button onClick={handleClose} className='global-modal--exit-btn'>
            <i className="fas fa-xmark modal-content__xmark" ></i>
          </button>
        )}

        {(modalState.type === "license-request") && (
          
          <RequestFormComponent onConfirm={(data) => modalState.onConfirm ? modalState.onConfirm(data) : () => { }} onCancel={handleClose} />
        )}



      </div>
    </div>
  );
};

export default GlobalModal;
