import axios, { AxiosError } from "axios";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ErrorResponse } from "./SignInAction";


export const lawfirmLawyerAddTask = (
    UserType: string,
    TaskTitle: string,
    TaskDescription: string[] | [],
    TaskDeadline: string
  ) => {
    return async (dispatch: any) => {
      try {
        const resp = await apis({
          method: 'POST',
          url: 'market/lawfirm/addTask',
          data: {
            UserType,
            TaskTitle,
            TaskDescription,
            TaskDeadline
          }
        });
  
        if (resp && resp.status === 200) {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: resp.data.message || 'Task added',
              modalType: 'success',
              duration: 3000
            })
          );
          return true;
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data) {
            const errorMessage = error.response.data as ErrorResponse;
            dispatch(
              showFeedbackModal({
                showModal: true,
                message:
                  errorMessage.message ||
                  errorMessage.error ||
                  'An unknown error occurred',
                modalType: 'error',
                duration: 3000
              })
            );
          } else {
            dispatch(
              showFeedbackModal({
                showModal: true,
                message: 'Network error, please try again',
                modalType: 'error',
                duration: 3000
              })
            );
          }
        } else {
          dispatch(
            showFeedbackModal({
              showModal: true,
              message: 'An unexpected error occurred',
              modalType: 'error',
              duration: 3000
            })
          );
        }
        console.error('lawfirmLawyerAddTask Error:', error);
        return undefined;
      }
    };
  };


export const getLawfirmLawyerTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "marketplace/lawfirmLawyer/getTask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const getAllLawfirmLawyerTask = (UserType: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "marketplace/lawfirmLawyer/getAllTasks",
                data: {
                    UserType,
                }
            })

            if (resp && resp.status === 200) {
                return resp.data
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}

export const deleteLawfirmAssignedTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/lawfirm/deleteTask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task deleted'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}


export const completeLawfirmAssignedTask = (UserType: string, TaskID: string) => {
    return async (dispatch: any) => {
        try {
            const resp: any = await apis({
                method: "POST",
                url: "market/lawfirm/completetask",
                data: {
                    UserType,
                    TaskID
                }
            })

            if (resp && resp.status === 200) {
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (resp.data.message || 'Task completed'),
                    modalType: 'success',
                    duration: 3000
                }));
                return true
            } else {
                if (axios.isAxiosError(resp)) {
                    const axiosError = resp as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message ? errorMessage.message : errorMessage.error || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }

    }
}