import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Onboarding.css';
import * as Yup from 'yup';
import { RootState, useAppDispatch } from '../../../../store';
import { hideModal, showModal, showPasswordModal } from '../../../../store/actions/modalActions';
import { SignUp } from '../../../../store/actions/SignUpAction';
import { getUserID, getUserType } from '../../../../store/actions/ChatAction';
import { useFormik } from 'formik';
import ClipLoader from 'react-spinners/ClipLoader';
import { getAllLawyers, getLegalSubjects, RequestLicenses } from '../../../../store/actions/LawfirmActions';
import { showFeedbackModal } from '../../../../store/actions/UserFeedbackActions';
import { useSelector } from 'react-redux';
import { fetchLawfirmRoles, fetchLegalSubjects } from '../../../../store/actions/DropdownActions';
import GlobalDropdown, { DropdownOption } from '../../../shared/GlobalDropdown';
import CustomDropdownLCM, { Option } from '../../../CustomDropdownLCM.tsx/CustomDropdownLCM';

interface OnboardingProps {
  addEmployee: (employee: Member) => void;
  closePanel: () => void; 
}

interface NewEmployee {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string; 
  practiceArea: string[];
  officeLocation: string;
}

interface Member {
  MemberID: string;
  MemberFirstName: string;
  MemberLastName: string;
  MemberRole: string;
  hireDate: string;
  MemberType: string;
  caseStats: {
    totalCases: number;
    successRate: number;
    notableCases: any[];
  };
  clientFeedback: {
    rating: number;
    reviews: any[];
  };
  skills: any[];
  certifications: any[];
  currentProjects: any[];
  availabilityStatus: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  practiceArea: string[];
  officeLocation: string;
}

const Onboarding: React.FC<OnboardingProps> = ({ addEmployee, closePanel }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userID = getUserID();
  const [lawyerLicense, setLawyerLicense] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {activeLicenseRequest,totalLicenseCount,licenseUsed,licenceLimitExceded }  = useSelector((state: RootState) => state.lawfirm)
  const legalSubjects = useSelector((state: RootState) => state.lawfirm.subjects);
  const userType = getUserType();


  useEffect(() => {
    if (userType) {
      dispatch(fetchLawfirmRoles());
      dispatch(getLegalSubjects())
    }
  }, [dispatch, userID]);

  const {
    lawfirmRoles,
    lawfirmRolesLoading,
    lawfirmRolesError,
  } = useSelector((state: RootState) => state.dropdown);

  const transformSubjects = (subjects: any[]) => {
    return subjects
      .filter(sub => sub.LegalSubjectID !== undefined && sub.LegalSubjectID !== null) 
      .map(sub => ({
        value: sub.LegalSubjectID.toString(),
        label: sub.SubCategory || sub.LegalSubjectName
      }));
  };


  const uniqueLegalSubjects = transformSubjects(legalSubjects);

  const mappedRoles: DropdownOption[] = lawfirmRoles.map(role => ({
    id: role.id,
    label: role.label
  }));

  const mappedPracticeAreas: Option[] = uniqueLegalSubjects.map(subject => ({
    value: subject.value,
    label: subject.label
  }));

  const formik = useFormik<NewEmployee>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      practiceArea: [],
      officeLocation: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('First Name is required')),
      lastName: Yup.string().required(t('Last Name is required')),
      email: Yup.string().email(t('Invalid email format')).required(t('Email is required')),
      phone: Yup.string().required(t('Phone Number is required')),
      role: Yup.string().required(t('Role is required')),
      practiceArea: Yup.array().required(t('Practice Area is required')),
      officeLocation: Yup.string().required(t('Office Location is required'))
    }),
    onSubmit: (values) => {
      if (!lawyerLicense) {
        alert(t('Please upload a Lawyer License.'));
        return;
      }
      dispatch(showPasswordModal(handlePasswordConfirm));
    }
  });

  const selectedRoleLabel = mappedRoles.find(option => option.id === formik.values.role)?.label || '';
  // const selectedPracticeAreaLabel = mappedPracticeAreas.find(option => option.value === formik.values.practiceArea)?.label || '';

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLawyerLicense(e.target.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setLawyerLicense(null);
    formik.setFieldValue("LawyerLicense", "");
  };


  const handlePasswordConfirm = async (adminPassword: string) => {
  
    const selectedRole = mappedRoles.find(option => option.id === formik.values.role);
    const selectedPracticeAreas = uniqueLegalSubjects.filter(option => formik.values.practiceArea.includes(option.value));

  
    const formData = new FormData();
    formData.append('FirstName', formik.values.firstName);
    formData.append('LastName', formik.values.lastName);
    formData.append('LawfirmID', userID || '');
    formData.append('Email', formik.values.email);
    formData.append('PhoneNumber', formik.values.phone);
    formData.append('PracticeArea', selectedPracticeAreas.map(pa => pa.label).join(', '));
    formData.append('CountryOfJurisdiction', 'Norway');
    formData.append('CompanyRole', selectedRole?.label || '');
    formData.append('AgreeToTerms', 'Yes');
    formData.append('LawyerLicense', lawyerLicense as Blob, lawyerLicense?.name || 'license');
    formData.append('Password', adminPassword);
  
    try {
      setIsSubmitting(true);
      const resp: any = await dispatch(SignUp(formData, 'lawFirmLawyer') as any);
      if (resp && resp.status === 200) {
        const employee: Member = {
          MemberID: Date.now().toString(),
          MemberFirstName: formik.values.firstName,
          MemberLastName: formik.values.lastName,
          MemberRole: formik.values.role, 
          hireDate: new Date().toISOString().split('T')[0],
          MemberType: 'LawfirmLawyer',
          caseStats: {
            totalCases: 0,
            successRate: 0,
            notableCases: []
          },
          clientFeedback: {
            rating: 0,
            reviews: []
          },
          skills: [],
          certifications: [],
          currentProjects: [],
          availabilityStatus: 'Available',
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          phone: formik.values.phone,
          role: formik.values.role,
          practiceArea: formik.values.practiceArea || [],
          officeLocation: formik.values.officeLocation,
        };
  
        addEmployee(employee);
        formik.resetForm();
        setLawyerLicense(null);
        await dispatch(getAllLawyers(userID));
        dispatch(showFeedbackModal({
          showModal: true,
          message: t('Employee onboarded'),
          modalType: 'success',
          duration: 3000
        }));
  
        closePanel(); 
      }
    } catch (error) {
      console.error('Error during onboarding:', error);
      dispatch(showFeedbackModal({
        showModal: true,
        message: t('Failed to onboard employee'),
        modalType: 'error',
        duration: 3000
      }));
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleLicenceRequest = () => {
    dispatch(showModal({
      type: "license-request",
      message: "Please provid information",
      onConfirm: (data: { RequestContent: string, LicenseCount: number }) => {
        const {RequestContent,LicenseCount} = data
        dispatch(RequestLicenses(RequestContent,LicenseCount))
      },
      onCancel: () => hideModal(),
      showModal: true,
    }))
    
  }

  const handlePracticeAreasChange = (selectedSubjects: string | string[]) => {
    const selectedIds = uniqueLegalSubjects
      .filter(subject => selectedSubjects.includes(subject.label))
      .map(subject => subject.value);
  
    formik.setFieldValue('practiceArea', selectedIds);
  };
  
  
  
  return (
    <div className='onboarding-container'>
      <h2>{t('Onboard New Employee')}</h2>
      <form onSubmit={formik.handleSubmit} className='onboarding-form'>
        <div className='onboarding-form-group__row'>
          <div className='onboarding-form-group'>
            <label>{t('First Name')}</label>
            <input
              type='text'
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <span style={{ color: 'red' }}>{formik.errors.firstName}</span>
            )}
          </div>
          <div className='onboarding-form-group'>
            <label>{t('Last Name')}</label>
            <input
              type='text'
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <span style={{ color: 'red' }}>{formik.errors.lastName}</span>
            )}
          </div>
        </div>
        <div className='onboarding-form-group__row'>
          <div className='onboarding-form-group'>
            <label>{t('Email')}</label>
            <input
              type='email'
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <span style={{ color: 'red' }}>{formik.errors.email}</span>
            )}
          </div>
          <div className='onboarding-form-group'>
            <label>{t('Phone Number')}</label>
            <input
              type='text'
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone && formik.errors.phone && (
              <span style={{ color: 'red' }}>{formik.errors.phone}</span>
            )}
          </div>
        </div>
        <div className='onboarding-form-group'>
          <label>{t('Office Location')}</label>
          <input
            type='text'
            name="officeLocation"
            value={formik.values.officeLocation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.officeLocation && formik.errors.officeLocation && (
            <span style={{ color: 'red' }}>{formik.errors.officeLocation}</span>
          )}
        </div>
        <div className='onboarding-form-group'>
          {/* Role Dropdown */}
          <label>{t('Company Role')}</label>
          {lawfirmRolesLoading ? (
            <ClipLoader size={20} color='#fff' />
          ) : lawfirmRolesError ? (
            <span style={{ color: 'red' }}>{t(lawfirmRolesError)}</span>
          ) : (
            <GlobalDropdown
              options={mappedRoles}
              selectedLabel={selectedRoleLabel}
              placeholder={t('Select Role')}
              onSelect={(id) => formik.setFieldValue('role', id)}
            />
          )}
          {formik.touched.role && formik.errors.role && (
            <span style={{ color: 'red' }}>{formik.errors.role}</span>
          )}
        </div>
        <div className='onboarding-form-group'>
          {/* Practice Area Dropdown */}
          <label>{t('Practice Areas')}</label>
            <CustomDropdownLCM 
              label={t('')}
              options={mappedPracticeAreas.map(subject => subject.label)} 
              value={mappedPracticeAreas
                .filter(subject => formik.values.practiceArea.includes(subject.value))
                .map(subject => subject.label)} 
              onChange={handlePracticeAreasChange} 
              placeholder={t('Select Practice areas')}
              multiSelect={true}
              className={`dropdown-practice-areas ${formik.errors.practiceArea && formik.touched.practiceArea ? 'input-error' : ''}`}
              error={formik.touched.practiceArea && formik.errors.practiceArea ? formik.errors.practiceArea : ''}
            />

          {formik.touched.practiceArea && formik.errors.practiceArea && (
            <span style={{ color: 'red' }}>{formik.errors.practiceArea}</span>
          )}
        </div>
        <div className='onboarding-form-group'>
          <label>{t('Lawyer License')}</label>
          <input
            type='file'
            id='file-input'
            name="LawyerLicense"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".pdf,.jpg,.png" 
          />
          {!lawyerLicense && ( 
          <label htmlFor='file-input' className='custom-file-upload--onboard'>
            <i className='fas fa-plus'></i>
          </label>
            )}

          {lawyerLicense && (
            <div className='file-details'>
              <span>{lawyerLicense.name}</span>
              <i onClick={handleRemoveFile} className='remove-file-btn fas fa-times'></i>
            </div>
          )}
        </div>
        <div style={{ width: '100%', alignSelf: 'flex-start', display: 'flex' }}>
          <button className={`primary-button ${licenceLimitExceded ? 'disabled': ""}`} type='submit' disabled={isSubmitting || licenceLimitExceded}>
            {isSubmitting ? <ClipLoader size={20} color='#fff' /> : t('Add Employee')}
          </button>
        </div>
      </form>
      <div className='employee-count'>
        <p>{licenseUsed} {t('out of')} {totalLicenseCount} {t('licenses used')}</p>
        <button className={`secondary-button ${activeLicenseRequest ? 'disabled': ""}`} disabled={activeLicenseRequest} onClick={handleLicenceRequest} >{activeLicenseRequest ? t('Requested') : t('Request more')}</button>
      </div>
    </div>

  );
};

export default Onboarding;
