
import axios, { AxiosError } from 'axios';
import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..';
import { SUBMIT_CASE_SUCCESS, SUBMIT_CASE_FAILURE, FETCH_CASE_LIST_SUCCESS, FETCH_CASE_LIST_FAILURE, UPDATE_CASE_STATUS_SUCCESS, UPDATE_CASE_STATUS_FAILURE, FETCH_LAWYERS_FAILURE, FETCH_LAWYERS_SUCCESS, FETCH_LCM_LIST_SUCCESS, GET_PUBLISHED_CASES_FAILURE, GET_PUBLISHED_CASES_SUCCESS, FETCH_BIDS_SUCCESS, FETCH_BIDS_FAILURE, UPDATE_BIDDING_STATUS_FAILURE, UPDATE_BIDDING_STATUS_REQUEST, UPDATE_BIDDING_STATUS_SUCCESS, FETCH_BIDDING_FAILURE, FETCH_BIDDING_SUCCESS, FETCH_FULL_CASE_FAILURE, FETCH_FULL_CASE_SUCCESS, PROPOSE_CASE_SUCCESS, PROPOSE_CASE_FAILURE, FETCH_URGENCY_LIST, FETCH_STATUS_LIST } from '../../types';
import { getUserID, getUserType } from '../ChatAction';
import { ICaseItem } from '../../../components/Marketplace/Interfaces/ICaseItem';

import apis from '../../../utils/apis';
import { Dispatch } from 'react';
import { ApiError } from './lawyerAction';
import { ErrorResponse } from '../SignInAction';
import { showFeedbackModal } from '../UserFeedbackActions';
import { BiddingDataProps } from '../../reducer/marketplace/bidReducer';
import { IFullCaseData } from '../../../components/Marketplace/Interfaces/IMarketCase';

type PublishedAction = {
  type: string;
  payload?: ICaseItem | ApiError;
};

type BidAction = {
  type: string;
  payload?: BiddingDataProps | ApiError;
}

export interface BiddingStatusUpdate {
  UserID: string;
  UserType: 'Client' | 'Employee';
  BiddingID: string;
  Status: 'Approved' | 'Declined';
}

export interface ProposeCaseData {
  CaseID: string;
  UserID: string;
  UserType: string;
  ClientMaxBudget: string;
  ClientMinBudget: string;
  CaseStatus: string;
  ClientMessage: string;
}



const fetchUrgencyListSuccess = (urgencyList: string[]) => ({
  type: FETCH_URGENCY_LIST,
  payload: urgencyList
});

const fetchStatusListSuccess = (statusList: string[]) => ({
  type: FETCH_STATUS_LIST,
  payload: statusList
});

const submitCaseSuccess = (data: any) => ({
  type: SUBMIT_CASE_SUCCESS,
  payload: data,
});

const submitCaseFailure = (error: any) => ({
  type: SUBMIT_CASE_FAILURE,
  payload: error,
});

const fetchCaseListSuccess = (caseList: ICaseItem[]) => ({
  type: FETCH_CASE_LIST_SUCCESS,
  payload: caseList,
});

const fetchLCMListSuccess = (caseList: []) => ({
  type: FETCH_LCM_LIST_SUCCESS,
  payload: caseList,
});

const fetchCaseListFailure = (error: any) => ({
  type: FETCH_CASE_LIST_FAILURE,
  payload: error,
});

const updateCaseStatusSuccess = (data: any) => ({
  type: UPDATE_CASE_STATUS_SUCCESS,
  payload: data,
});

export const updateBiddingStatusRequest = () => ({
  type: UPDATE_BIDDING_STATUS_REQUEST,
});

export const updateBiddingStatusSuccess = (data: BiddingStatusUpdate) => ({
  type: UPDATE_BIDDING_STATUS_SUCCESS,
  payload: data,
});

export const updateBiddingStatusFailure = (error: ApiError) => ({
  type: UPDATE_BIDDING_STATUS_FAILURE,
  payload: error,
});


const updateCaseStatusFailure = (error: any) => ({
  type: UPDATE_CASE_STATUS_FAILURE,
  payload: error,
});

export const fetchBidsSuccess = (bids: BiddingDataProps) => ({
  type: FETCH_BIDS_SUCCESS,
  payload: bids,
});

export const fetchBidsFailure = (error: any) => ({
  type: FETCH_BIDS_FAILURE,
  payload: error,
});

export const fetchBiddingSuccess = (bidding: BiddingDataProps) => ({
  type: FETCH_BIDDING_SUCCESS,
  payload: bidding,
});

export const fetchBiddingFailure = (error: any) => ({
  type: FETCH_BIDDING_FAILURE,
  payload: error,
});

export const fetchFullCaseSuccess = (fullCase: IFullCaseData) => ({
  type: FETCH_FULL_CASE_SUCCESS,
  payload: fullCase,
});

export const fetchFullCaseFailure = (error: any) => ({
  type: FETCH_FULL_CASE_FAILURE,
  payload: error,
});

export const fetchBids = (caseId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await apis({
      method: "POST",
      url: "market/case/biddingList",
      data: { CaseID: caseId }
    });

    if (response && response.status === 200) {
      dispatch(fetchBidsSuccess(response.data));
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let errorMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (errorMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: ('Network error, please try again'),
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }
  } catch (error: any) {
    dispatch(fetchBidsFailure(error));
  }
};

export const proposeCase = (data: ProposeCaseData) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const response = await apis({
      method: 'POST',
      url: 'market/proposeCase',
      data
    });

    if (response && response.status === 200) {
      dispatch({
        type: PROPOSE_CASE_SUCCESS,
        payload: response.data
      });
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Case proposed',
        modalType: 'success',
        duration: 3000
      }));
    } else {
      dispatch({
        type: PROPOSE_CASE_FAILURE,
        payload: response.data
      });
      dispatch(showFeedbackModal({
        showModal: true,
        message: response.data.message || 'An unknown error occurred',
        modalType: 'error',
        duration: 3000
      }));
    }
  } catch (error) {
    dispatch({
      type: PROPOSE_CASE_FAILURE,
      payload: error
    });
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Case proposal failed! Please try again',
      modalType: 'error',
      duration: 3000
    }));
  }
};

export const updateBiddingStatus = (
  biddingDetails: BiddingStatusUpdate
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(updateBiddingStatusRequest());
  try {
    const response = await apis({
      method: "POST",
      url: "market/case/updateBiddingStatus",
      data: biddingDetails
    });
    if (response && response.status === 200) {
      dispatch(updateBiddingStatusSuccess(response.data));
      if (biddingDetails.Status === "Approved") {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Bid accepted',
          modalType: 'success',
          duration: 3000
        }));
      } else if (biddingDetails.Status === "Declined") {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Bid declined',
          modalType: 'success',
          duration: 3000
        }));
      } else {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'An issue appeared. Please contact support.',
          modalType: 'error',
          duration: 3000
        }));
      }
    }

  } catch (error: any) {
    dispatch(updateBiddingStatusFailure(error));
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Failed to update bid.',
      modalType: 'error',
      duration: 3000
    }));
  }
};


export const fetchUrgencyList = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const response = await apis({
      method: "GET",
      url: "market/case/urgencyList"
    });
    const urgencyList = response.data.CaseUrgencyList;
    dispatch(fetchUrgencyListSuccess(urgencyList));
  } catch (error) {
    console.error(error);
  }
};



export const fetchStatusList = () => async (dispatch: any) => {
  try {
    const response = await apis({
      method: "GET",
      url: "market/case/statusList"
    });
    const statusList = response.data.CaseStatusList;
    dispatch(fetchStatusListSuccess(statusList));
  } catch (error) {
    console.error(error);
  }
};

export const submitCase = (formData: FormData) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const response = await apis({
      method: "POST",
      url: "market/client/addCase",
      data: formData
    });

    if (response && response.status === 200) {
      dispatch(submitCaseSuccess(response.data));
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Case submitted',
        modalType: 'success',
        duration: 3000
      }));
      return response.data
    } else {
      if (axios.isAxiosError(response)) {
        const axiosError = response as AxiosError;
        if (axiosError.response && axiosError.response.data) {
          let erroMessage = axiosError.response.data as ErrorResponse
          dispatch(showFeedbackModal({
            showModal: true,
            message: (erroMessage.message || 'An unknown error occurred'),
            modalType: 'error',
            duration: 3000
          }));
        } else {
          dispatch(showFeedbackModal({
            showModal: true,
            message: 'Network error, please try again',
            modalType: 'error',
            duration: 3000
          }));
        }
      }
    }

  } catch (error) {
    dispatch(submitCaseFailure(error));
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'Case submittion failed! please try again',
      modalType: 'error',
      duration: 3000
    }));
  };

}

export const fetchCaseList = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const userId = getUserID();
    const userType = getUserType();
    const response = await apis({
      method: "POST",
      url: "market/case/getCaseList",
      data: {
        UserID: userId,
        UserType: userType,
      }
    });
    dispatch(fetchCaseListSuccess(response.data));
  } catch (error) {
    dispatch(fetchCaseListFailure(error));
  }
};

export const fetchLCMList = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const userId = getUserID();
    const userType = getUserType();

    const response = await apis({
      method: "POST",
      url: "market/case/getLCMList",
      data: {
        UserID: userId,
        UserType: userType,

      }
    });

    dispatch(fetchLCMListSuccess(response.data));
  } catch (error) {
    dispatch(fetchCaseListFailure(error));
  }
};


export const updateCaseStatus = (caseId: string, newStatus: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const response = await apis({
      method: "POST",
      url: "market/case/updateStatus",
      data: {
        CaseID: caseId,
        Status: newStatus,
      }
    });

    dispatch(updateCaseStatusSuccess(response.data));
    if (response && response.status === 200) {
      if (newStatus === "Approved") {
        dispatch(showFeedbackModal({
          showModal: true,
          message: 'Bid accepted',
          modalType: 'success',
          duration: 3000
        }));
      } else {
      }

    }



  } catch (error) {
    dispatch(updateCaseStatusFailure(error));
    dispatch(showFeedbackModal({
      showModal: true,
      message: 'An error has occured! Please contact support.',
      modalType: 'error',
      duration: 3000
    }));
  }
};



export const fetchLawyers = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  try {
    const response = await apis({
      method: "GET",
      url: "market/case/getLawyerList"
    });
    dispatch({ type: FETCH_LAWYERS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_LAWYERS_FAILURE, payload: error });
  }
};

export const fetchPublishedCases = () => async (dispatch: Dispatch<PublishedAction>) => {
  try {
    const response = await apis({
      method: "GET",
      url: "market/case/getPublishedCases"
    });
    dispatch({ type: GET_PUBLISHED_CASES_SUCCESS, payload: response.data });
  } catch (error: any) {
    dispatch({ type: GET_PUBLISHED_CASES_FAILURE, payload: error });
  }
};

export const fetchCaseBidding = (caseId: string, status: string) => async (dispatch: Dispatch<any>) => {
  try {

    const response = await apis({
      method: "POST",
      url: "market/case/fetchCaseBidding",
      data: {
        CaseID: caseId,
        Status: status,

      }
    });
    if (response && response.status === 200) {
      dispatch(fetchBiddingSuccess(response.data));
      return response.data
    }


  } catch (error) {
    dispatch(fetchBiddingFailure(error));
  }

}

export const fetchFullCase = (caseId: string) => async (dispatch: Dispatch<any>) => {
  try {

    const response = await apis({
      method: "POST",
      url: "market/getCase",
      data: {
        CaseID: caseId,
      }
    });
    if (response && response.status === 200) {
      dispatch(fetchFullCaseSuccess(response.data));
      return response.data
    }
  } catch (error) {
    dispatch(fetchFullCaseFailure(error));
  }

}