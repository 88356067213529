import { AnyAction } from 'redux';
import axios, { AxiosError } from 'axios';
import { SWITCH_USER_REQUEST, SWITCH_USER_FAILURE } from '../types';
import apis from '../../utils/apis';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../types';
import { getUserType } from './ChatAction';

export const switchUser = (role: string) => async (
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) => {
  dispatch({ type: SWITCH_USER_REQUEST });

  const currentUserType = getUserType();

  const dataToSend = { UserType: currentUserType };

  let url = '';

  if (role === 'Admin') {
    url = '/user/switchUser/toAdmin';
  } else if (role === 'Employee') {
    url = '/user/switchUser/toEmployee';
  } else {
    throw new Error('Invalid role selected');
  }

  try {
    const response = await apis({
      method: 'POST',
      url: url,
      data: dataToSend,
    });

    if (response && response.status === 200) {
      const token = response.data?.AccessToken;
      const userInfo = response.data?.UserInfo;
      const userType = userInfo?.UserType;


      sessionStorage.setItem(
        'token',
        JSON.stringify({ token: token, userType: userType, userId: '', refreshToken: token })
      );

      let redirectUrl = '/dashboard';

      if (userType === 'LawfirmAdmin') {
        redirectUrl = '/dashboard/admin';
      } else if (userType === 'Lawfirmlawyer') {
        redirectUrl = '/dashboard/panel';
      }

      window.location.href = redirectUrl;
    } else {
      throw new Error('Switch user failed');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      dispatch({
        type: SWITCH_USER_FAILURE,
        payload: axiosError.response ? axiosError.response.data : 'An unknown error occurred',
      });
    } else {
      dispatch({
        type: SWITCH_USER_FAILURE,
        payload: 'An unknown error occurred',
      });
    }
  }
};
