import React, { useRef, useState } from 'react';
import './ClientPortal.css';
import { useAppDispatch } from '../../store';
import { fetchAllClients, onboardClient } from '../../store/actions/ClientAction';
import { ClientData } from '../../store/reducer/ClientReducer';
import { useTranslation } from 'react-i18next';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';

interface AddClientFormProps {
  onClose: () => void;
}

const AddClientForm: React.FC<AddClientFormProps> = ({ onClose }) => {
  const [clientType, setClientType] = useState<'individual' | 'business'>('individual');
  const [formData, setFormData] = useState<Record<string, any>>({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let clientData: ClientData;

    if (clientType === 'individual') {
      clientData = {
        client_type: 'individual',
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.contactEmail,
        phone_number: formData.phone,
        address: formData.address,
        // attachments: attachments, // Handle if necessary
      };
    } else {
      const contact_name = `${formData.first_name} ${formData.last_name}`;
      clientData = {
        client_type: 'business',
        company_name: formData.businessName,
        first_name: formData.first_name,
        last_name: formData.last_name,
        contact_name: contact_name,
        contact_email: formData.contactEmail,
        organization_number: formData.organizationNumber || '',
        contact_phone: formData.phone,
        address: formData.businessAddress,
        // attachments: attachments, // Handle if necessary
      };
    }

    const success = await dispatch(onboardClient(clientData));
    if (success) {
      await dispatch(fetchAllClients());
      onClose(); 
      dispatch(showFeedbackModal({
        modalType: 'success',
        message: t('Client onboarded'),
        duration: 3000,
        showModal: true
      }))
    } else {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: t('Could not add a new client. Please contact support.'),
        duration: 3000,
        showModal: true
      }))
    }
  };

  return (
    <div className="add-client-form">
      <div className="add-client-form--header">
        <h3>{t('Add New Client')}</h3>
        <div className="client-type-toggle">
          <button
            type="button"
            className={`client-type-btn ${clientType === 'individual' ? 'active' : ''}`}
            onClick={() => setClientType('individual')}
          >
            {t('Individual')}
          </button>
          <button
            type="button"
            className={`client-type-btn ${clientType === 'business' ? 'active' : ''}`}
            onClick={() => setClientType('business')}
          >
            {t('Business')}
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        {clientType === 'individual' ? (
          <>
            <label>
              {t('First Name')}
              <input type="text" name="first_name" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Last Name')}
              <input type="text" name="last_name" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Contact Email')}
              <input type="email" name="contactEmail" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Phone')}
              <input type="tel" name="phone" onChange={handleInputChange} />
            </label>
            <label>
              {t('Address')}
              <textarea name="address" onChange={handleInputChange} />
            </label>
          </>
        ) : (
          <>
            <label>
              {t('Business Name')}
              <input type="text" name="businessName" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Contact First Name')}
              <input type="text" name="first_name" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Contact Last Name')}
              <input type="text" name="last_name" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Contact Email')}
              <input type="email" name="contactEmail" onChange={handleInputChange} required />
            </label>
            <label>
              {t('Phone')}
              <input type="tel" name="phone" onChange={handleInputChange} />
            </label>
            <label>
              {t('Business Address')}
              <textarea name="businessAddress" onChange={handleInputChange} />
            </label>
            <label>
              {t('Organization Number')}
              <input type="text" name="organizationNumber" onChange={handleInputChange} />
            </label>
          </>
        )}

        {/* Attachments handling can be added here if needed */}

        <div className="add-client--buttons">
          <button type="submit" className="primary-button">
            {t('Add Client')}
          </button>
          <button type="button" className="destructive-button" onClick={onClose}>
            {t('Cancel')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddClientForm;
