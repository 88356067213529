import React, { useEffect, useState } from 'react';
import './TimeTracker.css';
import HeaderStats from './HeaderStats';
import TimekeepingTable from './TimeKeepingTable';
import UserInfo from './UserInfo';
import TimeTracker from './TimeTracker';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { fetchDayWeekData } from '../../store/actions/TimeManagementActions';
import { showTimekeepingSidePanel } from '../../store/actions/sidePanelActions';


const Timekeeping = () => {
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [time, setTime] = useState(0); 
  const [isActive, setIsActive] = useState(false); 
  const {t} = useTranslation()
  const [taskData,setTaskData] = useState<any[]>([])
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false);

  const stats = {
    activityThisWeek: '85%',
    tasksCompleted: 20,
    tasksTotal: 25,
    casesWorkedOn: 5
  };


// Update the function signature
const handleDayClick = async (
  mode: string,
  date: string,
  from_date: string,
  to_date: string
) => {
  setIsLoading(true);
  const resp: any = await dispatch(fetchDayWeekData(mode, date, from_date, to_date));
  if (resp) {
    setIsLoading(false);

    // Filter tasks and cases
    const tasks = resp.data.filter((item: any) => item.task_id !== '');
    const cases = resp.data.filter((item: any) => item.case_id !== '');

    // Calculate clocked hours
    const clocked_hours = tasks.reduce(
      (sum: number, task: any) => sum + parseFloat(task.duration),
      0
    );

    // Assuming negative_hours and overtime are calculated based on specific logic
    // Here, we'll set them to 0 for demonstration
    const negative_hours = 0;
    const overtime = 0;

    // Calculate total hours worked
    const total_hours_worked = clocked_hours + overtime - negative_hours;

    // Prepare stats
    const calculatedStats = {
      clocked_hours,
      negative_hours,
      overtime,
      total_hours_worked,
      tasks: tasks.map((task: any) => task.task_id),
      casesWorkedOn: cases.length || 0, // Set to 0 if no cases
    };

    // Dispatch action to show side panel with stats
    dispatch(showTimekeepingSidePanel(resp.day, resp.data, false, calculatedStats));
  } else {
    setIsLoading(false);
    // Handle error if needed
  }
};



  const formatDecimalHours = (decimalHours: number): string => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="timekeeping-page">
      {/* Header Stats */}
      <div className={`backdrop ${showSidePanel ? 'show' : ''}`} onClick={() => setShowSidePanel(false)}></div>
      <div className='timekeeping-page--row'>
        <div style={{ width: '50%' }}>
        <TimeTracker />
        </div>
        <div style={{ width: '50%' }}>
      <HeaderStats stats={stats} />
      </div>
      </div>
      {/* User Info */}
      {/* <UserInfo /> */}

      {/* Timekeeping Table */}
      <TimekeepingTable formatDecimalHours={formatDecimalHours} onDayClick={handleDayClick} />
    </div>
  );
};

export default Timekeeping;
