import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik'; // Assuming you're using Formik
import GlobalDropdown from '../../../shared/GlobalDropdown';
import "../../../shared/GlobalModal.css"
import "../FirmComponents/Onboarding.css"

// Define the props interface
interface RequestFormComponentProps {
    onConfirm: (data: { RequestContent: string, LicenseCount: number }) => void;
    onCancel: () => void;
    // mappedRoles: { id: string, label: string }[]; // Assuming this is an array of role objects
}

// The component
const RequestFormComponent: React.FC<RequestFormComponentProps> = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();
    const mappedRoles = [
        { id: "1", label: "10" },
        { id: "2", label: "20" },
        { id: "3", label: "30" }
    ]

    const formik = useFormik({
        initialValues: {
            RequestContent: '',
            LicenseCount: 0
        },
        validate: (values) => {
            const errors: { RequestContent?: string, LicenseCount?: string } = {};
            if (!values.RequestContent) {
                errors.RequestContent = t('Content Required');
            }
            if (!values.LicenseCount) {
                errors.LicenseCount = t('No of licenses required');
            }
            return errors;
        },
        onSubmit: (values) => {
            if (onConfirm) {
                onConfirm({ "RequestContent": values.RequestContent, "LicenseCount": Number(values.LicenseCount) });
            }
        },
    });
    return (
            <form onSubmit={formik.handleSubmit}>
                <div className='request-form-group'>
                    <label>{t('No of licenses')}</label>
                    <GlobalDropdown
                        options={mappedRoles}
                        selectedLabel={formik.values.LicenseCount.toString()}
                        placeholder={t('Select No of licenses')}
                        onSelect={(id) => {
                            return mappedRoles.find(role => role.id === id && formik.setFieldValue('LicenseCount', role.label))
                        }}
                        headerStyle={{ width: '97%' }}
                    />
                    {formik.touched.LicenseCount && formik.errors.LicenseCount && (
                        <span style={{ color: 'red' }}>{formik.errors.LicenseCount}</span>
                    )}
                </div>

                <div className='request-form-group'>
                    <label>{t('Elaborate')}</label>
                    <textarea
                        value={formik.values.RequestContent}
                        onChange={formik.handleChange("RequestContent")}
                        placeholder={t('Provide details for the request')}
                        className="request-textarea"
                    />
                    {formik.touched.RequestContent && formik.errors.RequestContent && (
                        <span style={{ color: 'red' }}>{formik.errors.RequestContent}</span>
                    )}
                </div>
                
                <div className="global-modal__pons--buttons">
                    <button className="primary-button" type='submit'>
                        {t('Request')}
                    </button>
                    <button className="destructive-button" onClick={onCancel}>
                        {t('Cancel')}
                    </button>
                </div>
            </form>
    );
};

export default RequestFormComponent;
