import React, { ReactNode, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { getPreferences, userInformation } from "../store/actions/DataAction";
import { getUserID, getUserType } from "../store/actions/ChatAction";
import SocketServices from "../utils/SocketServices";
import { useHistory } from "react-router-dom";

interface PrivateLayoutProps {
  children: ReactNode;
  activeView: string;
  setActiveView: (activeView: string) => void; 
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children, activeView, setActiveView }) => {
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
  const dispatch = useAppDispatch()
  const userId = getUserID();
  const userType = getUserType();
  const history = useHistory()

  useEffect(() => {
    if (userType) {
      // SocketServices.initializeSocket()
      const fetchData = async () => {
        dispatch(userInformation(userType))
        dispatch(getPreferences(userType));
      }
      fetchData()
    }
  }, [userId, userType, dispatch])

  const store_session = React.useCallback((data: any) => {
    
    const {session_id} = data
    if(session_id){
      sessionStorage.setItem("session_id", session_id)
    }
  },[])
  const sessionSignOut = React.useCallback(() => {
    localStorage.clear()
    sessionStorage.clear()
    history.push("/")
}, [history])

  useEffect(() => {
    SocketServices.on('session_created',store_session )
    SocketServices.on("session_signout",  sessionSignOut)
    return () => {
      SocketServices.removeListener('session_created')
      SocketServices.removeListener('session_signout');
    }
  },[store_session,sessionSignOut]) 



  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden', background: '#0D0D12' }}>
      <div style={{ display: 'flex', overflow: 'hidden', position: 'relative', justifyContent: 'space-between' }}>
        <Sidebar />
        <div style={{ width: isSidebarOpen ? 'calc(100vw - 15rem)' : 'calc(100vw - 80px)', padding: '0px', position: 'relative', overflow: 'hidden' }}>
          <Header activeView={activeView} setActiveView={setActiveView} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
