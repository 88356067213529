import React, { useEffect, useState } from 'react';
import '../Marketplace.css';
import FilterDropdown, { FilterSetting } from '../FilterDropdown';
import PublishedCaseModal from './PublishedCases/PublishedCaseModal';
import MyCaseModal from './MyCases/MyCasesModal';
import ProfileEditingModal, { ProfileEditingModalProps } from './Admin/ProfileEditingModal';
import ReviewsComponent from './Admin/LawyerRating';
import MyLegalDocuments from './Admin/MyLegalDocuments';
import LawyerFinance from './Admin/LawyerFinance';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { ActiveTasks, CompleteTask, CreateTask, DeleteTask, EditTask, GetAllTasks} from '../../../store/actions/marketplace/TasksActions';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { fetchCaseList, fetchLawyers, fetchPublishedCases, fetchUrgencyList } from '../../../store/actions/marketplace/caseActions';
import { useTranslation } from 'react-i18next';
import { ICaseItem } from '../Interfaces/ICaseItem';
import { fetchRatings } from '../../../store/actions/marketplace/lawyerAction';
import { LawyerData } from '../Client/ClientSection';
import GlobalDropdown from '../../shared/GlobalDropdown';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { hideModal, showModal} from '../../../store/actions/modalActions';
import { ClipLoader } from 'react-spinners';
import { UserProps } from '../../Settings/AccountSettings/PersonalInfoAndProfile';
import { EditUser, userInformation } from '../../../store/actions/DataAction';
import AdminProfileSection from './Admin/AdminProfileSection';
import { getAllCases } from '../../../store/actions/LawfirmActions';
import { IAdminCaseItem } from '../Interfaces/IAdminCaseItem';
import { selectCaseForViewing, setDetailsContent } from '../../../store/actions/marketplace/activeViewActions';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Lawyer } from '../../Dashboard/AdminFirm/interface/IFirmLawyer';
import EmployeeView from '../../Dashboard/AdminFirm/FirmComponents/EmployeeView';

dayjs.extend(customParseFormat);

export interface CaseItem {
  id: number;
  jurisdiction: string;
  urgency: string;
  caseType: string;
  preferredLanguage: string;
  amount: string;
  description: string;
  desiredOutcome: string;
  expectedOutcome: string;
  status: string;
  nextTask: string;
  statusColor: string;
  bids: Bid[];
  finalAgreement: string;
}


export interface Bid {
  bidAmount: number;
  bidDateTime: string;
  rateType: string;
  estimatedHours: number | null;
  approval?: string;
  lawyerMessage: string;
  approvalDateTime: string;
}


export type Task = {
  CompletionDate: string,
  CreationDate: string,
  ModificationDate: string,
  Status: string,
  TaskDeadline: string,
  TaskDescription: string,
  TaskID: string,
  TaskTitle: string,
  UserID: string,
  UserType: string
};

export type DataValue = {
  TaskDeadline: string,
  TaskDescription: string,
  TaskTitle: string,
  // UserID: string,
  UserType: string,
  CaseID?: string
  Assignees?: string[]
};

export interface PublishedCaseItem {
  id: number;
  jurisdiction: string;
  urgency: string;
  caseType: string;
  preferredLanguage: string;
  amount: string;
  description: string;
  desiredOutcome: string;
  expectedOutcome: string;
}

export interface FormDataProfile {
  hourlyRate?: string;
  biography?: string;
  profilePicture?: File | null;
}


type LawyerSectionProps = {
  activeView?: string
}

const LawyerSection: React.FC<LawyerSectionProps> = ({ activeView }) => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const lawyers = useSelector((state: RootState) => state.lawyers.lawyerList)
  const [profile, setProfile] = useState<LawyerData | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedPublishedCase, setSelectedPublishedCase] = useState<ICaseItem | null>(null);
  const [selectedMyCase, setSelectedMyCase] = useState<string | null>(null);
  const selectedCase = useSelector((state: RootState) => state.activeView.selectedCase);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const detailsContent = useSelector((state: RootState) => state.activeView.detailsContent)
  const publishedCases = useSelector((state: RootState) => state.lawyers.publishedCases)
  const userType = getUserType();
  const userId = getUserID();
  const TODO_LIST = useSelector((state: RootState) => state.toDo.ToDoList) || []
  const TASK_DETAIL = useSelector((state: RootState) => state.toDo.TaskDetail)
  const [isEditing, setIsEditing] = useState(false);
  const caseList = useSelector((root: RootState) => root.case.caseList)
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState<FilterSetting[]>([['none', t('No filters')]]);
  const [activeMyCaseFilters, setActiveMyCaseFilters] = useState<FilterSetting[]>([['none', t('Last Interaction')]]);
  const lawFirmCases = useSelector((state: RootState) => state.lawfirm.cases)
  const [filteredCases, setFilteredCases] = useState(publishedCases || []);
  const [editMode, setEditMode] = useState(false);
  const [formDataProfile, setFormDataProfile] = useState<FormDataProfile>({});
  const [selectedEmployee, setSelectedEmployee] = useState<Lawyer>();
  const [filteredCaseList, setFilteredCaseList] = useState<ICaseItem[] | IAdminCaseItem[]>([]);
  const { path } = useRouteMatch();
  const location = useLocation();
  
  useEffect(() => {
    if (userType === 'LawfirmAdmin' && userId) {
      dispatch(getAllCases(userId)); // Fetch law firm cases
    } else {
      dispatch(fetchCaseList()); // Fetch personal cases
    }
  }, [dispatch, userType, userId]);
  

  useEffect(() => {
    if (location.pathname === '/marketplace/lawyer/cases') {
      // Check for user type and set the filtered case list accordingly
      if (userType === 'LawfirmAdmin') {
        setFilteredCaseList(lawFirmCases || []); // Set company cases
      } else {
        setFilteredCaseList(caseList || []); // Set personal cases
      }
    }
  }, [location.pathname, lawFirmCases, caseList, userType]);
  


  const applyFilters = (filters: Record<string, string | number>) => {
    if (!publishedCases) return;

    let filtered = publishedCases.filter((item: ICaseItem) => {
      return (!filters.country || item.CountryOfJurisdiction === filters.country) &&
        (!filters.caseType || item.CaseType === filters.caseType) &&
        (!filters.language || item.PreferredLanguage === filters.language) &&
        (!filters.urgency || item.Urgency === filters.urgency) &&
        (!filters.minBudget || parseInt(item.MinBudget) >= Number(filters.minBudget)) &&
        (!filters.maxBudget || parseInt(item.MaxBudget) <= Number(filters.maxBudget));
    });
    setFilteredCases(filtered);

    const active = Object.entries(filters).filter(([key, value]) => value).map(([key, value]) => [key, value.toString()] as FilterSetting);
    setActiveFilters(active && active.length > 0 ? active : [['none', t('No filters')]]);
  };

  const applyCaseFilters = (filters: Record<string, string | number>) => {
    const listToFilter = userType === 'LawfirmAdmin' ? lawFirmCases : caseList;
    if (!listToFilter) return;

    const filtered = listToFilter.filter((item: ICaseItem | IAdminCaseItem) => {
      const isAdminCase = 'ClientMaxBudget' in item;
      const minBudget = isAdminCase ? parseInt(item.ClientMinBudget) : parseInt(item.MinBudget);
      const maxBudget = isAdminCase ? parseInt(item.ClientMaxBudget) : parseInt(item.MaxBudget);

      return (!filters.country || item.CountryOfJurisdiction === filters.country) &&
        (!filters.caseType || item.CaseType === filters.caseType) &&
        (!filters.language || item.PreferredLanguage === filters.language) &&
        (!filters.urgency || item.Urgency === filters.urgency) &&
        (!filters.minBudget || minBudget >= Number(filters.minBudget)) &&
        (!filters.maxBudget || maxBudget <= Number(filters.maxBudget));
    });
    
    setFilteredCaseList(filtered);

    const active = Object.entries(filters)
      .filter(([key, value]) => value)
      .map(([key, value]) => [key, value.toString()] as FilterSetting);
    setActiveMyCaseFilters(active && active.length > 0 ? active : [['none', 'Last Interaction']]);
  };
  useEffect(() => {
    if (location.pathname === "/marketplace/lawyer/market") {
      setFilteredCaseList([]); 
    } else if (location.pathname === '/marketplace/lawyer/cases') {
      setFilteredCases([]); 
    }
  }, [location.pathname]);


  useEffect(() => {
    if (location.pathname === '/marketplace/lawyer/market') {
      setFilteredCaseList([]); 
      setFilteredCases(publishedCases);
    }
  }, [location.pathname, publishedCases]);

  useEffect(() => {

    if (!lawyers.length) {
      dispatch(fetchLawyers());
    }
  }, [dispatch, lawyers.length]);

  useEffect(() => {
    dispatch(fetchUrgencyList());
  }, [dispatch])

  useEffect(() => {
    if (activeView === 'profile') {
      setIsLoading(true)
      dispatch(ActiveTasks(userId, userType))
      dispatch(GetAllTasks(userId, userType))
      dispatch(userInformation(userType))
      dispatch(setDetailsContent("TASK_TODO_LIST"))
      setIsLoading(false)
    } else {
      dispatch(setDetailsContent("NONE"))

    }
    if (userType !== 'LawfirmAdmin') {
    dispatch(fetchCaseList())
    }
    setIsAddingTask(false)
  }, [activeView, dispatch,userId, userType])


  useEffect(() => {
    dispatch(fetchRatings(userId, userType));
  }, [dispatch,userId, userType]);

  useEffect(() => {
    dispatch(fetchPublishedCases());
  }, [dispatch]);


  const handleStartAddTask = () => {
    setIsAddingTask(!isAddingTask);
  };

  const validationSchemaProfile = Yup.object({
    FirstName: Yup.string()
      .required('First name is required')
      .max(50, 'First name cannot be longer than 50 characters'),
    LastName: Yup.string()
      .required('Last name is required')
      .max(50, 'Last name cannot be longer than 50 characters'),
    PhoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^\+?[1-9]\d{1,14}$/, 'Phone number is not valid'),
    StreetAddress: Yup.string()
      .required('Street address is required'),
    Email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    Biography: Yup.string()
      .max(500, 'Biography cannot exceed 500 characters'),
    HourlyRate: Yup.number()
      .positive('Hourly rate must be positive')
      .required('Hourly rate is required'),
    ProfilePicture: Yup.mixed()
      .nullable()
      .notRequired()
    
  });




  const formikProfile = useFormik({
    initialValues: {
      FirstName: userInfo.FirstName,
      LastName: userInfo.LastName,
      PhoneNumber: userInfo.PhoneNumber,
      StreetAddress: userInfo.StreetAddress,
      Email: userInfo.Email,
      Biography: userInfo.Biography,
      HourlyRate: userInfo.HourlyRate,
      ProfilePicture: userInfo.ProfilePicture || '', 
      City: userInfo.City,
      Country: userInfo.Country,
      State: userInfo.State,
      DateOfBirth: userInfo.DateOfBirth,
      CountryOfJurisdiction: userInfo.CountryOfJurisdiction
    },
    validationSchema: validationSchemaProfile,
    onSubmit: async (values: UserProps) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("UserID", userId)
      formData.append("UserType", userType)
      formData.append("Country", userInfo?.Country ?? "")

      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "" && key !== 'ProfilePicture') {
          formData.append(key, value.toString());
        }
      });

      if (values.ProfilePicture && values.ProfilePicture.length > 0) {
        formData.append("ProfilePicture", values.ProfilePicture);
      }

      const resp = await dispatch(EditUser(formData, onSuccess));
      if (resp) {
        setIsLoading(false);
        setFormDataProfile(prevState => ({
          ...prevState,
          firstName: userInfo.FirstName,
          lastName: userInfo.LastName,
          phoneNumber: userInfo.PhoneNumber,
          streetAddress: userInfo.StreetAddress,
          email: userInfo.Email,
          zipCode: userInfo.ZipCode,
          biography: userInfo.Biography,
          hourlyRate: userInfo.HourlyRate,
          profilePicture: userInfo.ProfilePicture,
          city: userInfo.City ? userInfo.City : "",
          country: userInfo.Country ? userInfo.Country : "",
          state: userInfo.State ? userInfo.State : "",
          dob: userInfo.DateOfBirth

        }));
        setIsEditing(false);
      } else {
        setIsLoading(false);
      }
    },
  });

  const onSuccess = () => {
    setEditMode(false);
  };

  const getValidateSchema = () => {
    let SchemFields = {
      TaskTitle: Yup.string().required(t("Title for the task required")),
      TaskDescription: Yup.string().required(t("Description needed")),
      TaskDeadline: Yup.string().required(t("Deadline for the task required")),
      CaseID: Yup.string().notRequired()
    }

    return Yup.object().shape(SchemFields)
  }

  const getEditValidateSchema = () => {
    let SchemFields = {
      TaskTitle: Yup.string().required(t("Title for the task required")),
      TaskDescription: Yup.string().required(t("Description needed")),
      TaskDeadline: Yup.string().required("Deadline for the task required"),
      TaskID: Yup.string().required(t("Task id is required"))
    }

    return Yup.object().shape(SchemFields)
  }

  let validationSchema

  if (isEditing) {
    validationSchema = getEditValidateSchema()
  } else {
    validationSchema = getValidateSchema()
  }

  const formik = useFormik({
    initialValues: {
      TaskTitle: "",
      TaskDescription: "",
      TaskDeadline: "",
      CaseID: ""
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const dataValues: DataValue = {
        // UserID: userId,
        UserType: userType,
        ...values
      }

      if (dataValues.CaseID === "") {
        delete dataValues["CaseID"]
      }

      dispatch(CreateTask(dataValues))
      dispatch(ActiveTasks(userId, userType))
      setIsAddingTask(false);
      resetForm()

    }
  })



  const formikEdit = useFormik({
    initialValues: {
      TaskTitle: TASK_DETAIL.TaskTitle,
      TaskDescription: TASK_DETAIL.TaskDescription,
      TaskDeadline: TASK_DETAIL.TaskDeadline,
      TaskID: TASK_DETAIL.TaskID
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {

      const dataValues = {
        UserID: userId,
        UserType: userType,

        ...values
      }

      dispatch(EditTask(dataValues))
      dispatch(ActiveTasks(userId, userType))
      setIsEditing(false);
      resetForm()

    }
  })

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleViewListClick = () => {
    dispatch(setDetailsContent('TASK_TODO_LIST'))
  };


  const TaskListModal = () => {
    const [isHistoryShown, setIsHistoryShown] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);

    const ACTIVE_TODO_LIST = useSelector((state: RootState) => state.toDo.activeTasks);

    const toggleTaskHistory = () => {
      setIsHistoryShown(!isHistoryShown)
    }

    const handleRemoveTask = (taskId: string) => {
      setSelectedTaskId(taskId); // Set the task ID to state
      dispatch(showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this task?'),
        onConfirm: () => handleConfirmDelete(taskId),
        onCancel: handleCancel,
        showModal: true
      }));
    };

    const handleCompleteTask = async (id: string) => {
      setIsLoading(true);
      await dispatch(CompleteTask(userType, userId, id));
      await dispatch(ActiveTasks(userId, userType));
      await dispatch(GetAllTasks(userId, userType))
      setIsLoading(false);
    };


    const handleConfirmDelete = async (taskId?: string) => {
      if (taskId) {
        setIsLoading(true);
        await dispatch(DeleteTask(userType, userId, taskId));
        await dispatch(ActiveTasks(userId, userType));
        setIsLoading(false);
        setSelectedTaskId(null);
        dispatch(hideModal());
      }
    };


    const handleCancel = () => {
      setSelectedTaskId(null); // Reset the selected task ID
      dispatch(hideModal());
    };

    return (

      <div className="task-list-modal-content">

        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <ClipLoader color="#fff" loading={isLoading} size={30} />
          </div>
        ) : isEditing ? (
          <div className='task-detail-input'>
            <h3>{TASK_DETAIL.TaskTitle}</h3>
            <div className="detail-item">
              <input
                type="text"
                defaultValue={formikEdit.values.TaskTitle}
                onBlur={formikEdit.handleBlur("TaskTitle")}
                onChange={formikEdit.handleChange("TaskTitle")}
              />
            </div>

            <div className="detail-item">
              <input
                type="text"
                defaultValue={formikEdit.values.TaskDescription}
                onBlur={(e) => formikEdit.handleBlur("TaskDescription")}
                onChange={(e) => formikEdit.handleChange("TaskDescription")}

              />
            </div>

            <div className="detail-item">
              <input
                type="text"
                defaultValue={formikEdit.values.TaskDeadline}
                onBlur={(e) => formikEdit.handleBlur("TaskDeadline")}
                onChange={(e) => formikEdit.handleChange("TaskDeadline")}

              />
            </div>
            <div className='button-div'>
              <button type='submit' onClick={() => formikEdit.handleSubmit()}>{t('Edit')}</button>
              <button onClick={() => setIsEditing(false)}>{t('Back')}</button>
            </div>
          </div>
        ) :

          !isHistoryShown ? (
            <div key={new Date().getTime()} className="current-tasks">

                <div className='current-tasks--header'>
                <h3>{t('Current Tasks')}</h3>
                  <button className='add-task__button' onClick={handleStartAddTask}>{t('Add New Task')}</button>
                </div>
                <div className='task-list-wrapper current'>
                  {ACTIVE_TODO_LIST && (
                    ACTIVE_TODO_LIST.length > 0 ? (ACTIVE_TODO_LIST.map((task: Task) => (

                      <div key={task.TaskID} className="task-item">
                        <div className='task-item__top'>
                          <p className='task-item__title'>{task.TaskTitle}</p>
                          <p className='task-item__description'>{task.TaskDescription}</p>
                          <p className='task-item__deadline' title='Deadline Date'>{task.TaskDeadline}</p>
                        </div>
                        <div className="task-actions">
                          <button className="icon-button" onClick={() => handleCompleteTask(task.TaskID)} title="Complete Task">
                            <i className={`fa${task.Status === "Completed" ? 's' : 'r'} fa-check-circle`}></i>
                          </button>
                          <button className="icon-button delete" onClick={() => handleRemoveTask(task.TaskID)} title="Remove Task">
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    ))
                    ) : (
                      <p className='non-access'>{t('No Tasks Available')}</p>
                    )

                  )}


              </div>
              <button className='toggle-tasklist' onClick={toggleTaskHistory}>{isHistoryShown ? <p>{t("Current")}</p> : <p>{t('History')}</p>}</button>

            </div>
          ) : (

            <div className="completed-tasks">
                <h3>{t('Completed Tasks')}</h3>
                <div className='task-list-wrapper'>
                  {TODO_LIST.filter((task: Task) => task.Status === "Completed").length > 0 ? (
                    TODO_LIST && TODO_LIST.filter((task: Task) => task.Status === "Completed").map((task: Task) => (
                      <div key={task.TaskID} className="task-item">
                        <div className='task-item__top'>
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <p className='task-item__title'>{task.TaskTitle}</p>
                            <p className='task-item__deadline' style={{ color: '#fff', backgroundColor: '#111' }} title='Date Completed'>{task.CompletionDate}</p>
                          </div>
                          <p className='task-item__description'>{task.TaskDescription}</p>
                          <p className='task-item__deadline' title='Deadline Date'>{task.TaskDeadline}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className='non-access'>{t('No completed tasks available.')}</p>
                  )}
              </div>
              <button className='toggle-tasklist' onClick={toggleTaskHistory}>{isHistoryShown ? <p>{t('Current')}</p> : <p>{t('History')}</p>}</button>
            </div>
          )}
      </div>

    );
  };


  const handleProfileSave = (updatedProfile: ProfileEditingModalProps['profile']) => {
    setProfile(updatedProfile);
  };



  const handlePublishedCaseClick = (caseItem: any) => {
    setSelectedPublishedCase(caseItem);
    dispatch(setDetailsContent('PUBLISHED_CASE_DETAILS'))

  };

  const handleMyCaseClick = (caseItem: ICaseItem | IAdminCaseItem) => {
    const caseId = isIAdminCaseItem(caseItem) ? caseItem.CaseID : caseItem.id;
    setSelectedMyCase(caseId);
    dispatch(selectCaseForViewing(caseItem));
    dispatch(setDetailsContent('MY_CASE_DETAILS'));
  };
  
  

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Published': return '#1b8ed0';
      case 'Pending': return '#FF9800';
      case 'Ongoing': return '#249e24';
      case 'Completed': return '#222';
      case 'Off Market': return '#9E9E9E';
      default: return '#9E9E9E';
    }
  };

  const formatStatus = (status: string): string => {
    return status.replace(/([A-Z])/g, ' $1').trim();
  };
  
  const cancelTask = () => {
    setIsAddingTask(false);
  };

  const handleReviewsClick = () => {
    dispatch(setDetailsContent('REVIEWS'))
  }

  const handleDocumentsClick = () => {
    dispatch(setDetailsContent('MY_LEGAL_DOCUMENTS'))
  }

  const handleFinanceClick = () => {
    dispatch(setDetailsContent('LAWYER_FINANCE'))
  }


  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const formatNumber = (value: string) => {
    return value.toString().replace(/\B(?=(\d{3})+\b)/g, " ");
  };

  function checkOverflowAndSetTooltip(element: HTMLElement) {
    if (element.offsetWidth < element.scrollWidth) {
      element.title = element.innerText;  
    } else {
      element.title = '';  
    }
  }




  useEffect(() => {
    const nameElement = document.querySelector('.lawyer-profile__name-location h2') as HTMLElement;
    const occupationElement = document.querySelector('.profile-details__occupation') as HTMLElement;

    if (nameElement) checkOverflowAndSetTooltip(nameElement);
    if (occupationElement) checkOverflowAndSetTooltip(occupationElement);
  }, [userInfo]);

  const isICaseItem = (item: ICaseItem | IAdminCaseItem): item is ICaseItem => {
    return (item as ICaseItem).AttachmentsLink !== undefined;
  };
  
  const isIAdminCaseItem = (item: ICaseItem | IAdminCaseItem): item is IAdminCaseItem => {
    return (item as IAdminCaseItem).ClientMaxBudget !== undefined;
  };
  
  // Render function for a case item
  const renderCaseItem = (caseItem: ICaseItem | IAdminCaseItem) => {
    // Use CaseID as the unique identifier for both regular and admin cases
    const caseId = caseItem.CaseID;
    
    // Determine if the current item is selected
    const isSelected = selectedMyCase === caseId;
  
    // Handle click to set the selected case
    const handleClick = () => {
      setSelectedMyCase(caseId);  // Update selected case ID
      handleMyCaseClick(caseItem); 
    };
  
    // Admin Case Rendering
    if (isIAdminCaseItem(caseItem)) {
      const adminCase = caseItem;
      return (
        <div
          key={adminCase.CaseID}
          className={`my-case-item ${isSelected ? 'selected-case' : ''}`}
          onClick={handleClick}
        >
          <div className='lcm-list__row'>
            <div>
              <h3 className="lcm-type">{t(adminCase.CaseType)}</h3>
              <p style={{ color: '#ccc' }}>{adminCase.CountryOfJurisdiction}</p>
            </div>
            <div style={{ borderRadius: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: '#222' }}>
              <p className='lcm-status' style={{ color: getStatusColor(adminCase.CaseStatus) }}>{t(formatStatus(adminCase.CaseStatus))}</p>
            </div>
          </div>
          <div className='lcm-item__status-urgency'>
            <p className="lcm-price-range">{formatNumber(adminCase.ClientMinBudget)} - {formatNumber(adminCase.ClientMaxBudget)} {adminCase.LawyerBudgetCurrency}</p>
            <div style={{ borderWidth: 3, borderStyle: 'solid', borderColor: '#444', backgroundColor: '#444', borderRadius: 5, paddingLeft: 10, paddingRight: 10 }}>
              <div className='lcm-urgency'>
                <p style={{ color: '#64d2ff'}}>{t(adminCase.Urgency)}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    // Regular Case Rendering
    if (isICaseItem(caseItem)) {
      const normalCase = caseItem;
      return (
        <div
          key={normalCase.CaseID} // Ensure correct usage of unique CaseID
          className={`my-case-item ${isSelected ? 'selected-case' : ''}`} 
          onClick={handleClick}
        >
          <div className='lcm-list__row'>
            <div>
              <h3 className="lcm-type">{t(normalCase.CaseType)}</h3>
              <p style={{ color: '#ccc' }}>{normalCase.CountryOfJurisdiction}</p>
            </div>
            <div style={{ borderRadius: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: '#222' }}>
              <p className='lcm-status' style={{ color: getStatusColor(normalCase.Status) }}>{t(formatStatus(normalCase.Status))}</p>
            </div>
          </div>
          <div className='lcm-item__status-urgency'>
            <p className="lcm-price-range">{formatNumber(normalCase.MinBudget)} - {formatNumber(normalCase.MaxBudget)} {normalCase.Currency}</p>
            <p className='lcm-urgency'>{t(normalCase.Urgency)}</p>
          </div>
        </div>
      );
    }
  
    return null;
  };
  const formattedCaseOptions = caseList.map((caseItem: ICaseItem) => ({
    label: `${caseItem.CaseType}`,
    id: caseItem.CaseID
  }));

  const handleSelectEmployee = (employee: Lawyer) => {
    setSelectedEmployee(employee)
    dispatch(setDetailsContent('EMPLOYEE_VIEW'))
};

  return (
    <>
      <div className="lawyer-section">
        <div className='marketplace-container'>
        <Switch>
        <Route exact path={`${path}/market`}>
            <div className="published-cases">
              <div>
                <div className='published-cases__header'>
                  <h2>{t('Published Cases')}</h2>
                  <div style={{ position: 'relative' }}>
                    <button onClick={toggleDropdown}>
                      <p>{t('Filters')}</p>
                      <i className='fa-regular fa-bars-filter'></i>
                    </button>
                    {isDropdownOpen && (
                      <FilterDropdown applyFilters={applyFilters} toggleDropdown={toggleDropdown} />
                    )}
                  </div>
                </div>
                <div className="active-filters">
                  {activeFilters && activeFilters.map(([key, value], index) => (
                    <div key={index} className="filter-tag" title={key}>
                      {key === "minBudget" ? `Min ${formatNumber(value)}` : ""}
                      {key === "maxBudget" ? `Max ${formatNumber(value)}` : ""}
                      {key !== "minBudget" && key !== "maxBudget" ? value : ""}
                    </div>
                  ))}
                </div>

              </div>


              <div className="published-cases-list">
                {filteredCases && filteredCases.length > 0 ? (
                  filteredCases.map((caseItem: ICaseItem, index: number) => (
                    <div key={index} className={`published-case-item ${selectedPublishedCase?.CaseID === caseItem.CaseID ? "selected-case" : ""}`} onClick={() => handlePublishedCaseClick(caseItem)}>
                      <div className='published-case__details--top' >
                        <h3 className='lcm-type'>{t(caseItem.CaseType)}</h3>
                        <p className="lcm-price-range">{formatNumber(caseItem.MinBudget)} - {formatNumber(caseItem.MaxBudget)} {caseItem.Currency}</p>
                      </div>
                      <div className='published-case__details--bottom'>

                        <p className='published-case-jurisdiction' >{caseItem.CountryOfJurisdiction}</p>

                        <p className="lcm-urgency">{t(caseItem.Urgency)}</p>

                      </div>
                    </div>
                  ))
                ) : (
                  <div className='no-cases-available'>
                    <p className='non-access'>{t('No cases')}</p>
                  </div>
                )}
              </div>
            </div>
            <div style={{ overflowY: (detailsContent === "PUBLISHED_CASE_DETAILS" || detailsContent === "LAWYER_PROFILE" || detailsContent === 'MY_LEGAL_DOCUMENTS' || detailsContent === 'LAWYER_FINANCE' || detailsContent === 'EMPLOYEE_VIEW') ? "auto" : undefined }} className="details-container">
         
         {detailsContent === 'PUBLISHED_CASE_DETAILS' && selectedPublishedCase && (
           <PublishedCaseModal
             isOpen={!!selectedPublishedCase}
             onClose={() => setSelectedPublishedCase(null)}
             caseDetails={selectedPublishedCase}
           />
         )}
                       {!selectedPublishedCase && (
                    <div className='no-action-selected__container'>
                      <p className='non-access'>{t('No case selected')}</p>
                    </div>
                  )}
         </div>
            </Route>

            <Route exact path={`${path}/cases`}>
            <>
              <div className="my-cases">
                <div>
                  <div className='my-cases__header'>

                  {userType === "LawfirmAdmin" ? (
                        <h2>{t("Law Firm Cases")}</h2>
                      ) : (
                      <h2>{t("My Cases")}</h2>
                    )}
                    <div className='published-cases__header'>
                      <div style={{ position: 'relative' }}>
                        <button onClick={toggleDropdown}>
                          <p>{t('Filters')}</p>
                          <i className='fa-regular fa-bars-filter'></i>
                        </button>
                        {isDropdownOpen && userType === "LawfirmAdmin" ? (
                          <FilterDropdown applyFilters={applyCaseFilters} toggleDropdown={toggleDropdown} />
                        ) : (isDropdownOpen) && (
                          // <FilterDropdown applyFilters={applyCaseFilters} toggleDropdown={toggleDropdown} />
                          <></>

                        )}
                      </div>
                    </div>

                  </div>
                  <div className="active-filters">
                    {activeMyCaseFilters && activeMyCaseFilters.length > 0 && activeMyCaseFilters.map(([key, value], index) => (
                      <div key={index} className="filter-tag" title={key}>
                        {key === "minBudget" ? `Min ${formatNumber(value)}` : ""}
                        {key === "maxBudget" ? `Max ${formatNumber(value)}` : ""}
                        {key !== "minBudget" && key !== "maxBudget" ? value : ""}
                      </div>
                    ))
                    }
                  </div>
                </div>

                <div className="my-cases-list">

                  {filteredCaseList && filteredCaseList.length > 0 ? (filteredCaseList.map((caseItem) => (
                        renderCaseItem(caseItem)
                  ))) : (
                    <div className='no-cases-available'>
                      <p className='non-access'>{t('No cases')}</p>
                    </div>
                  )

                  }

                </div>
                
              </div>
              <div style={{ overflowY: (detailsContent === "PUBLISHED_CASE_DETAILS" || detailsContent === "LAWYER_PROFILE" || detailsContent === 'MY_LEGAL_DOCUMENTS' || detailsContent === 'LAWYER_FINANCE') ? "auto" : undefined }} className="details-container">
              {detailsContent === 'MY_CASE_DETAILS' && selectedCase && (
                <MyCaseModal
                isOpen={!!selectedCase}
                onClose={() => dispatch(selectCaseForViewing(selectedCase))}
                caseDetails={selectedCase}
                />
              )}
              {!selectedCase && (
                    <div className='no-action-selected__container'>
                      <p className='non-access'>{t('No case selected')}</p>
                    </div>
                  )}

              </div>
            </>
            </Route>


            <Route exact path={`${path}/profile`}>
            <AdminProfileSection handleSelectEmployee={handleSelectEmployee} handleDocumentsClick={handleDocumentsClick} handleFinanceClick={handleFinanceClick} handleReviewsClick={handleReviewsClick} handleViewListClick={handleViewListClick} userInfo={userInfo} />
            <div style={{ overflowY: (detailsContent === "PUBLISHED_CASE_DETAILS" || detailsContent === "LAWYER_PROFILE" || detailsContent === 'EMPLOYEE_VIEW' || detailsContent === 'MY_LEGAL_DOCUMENTS' || detailsContent === 'LAWYER_FINANCE') ? "auto" : undefined }} className="details-container">
            {
              isAddingTask ? (
                <div className="add-task-container" >
                  <div className='add-task__wrapper'>
                    <h2>{t('Add New Task')}</h2>
                    <div className='add-task__bottom'>
                      <div className='add-task-div'>
                        <label htmlFor="title">{t('Title')}</label>
                        <input
                          type="text"
                          name="TaskTitle"
                          placeholder={t("Enter task title...")}
                          value={formik.values.TaskTitle}
                          onChange={formik.handleChange("TaskTitle")}
                          onBlur={formik.handleBlur("TaskTitle")}
                          className="new-task-input"
                        />
                        {formik.errors?.TaskTitle && formik.touched.TaskTitle && <div className="error-message-display">{formik.errors?.TaskTitle}</div>}
                      </div>
                      <div className='add-task-div'>
                        <label htmlFor="case">{t('Case')}</label>
                        <GlobalDropdown
                            options={formattedCaseOptions}
                            onSelect={(id) => formik.setFieldValue("CaseID", id)}
                            selectedLabel={formattedCaseOptions.find((option: any) => option.value === formik.values.CaseID)?.label || ''}
                            style={{ width: '100%' }}
                            name='CaseID'
                          />
                        {formik.errors.CaseID && formik.values?.CaseID === "" && <div className="error-message-display">Please select a case</div>}

                      </div>
                    </div>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }}>
                      <label htmlFor="deadline">{t('Deadline')}</label>
                      <ThemeProvider theme={darkTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label=""
                        ampm={false}
                        value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
                        name="TaskDeadline"
                        onChange={(newValue: Dayjs | null) => {
                          if (newValue) {
                            formik.setFieldValue('TaskDeadline', newValue.format("DD-MM-YYYY HH:mm"));
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                      {formik.errors?.TaskDeadline && formik.touched.TaskDeadline && <div className="error-message-display">{formik.errors?.TaskDeadline}</div>}

                    </div>

                    <label htmlFor="description">{t('Description')}</label>
                    <textarea
                      name="TaskDescription"
                      placeholder={t("Enter task description...")}
                      value={formik.values.TaskDescription}
                      onChange={formik.handleChange("TaskDescription")}
                      className="new-task-input description"

                    />
                    {formik.errors?.TaskDescription && formik.touched.TaskDescription && <div className="error-message-display">{formik.errors?.TaskDescription}</div>}

                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%", gap: 10, marginBottom: 50 }}>
                    <button type='submit' onClick={() => { formik.handleSubmit(); }} className='primary-button'>
                      {t('Add')}
                    </button>
                    <button onClick={cancelTask} className='add-task__action--button cancel' >
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              ) : (

                <>
                  {detailsContent === 'NONE' && (
                    <div className='no-action-selected__container'>
                      <p className='non-access'>{t('No action selected')}</p>
                    </div>
                  )}

                  {detailsContent === 'TASK_TODO_LIST' && (
                    <TaskListModal />
                  )}
                  {detailsContent === 'REVIEWS' && (
                    <ReviewsComponent

                    />
                  )}
                  {detailsContent === 'MY_LEGAL_DOCUMENTS' && (
                    <MyLegalDocuments
                    />

                  )}

                  {detailsContent === 'LAWYER_FINANCE' && (
                    <LawyerFinance />
                  )}

                  {detailsContent === 'EMPLOYEE_VIEW' && (
                    <div style={{ padding: 5 }}>
                    <EmployeeView setSelectedMyCase={setSelectedMyCase} employee={selectedEmployee!} isAddingTaskPanelOpen={false} setIsAddingTaskPanelOpen={function (isAddingTaskPanelOpen: boolean): void {
                              throw new Error('Function not implemented.');
                            } }  />
                    </div>
                  )}
                  </>
              )}
                  </div>
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default LawyerSection;