
import { AnyAction, Dispatch } from 'redux';
import { ModalState } from '../reducer/modalReducer';
import { FolderProps } from '../../components/Documents/Files';
import { EditUser, userInformation } from './DataAction';
import { UserProps } from '../../components/Settings/AccountSettings/PersonalInfoAndProfile';
import { showFeedbackModal } from './UserFeedbackActions';
import { DropdownOption } from '../../components/shared/GlobalDropdown';

export const showModal = (payload: ModalState): AnyAction => ({
  type: 'SHOW_MODAL',
  payload,
});

export const hideModal = (): AnyAction => ({
  type: 'HIDE_MODAL',
});


export const showShareModal = (emailSubmitHandler: (email: string) => void) => {
  return showModal({
    type: 'share',
    message: 'Enter the email address to share the contract',
    onConfirm: (data?: string | File) => {
      if (typeof data === 'string') {
        emailSubmitHandler(data);
      } else {

      }
    },
    onCancel: () => { },
    showModal: true,
  });
};


export const showSignModal = (signSubmitHandler: (signature: string) => void) => {
  return showModal({
    type: 'sign',
    message: 'Select a contract to sign',
    onConfirm: (data?: string | File) => {
      if (typeof data === 'string') {
        signSubmitHandler(data);
      } else {
      }
    },
    onCancel: () => { },
    showModal: false,
  });
};

export const showAddTaskModal = () => {
  return showModal({
    type: 'addTask',
    message: '',
    onConfirm: () => { },
    onCancel: () => { },
    showModal: false,
    width: '50%'
  });
};

export const showCreateFolderModal = (handleCreateFolder?: (folderName: string) => void, FolderID?: string, handleCreateSubFolder?: (FolderName: string, FolderID: string) => void) => {
  return showModal({
    type: 'create',
    message: 'Create New Folder',
    onConfirm: (folderName?: string | File) => {
      if (typeof folderName === 'string') {
        if (FolderID && handleCreateSubFolder) {
          handleCreateSubFolder(folderName, FolderID)
        }
        if (handleCreateFolder) {
          handleCreateFolder(folderName);

        }
      } 
    },
    showModal: true,
    width: '30%'
  });
};

export const showAddFileModal = (folders: FolderProps[], folderId?: string) => {
  return (dispatch: Dispatch) => {
    let folderOptions:any =  [];

    if (folderId) {
      const selectedFolder = folders.find(folder => folder.FolderID.toString() === folderId);
      if (selectedFolder) {
        folderOptions.push(selectedFolder)
      }
    } else {
      folders &&
      folders
        .filter(folder => folder.Status === 'Active' && folder.FolderName !== 'LegalCaseMapping')
        .map(folder => (
        folderOptions.push(folder)
      ));
    }
    if(folderOptions){
      dispatch(showModal({
        type: 'addFile',
        message: '',
        folderOptions: folderOptions,
        preSelectedFolderId: folderId, 
        onConfirm: () => { },
        showModal: true,
        width: '45%'
      }));

    }
  };
};

export const showResetPasswordModal = () => {
  return showModal({
    type: 'forgotPassword',
    message: 'Enter your email to reset your password',
    onConfirm: (email?: string | File) => {
      if (typeof email === 'string') {
      }

    },
    showModal: true,
  });
};

export const showAddSignatureModal = () => {
  return showModal({
    type: 'addSignature',
    message: 'Enter your digital signature.',
    onConfirm: (signature?: File | string) => {
      if (typeof signature === 'object') {
      }
    },
    showModal: true,
  });
};

export const showPasswordModal = (onConfirm: (password: string) => void): AnyAction => ({
  type: 'SHOW_MODAL',
  payload: {
    type: 'password',
    message: 'Please confirm your password to onboard the new employee.',
    onConfirm: (password?: string | File) => {
      if (password && typeof password === 'string') {
        onConfirm(password);
      }
    },
    showModal: true,
  },
});

export const showPasswordProfileModal = (
  userProps: UserProps,
  userId: string,
  userType: string,
  onSuccess: () => void
) => {
  return (dispatch: any) => {
    dispatch(showModal({
      type: 'password',
      message: 'Enter your password',
      subMsg: ('Please confirm your password to update your personal information.'),
      onConfirm: (password?: string | File) => {
        if (password && typeof password === 'string') {
          const formData = new FormData();
          Object.entries(userProps).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
              if (key === 'ProfilePicture' && value instanceof File) {
                formData.append(key, value);
              } else {
                formData.append(key, value.toString());
              }
            }
          });
          formData.append("Password", password);
          formData.append("UserID", userId);
          formData.append("UserType", userType);
          dispatch(EditUser(formData, onSuccess));
          dispatch(userInformation(userType))
        }
      },
      showModal: true,
      width: '35%',
      gap: 10
    }));
  };
};


export const showPasswordProfileLawyerModal = (
  userId: string,
  userType: string,
  prepareFormData: (password: string) => void
) => {
  return (dispatch: any) => {
    dispatch(showModal({
      type: 'password',
      message: 'Please confirm your password to update your personal information.',
      onConfirm: (password?: string | File) => {
        if (password && typeof password === 'string') {
          prepareFormData(password);
        }
      },
      showModal: true
    }));
  };
};



export const ShowPaymentOptions = () => {

  return (dispatch: any) => {
    dispatch(showModal({
      type: 'payment-options',
      message: 'Please select your payment method',
      onConfirm: (password: any) => {
      },
      showModal: true
    }));
  };
};

export const ShowReceiverOptions = () => {

  return (dispatch: any) => {
    dispatch(showModal({
      type: 'receiver-options',
      message: 'Please select your receiver account',
      onConfirm: (password: any) => {
      },
      showModal: true
    }));
  };
};

export const ShowPromptFeedback = () => {
  return (dispatch: any) => {
    return new Promise<string>((resolve) => {
      dispatch(showModal({
        type: 'promptfeedback',
        message: 'Help us get better!',
        onConfirm: (data?: string | File) => {
          if (typeof data === 'string') {
            resolve(data || '');
            dispatch(showFeedbackModal({
              showModal: true,
              message: 'Feedback sent',
              modalType: 'success',
              duration: 3000
            }));
          }
        },
        showModal: true
      }));
    });
  };
};



export const ShowTips = (message: string) => {

  return (dispatch: any) => {
    dispatch(showModal({
      type: 'tips',
      message: 'Tip',
      info: message,
      showModal: true
    }));
  };
};

export const ShowHelp = (title: string, info: string) => {
  return (dispatch: any) => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        showModal: true,
        title,
        info,
        type: 'info'
      }
    });
  };
};