import React, { useState, useEffect } from 'react';
import "./Preferences.css";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getPreferences, updatePreferences } from '../../../store/actions/DataAction';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';
import { changeLanguage } from '../../../i18n/i18n';
import GlobalDropdown, { DropdownOption } from '../../shared/GlobalDropdown';
import { fetchPlatformCurrencies, fetchPlatformLanguages } from '../../../store/actions/DropdownActions';

const PreferencesSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const preferences = useSelector((state: RootState) => state.lists.preferences);
  const platformCurrencies = useSelector((state: RootState) => state.dropdown.platformCurrencies) as DropdownOption[];
  const platformLanguages = useSelector((state: RootState) => state.dropdown.platformLanguages) as DropdownOption[];

  const [selectedLanguage, setSelectedLanguage] = useState<DropdownOption | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownOption | null>(null);

  const userId = getUserID();
  const userType = getUserType();

  useEffect(() => {
    dispatch(getPreferences(userType));
    dispatch(fetchPlatformCurrencies());
    dispatch(fetchPlatformLanguages());
  }, [dispatch, userType]);

  const sortedPlatformLanguages = platformLanguages.sort((a, b) => {
    const aActive = a.active !== false; 
    const bActive = b.active !== false;
    return Number(bActive) - Number(aActive);
  });
  
  useEffect(() => {
    if (preferences) {
      const language = platformLanguages.find(lang => lang.id === preferences.Language.toLowerCase()) || null;
      setSelectedLanguage(language);

      const currency = platformCurrencies.find(curr => curr.id === preferences.Currency) || null;
      setSelectedCurrency(currency);

      if (language) {
        changeLanguage(language.id);
      }
    }
  }, [preferences, platformLanguages, platformCurrencies]);

  const handleLanguageChange = (id: string) => {
    const selectedOption = platformLanguages.find(option => option.id === id);
    if (selectedOption) {
      setSelectedLanguage(selectedOption);
      changeLanguage(selectedOption.id);
      updatePreferencesOnServer({ Language: selectedOption.id });
    }
  };

  const handleCurrencyChange = (id: string) => {
    const selectedOption = platformCurrencies.find(option => option.id === id);
    if (selectedOption) {
      setSelectedCurrency(selectedOption);
      updatePreferencesOnServer({ Currency: selectedOption.id, CurrencyName: selectedOption.label });
    }
  };

  const updatePreferencesOnServer = (updatedFields: Partial<typeof preferences>) => {
    const updatedPreferences = {
      UserID: userId,
      UserType: userType,
      Communication: 'Email',
      Language: updatedFields.Language || (selectedLanguage ? selectedLanguage.id : ''),
      Currency: updatedFields.Currency || (selectedCurrency ? selectedCurrency.id : ''),
      CurrencyName: updatedFields.CurrencyName || (selectedCurrency ? selectedCurrency.label : ''),
      ThemeColor: 'dark',
    };

    dispatch(updatePreferences(updatedPreferences));
  };

  return (
    <div className="preferences-container">
      <div className="preferences-container__toggles">
        <h2>{t('Platform Preferences')}</h2>
        <div className='preferences-options'>

          {/* Language Dropdown */}
          <div className="theme-preference">
            <div className="preference-text">
              <p>{t('Language')}</p>
              <span className="preference-description">{t('Choose your preferred language.')}</span>
            </div>
            <div>
              <GlobalDropdown
                options={sortedPlatformLanguages}
                selectedLabel={selectedLanguage ? selectedLanguage.label : 'Select Language'}
                onSelect={handleLanguageChange}
              />
            </div>
          </div>

          {/* Currency Dropdown */}
          <div className="theme-preference">
            <div className="preference-text">
              <p>{t('Currency')}</p>
              <span className="preference-description">{t('Choose your preferred currency.')}</span>
            </div>
            <div>
              <GlobalDropdown
                options={platformCurrencies}
                selectedLabel={selectedCurrency ? selectedCurrency.label : 'Select Currency'}
                onSelect={handleCurrencyChange}
                headerStyle={{ maxWidth: 150 }}
              />
            </div>
          </div>

          <div className="theme-preference">
            <div className="preference-text">
              <p>{t('Theme')}</p>
              <span className="preference-description">{t('Choose your preferred currency.')}</span>
            </div>
            <div>
              <GlobalDropdown
                options={[]}
                selectedLabel={'Dark'}
                isStatic={true}
                onSelect={() => {}}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PreferencesSection;
